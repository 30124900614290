import {
  Column,
  ContentArea,
  IconText,
  Row,
  Surface,
  Tag
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { type ReactNode } from "react";
import OrganizationRatingRow from "../organization/OrganizationRatingRow";
import type { BrowseShiftHeader_shift$key } from "./__generated__/BrowseShiftHeader_shift.graphql";

type Props = {
  children?: ReactNode;
  onInfo?: () => void;
};

export const BrowseShiftHeader = ({
  name,
  organization,
  primaryContact,
  onInfo,
  requiredReportTypes,
  children
}: FragmentContainerInnerComponentProps<
  BrowseShiftHeader_shift$key,
  Props
>) => {
  const isEOR = requiredReportTypes?.includes?.("EOR_WORKER");

  return (
    <Surface>
      <ContentArea gap="standard">
        <Row alignItems="center" gap="compact">
          <IconText
            icon="business-time"
            size="medium"
            iconAlign="center"
            textVariant="header"
            color={isEOR ? "purple" : "primary"}
            iconColor={isEOR ? "purple" : "primary"}
          >
            {name}
          </IconText>
          {isEOR && (
            <Tag icon="passport" color="purple" variant="clear" label="W-2" />
          )}
        </Row>
        <Column gap="compact">
          <OrganizationRatingRow
            fragmentRef={organization}
            primaryContactName={primaryContact?.displayName}
            onInfo={onInfo}
          />
          {children}
        </Column>
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<BrowseShiftHeader_shift$key, Props>(
  graphql`
    fragment BrowseShiftHeader_shift on GigLike {
      name
      requiredReportTypes
      primaryContact {
        displayName
      }
      organization {
        ...OrganizationRatingRow_org @arguments(skipPrimaryContact: true)
      }
    }
  `,
  BrowseShiftHeader
);
