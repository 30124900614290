/**
 * @generated SignedSource<<3f32c0d967d7495acace35e9965cb864>>
 * @relayHash eacd964fd518a07b0c614108d777a99b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:cwRjwUD0JHv7t0u68xZqqnQVQlp1S79CR3b5gfcL-q8

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
export type availablePickupShiftsListQuery$variables = {
  crisisId?: string | null | undefined;
  gigTypes?: ReadonlyArray<GigType> | null | undefined;
  maxDistance?: number | null | undefined;
  requiredReports?: ReadonlyArray<ReportType> | null | undefined;
  searchTerm?: string | null | undefined;
};
export type availablePickupShiftsListQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"availablePickupShiftsList_availableGigs">;
  } | null | undefined;
};
export type availablePickupShiftsListQuery = {
  response: availablePickupShiftsListQuery$data;
  variables: availablePickupShiftsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "crisisId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gigTypes"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDistance"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requiredReports"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v5 = {
  "kind": "Variable",
  "name": "crisisId",
  "variableName": "crisisId"
},
v6 = {
  "kind": "Variable",
  "name": "gigTypes",
  "variableName": "gigTypes"
},
v7 = {
  "kind": "Variable",
  "name": "maxDistance",
  "variableName": "maxDistance"
},
v8 = {
  "kind": "Variable",
  "name": "requiredReports",
  "variableName": "requiredReports"
},
v9 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "fields": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "kind": "Literal",
        "name": "pickupEligible",
        "value": true
      },
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "availablePickupShiftsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "availablePickupShiftsList_availableGigs"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "availablePickupShiftsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "AvailableGigsConnection",
                "kind": "LinkedField",
                "name": "availableGigs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailableGigsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "distance",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementPaymentInfo",
                        "kind": "LinkedField",
                        "name": "estimatedPayment",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "netPay",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requiredReportTypes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Pickup",
                            "kind": "LinkedField",
                            "name": "pickup",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "eligible",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "windowEnd",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          (v10/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "estimatedMileage",
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "gigType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logoUrl",
                                    "storageKey": null
                                  },
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "acceptsTips",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentType",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "area",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "placeId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isRecurring",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "kind": "LinkedField",
                                    "name": "organization",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigCategory",
                                    "kind": "LinkedField",
                                    "name": "category",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "displayName",
                                        "storageKey": null
                                      },
                                      (v11/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigPosition",
                                    "kind": "LinkedField",
                                    "name": "position",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v11/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "actualStartsAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timezone",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "negotiable",
                                    "storageKey": null
                                  }
                                ],
                                "type": "Gig",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "boosted",
                                    "storageKey": null
                                  }
                                ],
                                "type": "GigSeries",
                                "abstractKey": null
                              }
                            ],
                            "type": "GigLike",
                            "abstractKey": "__isGigLike"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "input"
                ],
                "handle": "connection",
                "key": "availablePickupShiftsList_availableGigs",
                "kind": "LinkedHandle",
                "name": "availableGigs"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:cwRjwUD0JHv7t0u68xZqqnQVQlp1S79CR3b5gfcL-q8",
    "metadata": {},
    "name": "availablePickupShiftsListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a42e6289849e55179f571aab67b4bbf9";

export default node;
