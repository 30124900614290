/**
 * @generated SignedSource<<59015f0adc0e07a0867093f05678a2ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEngagementListOffered_worker$data = {
  readonly engagements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currentState: {
          readonly name: EngagementStateName;
        };
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftAggregateCard_engagement" | "offeredEntry_engagement" | "useEngagementListSeriesAggregate_engagement">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "useEngagementListOffered_worker";
};
export type useEngagementListOffered_worker$key = {
  readonly " $data"?: useEngagementListOffered_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEngagementListOffered_worker">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "engagements"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalDocument",
  "kind": "LinkedField",
  "name": "pendingConsents",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConsentDocumentStepper_documents"
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v6 = [
  (v2/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "GigPosition",
  "kind": "LinkedField",
  "name": "position",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "GigSeries",
    "kind": "LinkedField",
    "name": "gigSeries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 0
          }
        ],
        "concreteType": "GigFieldItemDefinitionsConnection",
        "kind": "LinkedField",
        "name": "unmetQualifications",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": "unmetQualifications(first:0)"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 18,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeGigSeries"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./useEngagementListOfferedPageQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "useEngagementListOffered_worker",
  "selections": [
    {
      "alias": "engagements",
      "args": [
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "__engagementList_engagements_connection",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Engagement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementState",
                  "kind": "LinkedField",
                  "name": "currentState",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "offeredEntry_engagement"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WorkerShiftAggregateCard_engagement"
                },
                {
                  "condition": "includeGigSeries",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "useEngagementListSeriesAggregate_engagement",
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "useApplyMetadataIsEnrolled_gigOrEngagement",
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v4/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Gig",
                                      "kind": "LinkedField",
                                      "name": "gig",
                                      "plural": false,
                                      "selections": (v8/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "Engagement",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v4/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Gig",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": (v8/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "AvailableGigsEdge",
                                  "abstractKey": null
                                }
                              ],
                              "type": "AvailableGigOrEngagement",
                              "abstractKey": "__isAvailableGigOrEngagement"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "workerDistance",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EngagementPaymentInfo",
                          "kind": "LinkedField",
                          "name": "estimatedPayment",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "grossPay",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Gig",
                          "kind": "LinkedField",
                          "name": "gig",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "WorkerLatestArrivalTimeReminder_gig",
                              "selections": [
                                (v5/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "startsAt",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "latestArrivalTime",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "GigState",
                                  "kind": "LinkedField",
                                  "name": "currentState",
                                  "plural": false,
                                  "selections": (v3/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Place",
                              "kind": "LinkedField",
                              "name": "place",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "streetAddress",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "GigSeries",
                              "kind": "LinkedField",
                              "name": "gigSeries",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            },
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "c232d4bea2545e569820736406daf4c8";

export default node;
