import {
  ContentArea,
  List,
  Stack,
  Surface,
  Text,
  showModal
} from "@gigsmart/atorasu/";
import { HourlyRateBids, PositionsAndLocations } from "@gigsmart/feature-flags";
import React from "react";

interface Props {
  isEOR?: boolean;
  currentState?: string;
}

const NextStepsShiftModal = () => {
  return (
    <ContentArea fill>
      <Stack>
        <Text>
          Here's what happens{" "}
          <Text weight={HourlyRateBids.select("bold", undefined)}>
            after you
          </Text>
          <Text weight="bold"> Bid:</Text>
        </Text>
        <Surface variant="flat" color="foreground">
          <ContentArea>
            <Stack>
              <List>
                <Text>
                  The Requester will review your Bid. If they have any
                  questions, they will be able to message you through the app.
                  If you are no longer available to work the Shift, you can
                  withdraw your Bid. You can also edit your Bid while it is
                  awaiting review.
                </Text>
                <Text>
                  If your Bid is accepted, you will get an offer for the Shift
                  to accept or reject. The Requester may also send a
                  counter-offer. You can accept the counter-offer, submit a
                  counter-bid, or reject the Shift.
                </Text>
              </List>
              <Text>
                Note: You are not hired to work a Shift until you have received
                and accepted a Shift offer. Please do not show up to work a
                Shift unless you have been hired. If the Shift requires
                Verification, once accepting the offer, the Verification process
                begins.
              </Text>
            </Stack>
          </ContentArea>
        </Surface>
        <Text>
          Here's what happens after you
          <Text weight="bold"> get hired:</Text>
        </Text>
        <Surface variant="flat" color="foreground">
          <ContentArea>
            <List>
              <Text>
                Once the Shift is accepted (and Verification is completed if
                applicable), you'll be hired on the offered Shift(s). You will
                now have access to the full address of the Shift and will be
                able to call the Requester through the app.
              </Text>
              <Text>
                Be sure to use the app to go En Route, indicate you have
                Arrived, and if your location is confirmed to be on-site through
                the app, you will be able to start your clock.
              </Text>
              <Text>
                At the end of the Shift if there are any issues with your time
                worked you will be able to submit an updated timesheet for the
                Requester to review and they will be able to make any changes
                prior to approving your final timesheet.
              </Text>
              <Text>
                Once your final timesheet is approved you will be able to rate
                and review the Requester and your earnings will be deposited in
                your{" "}
                {PositionsAndLocations.select("Worker Wallet", "Gig Wallet")}.
                For your safety, receiving payment for Shifts outside the app is
                prohibited. Shifts outside the app is prohibited.
              </Text>
            </List>
          </ContentArea>
        </Surface>
      </Stack>
    </ContentArea>
  );
};

const NextStepsEORShiftModal = ({
  currentState
}: { currentState?: string }) => {
  const providerName =
    currentState === "OFFERED" || currentState === "APPLIED"
      ? "the W-2 provider"
      : "Ascen";
  return (
    <ContentArea>
      <Stack>
        <Text weight="bold">After Accepting a W-2 Shift Offer:</Text>
        <Surface variant="flat" color="foreground">
          <ContentArea>
            <Stack>
              <List>
                <Text>
                  You will receive an invitation to enroll as a W-2 Employee
                  with {providerName}. You must complete enrollment before you
                  are officially scheduled.
                </Text>
                <Text>
                  Once your enrollment is complete and any required verification
                  checks are cleared, you will be scheduled on the Shift.
                </Text>
              </List>
              <Text>
                Note: Your Shift payment will be processed by {providerName},
                not through the GigSmart app.
              </Text>
            </Stack>
          </ContentArea>
        </Surface>
        <Text weight="bold">After Being Scheduled on a W-2 Shift:</Text>
        <Surface variant="flat" color="foreground">
          <ContentArea>
            <List>
              <Text>
                You will gain access to the full Shift address and will be able
                to call the Requester directly through the app.
              </Text>
              <Text>
                Use the app to mark yourself En Route and Arrived. If your
                location is confirmed on-site, you will be able to start your
                clock.
              </Text>
              <Text>
                At the end of your Shift, if needed, you can submit an updated
                timesheet for the Requester to review. They may adjust it before
                final approval.
              </Text>
              <Text>
                Once your timesheet is approved, your earnings will be paid
                outside of the GigSmart app by {providerName}{" "}
                <Text weight="bold">within 2 business days</Text>
              </Text>
            </List>
          </ContentArea>
        </Surface>
      </Stack>
    </ContentArea>
  );
};

export default function showNextStepsShiftModal({
  isEOR,
  currentState
}: Props) {
  return showModal({
    useModalBody: true,
    useModalFooter: true,
    fixedHeight: true,
    scrollable: true,
    variant: "border",
    title: "Next Steps for Shifts",
    eventContext: null,
    children: isEOR ? (
      <NextStepsEORShiftModal currentState={currentState} />
    ) : (
      <NextStepsShiftModal />
    )
  });
}
