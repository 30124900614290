/**
 * @generated SignedSource<<c854233996e0661fb17d6683fc46634e>>
 * @relayHash 641eed9f80e00ff223c205ed7739f765
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:lDzpTzRhM37CIeT0nCWayxSSZap2SWaqkZI0XprikFc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type workerWalletScreenQuery$variables = {
  upcomingShiftsQuery?: string | null | undefined;
};
export type workerWalletScreenQuery$data = {
  readonly viewer: {
    readonly upcomingShiftCount?: {
      readonly totalCount: number;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EOREarningsTabContent_worker" | "ShiftEarningsTabContent_worker">;
  } | null | undefined;
};
export type workerWalletScreenQuery = {
  response: workerWalletScreenQuery$data;
  variables: workerWalletScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "upcomingShiftsQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": "upcomingShiftCount",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "upcomingShiftsQuery"
    }
  ],
  "concreteType": "EngagementsConnection",
  "kind": "LinkedField",
  "name": "engagements",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v6 = {
  "kind": "Literal",
  "name": "query",
  "value": "WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID AND requiredReportType != EOR_WORKER ORDER BY endsAt DESC"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v4/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Gig",
  "kind": "LinkedField",
  "name": "gig",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "paymentInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 4
  },
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "workerWalletScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ShiftEarningsTabContent_worker"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EOREarningsTabContent_worker"
              },
              (v2/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "workerWalletScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "walletBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerPayableAccount",
                "kind": "LinkedField",
                "name": "payableAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "paymentMethod",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last4",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "brand",
                            "storageKey": null
                          }
                        ],
                        "type": "BankCard",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bankName",
                            "storageKey": null
                          }
                        ],
                        "type": "BankAccount",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "gigType",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              (v5/*: any*/)
                            ],
                            "concreteType": "EngagementPaymentsConnection",
                            "kind": "LinkedField",
                            "name": "payments",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementPaymentsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementPayment",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "amount",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "payments(first:20)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": "engagements(first:20,query:\"WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID AND requiredReportType != EOR_WORKER ORDER BY endsAt DESC\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
              },
              {
                "alias": "lifetime",
                "args": null,
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": null
              },
              {
                "alias": "sevenDay",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P7D"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P7D\")"
              },
              {
                "alias": "thirtyDay",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P30D"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P30D\")"
              },
              {
                "alias": "dy0",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P0D"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"^PY\",dateTo:\"-P0D\")"
              },
              {
                "alias": "dy1",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P1Y^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P1Y$PY"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P1Y^PY\",dateTo:\"-P1Y$PY\")"
              },
              {
                "alias": "dy2",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P2Y^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P2Y$PY"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P2Y^PY\",dateTo:\"-P2Y$PY\")"
              },
              {
                "alias": "dy3",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "dateFrom",
                    "value": "-P3Y^PY"
                  },
                  {
                    "kind": "Literal",
                    "name": "dateTo",
                    "value": "-P3Y$PY"
                  }
                ],
                "kind": "ScalarField",
                "name": "totalEarnings",
                "storageKey": "totalEarnings(dateFrom:\"-P3Y^PY\",dateTo:\"-P3Y$PY\")"
              },
              {
                "alias": "w2Engagements",
                "args": (v11/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "engagements(first:4,query:\"WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID AND requiredReportType != EOR_WORKER ORDER BY endsAt DESC\")"
              },
              {
                "alias": "w2Engagements",
                "args": (v11/*: any*/),
                "filters": [
                  "query"
                ],
                "handle": "connection",
                "key": "CompletedW2ShiftsCardList_w2Engagements",
                "kind": "LinkedHandle",
                "name": "engagements"
              },
              (v2/*: any*/)
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:lDzpTzRhM37CIeT0nCWayxSSZap2SWaqkZI0XprikFc",
    "metadata": {},
    "name": "workerWalletScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1ba94c4e4aa10d279945f97a082595d8";

export default node;
