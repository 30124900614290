import {
  Button,
  ContentArea,
  FancyHeader,
  Stack,
  Surface,
  Text,
  toast
} from "@gigsmart/atorasu";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import React, { useState } from "react";
import type { EarningsHistoryCard_sendEmailMutation } from "./__generated__/EarningsHistoryCard_sendEmailMutation.graphql";

export default function EarningsHistoryCard() {
  const [sendingEarningsEmail, setSendingEarningsEmail] = useState(false);
  const [sendEarningsEmail] =
    useRelayMutation<EarningsHistoryCard_sendEmailMutation>(graphql`
      mutation EarningsHistoryCard_sendEmailMutation(
        $input: EmailWorkerEarningsInput!
      ) {
        emailWorkerEarnings(input: $input) {
          ok
        }
      }
    `);

  const handleSendEmail = () => {
    setSendingEarningsEmail(true);
    sendEarningsEmail(
      { input: {} },
      {
        onSuccess: () => {
          setSendingEarningsEmail(false);
          toast.success("Earnings Report Sent to Email Address on File");
        },
        onError: (error) => {
          setSendingEarningsEmail(false);
          toast.error(
            "Unable to Send Earnings Report. Contact Support for More Information"
          );
        }
      }
    );
  };

  return (
    <Surface testID="earnings-history-card">
      <FancyHeader title="Earnings History" icon="inbox-in" />
      <ContentArea fill>
        <Stack fill>
          <Text>
            If you would like to view all your Shift earnings history in one
            place, you can export a comprehensive earnings report to your email
            address on file.
          </Text>
          <Button
            outline
            onPress={handleSendEmail}
            label="Email Earnings Report"
            testID="send-earnings-report-email-btn"
            disabled={sendingEarningsEmail}
            size="small"
          />
        </Stack>
      </ContentArea>
    </Surface>
  );
}
