import {
  Column,
  ContentArea,
  Divider,
  HighlightedReminder,
  IconText,
  Row,
  Stack,
  Surface,
  Tag,
  VerticalLabel
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelaySubscribedFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { getBidSpec } from "@gigsmart/seibutsu/engagement/EngagementExtras";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "@gigsmart/seibutsu/gig-series/WorkerLatestArrivalTimeReminder";
import ShiftDescriptionHeader from "@gigsmart/seibutsu/gig/ShiftDescriptionHeader";
import ShiftGroupChatActionRow from "@gigsmart/seibutsu/gig/organization/ShiftGroupChatActionRow";
import ShiftOrgActionRow from "@gigsmart/seibutsu/gig/organization/ShiftOrgActionRow";
import useActiveGigPermissions from "@gigsmart/seibutsu/gig/useActiveGigPermissions";
import ShiftStatusTags from "@gigsmart/seibutsu/shifts/accessory/ShiftStatusTags";
import WorkerShiftStatusText from "@gigsmart/seibutsu/shifts/accessory/WorkerShiftStatusText";
import React, { type ReactNode } from "react";
import RequesterThumbnailRow from "../../requester/requester-thumbnail-row";
import ActiveEngagementActions from "../engagement-top-card/active-engagement-actions";
import EngagementSummarySurface from "./EngagementSummarySurface";
import type { EngagementDetailsCardSubscription } from "./__generated__/EngagementDetailsCardSubscription.graphql";
import type { EngagementDetailsCard_engagement$key } from "./__generated__/EngagementDetailsCard_engagement.graphql";

type Props = {
  actionNode?: ReactNode;
  isActiveShift?: boolean;
};

export const EngagementDetailsCard = ({
  actionNode,
  isActiveShift,
  requiredReportTypes,
  gigType,
  currentState,
  gig,
  result
}: FragmentContainerInnerComponentProps<
  EngagementDetailsCard_engagement$key,
  Props
>) => {
  const isEOR = requiredReportTypes?.includes?.("EOR_WORKER");
  const isBidState = !!getBidSpec(currentState);
  const isRunningLate = currentState?.name === "RUNNING_LATE";
  const isProject = gigType === "PROJECT";

  useActiveGigPermissions({
    trace: "TopCardApplets",
    requiresNotifications: isActiveShift,
    requiresBackgroundLocation: isActiveShift,
    requiresPreciseLocation: isActiveShift,
    request: isActiveShift
  });

  if (!result) return null;
  return (
    <Surface variant={isActiveShift ? "flat" : "shadow"}>
      <Row>
        {isActiveShift && (
          <VerticalLabel
            color="highlight"
            text={gigType === "PROJECT" ? "PROJECT" : "Active Shift"}
          />
        )}
        <Column fill>
          {isProject && (
            <>
              <RequesterThumbnailRow
                fragmentRef={result?.gig}
                right={
                  <ActiveEngagementActions
                    showProfileAction
                    showPhone={false}
                    engagementRef={result}
                  />
                }
              />
              <Divider />
            </>
          )}
          <ContentArea gap="standard">
            <Stack horizontal size="compact" alignItems="center">
              <IconText
                icon="business-time"
                size="medium"
                textVariant="header"
                color={isEOR ? "purple" : "primary"}
                spacing="medium"
              >
                {gig?.name}
              </IconText>
              {isEOR && (
                <Tag
                  icon="passport"
                  color="purple"
                  label="W-2"
                  variant="clear"
                />
              )}
            </Stack>
            <ShiftStatusTags fragmentRef={result} />
            <WorkerShiftStatusText fragmentRef={result} />
            {!isProject && <ShiftDescriptionHeader fragmentRef={result} />}
            {actionNode}
          </ContentArea>
          <Divider />
          {!isProject && (
            <ShiftOrgActionRow fragmentRef={result}>
              {!isActiveShift && (
                <EngagementSummarySurface
                  fragmentRef={result}
                  showAddress={isBidState}
                  bottomAccessory={
                    <Stack size="compact">
                      {isRunningLate && (
                        <HighlightedReminder
                          icon="circle-exclamation"
                          header="You did not arrive on time for this Shift. The Requester can decide to start your clock or cancel you without pay."
                        />
                      )}
                      {showLatestArrivalReminder(gig, currentState?.name) && (
                        <WorkerLatestArrivalTimeReminder
                          fragmentRef={gig}
                          workerStateName={currentState?.name}
                        />
                      )}
                    </Stack>
                  }
                />
              )}
              <ShiftGroupChatActionRow fragmentRef={gig} />
            </ShiftOrgActionRow>
          )}
        </Column>
      </Row>
    </Surface>
  );
};

export default createRelaySubscribedFragmentContainer<
  EngagementDetailsCard_engagement$key,
  EngagementDetailsCardSubscription,
  Props
>(EngagementDetailsCard, {
  fragment: graphql`
    fragment EngagementDetailsCard_engagement on Engagement {
      id
      requiredReportTypes
      gigType
      currentState {
        name
        action
      }
      gig {
        name
        ...WorkerLatestArrivalTimeReminder_gig
        ...ShiftGroupChatActionRow_gig
        ...requesterThumbnailRow_gig
      }
      ...WorkerShiftStatusText_shift
      ...ShiftStatusTags_shift
      ...ShiftDescriptionHeader_availableGig
      ...ShiftOrgActionRow_engagement
      ...EngagementSummarySurface_engagement
      ...activeEngagementActions_engagement
    }`,
  subscription: graphql`
      subscription EngagementDetailsCardSubscription($engagementId: ID!) {
        engagementUpdated(engagementId: $engagementId) {
          engagement {
            ...EngagementDetailsCard_engagement
          }
        }
      }
    `,
  subscriptionVariables: ({ result }) => ({ engagementId: String(result?.id) }),
  getSubscriptionFragmentRef: (data) => data?.engagementUpdated?.engagement
});
