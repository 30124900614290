/**
 * @generated SignedSource<<d4c69645a3dcf12d227d692875e79079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementPaymentInfoStatus = "ACCURATE" | "FINAL" | "INACCURATE" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerShiftInfoCollapsible_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly name: string | null | undefined;
    readonly timezone: string | null | undefined;
  };
  readonly paymentInfo: {
    readonly additionalPay: string;
    readonly basePay: string;
    readonly billableDuration: string;
    readonly netPay: string;
    readonly payRate: string;
    readonly serviceFees: ReadonlyArray<{
      readonly amount: string;
      readonly feeType: EngagementAddonFeeType;
      readonly hourlyRate: string | null | undefined;
    }>;
    readonly status: EngagementPaymentInfoStatus;
    readonly totalServiceFee: {
      readonly amount: string;
    };
  } | null | undefined;
  readonly timesheet: {
    readonly paymentStyle: EngagementTimesheetPaymentStyle;
    readonly states: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly action: EngagementStateAction;
          readonly transitionedAt: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly totalDurationWorked: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
  } | null | undefined;
  readonly " $fragmentType": "WorkerShiftInfoCollapsible_engagement";
};
export type WorkerShiftInfoCollapsible_engagement$key = {
  readonly " $data"?: WorkerShiftInfoCollapsible_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerShiftInfoCollapsible_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "overridden",
  "value": true
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerShiftInfoCollapsible_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billableDuration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalPay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TotalServiceFee",
          "kind": "LinkedField",
          "name": "totalServiceFee",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerServiceFee",
          "kind": "LinkedField",
          "name": "serviceFees",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hourlyRate",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timezone",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "FINAL"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentStyle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalDurationWorked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": [
                {
                  "transitionedAt": {
                    "direction": "ASC"
                  }
                }
              ]
            },
            (v2/*: any*/)
          ],
          "concreteType": "EngagementStateOrOverridesConnection",
          "kind": "LinkedField",
          "name": "states",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementStateOrOverridesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "action",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "transitionedAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:true)"
        },
        {
          "args": [
            (v2/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "TimesheetList_timesheet"
        }
      ],
      "storageKey": "timesheet(variant:\"FINAL\")"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "f0dbc36954aee20e1f6c597f180a4fc1";

export default node;
