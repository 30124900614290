import {
  ActionRow,
  Column,
  ContentArea,
  Divider,
  FancyHeader,
  Icon,
  Linking,
  Row,
  Surface,
  Tag,
  Text
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { YourEmployerCard_worker$key } from "./__generated__/YourEmployerCard_worker.graphql";

export function YourEmployerCard({
  id
}: FragmentContainerInnerComponentProps<YourEmployerCard_worker$key>) {
  const isEmployed = true;
  return (
    <Surface>
      <FancyHeader
        variant="purple"
        color="purple"
        icon="suitcase"
        title="Your Employer"
      />
      <ContentArea>
        <Column gap="standard">
          <Divider />
          <Row
            gap="standard"
            alignItems="center"
            justifyContent="space-between"
          >
            <Row gap="compact" alignItems="center">
              <Icon name="ascen" size="default" />
              <Text>Employment Status</Text>
            </Row>
            {isEmployed ? (
              <Tag label="Active" color="purple" />
            ) : (
              <Tag label="Inactive" color="disabled" />
            )}
          </Row>
          <Divider />
          <ActionRow
            testID="view-ascen-account"
            icon="user"
            title="View Your Ascen Account"
            onPress={() => null}
          />
        </Column>
      </ContentArea>
      <Divider />
      <ContentArea alignItems="center">
        <Text>
          For Support contact&nbsp;
          <Text
            color="info"
            onPress={async () =>
              await Linking.openURL("mailto:support@ascen.com")
            }
          >
            support@ascen.com
          </Text>
        </Text>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<YourEmployerCard_worker$key>(
  graphql`
  fragment YourEmployerCard_worker on Worker {
    id
  }
  `,
  YourEmployerCard
);
