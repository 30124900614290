/**
 * @generated SignedSource<<b8dd370668d8742eebb1daacfd21de9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftPreviewList_series$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly availableGigs: {
        readonly edges: ReadonlyArray<{
          readonly estimatedPayment: {
            readonly netPay: string;
          } | null | undefined;
          readonly node: {
            readonly id: string;
            readonly payRate: string | null | undefined;
            readonly requiredReportTypes: ReadonlyArray<ReportType>;
            readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewRow_shift">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly totalCount: number;
      } | null | undefined;
      readonly boosted: boolean;
      readonly id: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly totalCount: number;
  readonly " $fragmentType": "ShiftPreviewList_series";
};
export type ShiftPreviewList_series$key = {
  readonly " $data"?: ShiftPreviewList_series$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewList_series">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftPreviewList_series",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailableGigSeriesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigSeries",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boosted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 1
                }
              ],
              "concreteType": "AvailableGigsConnection",
              "kind": "LinkedField",
              "name": "availableGigs",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AvailableGigsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EngagementPaymentInfo",
                      "kind": "LinkedField",
                      "name": "estimatedPayment",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "netPay",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Gig",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ShiftPreviewRow_shift"
                        },
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "payRate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "requiredReportTypes",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "availableGigs(first:1)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "AvailableGigSeriesConnection",
  "abstractKey": null
};
})();

(node as any).hash = "c20867ff7cb5946052d24cb330e02935";

export default node;
