import {
  Button,
  Column,
  HighlightedStatement,
  IconCircle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  showModal
} from "@gigsmart/atorasu";
import { LatestArrivalTime } from "@gigsmart/feature-flags";
import { defaultResetHome } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserCheckSvg from "../Brand/UserCheckSvg";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "../gig-series/WorkerLatestArrivalTimeReminder";
import type {
  ReportType,
  VerificationReportState,
  WorkerHiredModalQuery
} from "./__generated__/WorkerHiredModalQuery.graphql";

const VerificationTypeNameMap: Record<ReportType, string | undefined> = {
  BACKGROUND_CHECK: "Background Check",
  MOTOR_VEHICLE_CHECK: "MVR Check",
  DRUG_SCREEN: "Drug Screening",
  CONTINUOUS_BACKGROUND_CHECK: undefined,
  EOR_WORKER: undefined,
  IDENTITY_CHECK: undefined,
  CONTINUOUS_MOTOR_VEHICLE_CHECK: undefined,
  EOR_ENGAGEMENT: undefined,
  "%future added value": undefined
} as const;

export function getReportTextAndVerbiage(
  requiredReportTypes: readonly ReportType[] = [],
  reports: ReadonlyArray<{
    readonly state: VerificationReportState;
    readonly type: ReportType;
  }> = []
) {
  const unmetReports: string[] = [];
  for (const requiredReport of requiredReportTypes) {
    const report = reports?.find((report) => report.type === requiredReport);
    const label = VerificationTypeNameMap[requiredReport];

    if (label && report?.type && report?.state !== "COMPLETE") {
      unmetReports.push(label);
    }
  }

  const verb = unmetReports.length > 1 ? "are" : "is";
  const txt =
    unmetReports.length > 1
      ? unmetReports
          .slice(0, unmetReports.length - 1)
          .join(", ")
          .concat(` and ${unmetReports[unmetReports.length - 1]}`)
      : unmetReports[0];

  return txt
    ? `This Requester requires additional verification for this Shift. Once your ${txt} ${verb} successfully completed, you will be hired.`
    : "This Requester requires additional verification for this Shift. Once successfully completed, you will be hired.";
}

export const showWorkerHiredModal = (props: Omit<Props, "onClose">) => {
  return showModal({
    children: (closeFn) => <WorkerHiredModal {...props} onClose={closeFn} />,
    eventContext: "Worker Hired Modal",
    variant: "full",
    useModalBody: false
  });
};

type Props = {
  variant?: "DEFAULT" | "PICKUP_SHIFT" | "PROJECT";
  shiftCount?: number;
  engagementId: string;
  onClose: () => void;
};

const WorkerHiredModal = createSuspendedQueryContainer<
  WorkerHiredModalQuery,
  Props
>(
  function WorkerHiredModal({
    variant = "DEFAULT",
    onClose,
    shiftCount,
    response
  }) {
    const history = useHistory();

    const node = response?.node;
    const stateName = node?.currentState?.name;
    const isProject = node?.gig?.gigType === "PROJECT";
    const isConfirming = stateName === "CONFIRMING";

    useEffect(() => {
      if (isProject) {
        defaultResetHome();
      } else {
        const shiftsTab =
          stateName === "CONFIRMING"
            ? "confirming"
            : stateName === "BID_REVIEW"
              ? "bidSubmitted"
              : "hired";
        history.push(`/shifts?shiftsTab=${shiftsTab}`);
      }
    }, []);

    const getWarningText = () =>
      isConfirming ? (
        <Text weight="bold" align="center">
          Do not show up for this Shift unless you are confirmed to be hired in
          the app.
        </Text>
      ) : (
        <Text
          weight={isProject ? "bold" : undefined}
          align="center"
          testID="warning-text"
        >
          Be sure to agree upon the price with the Requester before you start
          working.
        </Text>
      );

    const getVerificationText = () => {
      if (isConfirming) {
        return getReportTextAndVerbiage(
          node?.gig?.requiredReportTypes,
          node?.worker?.verification.reports
        );
      }

      return isProject
        ? "You have been hired to work this Project Gig."
        : "Congratulations, you are now scheduled to work this Shift. You now have access to the full address.";
    };

    return (
      <>
        <ModalHeader />
        <ModalBody constraint="xsmall">
          <Column fill={3} alignItems="center" justifyContent="center">
            {isConfirming ? (
              <IconCircle
                icon="circle-exclamation"
                color="purple"
                size="large"
                variant="well"
              />
            ) : (
              <UserCheckSvg width="100%" height="100%" />
            )}
          </Column>
          <Column fill={2} gap="standard" justifyContent="center">
            <Text
              variant="header"
              color={isConfirming ? "purple" : "primary"}
              align="center"
              testID="title"
            >
              {isConfirming
                ? "You are being confirmed for this Shift Gig. You are NOT hired yet."
                : isProject
                  ? "Congratulations!"
                  : variant === "PICKUP_SHIFT"
                    ? "You have picked up this Shift!"
                    : "You're Hired!"}
            </Text>
            <Text align="center" testID="subtitle">
              {getVerificationText()}
            </Text>
          </Column>
        </ModalBody>
        <ModalFooter>
          {isProject && (
            <HighlightedStatement
              icon="circle-exclamation"
              color="warning"
              iconVariant="solid"
            >
              {getWarningText()}
              {node?.gig?.paymentType === "CHECK" && (
                <Text align="center" testID="warning-subtext">
                  Payment by Check: Be sure you are comfortable with accepting a
                  personal check from the Requester before you start working.
                </Text>
              )}
              {node?.gig?.paymentType === "NO_PREFERENCE" && (
                <Text align="center" testID="warning-subtext">
                  Payment Method Undecided: Be sure you and Requester agree on a
                  method of how you will be paid before you start working.
                </Text>
              )}
              {node?.gig?.paymentType === "DIGITAL_TRANSFER" && (
                <Text align="center" testID="warning-subtext">
                  Payment by 3rd Party Digital Transfer: Be sure you can accept
                  the Requester's form of payment before you start working.
                </Text>
              )}
            </HighlightedStatement>
          )}
          {LatestArrivalTime.isEnabled() &&
            showLatestArrivalReminder(node?.gig, stateName) && (
              <WorkerLatestArrivalTimeReminder
                fragmentRef={node?.gig}
                isMultiple={!!shiftCount && shiftCount > 1}
                workerStateName={stateName}
              />
            )}
          <Button
            testID="ok-button"
            color={variant === "PICKUP_SHIFT" ? "emphasized" : "primary"}
            label="OK"
            onPress={onClose}
          />
        </ModalFooter>
      </>
    );
  },
  {
    query: graphql`
      query WorkerHiredModalQuery($engagementId: ID!) {
        node(id: $engagementId) {
          ... on Engagement {
            currentState {
              name
            }
            gig {
              latestArrivalTime
              startsAt
              gigType
              paymentType
              requiredReportTypes
              ...WorkerLatestArrivalTimeReminder_gig
            }
            worker {
              verification {
                reports {
                  state
                  type
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ engagementId }) => ({ engagementId })
  }
);
