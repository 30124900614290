import "./handlers";

import { Button, Card, ContentArea, Spacer, Stack } from "@gigsmart/atorasu";
import {} from "@gigsmart/biruda";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation,
  useRelayOrchestrator
} from "@gigsmart/relay";
import { memoize } from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import type { announcementDismissMutation } from "./__generated__/announcementDismissMutation.graphql";
import type {
  CqlFilterAnnouncementInput,
  announcementQuery
} from "./__generated__/announcementQuery.graphql";
import AnnouncementCard from "./announcement-card";
import { connectionToAnnouncementList } from "./helpers";
import type { AnnouncementType } from "./types";

export { registerAnnouncementHandler } from "./helpers";

export interface Options {
  audienceType: "REQUESTER" | "WORKER";
}

export const getAnnouncementQuery = memoize(
  (audienceType: Options["audienceType"]): CqlFilterAnnouncementInput => ({
    audienceType: { _eq: audienceType },
    expiresAt: { _gt: new Date().toISOString() },
    isDismissed: { _eq: false }
  })
);

export interface Props {
  showMore?: boolean;
  enableSpacing?: boolean;
  onShowMore?: (arg0: boolean) => void;
  onExpandAnnouncement?: (arg0: boolean) => void;
  onDismissAnnouncements?: () => void;
  variant?: "none" | "standard";
}

export function createAnnouncementsComponent({ audienceType }: Options) {
  return createSuspendedQueryContainer<announcementQuery, Props>(
    function Announcements({
      response,
      onShowMore,
      onExpandAnnouncement,
      showMore: externalShowMore,
      enableSpacing,
      onDismissAnnouncements,
      variant
    }) {
      const rawAnnouncements = response?.announcements;
      const [announcements, setAnnouncements] = useState<AnnouncementType[]>(
        []
      );
      const [internalShowMore, setShowMore] = useState(false);
      const [expandedAnnouncementId, setExpandedAnnouncementId] = useState<
        string | null | undefined
      >(null);
      const handleExpandAnnouncement = useCallback(
        (id?: string | null) => {
          setExpandedAnnouncementId(id);
          if (onExpandAnnouncement) onExpandAnnouncement(!!id);
        },
        [onExpandAnnouncement]
      );
      const handleShowMore = useCallback(() => {
        const nextShowMore = !(onShowMore
          ? externalShowMore
          : internalShowMore);
        onShowMore ? onShowMore(nextShowMore) : setShowMore(nextShowMore);
      }, [externalShowMore, internalShowMore, onShowMore]);
      const { fetchQuery } = useRelayOrchestrator();

      const [commitDismiss] = useRelayMutation<announcementDismissMutation>(
        graphql`
          mutation announcementDismissMutation(
            $input: DismissAnnouncementInput!
          ) {
            dismissAnnouncement(input: $input) {
              announcement {
                id @deleteRecord
              }
            }
          }
        `
      );
      const dismiss = useCallback(
        (announcementId: string) =>
          commitDismiss(
            { input: { announcementId } },
            {
              onSuccess: () => {
                if (announcements.length === 1) {
                  onDismissAnnouncements?.();
                }
                setAnnouncements(
                  announcements.filter((ann) => ann.id !== announcementId)
                );
              }
            }
          ),
        [announcements, commitDismiss, onDismissAnnouncements]
      );
      const showMore = externalShowMore ?? internalShowMore;
      useEffect(() => {
        if (rawAnnouncements) {
          void connectionToAnnouncementList(rawAnnouncements, fetchQuery).then(
            setAnnouncements
          );
        }
      }, [fetchQuery, rawAnnouncements]);

      if (!announcements?.length) return enableSpacing ? <Spacer /> : null;

      return (
        <ContentArea
          size={enableSpacing ? "standard" : "none"}
          variant={enableSpacing ? variant : "none"}
          constrainedVariant="none"
        >
          <Card
            testID="announcements-content"
            eventTargetName="Announcements"
            hideInnerMargin={enableSpacing}
          >
            <Stack variant="divider">
              {(showMore ? announcements : [announcements[0]]).map(
                (announcement, index) =>
                  announcement ? (
                    <AnnouncementCard
                      key={index}
                      expanded={expandedAnnouncementId === announcement.id}
                      onExpand={handleExpandAnnouncement}
                      onDismiss={dismiss}
                      {...announcement}
                    />
                  ) : null
              )}
              {announcements.length > 1 && (
                <Button
                  size="small"
                  variant="clear"
                  testID={showMore ? "show-less-btn" : "show-more-btn"}
                  label={
                    showMore
                      ? "Show Less"
                      : `Show ${announcements.length - 1} More`
                  }
                  onPress={handleShowMore}
                />
              )}
            </Stack>
          </Card>
        </ContentArea>
      );
    },
    {
      query: graphql`
        query announcementQuery($where: CqlFilterAnnouncementInput!) {
          announcements(
            first: 10,
            where: $where,
            orderBy: [{ priority: { direction: ASC_NULLS_LAST } }]
          )
            @connection(key: "app_announcements", filters: ["where"]) {
            edges {
              node {
                id
                isDismissed
                title
                content
                actionPrompt
                actionType
                actionMeta
                dismissable
                audienceType
              }
            }
          }
        }
      `,
      variables: { where: getAnnouncementQuery(audienceType) },
      fetchPolicy: "network-only"
    }
  );
}
