import { alias, identify, integrate, reportDeviceId } from "@gigsmart/dekigoto";
import {
  DisableLifecycleEvents,
  DisableRudderStack,
  DisableSentry,
  ReportAlias
} from "@gigsmart/feature-flags";
import { UseFeatureProvider } from "@gigsmart/feature-flags/registry";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelayFragment,
  useRelaySubscription
} from "@gigsmart/relay";
import LoadingScreen from "@gigsmart/seibutsu/Brand/LoadingScreen";
import React, { type ReactNode, useContext, useEffect } from "react";
import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import { getDeviceId } from "../app/device-id";
import { getDeviceId as getDeviceIdFallback } from "../app/device-id-fallback";
import type { currentUserDeviceSubscription } from "./__generated__/currentUserDeviceSubscription.graphql";
import type { currentUserQuery } from "./__generated__/currentUserQuery.graphql";
import type { currentUserUserSubscription } from "./__generated__/currentUserUserSubscription.graphql";
import type {
  currentUser_device$data,
  currentUser_device$key
} from "./__generated__/currentUser_device.graphql";
import type {
  currentUser_user$data,
  currentUser_user$key
} from "./__generated__/currentUser_user.graphql";

type ContextShape = {
  currentUser: currentUser_user$data | null | undefined;
  currentDevice: currentUser_device$data | null | undefined;
};

export type CurrentDeviceShape = currentUser_device$data | null | undefined;

export type CurrentUserShape = currentUser_user$data | null | undefined;

const Context = React.createContext<ContextShape>({
  currentUser: null,
  currentDevice: null
});

interface Props extends Record<string, unknown> {
  children?: ReactNode;
}

export const CurrentUserProvider = createSuspendedQueryContainer<
  currentUserQuery,
  Props
>(
  ({ response, children }) => {
    const currentDevice = useRelayFragment<currentUser_device$key>(
      graphql`
        fragment currentUser_device on UserDevice {
          id
          pushRegistrationToken
        }
      `,
      response?.currentDevice ?? null
    );

    useRelaySubscription<currentUserDeviceSubscription>(
      graphql`
        subscription currentUserDeviceSubscription($id: ID!) {
          nodeUpdated(id: $id) {
            node {
              ...currentUser_device
            }
          }
        }
      `,
      { id: String(currentDevice?.id) },
      { subscribe: !!currentDevice?.id }
    );

    const currentUser = useRelayFragment<currentUser_user$key>(
      graphql`
        fragment currentUser_user on User {
          id
          uuid
          insertedAt
          __typename
          firstName
          lastName
          displayName
          lastInitial
          restrictions(
            first: 0
            where: {
              _or: [
                { expiresAt: { _gt: "-PT0S" }, removedAt: { _isNull: true } }
                { expiresAt: { _isNull: true }, removedAt: { _isNull: true } }
              ]
              removedAt: { _isNull: true }
            }
          ) {
            totalCount
          }
          profilePhoto {
            url
          }
          primaryEmail {
            address
          }
          ... on Admin {
            role
          }
          ... on RequesterLike {
            isApproved
            accountMode
            primaryMobile {
              number
            }
            referralCode {
              code
            }
            postalCode
            devices(first: 50, query: "ORDER BY updatedAt DESC") {
              edges {
                node {
                  token: pushRegistrationToken
                }
              }
            }
            onboardingStates {
              onboardingState
              complete
            }
            ... on Requester {
              primaryOrganization {
                id
                uuid
                name
                logoUrl
                smartHireMode
                isApproved
              }
            }
            ... on OrganizationRequester {
              organization {
                id
                uuid
                name
                logoUrl
                smartHireMode
                isApproved
              }
              requester {
                uuid
                id
              }
            }
          }
          ... on Worker {
            accessState
            primaryMobile {
              number
            }
            referralCode {
              code
            }
            postalCode
            devices(first: 50, query: "ORDER BY updatedAt DESC") {
              edges {
                node {
                  token: pushRegistrationToken
                }
              }
            }
            overallRating
            verification {
              reports {
                status
                state
                type
              }
            }
          }
        }
      `,
      response?.currentUser ?? null
    );

    useRelaySubscription<currentUserUserSubscription>(
      graphql`
        subscription currentUserUserSubscription($id: ID!) {
          nodeUpdated(id: $id) {
            node {
              ...currentUser_user
            }
          }
        }
      `,
      { id: String(currentUser?.id) },
      { subscribe: !!currentUser?.id }
    );

    useEffect(() => {
      void trackUser(currentUser);
    }, [currentUser]);

    return (
      <Context.Provider value={{ currentDevice, currentUser }}>
        <UseFeatureProvider value={currentUser}>{children}</UseFeatureProvider>
      </Context.Provider>
    );
  },
  {
    query: graphql`
      query currentUserQuery {
        currentDevice {
          ...currentUser_device
        }
        currentUser: viewer {
          ...currentUser_user
        }
      }
    `,
    decorate: false,
    isBlocking: true,
    FallbackComponent: () => <LoadingScreen message="loading current user" />,
    variables: {}
  }
);

export function useCurrentUser() {
  return useContext(Context)?.currentUser;
}

export function useCurrentDevice() {
  return useContext(Context)?.currentDevice;
}

export function useCurrentOrganization() {
  const user = useCurrentUser();
  return user ? getCurrentOrganization(user) : null;
}

const getCurrentOrganization = (user: NonNullable<CurrentUserShape>) => {
  return user.__typename === "OrganizationRequester" ? user.organization : null;
};

const trackUser = async (user: CurrentUserShape) => {
  const shouldEvent =
    (!!user || Platform.OS !== "web") && user?.__typename !== "Admin";
  await integrate({
    rudderstack: shouldEvent && DisableRudderStack.isDisabled(),
    lifecycle: shouldEvent && DisableLifecycleEvents.isDisabled(),
    sentry: DisableSentry.isDisabled()
  });
  await getDeviceId()
    .catch(async () => await getDeviceIdFallback())
    .then(({ id }) => reportDeviceId(id));
  if (!user) return;

  const globalId = user?.requester?.id ?? user?.id ?? "";
  const userId = user?.requester?.uuid ?? user?.uuid ?? "";
  const email = user?.primaryEmail?.address ?? "";
  const org = getCurrentOrganization(user);

  if (ReportAlias.isEnabled()) alias(userId);

  identify(userId, {
    email: email ?? "",
    firstName: user.firstName ?? "",
    userType: user.__typename ?? "",
    lastName: user.lastName ?? "",
    phone: user.primaryMobile?.number ?? "",
    globalId,
    insertedAt: user?.insertedAt,
    organizationId: org?.uuid,
    devices: getConnectionNodes(user.devices).map(({ token }) => ({
      applicationName: process.env.DEEPLINK_BUNDLE_ID,
      platform: "GCM",
      token
    })),
    buildNumber: process.env.PACKAGE_BUILD_NUMBER,
    companyName:
      org?.name ?? (user.__typename === "Worker" ? "GigSmart Worker" : ""),
    zip: user.postalCode ?? "",
    ...(user?.accountMode ? { accountMode: user?.accountMode } : {}),
    ...(Platform.OS !== "web"
      ? { nativeBuildNumber: Number(DeviceInfo.getBuildNumber()) }
      : {})
  });
};
