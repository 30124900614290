import {
  Avatar,
  Column,
  ContentArea,
  IconText,
  Text,
  ViewButton
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import type { WorkerShiftCardHeader_gigLike$key } from "./__generated__/WorkerShiftCardHeader_gigLike.graphql";

interface Props {
  shiftCount?: number;
  attachment?: JSX.Element | null;
  isEor?: boolean;
}

export const WorkerShiftCardHeader = ({
  name,
  gigType,
  organization,
  shiftCount = 1,
  attachment,
  isEor
}: FragmentContainerInnerComponentProps<
  WorkerShiftCardHeader_gigLike$key,
  Props
>) => {
  return (
    <>
      {attachment}
      <ContentArea
        size={gigType !== "PROJECT" ? "compact" : "standard"}
        color="foreground"
        topRadius={attachment ? "none" : "small"}
        horizontal
        gap="medium"
        alignItems="center"
      >
        {gigType !== "PROJECT" && (
          <Avatar
            size="small"
            color="warning"
            icon="business-time"
            iconSize="large"
            uri={organization?.logoUrl}
            variant="square"
          />
        )}
        <Column gap="slim" fill>
          <Text numberOfLines={2} weight="bold">
            {name}
          </Text>
          {isEor && (
            <IconText
              icon="passport"
              color="purple"
              size="tiny"
              textWeight="bold"
            >
              W-2
            </IconText>
          )}
        </Column>
        <ViewButton
          testID="view-btn"
          label={shiftCount > 1 ? `View All ${shiftCount}` : "View"}
        />
      </ContentArea>
    </>
  );
};

export default createRelayFragmentContainer<
  WorkerShiftCardHeader_gigLike$key,
  Props
>(
  graphql`
    fragment WorkerShiftCardHeader_gigLike on GigLike {
      id
      name
      gigType
      organization {
        logoUrl
      }
    }
  `,
  WorkerShiftCardHeader
);
