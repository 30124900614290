/**
 * @generated SignedSource<<91d2f4899c777ec3b6514ee3600fdfb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type GigStateName = "ACTIVE" | "CANCELED" | "COMPLETED" | "DRAFT" | "EXPIRED" | "INACTIVE" | "INCOMPLETE" | "IN_PROGRESS" | "PENDING_REVIEW" | "RECONCILED" | "UPCOMING" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerLatestArrivalTimeReminder_gig$data = {
  readonly currentState: {
    readonly name: GigStateName;
  };
  readonly latestArrivalTime: string | null | undefined;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly startsAt: string | null | undefined;
  readonly " $fragmentType": "WorkerLatestArrivalTimeReminder_gig";
};
export type WorkerLatestArrivalTimeReminder_gig$key = {
  readonly " $data"?: WorkerLatestArrivalTimeReminder_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "WorkerLatestArrivalTimeReminder_gig"
};

(node as any).hash = "cffd9365386f142dba4d3f2fa6a663da";

export default node;
