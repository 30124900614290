import { ContentRow, FancyHeader, Row, Surface, Text } from "@gigsmart/atorasu";
import { duration } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerTimeWorkedSurface_engagement$key } from "./__generated__/WorkerTimeWorkedSurface_engagement.graphql";

export function WorkerTimeWorkedSurface({
  paymentInfo
}: FragmentContainerInnerComponentProps<WorkerTimeWorkedSurface_engagement$key>) {
  return (
    <Surface variant="outline">
      <FancyHeader icon="clock" title="Time Worked" />
      <ContentRow verticalSize="compact">
        <Row justifyContent="space-between" alignItems="center" fill>
          <Text weight="bold">Total Time Worked</Text>
          <Text weight="bold">
            {duration.humanize(
              paymentInfo?.billableDuration,
              "semi-compact-no-spaces"
            )}
          </Text>
        </Row>
      </ContentRow>
    </Surface>
  );
}

export default createRelayFragmentContainer<WorkerTimeWorkedSurface_engagement$key>(
  graphql`
    fragment WorkerTimeWorkedSurface_engagement on Engagement {
      paymentInfo {
        billableDuration
      }
    }
  `,
  WorkerTimeWorkedSurface
);
