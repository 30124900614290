import { StickyReminder, Text, TimeRemaining } from "@gigsmart/atorasu";
import { asEnum, where } from "@gigsmart/biruda";
import { useHistory } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import useGigLocalDateTime from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import { DateTime } from "luxon";
import React from "react";

import type {
  EngagementStateName,
  UpcomingShiftReminderQuery
} from "./__generated__/UpcomingShiftReminderQuery.graphql";

const IGNORED_STATES: EngagementStateName[] = [
  "PENDING_TIMESHEET_APPROVAL",
  "ENDED"
];

export const upcomingShiftReminderQuery = where({
  currentStateName: asEnum("SCHEDULED"),
  gigStateName: asEnum("UPCOMING")
})
  .orderBy("startsAt", "ASC")
  .toString();

export default createSuspendedQueryContainer<UpcomingShiftReminderQuery>(
  function UpcomingShiftReminder({ response }) {
    const { viewer } = response ?? {};
    const history = useHistory();
    const hasCurrentEngagement =
      !!viewer?.currentEngagement?.currentState &&
      !IGNORED_STATES.includes(viewer?.currentEngagement?.currentState?.name);
    const nextEngagement = viewer?.engagements?.edges?.[0]?.node;
    const nextGig = nextEngagement?.gig ?? null;
    const formattedDate = useGigLocalDateTime(nextGig, {
      dateFormat: "fullDateShort"
    });

    const startsIn24Hours = () => {
      const startTime = DateTime.fromISO(nextGig?.startsAt ?? "", {
        zone: nextGig?.timezone ?? ""
      });
      const currentTime = DateTime.local().setZone(nextGig?.timezone ?? "");
      const diff = startTime.diff(currentTime, "hours").hours;
      return diff < 24;
    };

    if (
      hasCurrentEngagement ||
      !nextEngagement ||
      !nextGig ||
      !startsIn24Hours()
    ) {
      return null;
    }

    return (
      <StickyReminder
        testID="next-shift-gig-reminder"
        icon="alarm-clock"
        label={
          <Text>
            Next Shift Starts in{" "}
            <TimeRemaining date={nextGig?.startsAt} showMinutes />
          </Text>
        }
        eventEntityType="UpcomingShiftReminder"
        onPress={() => history.push(`/gigs/${nextEngagement?.id}`)}
        note={`${nextGig?.name}, ${formattedDate.dateString} ${formattedDate.timeString}`}
      />
    );
  },
  {
    query: graphql`
      query UpcomingShiftReminderQuery($query: String!) {
        viewer {
          ... on Worker {
            currentEngagement {
              id
              currentState {
                name
              }
            }
            engagements(first: 1, query: $query) {
              edges {
                node {
                  id
                  gig {
                    name
                    startsAt
                    endsAt
                    timezone
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: { query: upcomingShiftReminderQuery }
  }
);
