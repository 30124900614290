import { Column, Icon, Stack, Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import AccountBalanceCard from "@gigsmart/seibutsu/wallet/AccountBalanceCard";
import EarningsAndTransfersCard from "@gigsmart/seibutsu/wallet/EarningsAndTransfersCard";
import EarningsHistoryCard from "@gigsmart/seibutsu/wallet/EarningsHistoryCard";
import LinkedAccountsCard from "@gigsmart/seibutsu/wallet/LinkedAccountsCard";
import React from "react";
import type { ShiftEarningsTabContent_worker$key } from "./__generated__/ShiftEarningsTabContent_worker.graphql";

export function ShiftEarningsTabContent({
  result
}: FragmentContainerInnerComponentProps<
  ShiftEarningsTabContent_worker$key,
  {}
>) {
  const worker = result ?? null;

  return (
    <Stack>
      <AccountBalanceCard fragmentRef={worker} />
      <LinkedAccountsCard fragmentRef={worker} />
      <EarningsAndTransfersCard fragmentRef={worker} />
      <EarningsHistoryCard />
      <Column alignSelf="center" testID="powered-by-stripe">
        <Stack horizontal size="compact" alignItems="center">
          <Text color="neutral">Powered By</Text>
          <Icon name="stripe" size="default" color="neutral" />
        </Stack>
      </Column>
    </Stack>
  );
}

export default createRelayFragmentContainer<
  ShiftEarningsTabContent_worker$key,
  {}
>(
  graphql`
    fragment ShiftEarningsTabContent_worker on Worker {
      ...AccountBalanceCard_worker
      ...LinkedAccountsCard_worker
      ...EarningsAndTransfersCard_worker
    }
    `,
  ShiftEarningsTabContent
);
