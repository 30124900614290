import { type Color, toast } from "@gigsmart/atorasu";
import { MessagingRefresh } from "@gigsmart/feature-flags";
import {
  type NotificationAlike,
  PushNotificationsListener
} from "@gigsmart/isomorphic-shared/app/notifications";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { stateColor } from "@gigsmart/isomorphic-shared/gig/helpers";
import NotificationSubscriptionListener from "@gigsmart/isomorphic-shared/notifications/subscription-listener";
import {
  type NavigationProp,
  useHistory,
  useNavigation
} from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import PaymentModal from "@gigsmart/seibutsu/gig/payment/PaymentModal";
import React, { useCallback, useState } from "react";
import type { WorkerParamList } from "../navigation/types";
import type { WorkerNotificationHandlerQuery } from "./__generated__/WorkerNotificationHandlerQuery.graphql";
import type { WorkerNotificationHandlerSubscription } from "./__generated__/WorkerNotificationHandlerSubscription.graphql";
import { useOnboardingCheck } from "./onboarding-check";

const validNotificationType = [
  "GigNotification",
  "PostedGigNotification",
  "GigWorkerUserMessage",
  "JobPostingNotification",
  "GigSeriesEngagementsTransition",
  "Notification"
];

export default createSuspendedQueryContainer<WorkerNotificationHandlerQuery>(
  function WorkerNotificationHandler({ response: result }) {
    const user = useCurrentUser();
    const { appFullyReady } = useOnboardingCheck();

    const history = useHistory();
    const nav = useNavigation<NavigationProp<WorkerParamList>>();
    const [visible, setVisible] = useState(false);

    useRelaySubscription<WorkerNotificationHandlerSubscription>(
      graphql`
        subscription WorkerNotificationHandlerSubscription {
          workerUpdated {
            worker {
              accessState
              currentEngagement {
                id
              }
            }
          }
        }
      `,
      {},
      { subscribe: appFullyReady }
    );

    const { currentEngagement } = result?.viewer ?? {};
    const handleNotification = useCallback(
      async (notificationIn: NotificationAlike, fromBackground: boolean) => {
        // Inspect notification payload
        if (
          !notificationIn?.data?.notificationType ||
          typeof notificationIn?.data?.body === "object" ||
          (typeof notificationIn?.data?.notificationType === "string" &&
            !validNotificationType.includes(
              notificationIn?.data?.notificationType
            ))
        )
          return;

        let onAction = () => {}; // noop
        let message: string =
          notificationIn.data.body ?? notificationIn.body ?? "";
        let toastColor: Color = "highlight";
        let stickyToast = false;
        let payload: any = {};

        try {
          payload =
            typeof notificationIn.data.payload === "string"
              ? JSON.parse(notificationIn.data.payload)
              : notificationIn.data;
        } catch (e) {
          console.error("error parsing push notification", {
            payload: notificationIn.data.payload
          });
          return;
        }

        const currentState = payload.currentState;
        const action = payload.action;
        const gigId = payload.globalGigId ?? payload.gigId;
        const nodeId = payload.globalId ?? payload.nodeId;
        const conversationId = payload.conversationId;
        const engagementId =
          payload.engagementGlobalId ?? payload.engagementId ?? nodeId;
        const body = notificationIn.data.body ?? notificationIn.body ?? "";
        const directHireClaimId = payload?.directHireClaimId;
        const GIG_OFFER = body.includes("offered") && !body.includes("counter");

        switch (notificationIn.data.notificationType) {
          case "Notification":
            if (directHireClaimId) {
              onAction = () => {
                history.push(`/direct-hire/${directHireClaimId}`);
              };
            } else {
              onAction = () => {
                let pathname = "";
                if (action === "engagement_payment") pathname = "/wallet";
                if (pathname) history.push(pathname);
              };
            }
            break;
          case "PostedGigNotification":
            if (process.env.IS_TESTING === "true") return;
            toastColor = "highlight";
            message = notificationIn.data.body ?? notificationIn.body ?? "";
            onAction = () => {
              history.push(`/browse/shifts/${payload.globalId ?? gigId}`);
            };
            break;

          case "GigWorkerUserMessage":
            toastColor = "highlight";
            if (typeof notificationIn.data?.title === "object") {
              return;
            }
            message = notificationIn.data?.title ?? notificationIn.title ?? "";
            if (conversationId && MessagingRefresh.isEnabled()) {
              onAction = () => history.push(`/conversation/${conversationId}`);
            } else if (engagementId) {
              onAction = () => history.push(`/conversation/${engagementId}`);
            }
            break;

          // Route to the Gig
          case "GigNotification":
            if (currentState === "offered" || GIG_OFFER) {
              message = "";
              if (currentEngagement) {
                message = body;
                onAction = () =>
                  nav.navigate("OfferedGigs", { gigType: undefined });
              }
            } else if (engagementId) {
              toastColor = stateColor(currentState?.toUpperCase());
              message = body;
              onAction = () => history.push(`/gigs/${engagementId}`);
            }
            stickyToast = action?.includes("transition_alert");
            break;
          case "JobPostingNotification":
            if (process.env.IS_TESTING === "true") return;
            toastColor = "highlight";
            message = notificationIn.data.body ?? notificationIn.body ?? "";
            onAction = () => {
              history.push(`/browse/jobs/${nodeId}`);
            };
            break;

          case "GigSeriesEngagementsTransition":
            onAction = () => history.push(`/gigs/${engagementId}`);
            break;
        }

        if (action === "no_payment_method") {
          setVisible(true);
        }

        if (fromBackground) {
          onAction();
          return;
        }

        if (!message) return;
        if (stickyToast) message = `${message}\n(tap to dismiss)`;

        toast.toast(message, {
          quick: false,
          label: notificationIn?.data?.engagementId ?? payload?.engagementId,
          color: toastColor,
          sticky: stickyToast,
          onPress: onAction
        });
      },
      [currentEngagement]
    );

    const enabled = appFullyReady;

    if (!user) return null;
    return (
      <>
        <PushNotificationsListener
          requestPermission
          enable={enabled}
          onNotification={handleNotification}
        />
        <NotificationSubscriptionListener
          enabled={enabled}
          onNotification={handleNotification}
        />
        <PaymentModal
          showPaymentModal={visible}
          hidePaymentModal={() => setVisible(false)}
        />
      </>
    );
  },
  {
    query: graphql`
      query WorkerNotificationHandlerQuery {
        viewer {
          ... on Worker {
            currentEngagement {
              id
            }
          }
        }
      }
    `,
    variables: {}
  }
);
