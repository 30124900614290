import {
  Button,
  type ButtonSize,
  type Color,
  IconButton,
  IconCircleButton,
  Linking,
  useMatchesViewport
} from "@gigsmart/atorasu";
import { OperatorCalling } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutationPromise
} from "@gigsmart/relay";
import React from "react";
import showOperatorModal from "./OperatorModal";
import showPhoneModal from "./PhoneModal";
import type { CallIconMutation } from "./__generated__/CallIconMutation.graphql";
import type { CallIconQuery } from "./__generated__/CallIconQuery.graphql";

interface Props {
  variant?: "icon" | "iconCircle" | "button";
  buttonSize?: ButtonSize;
  color?: Color;
  iconColor?: Color;
  recipientId: string;
}

export default createSuspendedQueryContainer<CallIconQuery, Props>(
  function CallIcon({
    variant,
    color,
    iconColor,
    recipientId,
    response,
    buttonSize = "small"
  }) {
    const recipientsName = response?.node?.user
      ? response?.node?.user?.displayName ?? ""
      : response?.node?.displayName ?? "";
    const [commit] = useRelayMutationPromise<CallIconMutation>(graphql`
      mutation CallIconMutation($input: AddUserPhoneProxyInput!) {
        addUserPhoneProxy(input: $input) {
          phoneNumber
        }
      }
    `);
    const currentUser = useCurrentUser();
    const isMobile = useMatchesViewport(({ size }) => size.small.down);
    const isWorker = currentUser?.__typename === "Worker";
    const handleCallPress = async () => {
      if (OperatorCalling.isEnabled()) {
        showOperatorModal({
          recipientId,
          recipientsName
        });
      } else {
        if (!isMobile) {
          showPhoneModal({
            recipientId,
            callerNumber: currentUser?.primaryMobile?.number,
            recipientsName,
            userType: isWorker ? "Requester" : "Worker"
          });
        } else {
          const result = await commit({
            input: { targetId: recipientId ?? "" }
          });
          await Linking.openURL(
            `tel:${result?.addUserPhoneProxy?.phoneNumber}`
          );
        }
      }
    };
    return variant === "button" ? (
      <Button
        outline
        size={buttonSize}
        label="Call"
        icon="phone"
        testID="call-worker-button"
        key="call-worker-button"
        onPress={handleCallPress}
      />
    ) : variant === "iconCircle" ? (
      <IconCircleButton
        size="small"
        testID="phone-call-btn"
        icon="phone"
        color={color ?? "primary"}
        iconColor={iconColor}
        onPress={handleCallPress}
      />
    ) : (
      <IconButton
        size="small"
        testID="phone-modal-btn"
        variant="solid"
        name="phone"
        color={color ?? "primary"}
        onPress={handleCallPress}
      />
    );
  },
  {
    query: graphql`
      query CallIconQuery($recipientId: ID!) {
        node(id: $recipientId) {
          ... on ConversationParticipant {
            user {
              displayName
            }
          }
          ... on User {
            displayName
          }
        }
      }
    `,
    variables: ({ recipientId }) => ({ recipientId })
  }
);
