/**
 * @generated SignedSource<<0280ca20914e56d8d9194f4079d3f62b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerVerificationSummaryRow_shift$data = {
  readonly requiredReportTypes?: ReadonlyArray<ReportType>;
  readonly " $fragmentType": "WorkerVerificationSummaryRow_shift";
};
export type WorkerVerificationSummaryRow_shift$key = {
  readonly " $data"?: WorkerVerificationSummaryRow_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerVerificationSummaryRow_shift">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "requiredReportTypes",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerVerificationSummaryRow_shift",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Gig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "GigSeries",
      "abstractKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};
})();

(node as any).hash = "f6d0f7a32bcb84b7135d79b244a366ad";

export default node;
