import {
  Column,
  ContentArea,
  Divider,
  type IconName,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React, { type ReactNode, useMemo } from "react";
import SummaryListRow from "../../gig/SummaryRows/SummaryListRow";
import type { WorkerShiftStatusText_shift$key } from "./__generated__/WorkerShiftStatusText_shift.graphql";

type Props = {};

export const ShiftStatusText = ({
  currentState,
  cancellationReason,
  systemTimesheet,
  timesheet,
  workerTimesheet,
  gig
}: FragmentContainerInnerComponentProps<
  WorkerShiftStatusText_shift$key,
  Props
>) => {
  const user = useCurrentUser();
  const [title, note] = useMemo((): [string, ReactNode] => {
    if (!currentState?.name) return ["", null]; // sanity check...

    const isEOR = gig?.requiredReportTypes?.includes?.("EOR_WORKER");
    const eorState = user?.verification?.reports?.find(
      ({ type }) => type === "EOR_WORKER"
    )?.state;

    const { action, name: stateName } = currentState;

    const byRequester = currentState.transitionedBy?.__typename === "Requester";
    const byWorker = currentState.transitionedBy?.__typename === "Worker";
    const neverStarted = systemTimesheet?.startedCount?.totalCount === 0;
    const hasScheduled = !!systemTimesheet?.scheduledCount?.totalCount;
    const isTimesheetPaid = !!timesheet?.paid;
    const hasTimesheet = !!timesheet || workerTimesheet?.editable === false;

    switch (stateName) {
      case "APPLIED":
        return [
          "",
          "You have applied. The Requester will review your Application."
        ];
      case "BID_REVIEW":
        return [
          "",
          "You have submitted a Bid. The Requester will review the Bid."
        ];

      case "BID_REQUESTED":
        return [
          "",
          "You have been offered to Bid on this Shift. You can accept the rate or counter with a new Hourly Rate Bid."
        ];
      case "OFFERED": {
        if (action === "OFFER") {
          return [
            "",
            "You have been offered this Shift. You can Accept or Reject the Shift."
          ];
        }
        return action === "COUNTER_OFFER" || action === "MODIFY_OFFER"
          ? [
              "",
              "Your Hourly Rate Bid was countered. You can accept at the Counter-Offered Hourly Rate, or submit a new Counter-Bid."
            ]
          : ["", ""];
      }
      case "CONFIRMING":
        if (isEOR && eorState !== "COMPLETE") {
          return [
            "",
            "This is a W-2 Shift offered through Ascen. You have enrolled to become an employee of Ascen. If your employment is accepted, you will be Scheduled for this Shift."
          ];
        }
        return [
          "",
          "Your profile is in the process of being verified. You will be notified once the verification process is complete!"
        ];
      case "HIRE_REQUESTED":
        return [
          "",
          "You've asked the Requester to confirm your hire on this Project Gig. You will be notified as soon as the Requester responds."
        ];
      case "PENDING_TIMESHEET_APPROVAL":
        return neverStarted && !hasTimesheet
          ? [
              "",
              "The time clock never started for you on this Shift. You can create a timesheet now if you worked this Shift."
            ]
          : ["", null];
      case "PENDING_REVIEW":
        if (!hasTimesheet) return ["", null];
        return [
          "",
          isTimesheetPaid
            ? "This Project Gig is pending final review from the Requester."
            : "This Project Gig is pending final pay and review from the Requester."
        ];
      case "REJECTED":
        return [
          "You've rejected this Shift offer.",
          "It will not be counted against your no-show or cancellation rate."
        ];
      case "MISSED":
        return [
          "You missed the Requester's Offer.",
          "Missed offers do not affect your no-show or cancellation rate."
        ];
      case "APPLICATION_CANCELED":
        return [
          byRequester
            ? "You were canceled from this Shift."
            : HourlyRateBids.select(
                "You’ve withdrawn your bid.",
                "You've withdrawn this application."
              ),
          "It will not be counted against your no-show or cancellation rate."
        ];
      case "APPLICATION_DENIED":
        return [
          "You were not hired on this Shift.",
          "It will not be counted against your no-show or cancellation rate."
        ];
      case "CANCELED": {
        if (byWorker) {
          return ["You canceled yourself from this Shift after hire", null];
        }
        if (byRequester || cancellationReason?.authoredBy === "REQUESTER") {
          if (cancellationReason?.reasonType === "REPORT_NO_SHOW") {
            return ["Requester marked you as a no-show.", null];
          }
          if (cancellationReason?.reasonType === "ISSUE_WITH_WORKER") {
            return ["", "The requester canceled you from the Shift."];
          }
          if ((cancellationReason?.disqualifications?.totalCount ?? 0) > 0) {
            const list = getConnectionNodes(
              cancellationReason?.disqualifications,
              (it) => it.gigFieldItemDefinition
            );
            const body = (
              <Column gap="medium">
                <Divider />
                <Stack variant="divider">
                  {list?.map((d) => (
                    <SummaryListRow
                      key={d.gigField.id}
                      icon={d.gigField.iconName as IconName}
                      title={d.gigField.title}
                      listVariant="bullet"
                    >
                      {d.label}
                    </SummaryListRow>
                  ))}
                </Stack>
                <Divider />
              </Column>
            );
            return [
              "You were canceled from the Shift as you did not meet the following Qualifications:",
              body
            ];
          }
          if (!hasScheduled) {
            return ["You've missed the Requester's Offer.", null];
          }

          return ["You were canceled from this Shift after hire", null];
        }

        return [
          action === "TIMEOUT_CONFIRMATION"
            ? "There was an issue with your verification that was not resolved prior to the Shift start time"
            : "The Shift was canceled before it started",
          ""
        ];
      }
      case "CANCELED_WITH_PAY":
        return [
          "The Requester canceled you off this Shift.",
          "You have been compensated for this cancellation. View the Summary for more details."
        ];
      default:
        return ["", ""];
    }
  }, [
    currentState?.name,
    timesheet,
    workerTimesheet,
    systemTimesheet,
    cancellationReason
  ]);

  if (!title && !note) return null;
  return (
    <ContentArea variant="none" size="slim" gap="slim">
      {!!title && <Text weight="bold">{title}</Text>}
      {note ? (
        typeof note === "string" ? (
          <Text variant="note" color="neutral">
            {note}
          </Text>
        ) : (
          note
        )
      ) : null}
    </ContentArea>
  );
};

export default createRelayFragmentContainer<
  WorkerShiftStatusText_shift$key,
  Props
>(
  graphql`
    fragment WorkerShiftStatusText_shift on Engagement {
      gig {
        requiredReportTypes
      }
      currentState {
        name
        action
        transitionedBy {
          __typename
        }
      }
      timesheet(variant: FINAL) {
        paid
      }
      workerTimesheet: timesheet(variant: WORKER) {
        editable
      }
      systemTimesheet: timesheet(variant: SYSTEM) {
        scheduledCount: states(first: 0, query: "WHERE name = SCHEDULED") {
          totalCount
        }
        startedCount: states(first: 0, query: "WHERE action = START") {
          totalCount
        }
      }
      cancellationReason {
        authoredBy
        reasonType
        disqualifications(first: 50) {
          totalCount
          edges {
            node {
              gigFieldItemDefinition {
                label
                gigField {
                  id
                  title
                  iconName
                }
              }
            }
          }
        }
      }
    }
  `,
  ShiftStatusText
);
