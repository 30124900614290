/**
 * @generated SignedSource<<b3e7b181608d341fb1e7fd8bfe797a40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EOREarningsTabContent_worker$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedW2ShiftsCard_worker" | "W2EarningsCard_worker" | "YourEmployerCard_worker">;
  readonly " $fragmentType": "EOREarningsTabContent_worker";
};
export type EOREarningsTabContent_worker$key = {
  readonly " $data"?: EOREarningsTabContent_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"EOREarningsTabContent_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EOREarningsTabContent_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "YourEmployerCard_worker"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompletedW2ShiftsCard_worker"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "W2EarningsCard_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "72ed969bd20d0ad117740b47253c5692";

export default node;
