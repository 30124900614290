import {
  ActionRow,
  ContentArea,
  Divider,
  IconCircle,
  Image,
  Row,
  Surface,
  Tag,
  Text
} from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { type ReactNode } from "react";
import type {
  BrowseShiftType,
  WorkerBrowseParams
} from "../../browse/browse.nav";
import type { ShiftPreviewRow_shift$key } from "./__generated__/ShiftPreviewRow_shift.graphql";

type Props = {
  boosted?: boolean;
  note?: ReactNode;
  footer?: ReactNode;
  type?: BrowseShiftType;
  onPress?: () => void;
};

export const ShiftPreviewRow = ({
  id,
  name,
  organization,
  requiredReportTypes,
  boosted,
  note,
  footer,
  type,
  onPress
}: FragmentContainerInnerComponentProps<ShiftPreviewRow_shift$key, Props>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const logoUrl = organization?.logoUrl;
  const isEor = requiredReportTypes?.includes("EOR_WORKER");

  return (
    <Surface variant="outline">
      <ActionRow
        eventEntityType="ShiftPreviewRow"
        testID={`shift-preview-${id}`}
        variant="flat"
        leftSpacing="small"
        onPress={
          onPress ?? (() => navigation.push("BrowseShiftDetails", { id, type }))
        }
        title={
          <Row alignItems="center" gap="slim" fill>
            {boosted && (
              <IconCircle color="emphasized" icon="chevrons-up" size="micro" />
            )}
            <Text
              color={isEor ? "purple" : "black"}
              variant="subheader"
              numberOfLines={1}
              wrap
            >
              {name}&nbsp;
            </Text>
            {isEor && (
              <Tag label="W-2" color="purple" icon="passport" variant="clear" />
            )}
          </Row>
        }
        icon="business-time"
        left={
          logoUrl ? (
            <Image height={36} variant="square" source={{ uri: logoUrl }} />
          ) : null
        }
        color="warning"
        pressableIconColor="primary"
        iconSquareVariant="standard"
        note={note}
      />
      {!!footer && (
        <>
          <Divider />
          <ContentArea size="compact" variant="compact">
            {footer}
          </ContentArea>
        </>
      )}
    </Surface>
  );
};

export default createRelayFragmentContainer<ShiftPreviewRow_shift$key, Props>(
  graphql`
    fragment ShiftPreviewRow_shift on Gig {
      id
      name
      organization {
        logoUrl
      }
      requiredReportTypes
    }
  `,
  ShiftPreviewRow
);
