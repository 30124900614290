import { Pressable, Snackbar, Stack, Surface, View } from "@gigsmart/atorasu";
import { HourlyRateBids } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { gigHelpers } from "@gigsmart/isomorphic-shared/gig";
import {
  type NavigationProp,
  useCurrentUrl,
  useHistory,
  useNavigation
} from "@gigsmart/kaizoku";
import { createRelayNodeContainer, graphql } from "@gigsmart/relay";
import WorkerShiftApplets from "@gigsmart/seibutsu/shifts/WorkerShiftApplets";
import { isEqual } from "lodash";
import React, {
  type ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";
import EngagementDetailsCard from "../gig/engagement-details/EngagementDetailsCard";
import ActiveEngagementSummaryRow from "../gig/engagement-top-card/active-engagement-summary-row";
import usePendingOfferCount from "../gig/use-pending-offer-count";
import type { WorkerParamList } from "../navigation/types";
import type { offeredGigTopSectionQuery } from "./__generated__/offeredGigTopSectionQuery.graphql";
import type { offeredGigTopSectionSubscription } from "./__generated__/offeredGigTopSectionSubscription.graphql";
import type { offeredGigTopSection_worker$key } from "./__generated__/offeredGigTopSection_worker.graphql";

const HIDE_CURRENT_ENGAGEMENT: string[] = ["/about", "/support"];

const HIDE_TOP_SECTION: string[] = [
  "/conversation",
  "/gigs/",
  "/browse",
  "/shifts",
  "/projects",
  "/jobs",
  "/gigsr",
  "/feature"
];

const IGNORE_STATES: Array<gigHelpers.EngagementStateName | undefined> = [
  "PENDING_TIMESHEET_APPROVAL",
  "ENDED"
];

export default createRelayNodeContainer<
  offeredGigTopSection_worker$key,
  offeredGigTopSectionQuery,
  offeredGigTopSectionSubscription
>(
  function OfferedGigTopSection({ result: worker }) {
    const nav = useNavigation<NavigationProp<WorkerParamList>>();
    const currentUrl = useCurrentUrl();
    const history = useHistory();

    let currentEngagement = worker?.currentEngagement ?? null;
    const stateName = currentEngagement?.currentState?.name;
    const {
      totalPendingOffers: allOffers,
      totalShiftOffers,
      totalProjectOffers,
      totalBidRequests
    } = usePendingOfferCount(worker);
    const totalPendingOffers = totalShiftOffers;
    const engagementId = worker?.currentEngagement?.id ?? "";
    const pendingTimesheetCount = worker?.pendingTimesheets?.totalCount ?? 0;
    if (IGNORE_STATES.includes(stateName)) {
      currentEngagement = null;
    }

    const handleCardPress = useCallback(() => {
      if (!engagementId) return;
      const newPath = `/gigs/${engagementId}`;
      if (newPath !== currentUrl) history.push(newPath);
    }, [engagementId, currentUrl, history]);

    const [display, setDisplay] = useState(() => displayNextState(currentUrl));
    useEffect(() => {
      const next = displayNextState(currentUrl);
      if (!isEqual(next, display)) setDisplay(displayNextState(currentUrl));
    }, [currentUrl, display]);

    const projectOffersSnackbarRender = () => {
      return (
        <Snackbar
          icon="circle-exclamation"
          count={totalProjectOffers}
          label={pluralize(totalProjectOffers, "Pending Project Offer", false)}
          buttonLabel="View"
          buttonColor="danger"
          onPress={() => nav.navigate("OfferedGigs", { gigType: "PROJECT" })}
          surfaceVariant="flat"
        />
      );
    };
    const pendingOffersSnackbar = () => {
      return HourlyRateBids.select(
        <Snackbar
          icon="circle-exclamation"
          count={totalPendingOffers}
          label={pluralize(totalPendingOffers, "Pending Offer", false)}
          buttonLabel="View"
          buttonColor="danger"
          onPress={() =>
            nav.navigate("OfferedGigs", { gigType: "SHIFT", state: "OFFERED" })
          }
          surfaceVariant="flat"
        />,
        <Snackbar
          icon="circle-exclamation"
          count={totalPendingOffers}
          label={pluralize(totalPendingOffers, "Unread Offer", false)}
          buttonLabel="View"
          buttonColor="danger"
          onPress={() => nav.navigate("OfferedGigs", { gigType: "ALL" })}
          surfaceVariant="flat"
        />
      );
    };

    const pendingBidRequestsSnackbar = () => {
      return HourlyRateBids.select(
        <Snackbar
          icon="circle-exclamation"
          buttonLabel="View"
          buttonColor="danger"
          surfaceVariant="flat"
          onPress={() =>
            nav.navigate("OfferedGigs", {
              gigType: "SHIFT",
              state: "BID_REQUESTED"
            })
          }
          count={totalBidRequests}
          label={pluralize(totalBidRequests, "Pending Bid Requests", false)}
        />,
        null
      );
    };

    const content: ReactElement | null = currentEngagement ? (
      <Surface zIndex={4} hideInnerMargin>
        <Stack variant="divider">
          {totalProjectOffers > 0 && projectOffersSnackbarRender()}
          {totalPendingOffers > 0 && pendingOffersSnackbar()}
          {totalBidRequests > 0 && pendingBidRequestsSnackbar()}
          {pendingTimesheetCount > 0 && (
            <Snackbar
              icon="file-lines"
              count={pendingTimesheetCount}
              label={`${pluralize(pendingTimesheetCount, "Timesheet", false)} ${
                pendingTimesheetCount === 1 ? "Needs" : "Need"
              } Review`}
              buttonLabel="View"
              buttonColor="danger"
              onPress={() => history.push("/shifts?shiftsTab=timesheetReview")}
              surfaceVariant="flat"
            />
          )}
          <View style={{ display: display.engagement }}>
            <Pressable
              eventEntityType="Card"
              testID="engagement-card"
              eventTargetName="CurrentEngagement"
              onPress={handleCardPress}
            >
              <EngagementDetailsCard
                isActiveShift
                fragmentRef={currentEngagement}
                actionNode={
                  <ActiveEngagementSummaryRow
                    engagementRef={currentEngagement}
                  />
                }
              />
            </Pressable>
            <WorkerShiftApplets fragmentRef={currentEngagement} />
          </View>
        </Stack>
      </Surface>
    ) : (
      <Surface zIndex={4} hideInnerMargin>
        {totalProjectOffers > 0 && projectOffersSnackbarRender()}
        {totalPendingOffers > 0 && pendingOffersSnackbar()}
        {totalBidRequests > 0 && pendingBidRequestsSnackbar()}
        {pendingTimesheetCount > 0 && (
          <Snackbar
            icon="file-lines"
            count={pendingTimesheetCount}
            label={`${pluralize(pendingTimesheetCount, "Timesheet", false)} ${
              pendingTimesheetCount === 1 ? "Needs" : "Need"
            } Review`}
            buttonLabel="View"
            buttonColor="danger"
            onPress={() => history.push("/shifts?shiftsTab=timesheetReview")}
          />
        )}
      </Surface>
    );

    return <View style={{ display: display.topSection }}>{content}</View>;
  },
  {
    query: graphql`
      query offeredGigTopSectionQuery {
        offeredViewer: viewer {
            ...offeredGigTopSection_worker
        }
      }
    `,
    fragment: graphql`
      fragment offeredGigTopSection_worker on Worker {
        id
        ...usePendingOfferCount_worker
        currentEngagement {
          ...WorkerShiftApplets_shift
          ...EngagementDetailsCard_engagement
          ...activeEngagementSummaryRow_engagement
          id
          currentState {
            name
          }
          reviewOfRequester {
            id
          }
        }
        pendingTimesheets: engagements(
          first: 0
          query: "WHERE workerCanApproveTimesheet = TRUE"
        ) {
          totalCount
        }
      }
    `,
    subscription: graphql`
      subscription offeredGigTopSectionSubscription {
        workerUpdated {
          worker {
            ...offeredGigTopSection_worker
          }
        }
      }
    `,
    getQueryFragmentRef: (data) => data.offeredViewer,
    getSubscriptionFragmentRef: (data) => data?.workerUpdated?.worker,
    variables: {},
    fetchPolicy: "network-only"
  }
);

function displayNextState(url: string) {
  return {
    topSection: HIDE_TOP_SECTION.find((d) => url.indexOf(d) === 0)
      ? "none"
      : "flex",
    engagement: HIDE_CURRENT_ENGAGEMENT.find((d) => url.indexOf(d) === 0)
      ? "none"
      : "flex"
  } as const;
}
