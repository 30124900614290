import {
  Button,
  Collapsible,
  Column,
  ContentArea,
  Divider,
  FancyHeader,
  IconText,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";
import UnpaidEngagementRow from "./UnpaidEngagementRow";
import UnpaidProjectEngagementRow from "./UnpaidProjectEngagementRow";
import type { AccountBalanceCard_worker$key } from "./__generated__/AccountBalanceCard_worker.graphql";

export function AccountBalanceCard({
  walletBalance,
  payableAccount,
  engagements: engagementEdges
}: FragmentContainerInnerComponentProps<AccountBalanceCard_worker$key>) {
  const history = useHistory();
  const workerBalance = currency.toFloat(walletBalance);
  const hasPaymentMethod = !!payableAccount?.paymentMethod;
  const engagements = getConnectionNodes(engagementEdges);
  const engagementCount = engagementEdges?.totalCount ?? 0;
  return (
    <Surface>
      <FancyHeader icon="dollar-sign" title="Current Balance" />
      <ContentArea>
        <Stack>
          <Text
            color={workerBalance > 0 ? "success" : "black"}
            align="center"
            variant="title"
          >
            {currency.humanize(walletBalance)}
          </Text>
          {hasPaymentMethod && workerBalance > 0 && (
            <Button
              testID="transfer-btn"
              label="Transfer"
              onPress={() => history.push("/wallet/transfer")}
            />
          )}
          {!hasPaymentMethod && (
            <Text color="neutral" align="center" variant="note">
              A debit card or bank account is required to transfer money
            </Text>
          )}
        </Stack>
      </ContentArea>
      {engagementCount > 0 && (
        <Stack>
          <Divider />
          <ContentArea size="none">
            <Collapsible
              startCollapsed
              testID="unpaid-engagements-collapsible"
              variant="outline"
              header={
                <IconText icon="circle-info" size="small">
                  {" "}
                  {engagementCount === 1
                    ? "Your balance is from your last Shift"
                    : `Your balance is from your last ${pluralize(
                        engagementCount,
                        "Shifts"
                      )}`}
                </IconText>
              }
            >
              <Column fill>
                {engagements?.map((engagement, index) => {
                  if (!engagement) return null;
                  if (engagement.gigType === "PROJECT")
                    return (
                      <UnpaidProjectEngagementRow
                        key={engagement?.id}
                        fragmentRef={engagement}
                        first={index === 0}
                      />
                    );
                  return (
                    <UnpaidEngagementRow
                      key={engagement?.id}
                      fragmentRef={engagement}
                      first={index === 0}
                    />
                  );
                })}
              </Column>
            </Collapsible>
            <Spacer />
          </ContentArea>
        </Stack>
      )}
    </Surface>
  );
}

export default createRelayFragmentContainer<AccountBalanceCard_worker$key>(
  graphql`
  fragment AccountBalanceCard_worker on Worker {
    walletBalance
    payableAccount {
      paymentMethod {
        __typename
      }
    }
    engagements(
      first: 20
      query: "WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID AND requiredReportType != EOR_WORKER ORDER BY endsAt DESC"
    ) {
      edges {
        node {
          id
          endsAt
          currentState {
            name
          }
          gigType
          ...UnpaidEngagementRow_engagement
          ...UnpaidProjectEngagementRow_engagement
        }
      }
      totalCount
    }
  }
  `,
  AccountBalanceCard
);
