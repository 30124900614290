import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { useEffect, useState } from "react";

import { useUserValue } from "../user-value";

export default function useSearchDistance(
  fallback = UnlimitedDistanceFeature.select(12425, 75)
) {
  const [isLoaded, setLoaded] = useState(false);
  const [distance, persistDistance] = useUserValue<number>(
    "availableGigSearchDistance"
  );

  useEffect(() => {
    if (distance === undefined || isLoaded) return; /// not loaded

    const minDistance = 75;
    let newDistance = distance || fallback;

    if (UnlimitedDistanceFeature.isDisabled()) {
      newDistance = Math.min(newDistance, minDistance);
    }

    if (distance !== newDistance) persistDistance(newDistance);
    setLoaded(true);
  }, [isLoaded, distance, fallback]);

  return {
    distance: distance ?? fallback,
    persistDistance,
    isLoaded
  };
}
