import {
  graphql,
  readRelayInlineFragment,
  useRelayFragment
} from "@gigsmart/relay";
import moment from "moment-timezone";
import { useMemo } from "react";
import type { useEnrollmentData_legalInformation$key } from "./__generated__/useEnrollmentData_legalInformation.graphql";
import type {
  ReportType,
  useEnrollmentData_verification$key
} from "./__generated__/useEnrollmentData_verification.graphql";
import type { useEnrollmentData_worker$key } from "./__generated__/useEnrollmentData_worker.graphql";

const reportStarted = ({ status } = { status: "NOT_STARTED" }) =>
  !["NOT_STARTED", "NOT_ELIGIBLE"].includes(status);

export function useEnrollmentDataFragment(
  workerRef?: useEnrollmentData_worker$key | null
) {
  const worker = useRelayFragment(
    graphql`
      fragment useEnrollmentData_worker on Worker {
        firstName
        lastName
        postalCode
        street1
        street2
        birthdate
        locality
        administrativeArea1
        primaryEmail {
          address
        }
        legalInformation {
          ...useEnrollmentData_legalInformation
        }
        verification {
          ...useEnrollmentData_verification
        }
        payableAccount {
          paymentMethodId
        }
      }
    `,
    workerRef ?? null
  );

  return useMemo(() => {
    const legal =
      readRelayInlineFragment<useEnrollmentData_legalInformation$key>(
        graphql`
          fragment useEnrollmentData_legalInformation on WorkerLegalInformation
          @inline {
            id
            sendReportCopies
            firstName
            middleName
            lastName
            birthdate
            street1
            street2
            locality
            administrativeArea1
            emailAddress
            postalCode
            backgroundCheckOptIn
            driverCheckOptIn
            driversLicenseLast4
            driversLicenseIssuer
            drugScreenOptIn
            pspTosAccepted
          }
        `,
        worker?.legalInformation ?? null
      );
    const verification =
      readRelayInlineFragment<useEnrollmentData_verification$key>(
        graphql`
          fragment useEnrollmentData_verification on WorkerVerification
          @inline {
            status
            reports {
              type
              status
              state
              statusDescription
            }
          }
        `,
        worker?.verification ?? null
      );

    if (!worker || typeof legal === "undefined") return;
    const verificationStatus = verification?.status;
    const emailAddress = worker.primaryEmail?.address ?? "";
    const prefiledEmail = emailAddress.endsWith("@privaterelay.appleid.com")
      ? ""
      : emailAddress;
    return {
      id: legal?.id,
      sendReportCopies: legal?.sendReportCopies,
      status: verificationStatus,
      firstName: legal?.firstName ?? worker.firstName,
      middleName: legal?.middleName,
      lastName: legal?.lastName ?? worker.lastName,
      street1: legal?.street1 ?? worker.street1,
      street2: legal?.street2 ?? worker.street2,
      emailAddress: legal?.emailAddress ?? prefiledEmail,
      locality: legal?.locality ?? worker.locality,
      administrativeArea1:
        legal?.administrativeArea1 ?? worker.administrativeArea1,
      postalCode: legal?.postalCode ?? worker.postalCode,
      birthdate: moment(
        legal?.birthdate ?? worker.birthdate ?? undefined
      ).format("YYYY-MM-DD"),
      backgroundCheckOptIn: legal?.backgroundCheckOptIn,
      driverCheckOptIn: legal?.driverCheckOptIn,
      driversLicenseLast4: legal?.driversLicenseLast4,
      drugScreenOptIn: legal?.drugScreenOptIn,
      driversLicenseIssuer: legal?.driversLicenseIssuer,
      pspTosAccepted:
        legal?.pspTosAccepted ?? verificationStatus !== "NOT_ENROLLED",
      reports: verification?.reports ?? [],
      hasReport: (report: ReportType) =>
        reportStarted(
          verification?.reports.find(({ type }) => type === report)
        ),
      hasReports: () => verification?.reports?.some(reportStarted),
      paymentMethodId: worker.payableAccount?.paymentMethodId
    };
  }, [worker]);
}
