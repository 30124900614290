import React, { type ComponentProps, type ReactNode } from "react";

import {
  Button,
  Card,
  ContentArea,
  Divider,
  FancyHeader,
  type IconName,
  Pressable,
  type UnitSize,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import type { ColorProp } from "@gigsmart/atorasu/style/theme/colors";

interface Props {
  headerTitle: string;
  headerTitleColor?: ColorProp;
  headerRightSpacing?: ComponentProps<typeof FancyHeader>["rightSpacing"];
  headerIcon: IconName;
  headerColor?: ColorProp;
  headerVariant?: ColorProp;
  onHeaderPress?: () => void;
  onDismiss?: () => void;
  children: ReactNode;
  contentAreaSize?: ComponentProps<typeof ContentArea>["size"];
  contentAreaVariant?: ComponentProps<typeof ContentArea>["variant"];
  contentAreaGap?: UnitSize;
  footerLinkText?: string;
  footerLinkUrl?: string;
  footer?: ReactNode;
  testID?: string;
  footerIcon?: IconName;
  footerIconPlacement?: "left" | "right";
  onFooterPress?: () => void;
  action?: ReactNode;
  hideContent?: boolean;
}

export default function HomeScreenCard({
  headerTitle,
  headerTitleColor,
  headerRightSpacing,
  headerIcon,
  headerColor,
  headerVariant,
  onHeaderPress,
  onDismiss,
  children,
  contentAreaSize,
  contentAreaVariant,
  contentAreaGap,
  footerLinkText,
  footerLinkUrl,
  footerIcon,
  footerIconPlacement = "left",
  footer,
  onFooterPress,
  action,
  testID = "home-screen-card",
  hideContent
}: Props) {
  const onPress = () => showEmbeddedBrowser({ url: footerLinkUrl ?? "" });

  return (
    <Card testID={testID} eventTargetName="Home Screen Card Header">
      {onHeaderPress ? (
        <Pressable
          onPress={onHeaderPress}
          disabled={!onHeaderPress}
          eventTargetName="Home Screen Card Header Pressable"
          testID={`${testID}-pressable`}
          eventEntityType="homeScreenCardHeaderPress"
        >
          <FancyHeader
            icon={headerIcon}
            title={headerTitle}
            onDismiss={onDismiss}
            testID={testID}
            color={headerColor}
            titleColor={headerTitleColor}
            variant={headerVariant ?? headerColor}
            action={action}
            rightSpacing={headerRightSpacing}
          />
        </Pressable>
      ) : (
        <FancyHeader
          icon={headerIcon}
          title={headerTitle}
          testID={testID}
          onDismiss={onDismiss}
          color={headerColor}
          titleColor={headerTitleColor}
          variant={headerVariant ?? headerColor}
          action={action}
          rightSpacing={headerRightSpacing}
        />
      )}
      {!hideContent && (
        <ContentArea
          size={contentAreaSize}
          variant={contentAreaVariant}
          gap={contentAreaGap}
        >
          {children}
        </ContentArea>
      )}
      {!footer && (footerLinkText || footerLinkUrl) && (
        <>
          <Divider />
          <Button
            icon={footerIcon ?? "external-link"}
            iconPlacement={footerIconPlacement}
            label={footerLinkText ?? ""}
            onPress={onFooterPress ?? onPress}
            color="primary"
            variant="clear"
            size="small"
            testID={`${testID}-footer-button`}
          />
        </>
      )}
      {footer && (
        <>
          <Divider />
          {footer}
        </>
      )}
    </Card>
  );
}
