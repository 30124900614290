/**
 * @generated SignedSource<<f7fe6166f719144c7786bcd6bca27e86>>
 * @relayHash 7f4759ef2249e11ae87678545b78ee54
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:c9FolzE4YCbqNZC2BIfQFDBZ4Xqg0sIxJMkK5qDeh_Y

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type TransitionEngagementInput = {
  action: EngagementStateAction;
  engagementId: string;
  onBehalfOf?: UserInput | null | undefined;
};
export type UserInput = {
  id: string;
  type: UserType;
};
export type offeredEntryEngagementMutation$variables = {
  input: TransitionEngagementInput;
};
export type offeredEntryEngagementMutation$data = {
  readonly transitionEngagement: {
    readonly engagement: {
      readonly availableActions: ReadonlyArray<EngagementStateAction>;
      readonly currentState: {
        readonly name: EngagementStateName;
      };
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"offeredEntry_engagement">;
    };
  } | null | undefined;
};
export type offeredEntryEngagementMutation = {
  response: offeredEntryEngagementMutation$data;
  variables: offeredEntryEngagementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableActions",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "offeredEntryEngagementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransitionEngagementPayload",
        "kind": "LinkedField",
        "name": "transitionEngagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "offeredEntry_engagement"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "offeredEntryEngagementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransitionEngagementPayload",
        "kind": "LinkedField",
        "name": "transitionEngagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Place",
                    "kind": "LinkedField",
                    "name": "place",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "streetAddress",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "placeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "area",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "negotiable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigPosition",
                    "kind": "LinkedField",
                    "name": "position",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 0
                      }
                    ],
                    "concreteType": "GigSkillsConnection",
                    "kind": "LinkedField",
                    "name": "skills",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "skills(first:0)"
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPosted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requiredReportTypes",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestArrivalTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "skill",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workerDistance",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementPaymentInfo",
                "kind": "LinkedField",
                "name": "estimatedPayment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "netPay",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:c9FolzE4YCbqNZC2BIfQFDBZ4Xqg0sIxJMkK5qDeh_Y",
    "metadata": {},
    "name": "offeredEntryEngagementMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "499d05ec853b9f01ce9f341efecb8a00";

export default node;
