import {
  Column,
  type FilterTabOption,
  FilterTabs,
  IconText,
  Text
} from "@gigsmart/atorasu";
import { EORWorkers, HourlyRateBids } from "@gigsmart/feature-flags";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import {} from "@gigsmart/relay";
import DismissableCard from "@gigsmart/seibutsu/shared/DismissableCard";
import type { WorkerEngagementSummaryState } from "@gigsmart/seibutsu/worker/useWorkerEngagementSummary";
import { compact } from "lodash";
import React, { useMemo } from "react";
import type { WorkerParamList } from "../../navigation/types";
import type {
  CqlFilterEngagementInput,
  CqlOrderEngagementInput
} from "./__generated__/ShiftGigsTabQuery.graphql";

export type ShiftFilterType = NonNullable<
  WorkerParamList["MyGigs"]["shiftsTab"]
>;

const allStates: EngagementStateName[] = [
  "PAUSED",
  "WORKING",
  "EN_ROUTE",
  "RUNNING_LATE",
  "AWAITING_START",
  "SCHEDULED",
  "CONFIRMING",
  "PENDING_TIMESHEET_APPROVAL"
];

const hiredStates: EngagementStateName[] = [
  "PAUSED",
  "WORKING",
  "EN_ROUTE",
  "RUNNING_LATE",
  "AWAITING_START",
  "SCHEDULED"
];

export const SHIFT_QUERY_STATES: Record<
  ShiftFilterType,
  CqlFilterEngagementInput
> = {
  shifts: {
    _or: [
      { currentStateName: { _in: allStates } },
      {
        currentStateName: { _eq: "ENDED" },
        anyEngagementDisputeDisposition: { _isNull: true }
      }
    ]
  },
  hired: {
    currentStateName: { _in: hiredStates }
  },
  confirming: {
    currentStateName: { _eq: "CONFIRMING" }
  },
  applied: {
    currentStateName: { _eq: "APPLIED" }
  },
  timesheetReview: {
    currentStateName: { _eq: "PENDING_TIMESHEET_APPROVAL" },
    workerCanApproveTimesheet: { _eq: true }
  },
  paymentPending: {
    currentStateName: { _eq: "PENDING_TIMESHEET_APPROVAL" },
    workerCanApproveTimesheet: { _eq: false }
  },
  disputePending: {
    currentStateName: { _eq: "ENDED" },
    anyEngagementDisputeDisposition: { _isNull: true }
  },
  pendingOffers: {
    currentStateName: { _in: ["OFFERED"] }
  },
  bidSubmitted: {
    currentStateName: { _eq: "BID_REVIEW" }
  },
  bidRequests: {
    currentStateName: { _eq: "BID_REQUESTED" }
  }
};

const makeOrderBy = (
  filter: ShiftFilterType = "shifts"
): CqlOrderEngagementInput[] => {
  switch (filter) {
    case "shifts":
      return [
        {
          currentStateName: {
            priority: [...allStates, "ENDED"],
            direction: "ASC"
          }
        },
        { startsAt: { direction: "ASC_NULLS_FIRST" } }
      ];
    case "hired":
      return [
        { currentStateName: { priority: hiredStates, direction: "ASC" } },
        { startsAt: { direction: "DESC_NULLS_FIRST" } }
      ];
    case "applied":
    case "confirming":
    case "bidSubmitted":
    case "bidRequests":
    case "pendingOffers":
      return [{ startsAt: { direction: "ASC_NULLS_FIRST" } }];
    case "paymentPending":
    case "disputePending":
      return [{ gigEndsAt: { direction: "ASC_NULLS_FIRST" } }];
    case "timesheetReview":
      return [{ currentStateTransitionedAt: { direction: "ASC" } }];
    default:
      throw new Error("Invalid params");
  }
};

export const makeShiftQuery = (filter: ShiftFilterType = "shifts") => {
  const where: CqlFilterEngagementInput = {
    ...SHIFT_QUERY_STATES[filter],
    gigType: { _neq: "PROJECT" }
  };
  return { where, orderBy: makeOrderBy(filter) };
};

type Props = {
  getCount: (state: WorkerEngagementSummaryState) => number;
  active?: ShiftFilterType;
  onChange: (value: ShiftFilterType) => void;
};

export default function ShiftGigsFilter({
  active = "shifts",
  getCount,
  onChange
}: Props) {
  const filters = useMemo(() => {
    const shiftsCount = getCount("ALL_SHIFTS");
    const pendingOffersCount = getCount("OFFERED");
    const bidRequestsCount = getCount("BID_REQUESTED");
    return compact<FilterTabOption & { key: ShiftFilterType }>([
      {
        key: "shifts",
        label: pluralize(shiftsCount, "Shifts", false),
        count: shiftsCount
      },
      { key: "hired", label: "Hired", count: getCount("ALL_HIRED") },
      {
        key: "confirming",
        label: "Confirming",
        count: getCount("CONFIRMING")
      },
      {
        key: "pendingOffers",
        label: pluralize(pendingOffersCount, "Pending Offer", false),
        count: pendingOffersCount
      },
      {
        key: "bidRequests",
        label: pluralize(bidRequestsCount, "Bid Request", false),
        count: bidRequestsCount
      },
      {
        key: "timesheetReview",
        label: "Timesheet Review",
        count: getCount("TIMESHEET_REVIEW")
      },
      {
        key: "paymentPending",
        label: "Payment Pending",
        count: getCount("PAYMENT_PENDING")
      },
      {
        key: "disputePending",
        label: "Dispute Pending",
        count: getCount("PENDING_DISPUTE")
      },
      {
        key: "bidSubmitted",
        label: "Bid Submitted",
        count: getCount("BID_REVIEW")
      },
      EORWorkers.isEnabled() && {
        key: "applied",
        label: "Applied",
        count: getCount("APPLIED")
      }
    ]);
  }, [getCount]);

  const index = filters.findIndex((it) => it.key === active);
  return (
    <Column gap="standard">
      <FilterTabs
        tabs={filters}
        value={index}
        onChange={(idx) => {
          const newValue = filters[idx]?.key;
          if (newValue) onChange(newValue);
        }}
      />
      {HourlyRateBids.select(
        null,
        <DismissableCard
          testID="applied-info-card"
          userValueKey="MY_GIGS_APPLIED_INFO_CARD"
        >
          <Column gap="standard">
            <IconText icon="user-check" color="primary" textWeight="bold">
              Note: Shift Applications
            </IconText>
            <Text>
              The list is meant to help you manage upcoming Shifts. Any Shifts
              you have applied to will not appear here. You can view Shifts you
              have applied to in the Applied tab.
            </Text>
          </Column>
        </DismissableCard>
      )}
    </Column>
  );
}
