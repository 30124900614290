/**
 * @generated SignedSource<<6e731d25b8ae67f3f19e113d2a205854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type W2EarningsCard_worker$data = {
  readonly dy0: string;
  readonly dy1: string;
  readonly dy2: string;
  readonly dy3: string;
  readonly lifetime: string;
  readonly sevenDay: string;
  readonly thirtyDay: string;
  readonly " $fragmentType": "W2EarningsCard_worker";
};
export type W2EarningsCard_worker$key = {
  readonly " $data"?: W2EarningsCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"W2EarningsCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "W2EarningsCard_worker",
  "selections": [
    {
      "alias": "lifetime",
      "args": null,
      "kind": "ScalarField",
      "name": "totalEarnings",
      "storageKey": null
    },
    {
      "alias": "sevenDay",
      "args": [
        {
          "kind": "Literal",
          "name": "dateFrom",
          "value": "-P7D"
        }
      ],
      "kind": "ScalarField",
      "name": "totalEarnings",
      "storageKey": "totalEarnings(dateFrom:\"-P7D\")"
    },
    {
      "alias": "thirtyDay",
      "args": [
        {
          "kind": "Literal",
          "name": "dateFrom",
          "value": "-P30D"
        }
      ],
      "kind": "ScalarField",
      "name": "totalEarnings",
      "storageKey": "totalEarnings(dateFrom:\"-P30D\")"
    },
    {
      "alias": "dy0",
      "args": [
        {
          "kind": "Literal",
          "name": "dateFrom",
          "value": "^PY"
        },
        {
          "kind": "Literal",
          "name": "dateTo",
          "value": "-P0D"
        }
      ],
      "kind": "ScalarField",
      "name": "totalEarnings",
      "storageKey": "totalEarnings(dateFrom:\"^PY\",dateTo:\"-P0D\")"
    },
    {
      "alias": "dy1",
      "args": [
        {
          "kind": "Literal",
          "name": "dateFrom",
          "value": "-P1Y^PY"
        },
        {
          "kind": "Literal",
          "name": "dateTo",
          "value": "-P1Y$PY"
        }
      ],
      "kind": "ScalarField",
      "name": "totalEarnings",
      "storageKey": "totalEarnings(dateFrom:\"-P1Y^PY\",dateTo:\"-P1Y$PY\")"
    },
    {
      "alias": "dy2",
      "args": [
        {
          "kind": "Literal",
          "name": "dateFrom",
          "value": "-P2Y^PY"
        },
        {
          "kind": "Literal",
          "name": "dateTo",
          "value": "-P2Y$PY"
        }
      ],
      "kind": "ScalarField",
      "name": "totalEarnings",
      "storageKey": "totalEarnings(dateFrom:\"-P2Y^PY\",dateTo:\"-P2Y$PY\")"
    },
    {
      "alias": "dy3",
      "args": [
        {
          "kind": "Literal",
          "name": "dateFrom",
          "value": "-P3Y^PY"
        },
        {
          "kind": "Literal",
          "name": "dateTo",
          "value": "-P3Y$PY"
        }
      ],
      "kind": "ScalarField",
      "name": "totalEarnings",
      "storageKey": "totalEarnings(dateFrom:\"-P3Y^PY\",dateTo:\"-P3Y$PY\")"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "535a1c865f873db7abbbe5f282f00434";

export default node;
