import { Column, HighlightedReminder, Row, Tag, Text } from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import React from "react";

export default function EOREnrollmentStatus() {
  const user = useCurrentUser();
  const eorState = user?.verification?.reports?.find(
    ({ type }) => type === "EOR_WORKER"
  )?.state;

  if (!["PENDING", "REQUIRES_ATTENTION"].includes(eorState ?? "")) return null;

  return (
    <Column gap="standard">
      <Row gap="compact" alignItems="center">
        <Text weight="bold" color="primary">
          Ascen Enrollment Status:
        </Text>
        <Tag color="neutral" label={eorStateLookup(eorState ?? "")} />
      </Row>
      {["REQUIRES_ATTENTION", "PENDING"].includes(eorState ?? "") && (
        <HighlightedReminder
          icon="circle-exclamation"
          header="You should have received email communication from Ascen on how to continue the enrollment process."
        />
      )}
    </Column>
  );
}

function eorStateLookup(status: string) {
  switch (status) {
    case "PENDING":
      return "Incomplete";
    case "REQUIRES_ATTENTION":
      return "Incomplete";
    default:
      return "Status Not Found";
  }
}
