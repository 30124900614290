import { Button, ContentArea, IconText, Row, Stack } from "@gigsmart/atorasu";
import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useMemo } from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import { SeriesShiftPreviewList } from "../../gig/worker/ShiftPreviewList";
import HomeScreenCard from "../HomescreenCard";
import JobPostSection from "./JobBoardSection";
import type { AvailableGigsCardQuery } from "./__generated__/AvailableGigsCardQuery.graphql";

interface AvailableGigsCardWithQueryProps {
  geolocation: any;
}

export default createSuspendedQueryContainer<
  AvailableGigsCardQuery,
  AvailableGigsCardWithQueryProps
>(
  ({ response }) => {
    const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
    const renderSection = (type: "shifts" | "eor" | "projects") => {
      const { icon, title, data } = {
        shifts: {
          title: "Shifts",
          icon: "business-time" as const,
          data: response?.viewer?.shifts
        },
        eor: {
          title: "W-2 Shifts",
          icon: "business-time" as const,
          data: response?.viewer?.eorShifts
        },
        projects: {
          title: "Project Gigs",
          icon: "briefcase" as const,
          data: response?.viewer?.projects
        }
      }[type];

      if (!data?.totalCount) return null;

      return (
        <ContentArea gap="compact" key={type}>
          <Row alignItems="center" justifyContent="space-between">
            <IconText icon={icon} textVariant="subheader" color="primary">
              {title}
            </IconText>
            <Button
              testID={`view-all-${type}-btn`}
              color="primary"
              icon="chevron-right"
              iconPlacement="right"
              label={
                type === "projects"
                  ? "View All Project Gigs"
                  : "View All Shifts"
              }
              onPress={() =>
                type === "projects"
                  ? navigation.push("BrowseProjects")
                  : navigation.push("BrowseShifts", { type })
              }
              variant="clear"
              size="small"
            />
          </Row>
          <SeriesShiftPreviewList fragmentRef={data} />
        </ContentArea>
      );
    };

    return (
      <HomeScreenCard
        headerIcon="location-dot"
        headerTitle="Available In Your Area"
        testID="available-area-card"
        contentAreaSize="none"
        contentAreaVariant="none"
      >
        <Stack variant="divider">
          {renderSection("shifts")}
          {renderSection("eor")}
          {renderSection("projects")}
          <JobPostSection
            jobPostFragmentRef={response?.viewer}
            affiliateJobPostFragmentRef={response}
          />
        </Stack>
      </HomeScreenCard>
    );
  },
  {
    query: graphql`
      query AvailableGigsCardQuery(
        $maxDistance: Int!
        $jobPostSearch: JobPostingSearch!
        $affiliateJobPostSearch: AffiliateJobPostingsInput!
      ) {
        ...JobBoardSection_root
          @arguments(affiliateJobPostSearch: $affiliateJobPostSearch)
        viewer {
          ... on Worker {
            ...JobBoardSection_worker @arguments(jobPostSearch: $jobPostSearch)

            shifts: availableGigSeries(first: 3, input: {
              maxDistance: $maxDistance,
              gigTypes: [PAID, VOLUNTEER],
              requiredReports: [IDENTITY_CHECK]
            }) {
              ...ShiftPreviewList_series
              totalCount
            }
            eorShifts: availableGigSeries(first: 3, input: {
              maxDistance: $maxDistance,
              gigTypes: [PAID, VOLUNTEER],
              requiredReports: [EOR_WORKER]
            }) {
              ...ShiftPreviewList_series
              totalCount
            }
            projects: availableGigSeries(first: 3, input: {
              maxDistance: $maxDistance,
              gigTypes: [PROJECT]
            }) {
              ...ShiftPreviewList_series
              totalCount
            }
          }
        }
      }
    `,
    variables: ({ geolocation }) => {
      const maxDistance = UnlimitedDistanceFeature.select(12425, 75);
      return useMemo(
        () => ({
          maxDistance,
          jobPostSearch: {
            maxDistance,
            orderBy: [{ field: "boosted", direction: "DESC" as const }],
            latitude: geolocation.latitude,
            longitude: geolocation.longitude
          },
          affiliateJobPostSearch: {
            maxDistance,
            latitude: geolocation.latitude,
            longitude: geolocation.longitude
          }
        }),
        [maxDistance, geolocation?.latitude, geolocation?.latitude]
      );
    }
  }
);
