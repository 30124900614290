/**
 * @generated SignedSource<<5a20ebd88ab0971a675d727cff9d7595>>
 * @relayHash 1be156830551c493422351ad9f55fa76
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:UjmEiRXPv1Xe-61b9Bm5nuA6QFDu32i76GqXEfCBVWQ

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiDaySelectGigsStepQuery$variables = {
  id: string;
  isApply: boolean;
  maxDistance: number;
};
export type MultiDaySelectGigsStepQuery$data = {
  readonly worker: {
    readonly gigSeries?: {
      readonly node: {
        readonly availableGigs?: {
          readonly edges: ReadonlyArray<{
            readonly distance: number | null | undefined;
            readonly estimatedPayment: {
              readonly netPay: string;
            } | null | undefined;
            readonly node: {
              readonly payRate: string | null | undefined;
              readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_gig">;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly engagements?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_engagement">;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MultiDaySelectGigsStepQuery = {
  response: MultiDaySelectGigsStepQuery$data;
  variables: MultiDaySelectGigsStepQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isApply"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDistance"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 30
},
v6 = [
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE currentStateName = OFFERED"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPay",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v15 = [
  (v5/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "maxDistance",
        "variableName": "maxDistance"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distance",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualStartsAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestArrivalTime",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MultiDaySelectGigsStepQuery",
    "selections": [
      {
        "alias": "worker",
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "AvailableGigSeriesEdge",
                "kind": "LinkedField",
                "name": "gigSeries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "condition": "isApply",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v6/*: any*/),
                            "concreteType": "EngagementsConnection",
                            "kind": "LinkedField",
                            "name": "engagements",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Engagement",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "InlineDataFragmentSpread",
                                        "name": "MultiDaySelectGigsStep_engagement",
                                        "selections": [
                                          (v4/*: any*/),
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Gig",
                                            "kind": "LinkedField",
                                            "name": "gig",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/),
                                              (v12/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v13/*: any*/)
                                        ],
                                        "args": null,
                                        "argumentDefinitions": []
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": "engagements(first:30,query:\"WHERE currentStateName = OFFERED\")"
                          }
                        ]
                      },
                      {
                        "condition": "isApply",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v15/*: any*/),
                            "concreteType": "AvailableGigsConnection",
                            "kind": "LinkedField",
                            "name": "availableGigs",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AvailableGigsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  (v16/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/),
                                      {
                                        "kind": "InlineDataFragmentSpread",
                                        "name": "MultiDaySelectGigsStep_gig",
                                        "selections": [
                                          (v4/*: any*/),
                                          (v8/*: any*/),
                                          (v17/*: any*/),
                                          (v9/*: any*/),
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          {
                                            "kind": "InlineDataFragmentSpread",
                                            "name": "WorkerLatestArrivalTimeReminder_gig",
                                            "selections": [
                                              (v18/*: any*/),
                                              (v8/*: any*/),
                                              (v19/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "GigState",
                                                "kind": "LinkedField",
                                                "name": "currentState",
                                                "plural": false,
                                                "selections": [
                                                  (v20/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "args": null,
                                            "argumentDefinitions": []
                                          }
                                        ],
                                        "args": null,
                                        "argumentDefinitions": []
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MultiDaySelectGigsStepQuery",
    "selections": [
      {
        "alias": "worker",
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "AvailableGigSeriesEdge",
                "kind": "LinkedField",
                "name": "gigSeries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "condition": "isApply",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v6/*: any*/),
                            "concreteType": "EngagementsConnection",
                            "kind": "LinkedField",
                            "name": "engagements",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Engagement",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Gig",
                                        "kind": "LinkedField",
                                        "name": "gig",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": "engagements(first:30,query:\"WHERE currentStateName = OFFERED\")"
                          }
                        ]
                      },
                      {
                        "condition": "isApply",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v15/*: any*/),
                            "concreteType": "AvailableGigsConnection",
                            "kind": "LinkedField",
                            "name": "availableGigs",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AvailableGigsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  (v16/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v4/*: any*/),
                                      (v8/*: any*/),
                                      (v17/*: any*/),
                                      (v9/*: any*/),
                                      (v11/*: any*/),
                                      (v18/*: any*/),
                                      (v19/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigState",
                                        "kind": "LinkedField",
                                        "name": "currentState",
                                        "plural": false,
                                        "selections": [
                                          (v20/*: any*/),
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Worker",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:UjmEiRXPv1Xe-61b9Bm5nuA6QFDu32i76GqXEfCBVWQ",
    "metadata": {},
    "name": "MultiDaySelectGigsStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "73d67e5725e6eca130ccb6d00b44e6c6";

export default node;
