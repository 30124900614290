import {
  Card,
  Checkbox,
  Column,
  ContentArea,
  Divider,
  Pressable,
  Row,
  Spacer,
  Text,
  toast,
  useStepper
} from "@gigsmart/atorasu";
import { GigDateTimeContent } from "@gigsmart/isomorphic-shared/gig";
import {
  createSuspendedQueryContainer,
  graphql,
  readRelayInlineFragment
} from "@gigsmart/relay";
import WorkerLatestArrivalTimeReminder, {
  showLatestArrivalReminder
} from "@gigsmart/seibutsu/gig-series/WorkerLatestArrivalTimeReminder";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import type { ObjectPath } from "@gigsmart/type-utils";
import { compact, sortBy } from "lodash";
import React, { Fragment, useMemo, useState, type ReactNode } from "react";
import StepperNavActions from "../../shared/Stepper/StepperNavActions";
import type { MultiDaySelectGigsStepQuery } from "./__generated__/MultiDaySelectGigsStepQuery.graphql";
import type { MultiDaySelectGigsStep_engagement$key } from "./__generated__/MultiDaySelectGigsStep_engagement.graphql";
import type { MultiDaySelectGigsStep_gig$key } from "./__generated__/MultiDaySelectGigsStep_gig.graphql";

export interface MultiDaySelectEntry {
  value: string; // gig or engagement ID
  area?: string | null;
  estimatedPayment?: { netPay?: string | null } | null;
  startsAt?: string | null;
  actualStartsAt?: string | null;
  endsAt?: string | null;
  distance?: number | null;
  reminderNode?: ReactNode;
  payRate?: string | null;
}

export const createMultiDaySelectEntryFromGig = (
  fragmentRef?: MultiDaySelectGigsStep_gig$key | null,
  extra?: Partial<MultiDaySelectEntry>
): MultiDaySelectEntry | null => {
  const gig = readRelayInlineFragment(
    graphql`
      fragment MultiDaySelectGigsStep_gig on Gig @inline {
        id
        startsAt
        actualStartsAt
        endsAt
        area
        payRate
        ...WorkerLatestArrivalTimeReminder_gig
      }
    `,
    fragmentRef ?? null
  );

  if (!gig) return null;
  return {
    ...extra,
    startsAt: gig.startsAt,
    value: gig.id,
    actualStartsAt: gig.actualStartsAt,
    area: gig.area,
    endsAt: gig.endsAt,
    payRate: gig.payRate,
    reminderNode: showLatestArrivalReminder(gig) && (
      <WorkerLatestArrivalTimeReminder fragmentRef={gig} />
    )
  };
};

export const createMultiDaySelectEntryFromEngagement = (
  fragmentRef?: MultiDaySelectGigsStep_engagement$key | null
) => {
  const engagement = readRelayInlineFragment(
    graphql`
      fragment MultiDaySelectGigsStep_engagement on Engagement @inline {
        id
        workerDistance
        startsAt
        endsAt
        insertedAt
        gig {
          area
          payRate
        }
        estimatedPayment {
          netPay
        }
      }
    `,
    fragmentRef ?? null
  );

  if (!engagement) return null;
  return {
    value: engagement.id,
    area: engagement.gig?.area,
    distance: engagement.workerDistance,
    startsAt: engagement.startsAt,
    endsAt: engagement.endsAt,
    actualStartsAt: engagement.startsAt ?? engagement.insertedAt,
    estimatedPayment: engagement.estimatedPayment
  };
};

export const createMultiDaySelectEntry = (
  it: ObjectPath<
    MultiDaySelectGigsStepQuery,
    [
      "response",
      "worker",
      "gigSeries",
      "node",
      "engagements" | "availableGigs",
      "edges",
      0
    ]
  >
): MultiDaySelectEntry | null => {
  if (!it?.node) return null;
  return "distance" in it
    ? createMultiDaySelectEntryFromGig(it.node, {
        distance: it.distance,
        estimatedPayment: it.estimatedPayment
      })
    : createMultiDaySelectEntryFromEngagement(it.node);
};

interface Props {
  seriesId: string;
  maxDistance?: number;
  isApply: boolean;
  isEOR: boolean;
}

export default createSuspendedQueryContainer<
  MultiDaySelectGigsStepQuery,
  Props
>(
  function MultiDaySelectGigsStep({ response: { worker } = {}, isEOR }) {
    const { nextStep } = useStepper<Step, {}>();

    const data = useMemo(
      () =>
        compact(
          (
            worker?.gigSeries?.node?.availableGigs?.edges ??
            worker?.gigSeries?.node?.engagements?.edges ??
            []
          ).map(createMultiDaySelectEntry)
        ),
      [
        worker?.gigSeries?.node?.availableGigs?.edges,
        worker?.gigSeries?.node?.engagements?.edges
      ]
    );

    const [selected, setSelected] = useState<string[]>([]);
    const allChecked = selected.length === data.length;
    const handleToggleAll = () =>
      setSelected(allChecked ? [] : data.map((d) => d.value));

    return (
      <>
        <StepperNavActions />
        {!isEOR && (
          <>
            <Text variant="header" color="primary" weight="bold">
              You will submit a Bid for each Shift on the next step.
            </Text>
            <Spacer />
          </>
        )}

        <Card
          testID="multi-day-select-gigs-step-card"
          highlight
          hideInnerMargin
          eventTargetName="Multi-day Select Gigs Step Card"
        >
          <Pressable
            onPress={handleToggleAll}
            testID="toggle-all"
            eventEntityType="row"
            eventTargetName="toggle-all"
          >
            <ContentArea color="foreground" size="medium">
              <Row alignItems="center">
                <Text fill weight="bold">
                  Select All
                </Text>
                <Checkbox
                  testID="multi-day-select-gigs-step-select-all-checkbox"
                  selected={allChecked}
                  onChange={handleToggleAll}
                />
              </Row>
            </ContentArea>
          </Pressable>
          {data?.map((d, idx) => {
            const isChecked = selected.includes(d.value);
            const handlePress = () => {
              setSelected(
                isChecked
                  ? selected?.filter((id) => id !== d.value)
                  : [...selected, d.value]
              );
            };
            return (
              <Fragment key={d.startsAt ?? idx}>
                {idx !== 0 && <Divider />}
                <Pressable
                  testID={`multi-day-select-gigs-step-${idx}-row`}
                  eventTargetName="multi-day-select-gigs-step"
                  eventEntityType="row"
                  onPress={handlePress}
                >
                  <ContentArea size="compact">
                    <Row>
                      <Column fill>
                        <GigDateTimeContent
                          small
                          distance={d.distance}
                          startsAt={d.startsAt}
                          endsAt={d.endsAt}
                          estimatedNetPay={d.estimatedPayment?.netPay}
                          payRate={d.payRate}
                          isEOR={isEOR}
                        />
                      </Column>
                      <Column alignContent="center" alignSelf="center">
                        <Checkbox
                          testID={`multi-day-select-gigs-step-${idx}-checkbox`}
                          selected={isChecked}
                          onChange={handlePress}
                        />
                      </Column>
                    </Row>
                    {!!d.reminderNode && (
                      <ContentArea variant="none" size="slim">
                        {d.reminderNode}
                      </ContentArea>
                    )}
                  </ContentArea>
                </Pressable>
              </Fragment>
            );
          })}
        </Card>
        <ResponsiveStepperBtnPortal.Entrance
          testID="multi-day-select-gig-stepper-next-button"
          label="Continue"
          disabled={selected.length === 0}
          onPress={() => {
            const selectedGigsData = sortBy(
              compact(selected.map((id) => data.find((it) => it.value === id))),
              "startsAt"
            );

            const toAccept = selectedGigsData.map((it) => it.value);
            const toReject = data
              .filter((it) => !selected.includes(it.value))
              .map((it) => it.value);

            nextStep({ toAccept, toReject, selectedGigsData });
          }}
          onPressDisabled={() =>
            toast.error("You need to select at least one shift to continue")
          }
        />
      </>
    );
  },
  {
    query: graphql`
      query MultiDaySelectGigsStepQuery(
        $id: ID!
        $maxDistance: Int!
        $isApply: Boolean!
      ) {
        worker: viewer {
          ... on Worker {
            gigSeries(id: $id) {
              node {
                id
                engagements(
                  first: 30
                  query: "WHERE currentStateName = OFFERED"
                ) @skip(if: $isApply) {
                  edges {
                    node {
                      ...MultiDaySelectGigsStep_engagement
                    }
                  }
                  totalCount
                }
                availableGigs(first: 30, input: { maxDistance: $maxDistance })
                  @include(if: $isApply) {
                  edges {
                    distance
                    estimatedPayment {
                      netPay
                    }
                    node {
                      payRate
                      ...MultiDaySelectGigsStep_gig
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ seriesId, maxDistance = 70, isApply }) => ({
      id: seriesId,
      maxDistance,
      isApply
    })
  }
);
