import { Button, Column, TopicPreviewSurface } from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import { SeriesProjectPreviewList } from "../../gig/worker/ProjectPreviewList";
import { showProjectGigsInfoModal } from "../../modals/ProjectGigsInfoModalTabs";
import ProjectTopicEmptyView from "./ProjectTopicEmptyView";
import type { ProjectTopicPreview_worker$key } from "./__generated__/ProjectTopicPreview_worker.graphql";

export const ProjectTopicPreview = ({
  availableProjectGigs
}: FragmentContainerInnerComponentProps<ProjectTopicPreview_worker$key>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  return (
    <TopicPreviewSurface
      testID="project-topic-preview"
      iconName="pen-ruler"
      color="primary"
      title="Project Gigs"
      subtitle="Work Project Gigs in person or remotely. Communicate directly with the Requester to finalize the timing of the Project and how much you will be paid."
      rightAccessory={
        <Button
          testID="learn-more-btn"
          size="xsmall"
          variant="clear"
          label="Learn More"
          icon="circle-question"
          color="primary"
          onPress={showProjectGigsInfoModal}
        />
      }
    >
      {!availableProjectGigs?.totalCount ? (
        <ProjectTopicEmptyView />
      ) : (
        <Column gap="standard">
          <SeriesProjectPreviewList fragmentRef={availableProjectGigs} />
          <Button
            alignSelf="center"
            testID="view-all-project-gigs-btn"
            size="small"
            outline
            label="View All Project Gigs"
            icon="chevron-right"
            iconPlacement="right"
            onPress={() => navigation.push("BrowseProjects")}
          />
        </Column>
      )}
    </TopicPreviewSurface>
  );
};

export default createRelayFragmentContainer<ProjectTopicPreview_worker$key>(
  graphql`
    fragment ProjectTopicPreview_worker on Worker
    @argumentDefinitions(maxDistance: { type: "Int" }) {
      availableProjectGigs: availableGigSeries(
        first: 3
        input: { gigTypes: [PROJECT], maxDistance: $maxDistance }
      ) {
        ...ProjectPreviewList_series
        totalCount
      }
    }
  `,
  ProjectTopicPreview
);
