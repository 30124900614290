import { type TBaseStep, Text, toast } from "@gigsmart/atorasu";
import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type NativeStackNavigationProp,
  defaultBackHandler,
  useNavigation
} from "@gigsmart/kaizoku";
import {
  type RecordSourceSelectorProxy,
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import ApplicationQuestionnaireStep from "@gigsmart/seibutsu/gig/ApplyGig/ApplicationQuestionnaireStep";
import { showConfirmationModal } from "@gigsmart/seibutsu/gig/ApplyGig/ApplyGigConfirmationModal";
import BidStep from "@gigsmart/seibutsu/gig/ApplyGig/BidStep";
import DriverEligibilityNotice from "@gigsmart/seibutsu/gig/ApplyGig/DriverEligibilityNotice";
import DriverInformationStep from "@gigsmart/seibutsu/gig/ApplyGig/DriverInformationStep";
import EnrollmentStep from "@gigsmart/seibutsu/gig/ApplyGig/EnrollmentStep";
import GigDistanceNotice from "@gigsmart/seibutsu/gig/ApplyGig/GigDistanceNotice";
import HourlyRateBidStep from "@gigsmart/seibutsu/gig/ApplyGig/HourlyRateBidStep";
import LegalStep from "@gigsmart/seibutsu/gig/ApplyGig/LegalStep";
import MultiDaySelectGigsStep from "@gigsmart/seibutsu/gig/ApplyGig/MultiDaySelectGigsStep";
import PaymentStep from "@gigsmart/seibutsu/gig/ApplyGig/PaymentStep";
import PositionsStep from "@gigsmart/seibutsu/gig/ApplyGig/PositionsStep";
import QualificationsStep from "@gigsmart/seibutsu/gig/ApplyGig/QualificationsStep";
import { showUnmetRequirementsModal } from "@gigsmart/seibutsu/gig/ApplyGig/UnmetRequirementsModal";
import VerificationOptInStep from "@gigsmart/seibutsu/gig/ApplyGig/VerificationOptInStep";
import useApplyMetadata from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useApplyMetadata";
import {
  type EngageToGigParams,
  useEngageToGig
} from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEngageToGig";
import { useEnrollmentDataFragment } from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEnrollmentData";
import useEnrollmentMutation from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useEnrollmentMutation";
import useGigFields from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useGigFields";
import useGigPositions from "@gigsmart/seibutsu/gig/ApplyGig/hooks/useGigPositions";
import usePickupConfirmation from "@gigsmart/seibutsu/gig/ApplyGig/hooks/usePickUpConfirmation";
import useActiveGigPermissions from "@gigsmart/seibutsu/gig/useActiveGigPermissions";
import {
  ResponsiveStepper,
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import LegalIntroductionModal from "@gigsmart/seibutsu/worker-verification/LegalIntroductionModal";
import { titleize } from "inflected";
import React, { useCallback, useMemo, useState } from "react";
import type { WorkerParamList } from "../../navigation/types";
import type { applyGigStepperQuery } from "./__generated__/applyGigStepperQuery.graphql";
import type { applyGigStepperSendReportCopiesMutation } from "./__generated__/applyGigStepperSendReportCopiesMutation.graphql";
import type { applyGigStepperTransitionEngagementMutation } from "./__generated__/applyGigStepperTransitionEngagementMutation.graphql";

const legalInformationUpdater = (
  store: RecordSourceSelectorProxy<
    applyGigStepperSendReportCopiesMutation["response"]
  >
) => {
  const mutation = store.getRootField("setWorkerLegalInformation");
  const legalInformation = mutation?.getLinkedRecord("workerLegalInformation");

  if (legalInformation) {
    const root = store.getRoot();
    const viewer = root.getLinkedRecord("viewer");
    viewer?.setLinkedRecord(legalInformation, "legalInformation");
  }
};

interface Props {
  isSeries?: boolean;
  gigId: string;
  action: "APPLY" | "ACCEPT" | "PICK_UP" | "BID";
  engagementsToAccept?: string[];
}

function PermissionsFallback({
  permissions
}: {
  permissions: ReturnType<typeof useActiveGigPermissions>;
}) {
  const navigator = useNavigation();
  return (
    <>
      <Text>
        Unfortunately, you do not have the permissions that are required for
        this Shift Gig.
      </Text>
      <ResponsiveStepperBtnPortal.Entrance
        testID="submit-position"
        label={permissions.determined ? "Go Back" : "Request Permissions"}
        onPress={
          permissions.determined ? navigator.goBack : permissions.request
        }
      />
    </>
  );
}

export default createSuspendedQueryContainer<applyGigStepperQuery, Props>(
  function ApplyGigStepper({
    response,
    variables,
    isSeries = false,
    gigId,
    action,
    engagementsToAccept: toAccept
  }) {
    const { gigHasCategoryPositions, initialWorkerPositionStatus } =
      useGigPositions(response?.node);

    const maxDistance = variables?.maxDistance ?? 75;
    const enrollmentData = useEnrollmentDataFragment(response?.viewer);
    const nav = useNavigation<NativeStackNavigationProp<WorkerParamList>>();
    const [provideLegalInformation] = useEnrollmentMutation(enrollmentData);
    const {
      shiftId = "",
      engagementId,
      gigDistance,
      isRemote,
      paymentThroughApp,
      pendingConsents,
      hasMissingOptIns,
      requiredReportTypes = [],
      driverEligibilityNotice,
      workerHasPaymentMethod,
      gigType,
      hasMultipleShifts,
      gigIsActive,
      isMissingDriverInfo,
      hasMissingQualifications
    } = useApplyMetadata(response?.viewer, enrollmentData);
    const isEOR = requiredReportTypes?.includes?.("EOR_WORKER");
    const isProjectAccept = gigType === "PROJECT" && action !== "APPLY";
    const isProject = gigType === "PROJECT";

    const { engageToGig, confirmedApplication, isLoading } = useEngageToGig({
      gigId: shiftId,
      engagementId,
      action,
      isProject,
      isEOR,
      isSeries
    });
    const { isPickingShift, handlePickup } = usePickupConfirmation({
      gigId: shiftId,
      isEOR
    });

    const navigator = useNavigation();

    const handleDismiss = useCallback(() => {
      toast.error(
        `You are unable to ${titleize(
          action
        ).toLowerCase()} this shift without enabling the required permissions.`
      );
      navigator.goBack();
    }, [navigator, action]);

    const locationPermissionsRequired =
      !isProject &&
      ((gigIsActive && action !== "APPLY") || action === "ACCEPT");

    const permissions = useActiveGigPermissions({
      trace: "ApplyGigStepper",
      request: !isProject,
      requiresBackgroundLocation: locationPermissionsRequired,
      requiresPreciseLocation: locationPermissionsRequired,
      requiresNotifications: false,
      onDismiss: locationPermissionsRequired ? handleDismiss : undefined
    });

    const gigFields = useGigFields(response?.node);
    const gigHasRequiredQualifications = gigFields.some(
      (field) => !!field?.selectedItems?.length
    );

    const workerId = useCurrentUser()?.id ?? "";

    const handleDeny = useCallback(
      () =>
        action === "ACCEPT"
          ? defaultBackHandler({ safeExit: true })
          : nav.replace("BrowseShiftDetails", { id: gigId }),
      [action, gigId]
    );

    const [showLegalIntro, setShowLegalIntro] = useState(true);
    const [showDriverInfo] = useState(
      () => enrollmentData?.driverCheckOptIn ?? true
    );

    const initial = useMemo(
      () => ({
        hasMissingOptIns,
        isMissingDriverInfo,
        workerHasPaymentMethod,
        isEnrolledInVerification: enrollmentData?.status === "ENROLLED"
      }),
      []
    );

    const [handleSendReportCopies] =
      useRelayMutation<applyGigStepperSendReportCopiesMutation>(
        graphql`
          mutation applyGigStepperSendReportCopiesMutation(
            $input: SetWorkerLegalInformationInput!
          ) @raw_response_type {
            setWorkerLegalInformation(input: $input) {
              workerLegalInformation {
                id
                sendReportCopies
              }
            }
          }
        `,
        {
          optimisticResponse: ({ input }) => ({
            setWorkerLegalInformation: {
              workerLegalInformation: {
                id: enrollmentData?.id ?? "",
                sendReportCopies: !!input.sendReportCopies
              }
            }
          }),
          optimisticUpdater: legalInformationUpdater,
          updater: legalInformationUpdater
        }
      );

    const [transitionEngagement] =
      useRelayMutation<applyGigStepperTransitionEngagementMutation>(graphql`
        mutation applyGigStepperTransitionEngagementMutation(
          $input: TransitionEngagementInput!
          $onBehalfOfId: ID
        ) {
          transitionEngagement(input: $input) @onBehalfOf(id: $onBehalfOfId) {
            engagement {
              id
              currentState {
                name
              }
            }
          }
        }
      `);

    const isMultiShift = isSeries && hasMultipleShifts;
    const initialValues = useMemo(
      () => ({ ...enrollmentData, toAccept }),
      [enrollmentData, toAccept]
    );

    const handleComplete = useCallback(
      (values: EngageToGigParams) => {
        if (action === "PICK_UP") {
          handlePickup(steps?.length !== 0);
        } else {
          showConfirmationModal({
            action,
            isEOR,
            gigType: gigType === "PROJECT" ? "PROJECT" : "SHIFT",
            isMultiShift: isSeries && hasMultipleShifts,
            onSubmit: async () => {
              if (permissions.has || action !== "ACCEPT") {
                await engageToGig(values);
              }
            }
          });
        }
      },
      [
        isSeries,
        hasMultipleShifts,
        permissions.has,
        engageToGig,
        action,
        gigType,
        handlePickup
      ]
    );

    const skipShiftStep = !!initialValues.toAccept?.length;
    const skipQuestionaire = gigType !== "PROJECT";
    if (
      !gigId ||
      !enrollmentData ||
      isLoading ||
      isPickingShift ||
      confirmedApplication ||
      initialWorkerPositionStatus === "LOADING"
    ) {
      return null;
    }

    if (
      UnlimitedDistanceFeature.isDisabled() &&
      !isRemote &&
      gigDistance > 75
    ) {
      // Render the distance notice if the Gig is too far and not a remote project
      return <GigDistanceNotice isProject={isProject} />;
    }

    if (driverEligibilityNotice) {
      // Driver not eligible
      return <DriverEligibilityNotice text={driverEligibilityNotice} />;
    }

    const handleUnmetRequirements = () => {
      showUnmetRequirementsModal(
        action === "ACCEPT" ? "Continue" : "Return to Available Gigs",
        () => {
          if (isProject) {
            nav.replace("BrowseProjects");
          } else {
            nav.replace("BrowseShifts", { type: "shifts" });
          }
        }
      );
      if (action !== "ACCEPT" && engagementId) {
        transitionEngagement({
          input: {
            engagementId,
            action: "REJECT"
          },
          onBehalfOfId: workerId
        });
      }
    };

    const steps = isProjectAccept
      ? []
      : ([
          {
            stepName: "permissionsStep",
            stepIndicatorLabel: "Permissions",
            stepTitle: "Permissions",
            component: <PermissionsFallback permissions={permissions} />,
            disabled: permissions.has || isProject
          },
          {
            stepName: "addPositionsStep",
            stepIndicatorLabel: "Add Shift Position",
            stepTitle: "Shift Gig Position",
            component: (
              <PositionsStep
                gigId={gigId}
                handleUnmetRequirements={handleUnmetRequirements}
              />
            ),
            disabled:
              !gigHasCategoryPositions ||
              initialWorkerPositionStatus === "CONFIRMED",
            buttonShouldAvoidKeyboard: true
          },
          {
            stepName: "addQualificationsStep",
            stepIndicatorLabel: "Add Qualifications",
            stepTitle: "Gig Qualifications",
            component: (
              <QualificationsStep
                gigId={gigId}
                workerId={workerId}
                handleUnmetRequirements={handleUnmetRequirements}
              />
            ),
            disabled:
              (action === "ACCEPT" && !hasMissingQualifications) ||
              !gigHasRequiredQualifications,
            buttonShouldAvoidKeyboard: true
          },
          {
            stepName: "verificationOptInStep",
            stepIndicatorLabel: "Verification Opt-In",
            stepTitle: "Required Verification Opt-Ins",
            component: (
              <VerificationOptInStep
                requiredReportTypes={requiredReportTypes}
                hasMissingOptIns={hasMissingOptIns}
                onSubmit={(nextStep, data) => {
                  const onSuccess = () => nextStep(data);
                  const hasNextEnrolmentStep =
                    enrollmentData?.status !== "ENROLLED" ||
                    (data.driverCheckOptIn && initial.isMissingDriverInfo);

                  if (hasNextEnrolmentStep) onSuccess();
                  else provideLegalInformation(data, { onSuccess });
                }}
              />
            ),
            disabled: !initial.hasMissingOptIns,
            buttonShouldAvoidKeyboard: true
          },
          {
            stepName: "enrollmentStep",
            stepIndicatorLabel: "Verification Enrollment",
            stepTitle: "Information",
            component: (
              <EnrollmentStep
                onSubmit={(data, nextStep) =>
                  provideLegalInformation(data ?? {}, {
                    onSuccess: () => nextStep(data)
                  })
                }
                verificationStatus={enrollmentData.status}
                gigId={gigId}
              />
            ),
            disabled:
              initial.isEnrolledInVerification || !initial.hasMissingOptIns
          },
          {
            stepName: "driverInfoStep",
            stepIndicatorLabel: "Verification Driver Info",
            stepTitle: "Information",
            component: (
              <DriverInformationStep
                onSubmit={(data, nextStep) =>
                  provideLegalInformation(data ?? {}, {
                    onSuccess: () => nextStep(data)
                  })
                }
              />
            ),
            buttonShouldAvoidKeyboard: true,
            disabled:
              !initial.isEnrolledInVerification ||
              !initial.isMissingDriverInfo ||
              showDriverInfo
          },
          {
            stepName: "legalStep",
            stepIndicatorLabel: "Legal Consent",
            stepTitle: "",
            component: (
              <>
                <LegalIntroductionModal
                  showLearnMore={!!requiredReportTypes.length}
                  visible={showLegalIntro}
                  onAccept={() => setShowLegalIntro(false)}
                  onDeny={() => {
                    setTimeout(handleDeny);
                  }}
                  onClose={() => setShowLegalIntro(false)}
                />
                <LegalStep
                  documents={pendingConsents}
                  additionalConsents={
                    requiredReportTypes.length
                      ? [
                          {
                            prompt:
                              "Check this box if you would like to receive a copy of your consumer report.",
                            checked: !!enrollmentData?.sendReportCopies,
                            onConsent: (sendReportCopies: boolean) =>
                              handleSendReportCopies({
                                input: {
                                  sendReportCopies
                                }
                              })
                          }
                        ]
                      : []
                  }
                />
              </>
            ),
            grow: true,
            disabled: !pendingConsents?.length
          },
          {
            stepName: "bidStep",
            stepIndicatorLabel: "Bid",
            stepTitle: "Please add your initial bid for the Project Gig",
            component: <BidStep gigId={gigId} />,
            disabled: !(gigType === "PROJECT" && action === "APPLY") || isEOR
          },
          {
            stepName: "paymentStep",
            stepIndicatorLabel: "Payment",
            stepTitle: "Where would you like to receive your earnings?",
            stepDescription:
              gigType === "PROJECT"
                ? "This Project Gig is paying through the GigSmart app. Add your debit card or bank account to receive any Project Gig earnings."
                : "All Gig payments are made through the Get Gigs app each time you work. Add a payment method and receive your earnings in your debit card or bank account within 2-5 business days.",
            component: <PaymentStep />,
            disabled:
              isEOR ||
              initial.workerHasPaymentMethod ||
              (isProject && !paymentThroughApp)
          },
          {
            stepName: "multiDaySelectGigStep",
            stepIndicatorLabel: "Select Days",
            stepTitle: isEOR
              ? "Select the Shift(s) you would like to apply to. "
              : "Select the Shift(s) you are available to work.",
            component: (
              <MultiDaySelectGigsStep
                seriesId={gigId}
                isApply={action === "APPLY" || action === "BID"}
                maxDistance={maxDistance}
                isEOR={isEOR}
              />
            ),
            disabled: !isMultiShift || skipShiftStep,
            buttonShouldAvoidKeyboard: true
          },
          {
            stepName: "hourlyRateStep",
            stepIndicatorLabel: "Hourly Rate Bid",
            stepTitle:
              action === "BID"
                ? "Please provide your Bid."
                : "Set your Bid for the following Shift(s).",
            component: (
              <HourlyRateBidStep
                isBid={skipQuestionaire}
                isMultiShift={isMultiShift}
                nodeId={engagementId ?? gigId}
              />
            ),
            disabled:
              isProject || action === "PICK_UP" || action === "ACCEPT" || isEOR,
            buttonShouldAvoidKeyboard: true,
            grow: true
          },
          {
            stepName: "applicationQuestionnaireStep",
            stepIndicatorLabel: "Questionnaire",
            stepTitle: isProject
              ? "Complete the Application Questionnaire"
              : "Application Questionnaire",
            component: <ApplicationQuestionnaireStep gigType={gigType} />,
            disabled: skipQuestionaire
          }
        ] satisfies Array<TBaseStep<Step>>);

    return (
      <ResponsiveStepper
        steps={steps}
        stepsData={initialValues}
        onComplete={handleComplete}
        eventContext="main-apply-gig-stepper"
      />
    );
  },
  {
    query: graphql`
      query applyGigStepperQuery(
        $gigId: ID!
        $maxDistance: Int!
        $isSeries: Boolean!
        $isApply: Boolean!
      ) {
        node(id: $gigId) {
          ... on Gig {
            actualStartsAt
          }
          ...useGigPositions_gigLike
          ...useGigFields_gigOrEngagement
        }
        viewer {
          ... on Worker {
            ...useEnrollmentData_worker
            ...useApplyMetadata_worker
              @arguments(
                gigId: $gigId
                maxDistance: $maxDistance
                isSeries: $isSeries
                isApply: $isApply
              )
          }
        }
      }
    `,
    variables: ({ gigId, isSeries = false, action }) => ({
      gigId,
      isSeries,
      maxDistance: UnlimitedDistanceFeature.select(12425, 75),
      isApply: action === "APPLY" || action === "BID"
    })
  }
);
