/**
 * @generated SignedSource<<1f28d2687ce5c3a278d58d12d0fa57b7>>
 * @relayHash 0c42685578fa1299fdde63be34f8650c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ReewVzJou0cVJPw1-TlLEvM3oC7a2vPyP2AK_jVucT8

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementDisputeSummaryStackQuery$variables = {
  engagementId: string;
};
export type EngagementDisputeSummaryStackQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"EngagementDisputeSummaryStack_engagement">;
  } | null | undefined;
};
export type EngagementDisputeSummaryStackQuery = {
  response: EngagementDisputeSummaryStackQuery$data;
  variables: EngagementDisputeSummaryStackQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "engagementId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "engagementId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementDisputeComment",
  "kind": "LinkedField",
  "name": "comments",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v4/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v11 = [
  (v4/*: any*/)
],
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 50
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": [
        {
          "transitionedAt": {
            "direction": "ASC"
          }
        }
      ]
    },
    {
      "kind": "Literal",
      "name": "overridden",
      "value": false
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementStateOrOverridesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "action",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transitionedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "transitionedBy",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementNegotiation",
              "kind": "LinkedField",
              "name": "negotiation",
              "plural": false,
              "selections": [
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "submittedBy",
                  "plural": false,
                  "selections": (v9/*: any*/),
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementState",
                  "kind": "LinkedField",
                  "name": "overridesState",
                  "plural": false,
                  "selections": (v11/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "EngagementStateOverride",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementStateLocation",
                  "kind": "LinkedField",
                  "name": "workerTransitionLocation",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latitude",
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "EngagementState",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:false)"
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Engagement",
  "kind": "LinkedField",
  "name": "engagement",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredReportTypes",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "paymentInfo",
  "plural": false,
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementTimesheetDiff",
  "kind": "LinkedField",
  "name": "diff",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basePayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWorkerServiceFeeAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalPayment",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementTimesheetComparison",
  "kind": "LinkedField",
  "name": "disputedFinalTimesheet",
  "plural": false,
  "selections": [
    (v18/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "engagementTimesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentInfo",
          "kind": "LinkedField",
          "name": "paymentInfo",
          "plural": false,
          "selections": [
            (v15/*: any*/),
            (v16/*: any*/),
            (v14/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v21 = {
  "kind": "InlineFragment",
  "selections": (v11/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "disputeTarget",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v17/*: any*/),
        (v19/*: any*/),
        (v20/*: any*/)
      ],
      "type": "EngagementTimesheet",
      "abstractKey": null
    },
    (v21/*: any*/)
  ],
  "storageKey": null
},
v23 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE approvalDisposition = APPROVED"
  }
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedAt",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementDisputeApproval",
  "kind": "LinkedField",
  "name": "approval",
  "plural": false,
  "selections": [
    (v24/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v26 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE ApprovalDisposition = REJECTED"
  }
],
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementDisputeApproval",
  "kind": "LinkedField",
  "name": "approval",
  "plural": false,
  "selections": [
    (v24/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "submittedBy",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "disputeTarget",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v17/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheetComparison",
          "kind": "LinkedField",
          "name": "disputedFinalTimesheet",
          "plural": false,
          "selections": [
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementTimesheet",
              "kind": "LinkedField",
              "name": "engagementTimesheet",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementPaymentInfo",
                  "kind": "LinkedField",
                  "name": "paymentInfo",
                  "plural": false,
                  "selections": [
                    (v15/*: any*/),
                    (v16/*: any*/),
                    (v14/*: any*/)
                  ],
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v20/*: any*/)
      ],
      "type": "EngagementTimesheet",
      "abstractKey": null
    },
    (v21/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngagementDisputeSummaryStackQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EngagementDisputeSummaryStack_engagement"
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngagementDisputeSummaryStackQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "openDisputes",
                "args": [
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE approvalDisposition = NULL"
                  }
                ],
                "concreteType": "EngagementDisputesConnection",
                "kind": "LinkedField",
                "name": "disputes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementDisputesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementDispute",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = NULL\")"
              },
              {
                "alias": "approvedDisputes",
                "args": (v23/*: any*/),
                "concreteType": "EngagementDisputesConnection",
                "kind": "LinkedField",
                "name": "disputes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementDisputesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementDispute",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v25/*: any*/),
                          (v6/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = APPROVED\")"
              },
              {
                "alias": "rejectedDisputes",
                "args": (v26/*: any*/),
                "concreteType": "EngagementDisputesConnection",
                "kind": "LinkedField",
                "name": "disputes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementDisputesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementDispute",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v5/*: any*/),
                          (v27/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "disputes(first:1,query:\"WHERE ApprovalDisposition = REJECTED\")"
              },
              {
                "alias": "finalTimesheet",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "variant",
                    "value": "FINAL"
                  }
                ],
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": "resolvedDisputesCount",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 0
                      }
                    ],
                    "concreteType": "EngagementDisputesConnection",
                    "kind": "LinkedField",
                    "name": "resolvedDisputes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "resolvedDisputes(first:0)"
                  },
                  {
                    "alias": "approvedDisputes",
                    "args": (v23/*: any*/),
                    "concreteType": "EngagementDisputesConnection",
                    "kind": "LinkedField",
                    "name": "disputes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementDisputesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementDispute",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v25/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v28/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = APPROVED\")"
                  },
                  {
                    "alias": "rejectedDisputes",
                    "args": (v26/*: any*/),
                    "concreteType": "EngagementDisputesConnection",
                    "kind": "LinkedField",
                    "name": "disputes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementDisputesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementDispute",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v6/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "disputes(first:1,query:\"WHERE ApprovalDisposition = REJECTED\")"
                  }
                ],
                "storageKey": "timesheet(variant:\"FINAL\")"
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:ReewVzJou0cVJPw1-TlLEvM3oC7a2vPyP2AK_jVucT8",
    "metadata": {},
    "name": "EngagementDisputeSummaryStackQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5055008763266aa363dfcc2601fc02b5";

export default node;
