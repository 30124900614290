import React from "react";

import { Stack } from "@gigsmart/atorasu";
import {} from "@gigsmart/biruda";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import CompletedW2ShiftsCard from "./CompletedW2ShiftsCard";
import W2EarningsCard from "./W2EarningsCard";
import YourEmployerCard from "./YourEmployerCard";
import type { EOREarningsTabContent_worker$key } from "./__generated__/EOREarningsTabContent_worker.graphql";

export function EOREarningsTabContent({
  result
}: FragmentContainerInnerComponentProps<EOREarningsTabContent_worker$key, {}>) {
  const worker = result ?? null;

  return (
    <Stack>
      <YourEmployerCard fragmentRef={worker} />
      <CompletedW2ShiftsCard fragmentRef={worker} />
      <W2EarningsCard fragmentRef={worker} />
    </Stack>
  );
}

export default createRelayFragmentContainer<
  EOREarningsTabContent_worker$key,
  {}
>(
  graphql`
    fragment EOREarningsTabContent_worker on Worker {
      id
      ...YourEmployerCard_worker
      ...CompletedW2ShiftsCard_worker
      ...W2EarningsCard_worker
    }
    `,
  EOREarningsTabContent
);
