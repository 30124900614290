/**
 * @generated SignedSource<<aa533aecb50f759622de43afdf3687d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerPaymentSurface_shift$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly reconcilesAt: string | null | undefined;
  };
  readonly id: string;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly systemTimesheet: {
    readonly startedCount: {
      readonly totalCount: number;
    } | null | undefined;
    readonly workerApprovals: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly workerTimesheet: {
    readonly editable: boolean;
    readonly isOverridden: boolean;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WithEngagementCapability_engagement" | "WorkerGigReceiptSurface_engagement" | "WorkerTimeWorkedSurface_engagement" | "WorkerUpdatedTimesheetReminder_engagement">;
  readonly " $fragmentType": "WorkerPaymentSurface_shift";
};
export type WorkerPaymentSurface_shift$key = {
  readonly " $data"?: WorkerPaymentSurface_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentSurface_shift">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerPaymentSurface_shift",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerTimeWorkedSurface_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerGigReceiptSurface_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerUpdatedTimesheetReminder_engagement"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "WithEngagementCapability_engagement",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementCapability",
          "kind": "LinkedField",
          "name": "capabilities",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "restrictedBy",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expiresAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredReportTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reconcilesAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "workerTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "WORKER"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOverridden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "editable",
          "storageKey": null
        }
      ],
      "storageKey": "timesheet(variant:\"WORKER\")"
    },
    {
      "alias": "systemTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "SYSTEM"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": "startedCount",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE action = START"
            }
          ],
          "concreteType": "EngagementStateOrOverridesConnection",
          "kind": "LinkedField",
          "name": "states",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "states(first:0,query:\"WHERE action = START\")"
        },
        {
          "alias": "workerApprovals",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE approvedByType = WORKER"
            }
          ],
          "concreteType": "EngagementTimesheetApprovalsConnection",
          "kind": "LinkedField",
          "name": "approvals",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "approvals(first:0,query:\"WHERE approvedByType = WORKER\")"
        }
      ],
      "storageKey": "timesheet(variant:\"SYSTEM\")"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "b4ba6d867c9dbb16de7b5c4257198ce2";

export default node;
