import { memoize } from "lodash";
import qs from "query-string";
import React, { useEffect, useState } from "react";

import {
  ActivityIndicator,
  BlogPostPreviewRow,
  Stack,
  Surface,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { time } from "@gigsmart/isomorphic-shared/iso";

import HomeScreenCard from "./HomescreenCard";

const BASE_URL = "https://gigsmart.com";

interface BlogPost {
  id: string;
  link: string;
  publishedAt?: Date;
  image?: string;
  title?: string;
  description?: string;
  author?: string;
}

const fetchPosts = memoize(async (count = 3) => {
  const args = qs.stringify({
    per_page: count,
    user_type: 48, // user_type = worker
    _embed: ["author"].join(","),
    _fields: [
      "id",
      "link",
      "date_gmt",
      "yoast_head_json.title",
      "yoast_head_json.description",
      "yoast_head_json.og_image",
      "_links.author",
      "_embedded"
    ].join(",")
  });

  return await fetch(`${BASE_URL}/wp-json/wp/v2/posts?${args}`)
    .then(async (res) => await res.json())
    .then((list) =>
      list.map(
        (it: any): BlogPost => ({
          id: it.id,
          link: it.link,
          publishedAt: it.date_gmt ? new Date(`${it.date_gmt}Z`) : undefined,
          image: it.yoast_head_json?.og_image?.[0]?.url,
          title: it.yoast_head_json?.title ?? "",
          description: it.yoast_head_json?.description,
          author: it._embedded?.author?.[0]?.name
        })
      )
    )
    .catch(() => []);
});

export default function GigSmartBlogSection() {
  const [list, setList] = useState<BlogPost[]>([]);
  useEffect(() => {
    void fetchPosts().then((list) => {
      setList(list);
    });
  }, []);

  const handleUrl = (url: string) => showEmbeddedBrowser({ url });

  if (list.length === 0) return <ActivityIndicator />;

  return (
    <HomeScreenCard
      headerIcon="comment-lines"
      testID="gigsmart-blog-card"
      headerTitle="GigSmart Blog"
      footerLinkText="Visit Full Blog"
      footerIcon="external-link"
      footerLinkUrl={`${BASE_URL}/resources/?type_of_content=blog&user_type=worker&&sort=recent`}
      onFooterPress={() =>
        handleUrl(
          `${BASE_URL}/resources/?type_of_content=blog&user_type=worker&&sort=recent`
        )
      }
    >
      <Stack size="compact">
        {list.map((it) => {
          const note = `${it.author ?? ""} | ${time.humanize(
            it.publishedAt,
            "dateShort"
          )}`;

          return (
            <Surface variant="outline" key={it.id}>
              <BlogPostPreviewRow
                image={it.image ?? ""}
                title={it.title ?? ""}
                author={note}
                subText={it.description ?? ""}
                onPress={() => handleUrl(it.link)}
                testID="gigsmart-blog-section"
              />
            </Surface>
          );
        })}
      </Stack>
    </HomeScreenCard>
  );
}
