import { currency } from "@gigsmart/isomorphic-shared/iso";
import { DataRow, DataRowCard } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import moment from "moment-timezone";
import React, { useMemo } from "react";
import type { WalletStats_worker$key } from "./__generated__/WalletStats_worker.graphql";

const yearsBeforeArr = [0, 1, 2, 3] as const;

export function WalletStats({
  insertedAt,
  lifetime,
  sevenDay,
  thirtyDay,
  dy0,
  dy1,
  dy2,
  dy3
}: FragmentContainerInnerComponentProps<WalletStats_worker$key, {}>) {
  const getEarnings = (years: number) => {
    return years === 0
      ? dy0
      : years === 1
        ? dy1
        : years === 2
          ? dy2
          : years === 3
            ? dy3
            : null;
  };
  // - Load 3y in advance
  // - set the last year to render as the last one the worker had any earnings
  // - if the last year to render matches current year, don't show Lifetime row
  const { showLifetime, startFromYear } = useMemo(() => {
    let startFromYear = moment().format("YYYY");
    yearsBeforeArr.forEach((d) => {
      const hasEarnings = currency.toFloat(getEarnings(d)) > 0;
      if (hasEarnings) {
        startFromYear = moment()
          .startOf("year")
          .subtract(d, "year")
          .format("YYYY");
      }
    });

    return {
      startFromYear,
      showLifetime: startFromYear !== moment().format("YYYY")
    };
  }, [dy0, dy1, dy2, dy3]);

  return (
    <DataRowCard testID="stats-tab-content">
      <DataRow
        testID="seven-day-earnings"
        label="Last 7 Days"
        secondaryTitle="Earnings"
        data={currency.humanize(sevenDay)}
      />
      <DataRow
        testID="thirty-day-earnings"
        label="Last 30 Days"
        secondaryTitle="Earnings"
        data={currency.humanize(thirtyDay)}
      />
      {yearsBeforeArr.map((yearsBefore) => {
        const earnings = getEarnings(yearsBefore);
        const year = moment()
          .startOf("year")
          .subtract(yearsBefore, "year")
          .format("YYYY");

        if (year < startFromYear) return null;
        return (
          <DataRow
            key={year}
            testID="ytd-earnings"
            label={
              yearsBefore === 0 ? `Year to Date (${year})` : `Year ${year}`
            }
            secondaryTitle="Earnings"
            data={currency.humanize(earnings)}
          />
        );
      })}
      {showLifetime && (
        <DataRow
          testID="lifetime-earnings"
          label="Lifetime"
          secondaryTitle="Earnings"
          data={currency.humanize(lifetime)}
        />
      )}
    </DataRowCard>
  );
}

export default createRelayFragmentContainer(
  graphql`
  fragment WalletStats_worker on Worker {
    insertedAt
    lifetime: totalEarnings
    sevenDay: totalEarnings(dateFrom: "-P7D")
    thirtyDay: totalEarnings(dateFrom: "-P30D")
    dy0: totalEarnings(dateFrom: "^PY", dateTo: "-P0D")
    dy1: totalEarnings(dateFrom: "-P1Y^PY", dateTo: "-P1Y$PY")
    dy2: totalEarnings(dateFrom: "-P2Y^PY", dateTo: "-P2Y$PY")
    dy3: totalEarnings(dateFrom: "-P3Y^PY", dateTo: "-P3Y$PY")
  }
`,
  WalletStats
);
