import LogoutScreen from "@gigsmart/isomorphic-shared/auth/logout";
import ResetPasswordScreen from "@gigsmart/isomorphic-shared/auth/reset-password";
import ResetPasswordSuccess from "@gigsmart/isomorphic-shared/auth/reset-password-success";
import TokenScreen from "@gigsmart/isomorphic-shared/auth/token";
import { ConfirmRegistrationScreen } from "@gigsmart/isomorphic-shared/onboarding/confirm-registration";
import BrowseShiftDetailsScreen from "@gigsmart/seibutsu/browse/BrowseShiftDetailsScreen";
import WelcomeScreen from "@gigsmart/seibutsu/shared/Auth/WelcomeScreen";
import EngagementDetailsFallback from "@gigsmart/seibutsu/shifts/EngagementDetailsFallback";
import React from "react";
import LocationDebugScreen from "../app/location-debug";
import ForgotPasswordScreen from "../auth/forgot-password";
import LoginScreen from "../auth/login";
import SignupScreen from "../auth/signup";
import AvailableGigApply from "../available-gig/available-gig-apply";
import AvailableGigDetailsScreen from "../available-gig/available-gig-details-screen";
import ProjectGigsScreen from "../available-gig/project-gigs-screen";
import AvailableGigRequesterPrimaryContactScreen from "../available-gig/requester-primary-contact-screen";
import AvailableGigRequesterReviewsScreen from "../available-gig/requester-reviews-screen";
import AvailableGigRequesterSummaryScreen from "../available-gig/requester-summary-screen";
import ShiftGigsScreen from "../available-gig/shift-gigs-screen/shift-gigs-screen";
import AddDirectPaymentOrHireScreen from "../direct-hire/add-direct-payment-or-hire-screen";
import DirectPaymentsAndHiresDetailsScreen from "../direct-hire/direct-payments-and-hires-details-screen";
import DirectPaymentsAndHiresScreen from "../direct-hire/direct-payments-and-hires-screen";
import GetVerified from "../get-verified";
import GigSeriesOfferedScreen from "../gig-series/gig-series-offered-screen";
import NegotiateBidScreen from "../gig/NegotiateBidScreen";
import CompletionQuestionnaireScreen from "../gig/completion-questionnaire-screen";
import EditTimesheetScreen from "../gig/edit-timesheet-screen";
import EngagementAccept from "../gig/engagement-accept";
import EngagementDetails from "../gig/engagement-details";
import GigCancelScreen from "../gig/gig-cancel-screen";
import GigRequester from "../gig/gig-requester";
import HistoryScreen from "../gig/history-screen";
import PendingTimesheetsScreen from "../gig/pending-timesheets-screen";
import ReviewRequesterScreen from "../gig/review-requester-screen";
import ReviewTimesheetScreen from "../gig/review-timesheet-screen";
import DisputeTimesheetScreen from "../gig/timesheet-disputes/dispute-timesheet-screen";
import JobDetailsRedirect from "../job/job-details-redirect";
import JobDetailsScreen from "../job/job-details-screen";
import JobsIntroduction from "../job/jobs-introduction";
import JobsListScreen from "../job/jobs-list-screen";
import ConversationScreen from "../messaging/conversation-screen";
import MessageCenterScreen from "../messaging/message-center-screen";
import ShiftGroupChatsScreen from "../messaging/shift-group-chats-screen";
import NotificationCenterScreen from "../notifications";
import OfferedGigScreen from "../offered-gig/offered-gig-screen";

import WorkerOnboardingScreen from "../onboarding/WorkerOnboardingScreen";
import OrganizationReviews from "../organization/organization-reviews-screen";
import OrganizationSummary from "../organization/organization-summary-screen";
import PendingTimesheetApprovalScreen from "../pending-timesheet-approval";
import AccountScreen from "../profile/account-screen";
import AttendanceFilteredShiftsScreen from "../profile/attendance-filtered-shifts-screen";
import AttendanceOverviewScreen from "../profile/attendance-overview-screen";
import AttireScreen from "../profile/attire-screen";
import BlockedOrganizationManagementScreen from "../profile/blocked-organization-management-screen";
import CategoriesScreen from "../profile/categories-screen";
import CommercialDriversLicenseScreen from "../profile/commercial-drivers-license-screen";
import EditProfileScreen from "../profile/edit-profile-screen";
import EducationScreen from "../profile/education-screen";
import EmergencyContactsScreen from "../profile/emergency-contacts-screen";
import ExperienceScreen from "../profile/experience-screen";
import LicensesCertificationsScreen from "../profile/licenses-certifications-screen";
import ManageLanguageScreen from "../profile/manage-language-screen";
import ManageMilitaryExperienceScreen from "../profile/manage-military-exp-screen";
import PhysicalRequirementsScreen from "../profile/physical-requirements-screen";
import PositionsScreen from "../profile/positions-screen";
import PublicViewScreen from "../profile/public-view-screen";
import QualificationsScreen from "../profile/qualifications-screen";
import ToolsEquipmentScreen from "../profile/tools-equipment-screen";
import TransportationMethodScreen from "../profile/transportation-method-screen";
import TravelScreen from "../profile/travel-screen";
import VehicleScreen from "../profile/vehicle-screen";
import WorkHistoryScreen from "../profile/work-history-screen";
import RequesterAttendanceFilteredShiftsScreen from "../requester/requester-attendance-filtered-shifts-screen";
import ShiftDetailsScreen from "../shifts/WorkerShiftDetailsScreen";
import AddPaymentMethodScreen from "../wallet/AddPaymentMethodScreen";
import WalletTransferScreen from "../wallet/transfer-screen";
import { OnboardingFallback } from "./OnboardingFallback";
import WorkerTabs from "./WorkerTabs";
import type { WorkerTypedNavigator } from "./types";

interface WorkerRoutesOptions {
  Nav: WorkerTypedNavigator;
  isAuthenticated: boolean;
  hasFinishedOnboarding: boolean;
}
export function useWorkerRoutes({
  Nav,
  isAuthenticated,
  hasFinishedOnboarding
}: WorkerRoutesOptions) {
  if (!isAuthenticated) {
    return (
      <Nav.Group>
        <Nav.Screen name="Home" component={WelcomeScreen} />
        <Nav.Screen name="ResetPassword" component={ResetPasswordScreen} />
        <Nav.Screen name="Onboarding" component={WorkerOnboardingScreen} />
        <Nav.Screen name="Token" component={TokenScreen} />
        <Nav.Screen name="JobDetailsRedirect" component={JobDetailsRedirect} />
        <Nav.Screen
          name="Signup"
          component={SignupScreen}
          options={{ eventContext: "Signup" }}
        />
        <Nav.Screen
          name="Login"
          component={LoginScreen}
          options={{ eventContext: "Login" }}
        />
        <Nav.Screen
          name="ForgotPassword"
          component={ForgotPasswordScreen}
          options={{ eventContext: "Forgot Password" }}
        />
        <Nav.Screen
          name="ResetPasswordSuccess"
          component={ResetPasswordSuccess}
          options={{ eventContext: "Reset Password" }}
        />
      </Nav.Group>
    );
  }

  if (!hasFinishedOnboarding) {
    return (
      <Nav.Group>
        <Nav.Screen name="Home" component={OnboardingFallback} />
        <Nav.Screen
          name="ConfirmRegistration"
          component={ConfirmRegistrationScreen}
        />
        <Nav.Screen name="Onboarding" component={WorkerOnboardingScreen} />
        <Nav.Screen name="Logout" component={LogoutScreen} />
      </Nav.Group>
    );
  }

  return (
    <Nav.Group>
      <Nav.Screen name="Home" component={WorkerTabs} />
      <Nav.Screen
        name="ConfirmRegistration"
        component={ConfirmRegistrationScreen}
      />
      <Nav.Screen
        name="PendingTimesheetApproval"
        component={PendingTimesheetApprovalScreen}
      />
      <Nav.Screen
        name="MessageCenter"
        component={MessageCenterScreen}
        options={{ eventContext: "Message Center" }}
      />
      <Nav.Screen
        name="Conversation"
        component={ConversationScreen}
        options={{ eventContext: "Shift Group Chat" }}
      />
      <Nav.Screen name="ShiftGroupChats" component={ShiftGroupChatsScreen} />
      <Nav.Screen name="Notifications" component={NotificationCenterScreen} />
      <Nav.Screen name="Logout" component={LogoutScreen} />
      <Nav.Screen name="ResetPassword" component={ResetPasswordScreen} />
      <Nav.Screen name="Token" component={TokenScreen} />
      <Nav.Screen
        name="History"
        component={HistoryScreen}
        initialParams={{ tab: "completed" }}
      />
      <Nav.Screen
        name="WorkerGigSeriesOffered"
        component={GigSeriesOfferedScreen}
      />
      <Nav.Screen name="ProfilePublicView" component={PublicViewScreen} />
      <Nav.Screen name="ProfileAboutMe" component={EditProfileScreen} />
      <Nav.Screen name="ProfileCategories" component={CategoriesScreen} />
      <Nav.Screen
        name="ProfileQualifications"
        component={QualificationsScreen}
      />
      <Nav.Screen
        name="ProfileLicensesCertifications"
        component={LicensesCertificationsScreen}
      />
      <Nav.Screen
        name="ProfilePhysicalRequirements"
        component={PhysicalRequirementsScreen}
      />
      <Nav.Screen
        name="ProfileToolsEquipments"
        component={ToolsEquipmentScreen}
      />
      <Nav.Screen name="ProfileAttire" component={AttireScreen} />
      <Nav.Screen name="ProfileVehicle" component={VehicleScreen} />
      <Nav.Screen name="ProfileTravel" component={TravelScreen} />
      <Nav.Screen
        name="ProfileCDL"
        component={CommercialDriversLicenseScreen}
      />
      <Nav.Screen name="ProfileExperience" component={ExperienceScreen} />
      <Nav.Screen name="ProfileWorkHistory" component={WorkHistoryScreen} />
      <Nav.Screen name="ProfileEducation" component={EducationScreen} />
      <Nav.Screen
        name="ProfileTransportation"
        component={TransportationMethodScreen}
      />
      <Nav.Screen name="ProfileAddLanguage" component={ManageLanguageScreen} />
      <Nav.Screen name="ProfileEditLanguage" component={ManageLanguageScreen} />
      <Nav.Screen
        name="ProfileAddMilitary"
        component={ManageMilitaryExperienceScreen}
      />
      <Nav.Screen
        name="ProfileEditMilitary"
        component={ManageMilitaryExperienceScreen}
      />
      <Nav.Screen name="ProfileAccount" component={AccountScreen} />
      <Nav.Screen
        name="ProfileEmergencyContacts"
        component={EmergencyContactsScreen}
      />
      <Nav.Screen
        name="BlockedOrganizationManagement"
        component={BlockedOrganizationManagementScreen}
      />
      <Nav.Screen name="ProfilePositions" component={PositionsScreen} />
      <Nav.Screen
        name="ProfileAttendanceOverview"
        component={AttendanceOverviewScreen}
      />
      <Nav.Screen
        name="ProfileAttendanceFilteredShifts"
        component={AttendanceFilteredShiftsScreen}
      />
      <Nav.Screen name="WalletTransfer" component={WalletTransferScreen} />
      <Nav.Screen name="AddPaymentMethod" component={AddPaymentMethodScreen} />
      <Nav.Screen name="LocationDebug" component={LocationDebugScreen} />
      <Nav.Screen name="JobDetailsRedirect" component={JobDetailsRedirect} />
      <Nav.Screen name="GetVerified" component={GetVerified} />
      <Nav.Screen name="OrganizationSummary" component={OrganizationSummary} />
      <Nav.Screen name="OrganizationReviews" component={OrganizationReviews} />
      {/* job routes */}
      <Nav.Screen name="BrowseJobs" component={JobsListScreen} />
      <Nav.Screen name="BrowseJobsIntro" component={JobsIntroduction} />
      <Nav.Screen name="BrowseJobDetails" component={JobDetailsScreen} />
      <Nav.Screen name="JobDetails" component={JobDetailsScreen} />
      {/* gig routes */}
      <Nav.Screen name="OfferedGigs" component={OfferedGigScreen} />
      <Nav.Screen
        name="PendingTimesheets"
        component={PendingTimesheetsScreen}
      />
      <Nav.Screen
        name="EngagementDetails"
        component={EngagementDetailsFallback}
      />
      <Nav.Screen name="ShiftDetails" component={ShiftDetailsScreen} />
      <Nav.Screen name="ProjectDetails" component={EngagementDetails} />
      <Nav.Screen name="EngagementAccept" component={EngagementAccept} />
      <Nav.Screen name="ReviewTimesheet" component={ReviewTimesheetScreen} />
      <Nav.Screen name="DisputeTimesheet" component={DisputeTimesheetScreen} />
      <Nav.Screen name="EditTimesheet" component={EditTimesheetScreen} />
      <Nav.Screen name="CreateTimesheet" component={EditTimesheetScreen} />
      <Nav.Screen name="CancelEngagement" component={GigCancelScreen} />
      <Nav.Screen name="ShiftRequesterProfile" component={GigRequester} />
      <Nav.Screen name="ProjectRequesterProfile" component={GigRequester} />
      <Nav.Screen
        name="MarkProjectComplete"
        component={CompletionQuestionnaireScreen}
      />
      <Nav.Screen name="ReviewRequester" component={ReviewRequesterScreen} />
      <Nav.Screen name="NegotiateBid" component={NegotiateBidScreen} />
      <Nav.Screen name="BrowseShifts" component={ShiftGigsScreen} />
      <Nav.Screen
        name="BrowseShiftDetails"
        initialParams={{ type: "shifts" }}
        component={BrowseShiftDetailsScreen}
      />
      <Nav.Screen
        name="BrowseProjectDetails"
        component={AvailableGigDetailsScreen}
      />
      <Nav.Screen
        name="BrowseShiftAction"
        initialParams={{ type: "shifts" }}
        component={AvailableGigApply}
      />
      <Nav.Screen name="BrowseProjects" component={ProjectGigsScreen} />
      <Nav.Screen
        name="BrowseGigRequester"
        component={AvailableGigRequesterSummaryScreen}
      />
      <Nav.Screen
        name="BrowseGigPrimaryContact"
        component={AvailableGigRequesterPrimaryContactScreen}
      />
      <Nav.Screen
        name="BrowseGigReviews"
        component={AvailableGigRequesterReviewsScreen}
      />
      {/* direct hire routes */}
      <Nav.Screen
        name="DirectPaymentsAndHires"
        component={DirectPaymentsAndHiresScreen}
      />
      <Nav.Screen
        name="AddDirectPaymentOrHire"
        component={AddDirectPaymentOrHireScreen}
      />
      <Nav.Screen
        name="DirectPaymentsAndHiresDetails"
        component={DirectPaymentsAndHiresDetailsScreen}
      />
      <Nav.Screen
        name="OrganizationAttendanceFilteredShifts"
        component={RequesterAttendanceFilteredShiftsScreen}
      />
    </Nav.Group>
  );
}
