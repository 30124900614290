import {
  Button,
  CollapsibleContainer,
  CollapsibleRow,
  type Color,
  ContentArea,
  Divider,
  HighlightedReminder,
  type IconName,
  ProductInfoRow,
  Spacer,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { currency, duration, time } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { Duration } from "luxon";
import React from "react";
import DisputeCommentContainer from "../DisputeCommentContainer";
import EngagementDisputeSummaryTabs from "./EngagementDisputeSummaryTabs";
import type { EngagementDisputeSummary_EngagementTimesheet$key } from "./__generated__/EngagementDisputeSummary_EngagementTimesheet.graphql";

interface Props {
  disputeStatus: "PENDING" | "APPROVED" | "REJECTED" | "MODIFIED" | null;
  isRejected?: boolean;
  approvedAt?: string;
  expiresAt?: string | null | undefined;
  rejectedDisputeComment?: string;
  timesheetComment?: string;
  additionalPayComment?: string;
  onEscalateDispute?: () => void;
  onWithdrawDispute?: () => void;
  collapsible?: boolean;
  noResponse?: boolean;
}

export function EngagementDisputeSummary({
  disputeStatus,
  isRejected,
  noResponse,
  disputedFinalTimesheet,
  rejectedDisputeComment,
  timesheetComment,
  additionalPayComment,
  onEscalateDispute,
  onWithdrawDispute,
  approvedAt,
  expiresAt,
  collapsible,
  paymentStyle,
  result
}: FragmentContainerInnerComponentProps<
  EngagementDisputeSummary_EngagementTimesheet$key,
  Props
>) {
  const WrapperComponent = collapsible ? CollapsibleRow : React.Fragment;
  const wrapperProps = {
    title:
      disputeStatus === "MODIFIED"
        ? "Requester's Payment Response"
        : "Your Payment Dispute",
    note: getDisputeNote(disputeStatus),
    noteColor: "neutral" as Color,
    icon: "file-exclamation" as IconName,
    tagLabel: getDisputeTagLabel(disputeStatus, noResponse),
    tagColor: getDisputeTagColor(disputeStatus, noResponse),
    iconColor: "primary" as Color,
    startCollapsed: true
  };
  const basePaymentMinusFees = (
    Number(disputedFinalTimesheet?.diff?.basePayment?.replace(" USD", "")) -
    Number(
      disputedFinalTimesheet?.diff?.totalWorkerServiceFeeAmount?.replace(
        " USD",
        ""
      ) ?? 0
    )
  ).toFixed(2);
  const timesheetDisputed =
    !!disputedFinalTimesheet?.diff?.billableDuration &&
    Duration.fromISO(
      disputedFinalTimesheet?.diff?.billableDuration
    ).toMillis() !== 0;
  const additionalPayDisputed =
    disputedFinalTimesheet?.diff?.additionalPayment !== "0 USD";
  const paymentInfoStatus =
    disputedFinalTimesheet?.engagementTimesheet?.paymentInfo?.status;
  return (
    <WrapperComponent {...wrapperProps} testID="engagement-dispute-summary">
      {collapsible && <Spacer size="slim" />}
      <CollapsibleContainer
        testID="dispute-status-container"
        startCollapsed
        header={
          <>
            {!collapsible && (
              <ProductInfoRow
                testID="dispute-status-header"
                icon="file-exclamation"
                name={
                  disputeStatus === "MODIFIED"
                    ? "Requester's Payment Response"
                    : "Your Payment Dispute"
                }
                note={
                  <Text variant="note" color="neutral">
                    {getDisputeNote(disputeStatus)}
                  </Text>
                }
                tagColor={getDisputeTagColor(disputeStatus, noResponse)}
                tagText={getDisputeTagLabel(disputeStatus, noResponse)}
              />
            )}
            <ContentArea>
              <Stack>
                {disputeStatus === "MODIFIED" && (
                  <HighlightedReminder
                    variant="success"
                    icon="check"
                    header={`Your Dispute was rejected by the Requester and they approved another amount on ${time.humanize(
                      approvedAt,
                      "dateTimeDateFirst"
                    )}. Payment will be shown in your Wallet.`}
                  />
                )}
                {disputeStatus === "APPROVED" && (
                  <HighlightedReminder
                    variant="success"
                    icon="check"
                    header={`Your Dispute was approved by the Requester on ${time.humanize(
                      approvedAt,
                      "dateTimeDateFirst"
                    )}. Payment will be shown in your Wallet.`}
                  />
                )}
                {isRejected && !noResponse && (
                  <HighlightedReminder
                    variant="error"
                    icon="circle-exclamation"
                    header={`Your Dispute was rejected by the Requester on ${time.humanize(
                      approvedAt,
                      "dateTimeDateFirst"
                    )}.`}
                  />
                )}
                {isRejected && noResponse && (
                  <HighlightedReminder
                    variant="error"
                    icon="circle-exclamation"
                    header={
                      "The Requester did not respond to your Payment Dispute submission, so it was not paid."
                    }
                  />
                )}
                {rejectedDisputeComment && (
                  <DisputeCommentContainer
                    label={
                      isRejected
                        ? "Why the Requester rejected your Dispute"
                        : "Why the Requester approved a different amount than your pay Dispute"
                    }
                    comment={rejectedDisputeComment}
                  />
                )}
                <Stack size="slim">
                  {timesheetDisputed && (
                    <Stack
                      horizontal
                      size="slim"
                      justifyContent="space-between"
                    >
                      <Text>
                        {paymentStyle === "FIXED_HOURS"
                          ? "Time Added via Time Adjustment"
                          : "Time Added"}
                      </Text>
                      <Text
                        color={isRejected ? "disabled" : "success"}
                        weight="semibold"
                        strikethrough={isRejected}
                      >
                        +{" "}
                        {duration.humanize(
                          disputedFinalTimesheet?.diff?.billableDuration,
                          "semi-compact-no-spaces"
                        )}
                      </Text>
                    </Stack>
                  )}
                  {timesheetDisputed && paymentInfoStatus !== "INACCURATE" && (
                    <Stack
                      horizontal
                      size="slim"
                      justifyContent="space-between"
                    >
                      <Text>Additional Earnings via Time Added</Text>
                      <Text
                        color={isRejected ? "disabled" : "success"}
                        weight="semibold"
                        strikethrough={isRejected}
                      >
                        + ${basePaymentMinusFees}
                      </Text>
                    </Stack>
                  )}
                  {additionalPayDisputed && (
                    <Stack
                      horizontal
                      size="slim"
                      justifyContent="space-between"
                    >
                      <Text>Additional Payment Request</Text>
                      <Text
                        color={isRejected ? "disabled" : "success"}
                        weight="semibold"
                        strikethrough={isRejected}
                      >
                        +{" "}
                        {currency.humanize(
                          disputedFinalTimesheet?.diff?.additionalPayment
                        )}
                      </Text>
                    </Stack>
                  )}
                </Stack>
                {paymentInfoStatus !== "INACCURATE" && (
                  <>
                    <Divider />
                    <Stack
                      horizontal
                      size="slim"
                      justifyContent="space-between"
                    >
                      <Text weight="semibold">
                        Dispute Total {isRejected ? "(Rejected)" : null}
                      </Text>
                      <Text
                        color={isRejected ? "disabled" : "success"}
                        weight="semibold"
                        strikethrough={isRejected}
                      >
                        +{" "}
                        {currency.humanize(
                          disputedFinalTimesheet?.diff?.totalPayment
                        )}
                      </Text>
                    </Stack>
                  </>
                )}
                <Divider />
                {disputeStatus === "PENDING" && (
                  <Text weight="semibold">
                    The Requester has until{" "}
                    {time.humanize(expiresAt, "dateTimeShort")} to review your
                    Dispute. Please DO NOT contact GigSmart regarding this
                    Dispute until this time has passed.
                  </Text>
                )}
                {(disputeStatus === "REJECTED" ||
                  disputeStatus === "MODIFIED") && (
                  <Stack>
                    <Text weight="semibold" align="center">
                      Not satisfied with the results of your Dispute?
                    </Text>
                    <Button
                      testID="contact-support-btn"
                      variant="clear"
                      icon="file-export"
                      label="Contact Support"
                      onPress={onEscalateDispute}
                    />
                  </Stack>
                )}
              </Stack>
            </ContentArea>
          </>
        }
      >
        <Divider />
        <ContentArea>
          <Stack>
            <Text>{getDisputeStatusText(disputeStatus)}</Text>
            <EngagementDisputeSummaryTabs
              fragmentRef={result}
              isRejected={isRejected}
              timesheetDisputed={timesheetDisputed}
              additionalPayDisputed={additionalPayDisputed}
            />
            {timesheetComment && disputeStatus !== "MODIFIED" && (
              <DisputeCommentContainer
                label="Why you updated your Timesheet"
                comment={timesheetComment}
              />
            )}
            {additionalPayComment && disputeStatus !== "MODIFIED" && (
              <DisputeCommentContainer
                label="Why you requested Additional Payment"
                comment={additionalPayComment}
              />
            )}
            {disputeStatus === "PENDING" && (
              <Button
                testID="withdraw-dispute-btn"
                variant="clear"
                label="Withdraw Dispute"
                icon="ban"
                onPress={onWithdrawDispute}
              />
            )}
          </Stack>
        </ContentArea>
      </CollapsibleContainer>
    </WrapperComponent>
  );
}

export default createRelayFragmentContainer<
  EngagementDisputeSummary_EngagementTimesheet$key,
  Props
>(
  graphql`
    fragment EngagementDisputeSummary_EngagementTimesheet on EngagementTimesheet {
      ...EngagementDisputeSummaryTabs_EngagementTimesheet
      paymentStyle
      disputedFinalTimesheet {
        diff {
          billableDuration
          basePayment
          totalWorkerServiceFeeAmount
          additionalPayment
          totalPayment
        }
        engagementTimesheet {
          paymentInfo {
            status
            billableDuration
            netPay
          }
        }
      }
    }
  `,
  EngagementDisputeSummary
);

function getDisputeNote(
  status: "PENDING" | "MODIFIED" | "REJECTED" | "APPROVED" | null
): string {
  if (status === "PENDING") return "Waiting for Requester Response";
  if (status === "APPROVED") return "Requester Paid Dispute";
  if (status === "REJECTED") return "Requester Rejected Dispute";
  if (status === "MODIFIED") return "Requester Paid a Different Amount";
  return "";
}

function getDisputeTagColor(
  status: "PENDING" | "MODIFIED" | "REJECTED" | "APPROVED" | null,
  noResponse?: boolean
): Color {
  if (status === "APPROVED" || status === "MODIFIED") return "success";
  if (status === "REJECTED" && noResponse) return "disabled";
  if (status === "REJECTED") return "error";
  return "disabled";
}

function getDisputeTagLabel(
  status: "PENDING" | "MODIFIED" | "REJECTED" | "APPROVED" | null,
  noResponse?: boolean
): string {
  if (status === "PENDING") return "Submitted";
  if (status === "APPROVED" || status === "MODIFIED") return "Paid";
  if (status === "REJECTED" && noResponse) return "No Response to Dispute";
  if (status === "REJECTED") return "Rejected";
  return "";
}

function getDisputeStatusText(
  status: "PENDING" | "MODIFIED" | "REJECTED" | "APPROVED" | null
): string {
  if (status === "PENDING")
    return "The following Dispute has been sent to the Requester.";
  if (status === "APPROVED" || status === "MODIFIED")
    return "The following information was approved by the Requester.";
  if (status === "REJECTED")
    return "The following information was rejected by the Requester.";
  return "";
}
