import { ActionRow, Text } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import formatGigLocalDateTime from "../useGigLocalDateTime";
import type { ProjectPreviewRow_project$key } from "./__generated__/ProjectPreviewRow_project.graphql";

type Props = {
  onPress?: () => void;
};

export const ProjectPreviewRow = ({
  id,
  isAsap,
  name,
  startsAt,
  timezone,
  onPress
}: FragmentContainerInnerComponentProps<
  ProjectPreviewRow_project$key,
  Props
>) => {
  const formattedDate = formatGigLocalDateTime(
    { startsAt, timezone },
    { dateFormat: "fullDateShort" }
  );

  const flexible = !isAsap && !startsAt ? "Flexible Schedule" : null;

  return (
    <ActionRow
      eventEntityType="ProjectPreviewRow"
      testID={`project-${id}`}
      variant="outline"
      leftSpacing="small"
      onPress={onPress}
      title={
        <Text variant="subheader" numberOfLines={1} color="black">
          {name}
        </Text>
      }
      icon="pen-ruler"
      color="warning"
      pressableIconColor="primary"
      iconSquareVariant="standard"
      note={
        <Text variant="note" color="neutral">
          {flexible ?? formattedDate.dateString}
        </Text>
      }
    />
  );
};

export default createRelayFragmentContainer<
  ProjectPreviewRow_project$key,
  Props
>(
  graphql`
    fragment ProjectPreviewRow_project on Gig {
      id
      name
      startsAt
      isAsap
      timezone
    }
  `,
  ProjectPreviewRow
);
