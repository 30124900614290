import { usePermission } from "@gigsmart/atorasu";
import { defaultBackHandler } from "@gigsmart/kaizoku";
import { graphql, useRelayMutationPromise } from "@gigsmart/relay";
import { DateTime, Duration } from "luxon";
import { useCallback, useState } from "react";
import { showWorkerHiredModal } from "../../../worker/WorkerHiredModal";
import { showFirstConfirmationModal } from "../../../worker/pickup-shift/FirstConfirmationModal";
import { showSecondConfirmationModal } from "../../../worker/pickup-shift/SecondConfirmationModal";
import { showShiftFarAwayModal } from "../../../worker/pickup-shift/ShiftFarAwayModal";
import { showShiftFilledModal } from "../../../worker/pickup-shift/ShiftFilledModal";
import { showShiftNotAvailableModal } from "../../../worker/pickup-shift/ShiftNotAvailableModal";
import type { usePickUpConfirmationMutation } from "./__generated__/usePickUpConfirmationMutation.graphql";

interface Props {
  gigId: string;
  isEOR: boolean;
}

export default function usePickupConfirmation({ gigId, isEOR }: Props) {
  const [isPickingShift, setIsPickingShift] = useState(false);
  const { has: hasBgLocationPermission } = usePermission("backgroundLocation");
  const [addEngagement] =
    useRelayMutationPromise<usePickUpConfirmationMutation>(
      graphql`
        mutation usePickUpConfirmationMutation($input: AddEngagementInput!) {
          addEngagement(input: $input) {
            newEngagementEdge {
              node {
                id
                gigType
                currentState {
                  name
                }
              }
            }
          }
        }
      `
    );

  const handlePickup = useCallback(
    (backOnDismiss: boolean) => {
      if (!hasBgLocationPermission || isPickingShift) return;
      setIsPickingShift(true);

      const handleDismiss = () => {
        if (backOnDismiss) defaultBackHandler({ safeExit: true });
        else setIsPickingShift(false);
      };

      const handleGoBack = () => defaultBackHandler({ safeExit: true });

      const handleNext = (travelTime: string, latestArrivalTime: string) => {
        const canReachToLocationInTime =
          !!travelTime &&
          DateTime.now().plus(Duration.fromISO(travelTime)) <=
            DateTime.fromISO(latestArrivalTime);

        modal = canReachToLocationInTime
          ? showSecondConfirmationModal({
              onConfirm: handleFinal,
              onDeny: handleGoBack,
              onDismiss: handleDismiss,
              isEOR
            })
          : showShiftFarAwayModal({ gigId, onClose: handleGoBack });
      };

      const handleFinal = async () => {
        try {
          const res = await addEngagement({
            input: { gigId, initialState: "SCHEDULED" }
          });
          const engagementId = res.addEngagement?.newEngagementEdge?.node?.id;
          if (engagementId) {
            // Show confirmation over current modal
            showWorkerHiredModal({ engagementId, variant: "PICKUP_SHIFT" });
          }
        } catch (err) {
          setTimeout(() => {
            if (err.code === "filled") showShiftFilledModal(handleGoBack);
            else showShiftNotAvailableModal(handleGoBack);
          }, 500);
        } finally {
          defaultBackHandler({ safeExit: true });
        }
      };

      let modal = showFirstConfirmationModal({
        gigId,
        onDismiss: handleDismiss,
        onConfirm: handleNext
      });

      return () => {
        modal.dispose();
      };
    },
    [isPickingShift, hasBgLocationPermission]
  );

  return { handlePickup, isPickingShift };
}
