import {
  Column,
  ContentArea,
  Divider,
  IconText,
  Tag,
  Text
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import moment from "moment";
import React from "react";
import type { EngagementTopAccessory_engagement$key } from "./__generated__/EngagementTopAccessory_engagement.graphql";

type Props = {};

export const EngagementTopAccessory = ({
  insertedAt,
  currentState
}: FragmentContainerInnerComponentProps<
  EngagementTopAccessory_engagement$key,
  Props
>) => {
  switch (currentState?.name) {
    case "APPLIED":
    case "BID_REVIEW": {
      return (
        <Column>
          <ContentArea size="compact" color="surface">
            <IconText iconAlign="center" icon="user-check" size="small">
              Applied on:{" "}
              <Text weight="bold">
                {moment(insertedAt).format("MMM Do, YYYY")}
              </Text>
            </IconText>
          </ContentArea>
          <Divider />
        </Column>
      );
    }

    case "CONFIRMING": {
      return (
        <Column>
          <ContentArea
            horizontal
            gap="standard"
            alignItems="center"
            size="compact"
            color="surface"
          >
            <Tag
              icon="circle-exclamation"
              color="purple"
              label="Confirming"
              variant="clear"
              alignSelf="center"
            />
            <Text fill>You are not hired yet.</Text>
          </ContentArea>
          <Divider />
        </Column>
      );
    }

    default: {
      return null;
    }
  }
};

export default createRelayFragmentContainer<
  EngagementTopAccessory_engagement$key,
  Props
>(
  graphql`
    fragment EngagementTopAccessory_engagement on Engagement {
      insertedAt
      currentState {
        name
      }
    }
  `,
  EngagementTopAccessory
);
