import { Button, ContentArea, FooterSpacer, toast } from "@gigsmart/atorasu";
import useTransitionEngagement from "@gigsmart/isomorphic-shared/engagement/use-transition-engagement";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useMemo } from "react";
import type {
  EngagementStateName,
  WithdrawButton_engagement$key
} from "./__generated__/WithdrawButton_engagement.graphql";

const shiftCancelStates: EngagementStateName[] = [
  "SCHEDULED",
  "EN_ROUTE",
  "AWAITING_START",
  "CONFIRMING",
  "RUNNING_LATE"
];
const projectCancelStates: EngagementStateName[] = [
  "APPLIED",
  "ENGAGED",
  "HIRE_REQUESTED"
];

export const WithdrawButton = ({
  currentState,
  availableActions,
  gigType,
  id,
  result
}: FragmentContainerInnerComponentProps<WithdrawButton_engagement$key>) => {
  const history = useHistory();
  const stateName = currentState?.name;
  const isProject = gigType === "PROJECT";
  const [withdrawBid] = useTransitionEngagement({
    action: "CANCEL",
    engagement: result,
    confirmationTitle: "Are you sure you want to withdraw your bid?",
    confirmationMessage: "This action cannot be undone.",
    confirmationNoLabel: "No, Go Back",
    confirmationYesLabel: "Yes",
    confirmationYesLabelStyle: "destructive",
    onSuccess: () => toast.success("Bid withdrawn successfully")
  });

  const buttonLabel = useMemo(() => {
    if (isProject) {
      return projectCancelStates.includes(stateName)
        ? "Withdraw Application"
        : stateName === "SCHEDULED"
          ? "Cancel this Project Gig"
          : null;
    }

    return stateName === "APPLIED"
      ? "Withdraw Application"
      : stateName === "BID_REVIEW"
        ? "Withdraw Bid"
        : shiftCancelStates.includes(stateName) &&
            availableActions.includes("CANCEL")
          ? "Cancel from Shift"
          : null;
  }, [isProject, stateName, availableActions]);

  const handlePress = () => {
    const isBidReview = currentState?.name === "BID_REVIEW";
    if (isBidReview) withdrawBid();
    else history.push(`/gigs/${id}/cancel`);
  };

  if (!buttonLabel) return null;

  return (
    <ContentArea size="none">
      <Button
        testID="cancel-gig-btn"
        variant="clear"
        outline
        color="danger"
        label={buttonLabel}
        onPress={handlePress}
      />
      <FooterSpacer />
    </ContentArea>
  );
};

export default createRelayFragmentContainer<WithdrawButton_engagement$key>(
  graphql`
    fragment WithdrawButton_engagement on Engagement {
      id
      currentState {
        name
      }
      availableActions
      gigType
      ...useTransitionEngagement_engagement
    }
  `,
  WithdrawButton
);
