import { Button, Column, TopicPreviewSurface } from "@gigsmart/atorasu";
import { EORWorkers } from "@gigsmart/feature-flags";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import { PickupShiftPreviewList } from "./ShiftPreviewList";
import type { PickupShiftTopicPreview_worker$key } from "./__generated__/PickupShiftTopicPreview_worker.graphql";

function PickupShiftTopicPreview({
  availableGigs,
  availableEorGigs
}: FragmentContainerInnerComponentProps<PickupShiftTopicPreview_worker$key>) {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();

  return (
    <>
      {!!availableGigs?.totalCount && (
        <TopicPreviewSurface
          testID="shift-topic-preview"
          iconName="alarm-clock"
          color="emphasized"
          title="Shifts Available for Pick Up Today"
          subtitle="Pick Up available Shifts in your area and work today!"
          variant="emphasized"
        >
          <Column gap="standard">
            <PickupShiftPreviewList fragmentRef={availableGigs} />
            <Button
              alignSelf="center"
              testID="view-all-shift-gigs-btn"
              size="small"
              outline
              label="View All Available Shifts"
              icon="chevron-right"
              iconPlacement="right"
              onPress={() =>
                navigation.push("BrowseShifts", { type: "pickups" })
              }
            />
          </Column>
        </TopicPreviewSurface>
      )}
      {EORWorkers.isEnabled() && !!availableEorGigs?.totalCount && (
        <TopicPreviewSurface
          testID="eor-shift-topic-preview"
          iconName="alarm-clock"
          color="purple"
          title="W-2 Shifts Available for Pick Up Today"
          subtitle="Pick Up available W-2 Shifts in your area and work today!"
          variant="purple"
        >
          <Column gap="standard">
            <PickupShiftPreviewList fragmentRef={availableEorGigs} />
            <Button
              alignSelf="center"
              testID="view-all-eor-shift-gigs-btn"
              size="small"
              outline
              label="View All Available W-2 Shifts"
              icon="chevron-right"
              iconPlacement="right"
              onPress={() =>
                navigation.push("BrowseShifts", { type: "pickups-eor" })
              }
            />
          </Column>
        </TopicPreviewSurface>
      )}
    </>
  );
}

export default createRelayFragmentContainer<PickupShiftTopicPreview_worker$key>(
  graphql`
    fragment PickupShiftTopicPreview_worker on Worker
      @argumentDefinitions(maxDistance: { type: "Int" }) {
      availableGigs(
        first: 3
        input: {
          gigTypes: [PAID, VOLUNTEER]
          maxDistance: $maxDistance
          pickupEligible: true
          excludeRequiredReports: [EOR_WORKER]
        }
      ) {
        totalCount
        ...ShiftPreviewList_pickup_availableGigs @arguments(isEor: false)
      }
      availableEorGigs: availableGigs(
        first: 3
        input: {
          gigTypes: [PAID, VOLUNTEER]
          maxDistance: $maxDistance
          pickupEligible: true
          requiredReports: [EOR_WORKER]
        }
      ) {
        totalCount
        ...ShiftPreviewList_pickup_availableGigs @arguments(isEor: true)
      }
    }
  `,
  PickupShiftTopicPreview
);
