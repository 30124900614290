/**
 * @generated SignedSource<<82a38286857935abfc02dcf515313f6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftPreviewRow_shift$data = {
  readonly id: string;
  readonly name: string | null | undefined;
  readonly organization: {
    readonly logoUrl: string | null | undefined;
  } | null | undefined;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly " $fragmentType": "ShiftPreviewRow_shift";
};
export type ShiftPreviewRow_shift$key = {
  readonly " $data"?: ShiftPreviewRow_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewRow_shift">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftPreviewRow_shift",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredReportTypes",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};

(node as any).hash = "5c97a05ecd1cfb3777fec7cd4cd303db";

export default node;
