/**
 * @generated SignedSource<<5a8d2288dddb7c3fa2e505c4a210dda4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingTimesheetsReviewCard_worker$data = {
  readonly id: string;
  readonly reviewProjects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly gig: {
          readonly endsAt: string | null | undefined;
          readonly insertedAt: string;
          readonly name: string | null | undefined;
          readonly organization: {
            readonly logoUrl: string | null | undefined;
          } | null | undefined;
          readonly position: {
            readonly name: string;
          } | null | undefined;
          readonly startsAt: string | null | undefined;
          readonly timezone: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewRow_shift">;
        };
        readonly id: string;
        readonly timesheet: {
          readonly states: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly transitionedAt: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "PendingTimesheetsReviewCard_worker";
};
export type PendingTimesheetsReviewCard_worker$key = {
  readonly " $data"?: PendingTimesheetsReviewCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingTimesheetsReviewCard_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PendingTimesheetsReviewCard_worker",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "reviewProjects",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": {
              "_eq": "PENDING_TIMESHEET_APPROVAL"
            },
            "workerCanApproveTimesheet": {
              "_eq": true
            }
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Engagement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "variant",
                      "value": "SYSTEM"
                    }
                  ],
                  "concreteType": "EngagementTimesheet",
                  "kind": "LinkedField",
                  "name": "timesheet",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1
                        },
                        {
                          "kind": "Literal",
                          "name": "query",
                          "value": "WHERE name = PENDING_TIMESHEET_APPROVAL"
                        }
                      ],
                      "concreteType": "EngagementStateOrOverridesConnection",
                      "kind": "LinkedField",
                      "name": "states",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "EngagementStateOrOverridesEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "transitionedAt",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "states(first:1,query:\"WHERE name = PENDING_TIMESHEET_APPROVAL\")"
                    }
                  ],
                  "storageKey": "timesheet(variant:\"SYSTEM\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "gig",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ShiftPreviewRow_shift"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startsAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endsAt",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "insertedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timezone",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Organization",
                      "kind": "LinkedField",
                      "name": "organization",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logoUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GigPosition",
                      "kind": "LinkedField",
                      "name": "position",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "engagements(first:5,where:{\"currentStateName\":{\"_eq\":\"PENDING_TIMESHEET_APPROVAL\"},\"workerCanApproveTimesheet\":{\"_eq\":true}})"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "63562ed81a9d16e4aca7b4d9e48a7f35";

export default node;
