import {
  Button,
  Collapsible,
  type CollapsibleRef,
  Column,
  ContentArea,
  Divider,
  Row,
  Spacer,
  Text,
  humanizeDate
} from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory, useIsFocused } from "@gigsmart/kaizoku";
import { DataRow } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React, { useEffect, useRef } from "react";
import type { PriorTransfersRow_payout$key } from "./__generated__/PriorTransfersRow_payout.graphql";
import {
  formatDate,
  formatPaymentAmount,
  formatPaymentMethod,
  formatStatusText,
  todayOrFutureDate
} from "./helpers";

interface Props {
  first: boolean;
}

export function PriorTransfersRow({
  id,
  amount,
  feeAmount,
  insertedAt,
  type,
  providerDetails,
  engagements: engagementEdges,
  first
}: FragmentContainerInnerComponentProps<PriorTransfersRow_payout$key, Props>) {
  const history = useHistory();
  const statusText = formatStatusText(providerDetails?.status ?? "");
  const dateText = "Earnings Date:";
  let statusColor = "neutralDark";
  const engagements = getConnectionNodes(engagementEdges);
  const isFocused = useIsFocused();
  const collapsibleRef = useRef<CollapsibleRef>(null);
  const prevIsFocused = usePrevious(isFocused);

  useEffect(() => {
    if (!prevIsFocused && isFocused) {
      collapsibleRef?.current?.setCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  if (statusText === "Paid") statusColor = "green";
  if (statusText === "Failed") statusColor = "red";
  return (
    <ContentArea size="none">
      <Collapsible
        ref={collapsibleRef}
        startCollapsed
        testID={`prior-transfers-${id}-row`}
        variant="outline"
        header={
          <Row alignItems="center" justifyContent="space-between">
            <Column fill={1}>
              <Text color="neutral" variant="note">
                Initiated:
              </Text>
              <Text testID="payout-started-date" variant="note">
                {formatDate(insertedAt)}
              </Text>
            </Column>
            <Column fill={1}>
              <Text color="neutral" variant="note">
                {statusText === "Paid" ? "Completed:" : "Est. Arrival:"}
              </Text>
              <Text
                weight="semibold"
                testID="payout-completed-date"
                variant="note"
              >
                {todayOrFutureDate(providerDetails?.arrivesAt)}
              </Text>
            </Column>
            <Column fill={0.8}>
              {type === "INSTANT" && statusText === "Paid" && (
                <Text
                  align="center"
                  weight="bold"
                  color="success"
                  testID="payout-rapid"
                >
                  Rapid
                </Text>
              )}
              <Text align="center" weight="bold" testID="payout-status-text">
                {statusText}
              </Text>
            </Column>
            <Column fill={1}>
              <Text align="center" weight="semibold">
                {formatPaymentAmount(currency.toFloat(amount))}
              </Text>
            </Column>
          </Row>
        }
      >
        <Column>
          <ContentArea>
            <Divider />
            <Column fill>
              {engagements?.map((engagement) => (
                <Column fill key={engagement?.id}>
                  <Divider />
                  <ContentArea size="slim" variant="none">
                    <Column fill>
                      <Row gap="compact" fill>
                        <Column fill={2} justifyContent="center">
                          <Column>
                            <Text numberOfLines={1}>
                              {engagement?.gig?.name}
                            </Text>
                          </Column>
                          <Column>
                            <Text variant="note" color="disabled">
                              {`${dateText} ${humanizeDate({
                                startsAt: engagement?.endsAt,
                                size: "sm"
                              })}`}
                            </Text>
                          </Column>
                        </Column>
                        <Column
                          fill={0.7}
                          gap="slim"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            testID="view-shift-btn"
                            size="xsmall"
                            variant="clear"
                            label="View Shift"
                            onPress={() =>
                              history.push(`/gigs/${engagement.id}`)
                            }
                          />
                        </Column>
                        <Column
                          fill={0.7}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text>
                            {engagement.paymentInfo?.netPay
                              ? currency.humanize(
                                  engagement?.paymentInfo?.netPay
                                )
                              : "-"}
                          </Text>
                        </Column>
                      </Row>
                    </Column>
                  </ContentArea>
                  <Divider />
                </Column>
              ))}
            </Column>
            {type === "INSTANT" && (
              <DataRow
                color="neutralDark"
                label="Rapid Transfer Fee"
                secondaryTitle="3% deducted from payout"
                data={
                  <Text color="neutral">{currency?.humanize(feeAmount)}</Text>
                }
              />
            )}
            <Spacer size="compact" />
            <Text align="center" color="neutral" variant="note">
              Transferred to:{" "}
              {providerDetails?.destination
                ? formatPaymentMethod(providerDetails?.destination)
                : null}
            </Text>
          </ContentArea>
        </Column>
      </Collapsible>
    </ContentArea>
  );
}

export default createRelayFragmentContainer<
  PriorTransfersRow_payout$key,
  Props
>(
  graphql`
  fragment PriorTransfersRow_payout on WorkerPayout {
    id
    amount
    feeAmount
    insertedAt
    type
    providerDetails {
      method
      arrivesAt
      automatic
      destination {
        ... on BankAccount {
          bankName
        }
        ... on BankCard {
          brand
        }
        last4
        __typename
      }
      status
    }
    engagements(first: 10, query: "WHERE gigType != PROJECT") {
      edges {
        node {
          id
          endsAt
          gig {
            name
          }
          paymentInfo {
            netPay
          }
        }
      }
    }
  }
`,
  PriorTransfersRow
);

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
