import { Text, useDebouncedCallback } from "@gigsmart/atorasu";
import { useStyles } from "@gigsmart/atorasu/style";
import {
  PositionsAndLocations,
  UnlimitedDistanceFeature
} from "@gigsmart/feature-flags";
import type { GigType } from "@gigsmart/isomorphic-shared/gig/helpers";
import MapView, { type Region } from "@gigsmart/isomorphic-shared/map";
import type { AppNavigationProp } from "@gigsmart/kaizoku";
import { Card } from "@gigsmart/katana";
import { WorkerAvailableSeriesCard } from "@gigsmart/seibutsu/gig-like/WorkerShiftCard";
import {
  type RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { FlatList, View, useWindowDimensions } from "react-native";
import type { WorkerParamList } from "../../navigation/types";
import type { SearchFilter } from "../browse-screen-base/search";
import type { shiftGigsMapHooksPinsQuery } from "./__generated__/shiftGigsMapHooksPinsQuery.graphql";
import { useFilteredGigs, usePins } from "./shift-gigs-map-hooks";

const wrap = (x: number | undefined, min: number, max: number) => {
  if (x === undefined) return x;
  const d = max - min;
  return x === max ? x : ((((x - min) % d) + d) % d) + min;
};

const buildVars = (
  { searchTerm, radiusMiles, gigTypes, requiredReports }: SearchFilter,
  pickupEligible?: boolean,
  region?: Region | null,
  after?: string
): shiftGigsMapHooksPinsQuery["variables"] => ({
  ...UnlimitedDistanceFeature.select(
    {
      latitude: wrap(region?.latitude, -90, 90),
      longitude: wrap(region?.longitude, -180, 180),
      maxDistance: Math.max(Math.ceil(radiusMiles / 10) * 10, 2)
    },
    { maxDistance: 75 }
  ),
  searchTerm,
  count: 20,
  after,
  gigTypes,
  requiredReports,
  pickupEligible: !!pickupEligible
});

interface Props {
  filter: SearchFilter;
  isPickupShifts?: boolean;
}

export default function ShiftGigsMap({ filter, isPickupShifts }: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      flex: 1,
      backgroundColor: getColor("surface", "fill")
    },
    cardsContainer: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: getUnits(5)
    },
    cardsContent: {
      alignItems: "flex-end",
      paddingHorizontal: getUnits(1),
      paddingBottom: getUnits(1)
    },
    cardEntry: { padding: getUnits(1.5) },
    noGigInArea: {
      position: "absolute",
      top: getUnits(2),
      left: getUnits(4),
      right: getUnits(4),
      borderRadius: getUnits(1)
    },
    textAlign: { textAlign: "center" }
  }));
  const navigation = useNavigation<AppNavigationProp<WorkerParamList>>();
  const route = useRoute<RouteProp<WorkerParamList, "BrowseShifts">>();
  const { region, filteredGigIds } = route.params;

  const regionRef = useRef<Region | undefined>(region);
  const initialRegion = useRef(region).current;
  const shiftGigTypes: GigType[] = ["PAID", "VOLUNTEER"];
  const [boolVisibleMarkers, setBoolVisibleMarkers] = useState(false);
  const [vars, setVars] = useState<shiftGigsMapHooksPinsQuery["variables"]>();
  const listRef = useRef<FlatList | null>(null);

  const pins = usePins(vars, isPickupShifts);
  const filtered = useFilteredGigs(vars, filteredGigIds);

  const { width: w } = useWindowDimensions();
  const cardWidth = Math.floor(w - 56);
  const insetSpace = (w - cardWidth) / 2;

  const handleFilterGigs = (ids = filteredGigIds) => {
    listRef.current?.scrollToOffset({ offset: 0 });
    navigation.setParams({
      filteredGigIds: ids ?? [],
      region: regionRef.current
    });
  };

  const handleUpdateVars = useDebouncedCallback(
    (newVars: shiftGigsMapHooksPinsQuery["variables"]) => setVars(newVars),
    300
  );

  useEffect(() => {
    if (vars && filter.searchTerm !== vars.searchTerm && regionRef.current) {
      handleUpdateVars(buildVars(filter, isPickupShifts, regionRef.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.searchTerm]);

  return (
    <View style={styles.container}>
      <MapView
        testID="available-gig-map-view"
        pins={pins}
        onPressMarker={(evt: any, ids: any) => {
          evt.stopPropagation(); // we need to stop the propagation so the onPressMap does not get called.
          handleFilterGigs(ids);
        }}
        onMapPress={() => handleFilterGigs([])}
        clusterize
        onVisibleMilesChange={(miles: any) => {
          const roundedMiles = Math.round(miles);
          if (roundedMiles !== vars?.maxDistance) {
            handleUpdateVars(
              buildVars(filter, isPickupShifts, regionRef.current)
            );
          }
        }}
        onMarkersVisibleChange={(visible: any) =>
          setBoolVisibleMarkers(visible)
        }
        onRegionChange={(region: any) => (regionRef.current = region)}
        initialRegion={initialRegion}
      />

      {boolVisibleMarkers ? (
        <FlatList
          ref={listRef}
          decelerationRate="fast"
          snapToInterval={cardWidth}
          style={styles.cardsContainer}
          contentContainerStyle={[
            styles.cardsContent,
            { paddingHorizontal: insetSpace }
          ]}
          horizontal
          scrollEnabled={filtered.length > 1}
          data={filtered}
          keyExtractor={(item, index) => item.node?.id ?? `${index}`}
          renderItem={({ item }) => {
            if (!filtered || !item?.node?.id) return null;

            return (
              <View style={[styles.cardEntry, { width: cardWidth }]}>
                <WorkerAvailableSeriesCard
                  fragmentRef={item}
                  onPress={() =>
                    navigation.push("BrowseShiftDetails", {
                      id: item.node.id,
                      type: isPickupShifts ? "pickups" : "shifts"
                    })
                  }
                />
              </View>
            );
          }}
        />
      ) : filter.searchTerm ? (
        <Card style={styles.noGigInArea} paddingUnits={2}>
          <Text style={styles.textAlign}>
            No {PositionsAndLocations.isEnabled() ? "Shifts" : "Shift Gigs"}{" "}
            found for <Text weight="bold">"{filter.searchTerm}"</Text>
          </Text>
        </Card>
      ) : (
        <Card style={styles.noGigInArea} paddingUnits={2}>
          <Text style={styles.textAlign}>
            We're sorry to inform you that there are no available{" "}
            {PositionsAndLocations.isEnabled() ? "Shifts" : "Shift Gigs"} in
            your area at this moment.
          </Text>
        </Card>
      )}
    </View>
  );
}
