import {
  Button,
  Column,
  ContentArea,
  Divider,
  HighlightedReminder,
  ProductInfoRow,
  ScreenScroll,
  Stack,
  Surface
} from "@gigsmart/atorasu";
import { toast } from "@gigsmart/atorasu";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { NavPortal, confirmPrompt, dismiss } from "@gigsmart/katana";
import type { StepProps } from "@gigsmart/katana";
import {
  createSuspendedQueryContainer,
  graphql,
  useRelayMutation
} from "@gigsmart/relay";
import DisputeCommentContainer from "@gigsmart/seibutsu/engagement-dispute/DisputeCommentContainer";
import type { StepPropsType } from "@gigsmart/seibutsu/engagement-dispute/worker//types";
import EngagementDisputePaymentRows from "@gigsmart/seibutsu/engagement-dispute/worker/EngagementDisputePaymentRows";
import EngagementDisputeSummaryTabs from "@gigsmart/seibutsu/engagement-dispute/worker/EngagementDisputeSummaryTabs";
import StepHeader from "@gigsmart/seibutsu/shared/Stepper/StepHeader";
import React from "react";
import type {
  EngagementDisputeCommentType,
  reviewDisputeStepAddEngagementDisputeMutation
} from "./__generated__/reviewDisputeStepAddEngagementDisputeMutation.graphql";
import type { reviewDisputeStepQuery } from "./__generated__/reviewDisputeStepQuery.graphql";

type Props = StepProps<StepPropsType>;

export default createSuspendedQueryContainer<reviewDisputeStepQuery, Props>(
  function ReviewDisputeStep({
    gotoStepByName,
    stepData,
    prevStep,
    response: result
  }) {
    const [commit] =
      useRelayMutation<reviewDisputeStepAddEngagementDisputeMutation>(graphql`
        mutation reviewDisputeStepAddEngagementDisputeMutation(
          $input: AddEngagementDisputeInput!
        ) {
          addEngagementDispute(input: $input) {
            newEngagementDisputeEdge {
              node {
                id
              }
            }
          }
        }
      `);
    const history = useHistory();
    const submitDispute = () => {
      const comments = [];
      if (stepData.disputeTimesheet && stepData.timesheetComment) {
        comments.push({
          type: "TIMESHEET" as EngagementDisputeCommentType,
          body: stepData?.timesheetComment
        });
      } else {
      }
      if (stepData.disputeAdditionalPayment && stepData.additionalPayComment) {
        comments.push({
          type: "ADDITIONAL_PAYMENT" as EngagementDisputeCommentType,
          body: stepData?.additionalPayComment
        });
      }
      commit(
        {
          input: {
            engagementId: stepData.engagementId,
            disputeTargetId: stepData?.disputeTimesheetId ?? "",
            comments
          }
        },
        {
          onSuccess: () => {
            history.replace(`/gigs/${stepData.engagementId}`, {
              safeExit: true
            });
            toast.success(
              " Your Dispute has been successfully submitted to the Requester for review"
            );
          }
        }
      );
    };
    return (
      <ScreenScroll testID="review-dispute-step">
        <Column fill justifyContent="space-between">
          <>
            <NavPortal.Entrance
              onPressBack={() => gotoStepByName("dispute-payment")}
            />
            <Stack>
              <StepHeader
                name="Review Your Dispute Summary"
                note={`Please double check your Payment Dispute below. To make an edit, select the "Edit" button. Make any needed edits before you submit to the Requester for review.`}
              />
              <Surface>
                <ProductInfoRow
                  testID="dispute-summary"
                  variant="shadow"
                  icon="clipboard-list"
                  name="Dispute Summary"
                  callToActionLabel="Edit"
                  callToActionIcon="pen-to-square"
                  callToActionOnPress={prevStep}
                />
                <EngagementDisputePaymentRows
                  fragmentRef={result?.timesheet}
                  timesheetDisputed={stepData?.disputeTimesheet}
                  additionalPayDisputed={stepData?.disputeAdditionalPayment}
                />
                <Divider />
                <ContentArea>
                  <Stack>
                    <EngagementDisputeSummaryTabs
                      fragmentRef={result?.timesheet}
                      isRejected={false}
                      timesheetDisputed={stepData?.disputeTimesheet}
                      additionalPayDisputed={stepData?.disputeAdditionalPayment}
                    />
                    {stepData.timesheetComment && (
                      <DisputeCommentContainer
                        label="Why you updated your Timesheet"
                        comment={stepData.timesheetComment}
                      />
                    )}
                    {stepData.additionalPayComment && (
                      <DisputeCommentContainer
                        label="Why you requested Additional Payment"
                        comment={stepData.additionalPayComment}
                      />
                    )}
                  </Stack>
                </ContentArea>
              </Surface>
            </Stack>
          </>
          <ContentArea>
            <Stack>
              <HighlightedReminder
                icon="circle-exclamation"
                header="Please review your Dispute carefully, it can only be submitted once. Once you submit your Dispute, you will no longer be able to make any edits."
              />
              <Button
                outline
                testID="cancel-dispute-btn"
                label="Cancel, I No Longer Wish to Dispute"
                onPress={() => {
                  confirmPrompt({
                    cancelLabel: "No",
                    title: "Are you sure you want to cancel your Dispute?",
                    subTitle: "Your Dispute will not be saved if you cancel.",
                    onDo: () => {
                      history.replace(`/gigs/${stepData.engagementId}`, {
                        safeExit: true
                      });
                      toast.notice("Your Dispute has been canceled");
                    },
                    onCancel: dismiss
                  });
                }}
              />
              <Button
                testID="submit-dispute-btn"
                onPress={() => {
                  confirmPrompt({
                    cancelLabel: "No",
                    title: "Ready to submit your Dispute?",
                    subTitle:
                      "Edits cannot be made after your Dispute is submitted. Submitting an inaccurate Dispute will result in an indefinite suspension of your Get Gigs Account.",
                    onCancel: dismiss,
                    onDo: () => {
                      setTimeout(submitDispute, 450, undefined);
                    }
                  });
                }}
                label={`Submit my Dispute for ${currency.humanize(
                  result?.timesheet?.disputedFinalTimesheet?.diff?.totalPayment
                )}`}
              />
            </Stack>
          </ContentArea>
        </Column>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query reviewDisputeStepQuery($engagementId: ID!, $timesheetId: ID!) {
        engagement: node(id: $engagementId) {
          ... on Engagement {
            ...WorkerShiftInfoCollapsible_engagement
          }
        }
        timesheet: node(id: $timesheetId) {
          ... on EngagementTimesheet {
            totalDurationWorked
            paymentStyle
            totalPaymentAmount
            disputedFinalTimesheet {
              diff {
                totalPayment
              }
            }
            ...EngagementDisputePaymentRows_EngagementTimesheet
            ...EngagementDisputeSummaryTabs_EngagementTimesheet
          }
        }
      }
    `,
    variables: ({ stepData }) => ({
      engagementId: stepData.engagementId,
      timesheetId: stepData?.disputeTimesheetId ?? ""
    })
  }
);
