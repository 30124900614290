import {
  DescriptionItem,
  HighlightedReminder,
  IconText,
  Row,
  Spacer,
  Stack,
  Text,
  useCurrentTime
} from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import { getTransportationMethodIcon } from "@gigsmart/isomorphic-shared/engagement/gig-action-map";
import {
  engagementMoneyEarnedStr,
  engagementTimeEarnedStr
} from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  currency,
  duration,
  numeric,
  time
} from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showLocation } from "react-native-map-link";
import ClockinInfoHeaderRow from "../../locations/ClockinInfoHeaderRow";
import ParkingInfoHeaderRow from "../../locations/ParkingInfoHeaderRow";
import { showTotalEarnedModal } from "../TotalEarnedModal";
import type {
  EngagementStateName,
  ShiftDescriptionItem_shift$key
} from "./__generated__/ShiftDescriptionItem_shift.graphql";

type Props = {
  fill?: boolean;
  showParkingInfo?: boolean;
  showParkingReminder?: boolean;
};

export const ShiftDescriptionItem = ({
  id,
  currentState,
  worker,
  workerDistance,
  workerEta,
  workerTimesheet,
  gig,
  gigSeries,
  paymentInfo,
  fill,
  showParkingInfo,
  result,
  showParkingReminder
}: FragmentContainerInnerComponentProps<
  ShiftDescriptionItem_shift$key,
  Props
>) => {
  const isEOR = gig?.requiredReportTypes?.includes("EOR_WORKER");
  const workerStateName = currentState?.name; /// sometimes relay makes it null
  const variant = getDescriptionVariant(workerStateName);
  const place = gig?.organizationLocation?.place;
  const addr = place?.streetAddress ?? gigSeries?.area ?? "N/A";
  const shouldUseTimesheetDuration =
    workerStateName === "PENDING_TIMESHEET_APPROVAL" &&
    workerTimesheet?.editable === false &&
    !!workerTimesheet?.totalDurationWorked;

  const currentTime = useCurrentTime(
    1000,
    variant === "time-earned" &&
      !shouldUseTimesheetDuration &&
      currentState?.name === "WORKING"
  );

  switch (variant) {
    case "location": {
      return (
        <Stack size="none">
          {showParkingInfo && (
            <ParkingInfoHeaderRow fragmentRef={gig.organizationLocation} />
          )}
          {LocationUpdates.select(
            <ClockinInfoHeaderRow fragmentRef={result} />,
            <DescriptionItem
              fill={fill}
              title={addr}
              onTitlePress={() =>
                showLocation({
                  latitude: place?.location?.latitude ?? "",
                  longitude: place?.location?.longitude ?? "",
                  googlePlaceId: place?.id ?? "",
                  alwaysIncludeGoogle: true,
                  dialogTitle: "Get Directions",
                  dialogMessage: "Select a preferred navigation app"
                })
              }
            >
              <IconText
                icon={getTransportationMethodIcon(worker?.transportationMethod)}
                size="medium"
                textWeight="bold"
              >
                {workerStateName === "SCHEDULED" || !workerEta
                  ? typeof workerDistance === "number"
                    ? `${numeric.humanize(workerDistance, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 1
                      })} mi`
                    : null
                  : `ETA ${time.humanize(workerEta, "hm")}`}
              </IconText>
            </DescriptionItem>
          )}
          {showParkingReminder && (
            <>
              <Spacer size="compact" />
              <HighlightedReminder
                header="Check out the important Parking Location information in the Shift Details."
                icon="square-parking"
              />
            </>
          )}
        </Stack>
      );
    }

    case "time-earned": {
      const isEnded = workerStateName === "ENDED";
      const moneyEarned = isEnded
        ? currency?.humanize(paymentInfo?.netPay)
        : shouldUseTimesheetDuration
          ? currency?.humanize(workerTimesheet?.paymentInfo?.netPay)
          : engagementMoneyEarnedStr({
              currentState: currentState,
              billableDuration: paymentInfo?.billableDuration,
              basePay: paymentInfo?.basePay,
              payRate: paymentInfo?.payRate ?? "",
              serviceFees: paymentInfo?.serviceFees,
              currentTime,
              showCurrency: true
            });
      const timeWorked = shouldUseTimesheetDuration
        ? duration.humanize(workerTimesheet?.totalDurationWorked, "full-clock")
        : engagementTimeEarnedStr({
            variant: "full-clock",
            billableDuration: paymentInfo?.billableDuration,
            currentState: currentState,
            currentTime
          }) || "00:00:00";

      return (
        <Row fill={fill} gap="slim">
          <DescriptionItem fill title="Time Worked">
            <Text variant="bodyLg" weight="bold">
              {timeWorked}
            </Text>
          </DescriptionItem>
          {!isEOR && (
            <DescriptionItem
              fill
              title="Earnings"
              onInfo={() => showTotalEarnedModal(id)}
            >
              <Text variant="bodyLg" weight="bold">
                {moneyEarned}
              </Text>
            </DescriptionItem>
          )}
        </Row>
      );
    }
    default:
      return null;
  }
};

const getDescriptionVariant = (
  workerStateName?: EngagementStateName | null
) => {
  switch (workerStateName) {
    case "SCHEDULED":
    case "EN_ROUTE":
      return "location";
    case "BID_REQUESTED":
    case "BID_REVIEW":
    case "OFFERED":
    case "APPLIED":
    case "CONFIRMING":
      return "hidden";
    default:
      return "time-earned";
  }
};

export default createRelayFragmentContainer<
  ShiftDescriptionItem_shift$key,
  Props
>(
  graphql`
    fragment ShiftDescriptionItem_shift on Engagement {
      id
      ...ClockinInfoHeaderRow_orgLocation
      currentState {
        name
        transitionedAt
      }
      workerEta
      workerDistance
      worker {
        transportationMethod
      }
      gigSeries {
        area
      }
      gig {
        requiredReportTypes
        organizationLocation {
          place {
            id
            streetAddress
            location {
              latitude
              longitude
            }
          }
          ...ParkingInfoHeaderRow_orgLocation
        }
      }
      paymentInfo {
        billableDuration
        basePay
        payRate
        netPay
        serviceFees {
          feeType
          amount
          hourlyRate
        }
      }
      workerTimesheet: timesheet(variant: WORKER) {
        editable
        totalDurationWorked
        paymentInfo {
          netPay
        }
      }
    }
  `,
  ShiftDescriptionItem
);
