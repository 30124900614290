/**
 * @generated SignedSource<<fba71a6640e5b2de8816def9af5c7048>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementPaymentInfoStatus = "ACCURATE" | "FINAL" | "INACCURATE" | "%future added value";
export type GigPaySchedule = "FIXED" | "HOURLY" | "INFO_REQUIRED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerPaymentRows_payment$data = {
  readonly billableDuration: string;
  readonly grossPay: string;
  readonly netPay: string;
  readonly payRate: string;
  readonly paySchedule: GigPaySchedule;
  readonly serviceFees: ReadonlyArray<{
    readonly amount: string;
    readonly feeType: EngagementAddonFeeType;
    readonly hourlyRate: string | null | undefined;
  }>;
  readonly status: EngagementPaymentInfoStatus;
  readonly " $fragmentType": "WorkerPaymentRows_payment";
};
export type WorkerPaymentRows_payment$key = {
  readonly " $data"?: WorkerPaymentRows_payment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerPaymentRows_payment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "WorkerPaymentRows_payment"
};

(node as any).hash = "3152d991279a9ec9037ea40e54141f14";

export default node;
