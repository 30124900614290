/**
 * @generated SignedSource<<990b8aff812abe9f318a4c396726687f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PickupShiftTopicPreview_worker$data = {
  readonly availableEorGigs: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewList_pickup_availableGigs">;
  } | null | undefined;
  readonly availableGigs: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewList_pickup_availableGigs">;
  } | null | undefined;
  readonly " $fragmentType": "PickupShiftTopicPreview_worker";
};
export type PickupShiftTopicPreview_worker$key = {
  readonly " $data"?: PickupShiftTopicPreview_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"PickupShiftTopicPreview_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 3
},
v1 = [
  "EOR_WORKER"
],
v2 = {
  "kind": "Literal",
  "name": "gigTypes",
  "value": [
    "PAID",
    "VOLUNTEER"
  ]
},
v3 = {
  "kind": "Variable",
  "name": "maxDistance",
  "variableName": "maxDistance"
},
v4 = {
  "kind": "Literal",
  "name": "pickupEligible",
  "value": true
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxDistance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PickupShiftTopicPreview_worker",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            {
              "kind": "Literal",
              "name": "excludeRequiredReports",
              "value": (v1/*: any*/)
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "AvailableGigsConnection",
      "kind": "LinkedField",
      "name": "availableGigs",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isEor",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "ShiftPreviewList_pickup_availableGigs"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "availableEorGigs",
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "kind": "Literal",
              "name": "requiredReports",
              "value": (v1/*: any*/)
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "AvailableGigsConnection",
      "kind": "LinkedField",
      "name": "availableGigs",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isEor",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "ShiftPreviewList_pickup_availableGigs"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "8f6159f7d4043cffd8e93fdc519f0e9c";

export default node;
