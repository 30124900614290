import {
  ContentArea,
  ErrorPage,
  ScreenScroll,
  Surface
} from "@gigsmart/atorasu";
import {
  type AppParamList,
  type AppScreenProps,
  useNavigation
} from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import LegalDocument from "@gigsmart/seibutsu/user-consent/LegalDocument";
import React, { useEffect } from "react";
import { useLegalDocumentData } from "../user-consent/useLegalDocument";
import type { LegalScreenQuery } from "./__generated__/LegalScreenQuery.graphql";

export default createSuspendedQueryContainer<
  LegalScreenQuery,
  AppScreenProps<AppParamList, "Legal">
>(
  function LegalScreen({ response }) {
    const navigation = useNavigation();
    const { title } = useLegalDocumentData(
      response?.consentStatus?.legalDocument
    );
    useEffect(() => {
      navigation.setOptions({
        title
      });
    });
    if (!response?.consentStatus) {
      return <ErrorPage title="Not Found" message="Oops!" />;
    }
    return (
      <ScreenScroll testID="legal-screen">
        <Surface>
          <ContentArea>
            <LegalDocument
              document={response?.consentStatus?.legalDocument}
              consent={response?.consentStatus?.consent}
            />
          </ContentArea>
        </Surface>
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query LegalScreenQuery($filename: String!) {
        consentStatus(filename: $filename) {
          consent {
            ...LegalDocument_consent
          }
          legalDocument {
            ...useLegalDocument_document
            ...LegalDocument_document
          }
        }
      }
    `,
    variables: ({ route: { params } }) => {
      const filename = params?.filename ?? "";
      return {
        filename: filename.includes("/") ? filename : `gigsmart/${filename}`
      };
    },
    FallbackComponent: () => null
  }
);
