import { ContentArea, Linking, Surface } from "@gigsmart/atorasu";
import { useTheme } from "@gigsmart/atorasu/style";
import React from "react";
import RenderHtml, { useContentWidth } from "react-native-render-html";

interface Props {
  html: string;
}

export default React.memo<Props>(
  function LegalHtml({ html }: Props) {
    const theme = useTheme();
    const width = useContentWidth();

    return (
      <Surface variant="outline" testID="legal-html">
        <ContentArea>
          <RenderHtml
            emSize={theme.getUnits(3)}
            contentWidth={width}
            baseStyle={{
              fontFamily: theme.fonts.sansSerif,
              color: theme.getColor("black", "fill"),
              fontSize: "1em"
            }}
            tagsStyles={{
              a: {
                color: theme.getColor("link", "fill")
              }
            }}
            source={{ html }}
            renderersProps={{
              a: {
                onPress: (_, href) =>
                  void Linking.openURL(href).catch((err) => console.warn(err))
              }
            }}
          />
        </ContentArea>
      </Surface>
    );
  },
  (prev, next) => prev.html === next.html
);
