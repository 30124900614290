import {
  Column,
  ContentArea,
  HighlightedReminder,
  Tag,
  Text,
  TimeRemaining
} from "@gigsmart/atorasu";
import { WorkerShiftCardUI } from "@gigsmart/feature-flags";
import type { EngagementCurrentState } from "@gigsmart/isomorphic-shared/gig/helpers";
import { time } from "@gigsmart/isomorphic-shared/iso";
import React from "react";
import { readEngagementCapability } from "../WithEngagementCapability";
import type {
  EngagementStateName,
  WorkerEngagementCard_engagement$data
} from "./__generated__/WorkerEngagementCard_engagement.graphql";

interface Props {
  id: string;
  state: EngagementStateName;
  reconcilesAt: string | null | undefined;
  capabilities: WorkerEngagementCard_engagement$data["capabilities"];
  openDisputeExpiration: string | null | undefined;
  isPickupShift: boolean;
  isFilled?: boolean;
  fill?: boolean;
  isEOR?: boolean;
  eorState?: string;
}

export const WorkerEngagementCardFooter = ({
  id,
  state,
  reconcilesAt,
  capabilities,
  openDisputeExpiration,
  isPickupShift,
  isFilled,
  isEOR,
  eorState
}: Props) => {
  const canApproveTimesheet = readEngagementCapability(
    capabilities,
    "APPROVE_SYSTEM_TIMESHEET"
  );
  return (
    <Column testID={`engagement-card-header-${id}-footer`}>
      <ContentArea size="compact" gap="compact">
        {openDisputeExpiration && (
          <Text>
            The Requester has until{" "}
            <Text weight="semibold">
              {time.humanize(openDisputeExpiration, "dateTimeShort")}
            </Text>{" "}
            to review your Dispute.
          </Text>
        )}
        {["APPLIED", "CONFIRMING", "BID_REVIEW"].includes(state) && (
          <>
            {isFilled && (
              <HighlightedReminder
                icon="circle-info"
                header="Heads up! This Shift is currently filled."
              />
            )}
            {isEOR && eorState !== "COMPLETE" && state === "APPLIED" && (
              <HighlightedReminder
                icon="circle-info"
                header="If you are offered this W-2 Shift, you can enroll to become a W-2 Employee."
              />
            )}
            {isEOR && eorState !== "COMPLETE" && state === "CONFIRMING" && (
              <HighlightedReminder
                icon="circle-info"
                header="You must complete the enrollment process through Ascen."
              />
            )}
            <Text testID={`engagement-card-header-${id}-footer-text`}>
              Do <Text weight="bold">NOT</Text> show up for this shift unless
              you are hired & notified via the app.
            </Text>
          </>
        )}
        {state === "PENDING_TIMESHEET_APPROVAL" &&
          (canApproveTimesheet?.available ? (
            <Text testID={`engagement-card-header-${id}-footer-text`}>
              You have{" "}
              <TimeRemaining
                date={canApproveTimesheet?.expiresAt}
                color="danger"
                weight="bold"
              />{" "}
              left to submit your Timesheet to the Requester.
            </Text>
          ) : (
            <Text testID={`engagement-card-header-${id}-footer-text`}>
              The Requester has until{" "}
              <Text weight="bold">
                {time.humanize(reconcilesAt, "dateTimeShort")}
              </Text>{" "}
              to review & approve a Timesheet for this Shift.
            </Text>
          ))}
        {WorkerShiftCardUI.isDisabled() &&
          isPickupShift &&
          state === "SCHEDULED" && (
            <Tag
              icon="alarm-clock"
              color="emphasized"
              label="Shift Scheduled Today"
              variant="clear"
            />
          )}
      </ContentArea>
    </Column>
  );
};

WorkerEngagementCardFooter.isVisible = (
  currentState: EngagementCurrentState,
  openDisputeExpiration?: string | null | undefined,
  isPickupShift?: boolean
) => {
  return (
    !!openDisputeExpiration ||
    (currentState.name === "SCHEDULED" && isPickupShift) ||
    [
      "APPLIED",
      "CONFIRMING",
      "PENDING_TIMESHEET_APPROVAL",
      "BID_REVIEW"
    ].includes(currentState.name)
  );
};
