import {
  ActionRow,
  Button,
  Column,
  ContentArea,
  Divider,
  FancyHeader,
  HighlightedReminder,
  Row,
  Surface
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";
import useInfiniteList from "../shared/InfiniteList/useInfiniteList";
import UnpaidEngagementRow from "./UnpaidEngagementRow";
import type { CompletedW2ShiftsCardListPageQuery } from "./__generated__/CompletedW2ShiftsCardListPageQuery.graphql";
import type { CompletedW2ShiftsCardList_worker$key } from "./__generated__/CompletedW2ShiftsCardList_worker.graphql";
import type { CompletedW2ShiftsCard_worker$key } from "./__generated__/CompletedW2ShiftsCard_worker.graphql";

export function CompletedW2Shifts({
  result
}: FragmentContainerInnerComponentProps<CompletedW2ShiftsCard_worker$key>) {
  const { data, isLoadingNext, hasNext, onEndReached } = useInfiniteList<
    CompletedW2ShiftsCardListPageQuery,
    CompletedW2ShiftsCardList_worker$key,
    null
  >(
    graphql`
    fragment CompletedW2ShiftsCardList_worker on Worker
    @refetchable(queryName: "CompletedW2ShiftsCardListPageQuery")
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 4 }
      after: { type: "String" }
    ) {
      w2Engagements: engagements(
      first: $count,
      after: $after
      query: "WHERE hasPayout = FALSE AND currentStateName IN [PAID, ENDED, CANCELED_WITH_PAY] AND gigType = PAID AND requiredReportType != EOR_WORKER ORDER BY endsAt DESC"
      ) @connection(key: "CompletedW2ShiftsCardList_w2Engagements") {
        edges {
          node {
            ...UnpaidEngagementRow_engagement
          }
        }
      }
    }
  `,
    result ?? null,
    {
      pageSize: 4,
      getData: (data) => getConnectionNodes(data?.w2Engagements)
    }
  );
  return (
    <Surface>
      <FancyHeader
        variant="purple"
        color="purple"
        title="Completed W-2 Shifts"
        icon="business-time"
        action={
          <Button
            testID="email-w2-shifts-btn"
            label="Email All"
            icon="download"
            variant="clear"
            size="xsmall"
          />
        }
      />
      <ContentArea gap="standard">
        <ActionRow
          testID="view-ascen-paystubs"
          icon="receipt"
          title="View Paystubs on Ascen"
          onPress={() => null}
        />
        <HighlightedReminder
          icon="circle-info"
          header="Earnings are paid by Ascen, outside of the GigSmart app."
        />

        <Column>
          <Divider />
          {data?.map((engagement) => (
            <UnpaidEngagementRow fragmentRef={engagement} />
          ))}
        </Column>
        {hasNext && (
          <Row justifyContent="center">
            <Button
              size="small"
              outline
              testID="load-more-w2-shifts-btn"
              label="Load More"
              onPress={() => onEndReached()}
              disabled={isLoadingNext}
            />
          </Row>
        )}
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<CompletedW2ShiftsCard_worker$key>(
  graphql`
  fragment CompletedW2ShiftsCard_worker on Worker {
    ...CompletedW2ShiftsCardList_worker
  }
  `,
  CompletedW2Shifts
);
