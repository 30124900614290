/**
 * @generated SignedSource<<a647d392651f4cc780f53ae44fd93bc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectTopicPreview_worker$data = {
  readonly availableProjectGigs: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectPreviewList_series">;
  } | null | undefined;
  readonly " $fragmentType": "ProjectTopicPreview_worker";
};
export type ProjectTopicPreview_worker$key = {
  readonly " $data"?: ProjectTopicPreview_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectTopicPreview_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxDistance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectTopicPreview_worker",
  "selections": [
    {
      "alias": "availableProjectGigs",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        },
        {
          "fields": [
            {
              "kind": "Literal",
              "name": "gigTypes",
              "value": [
                "PROJECT"
              ]
            },
            {
              "kind": "Variable",
              "name": "maxDistance",
              "variableName": "maxDistance"
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "AvailableGigSeriesConnection",
      "kind": "LinkedField",
      "name": "availableGigSeries",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectPreviewList_series"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "ad5dcade95bcdd9b315f67ad5eed4e52";

export default node;
