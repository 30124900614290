import { useStyles } from "@gigsmart/atorasu/style";
import { Form, FormToggle } from "@gigsmart/fomu";
import type { ReportType } from "@gigsmart/isomorphic-shared/report/types";
import { Card, type StepProps, StyledLink } from "@gigsmart/katana";
import FomuSubmit from "@gigsmart/seibutsu/fomu/inputs/FomuSubmit";
import { isEqual } from "lodash";
import React from "react";

import StepDetail from "../app/step-detail";
import OptInRow from "./opt-in-row";

interface OptIn {
  title: string;
  description: string;
  required?: boolean;
  fieldName?: string;
}

type Props = StepProps & {
  requiredReportTypes?: readonly ReportType[];
  headerText?: string;
};

export default function VerifiedOptIns({
  requiredReportTypes = [],
  headerText = "Select Verification Opt-ins",
  ...props
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    learnMoreLink: {
      margin: getUnits(4),
      textAlign: "center"
    }
  }));
  const optIns: OptIn[] = [
    {
      title: "Financial Verification",
      description: "Verify key account information to ensure you get paid.",
      required: true
    },
    {
      title: "Background Checks",
      fieldName: "backgroundCheckOptIn",
      description:
        "Verify your background so you are eligible to work gigs that require background checks.",
      required: requiredReportTypes.includes("BACKGROUND_CHECK")
    },
    {
      title: "MVR Checks",
      fieldName: "driverCheckOptIn",
      description:
        "Verify your driving record so you are eligible to work gigs that require Motor Vehicle Record checks.",
      required: requiredReportTypes.includes("MOTOR_VEHICLE_CHECK")
    },
    {
      title: "Drug Screens",
      fieldName: "drugScreenOptIn",
      description: "Pass an on-site 9-panel comprehensive drug screening.",
      required: requiredReportTypes.includes("DRUG_SCREEN")
    }
  ];
  const last = optIns.length - 1;
  const { dirty, initialData, ...stepData } = props.stepData;
  return (
    <Form
      initialValues={stepData}
      onSubmit={({ values, valid }) => {
        valid &&
          props.nextStep({
            ...values,
            driverCheckOptIn: requiredReportTypes.includes(
              "MOTOR_VEHICLE_CHECK"
            )
              ? requiredReportTypes.includes("MOTOR_VEHICLE_CHECK")
              : props.stepData.driverCheckOptIn,
            backgroundCheckOptIn: requiredReportTypes.includes(
              "BACKGROUND_CHECK"
            )
              ? requiredReportTypes.includes("BACKGROUND_CHECK")
              : props.stepData.backgroundCheckOptIn,
            drugScreenOptIn: requiredReportTypes.includes("DRUG_SCREEN")
              ? requiredReportTypes.includes("DRUG_SCREEN")
              : props.stepData.drugScreenOptIn
          });
      }}
      onChange={({ values }) => {
        if (!isEqual(values, initialData)) {
          props.updateData({ ...values, dirty: true });
        } else {
          props.updateData({ ...values, dirty: false });
        }
      }}
    >
      <StepDetail
        stickyFooter
        title={headerText}
        description="Your information is secure and used only for verification purposes."
        renderNextButton={() => (
          <FomuSubmit
            allowDirty
            disabled={!dirty && initialData?.status !== "NOT_ENROLLED"}
            testID="continue-button"
            label={props.hasNext ? "Select & Continue" : "Save Changes"}
          />
        )}
        {...props}
        testID="opt-ins-screen"
      >
        <Card>
          {optIns.map(({ fieldName, title, description, required }, index) =>
            !fieldName || required ? (
              <OptInRow
                key={title}
                title={`${title} (required)`}
                eventTargetName="Financial Verification Required Checkbox"
                description={description}
                checked
                disabled
                border={index < last}
              />
            ) : (
              <FormToggle key={title} name={fieldName}>
                {({ value, toggle }) => (
                  <OptInRow
                    title={title}
                    description={description}
                    eventTargetName="Optional Opt-In Checkbox"
                    onPress={() => {
                      toggle();
                    }}
                    checked={value}
                    border={index < last}
                    testID={`${fieldName}-toggle`}
                  />
                )}
              </FormToggle>
            )
          )}
        </Card>
        <StyledLink
          style={styles.learnMoreLink}
          fontSize={16}
          openURL="https://gigsmart.com/get-verified"
        >
          Learn how your information will be used
        </StyledLink>
      </StepDetail>
    </Form>
  );
}
