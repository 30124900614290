import { Screen, Spacer } from "@gigsmart/atorasu";
import type {
  AppNavigationProp,
  BottomTabScreenProps
} from "@gigsmart/kaizoku";
import TabLayout from "@gigsmart/katana/nav/katana-tabs/tab-layout";
import React from "react";
import WorkerAnnouncements from "../../announcement";
import type { WorkerParamList } from "../../navigation/types";
import ProjectGigsTab from "./ProjectGigsTab";
import ShiftGigsTab from "./ShiftGigsTab";
import MyGigsJobsTab from "./my-gigs-jobs-tab";

type Props = BottomTabScreenProps<WorkerParamList, "MyGigs">;

const tabs = [
  { key: "shifts", label: "SHIFTS" },
  { key: "projects", label: "PROJECTS" },
  { key: "jobs", label: "JOB BOARD" }
] as const;

export default function MyGigsScreen({ navigation, route }: Props) {
  const parent = navigation.getParent<AppNavigationProp<WorkerParamList>>();
  return (
    <Screen testID="my-gigs-screen">
      <WorkerAnnouncements enableSpacing={false} />
      <Spacer />
      <TabLayout
        tabs={tabs}
        active={route.params.tab ?? "shifts"}
        onSelectTab={(tab) => navigation.setParams({ tab })}
        renderContent={({ key }) => {
          switch (key) {
            case "jobs":
              return (
                <MyGigsJobsTab
                  onPressJob={(id) => parent.push("JobDetails", { id })}
                />
              );
            case "projects":
              return (
                <ProjectGigsTab
                  active={route.params.projectsTab}
                  onChange={(projectsTab) =>
                    navigation.setParams({ projectsTab })
                  }
                  onPressItem={(id) => parent.push("ProjectDetails", { id })}
                />
              );
            default:
              return (
                <ShiftGigsTab
                  active={route.params.shiftsTab ?? "shifts"}
                  onChange={(shiftsTab) => navigation.setParams({ shiftsTab })}
                  onPressItem={(id) => parent.push("ShiftDetails", { id })}
                />
              );
          }
        }}
      />
    </Screen>
  );
}
