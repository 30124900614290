import {} from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionEdges,
  graphql
} from "@gigsmart/relay";
import { WorkerAvailableShiftCard } from "@gigsmart/seibutsu/gig-like/WorkerShiftCard";
import InfiniteGridList from "@gigsmart/seibutsu/shared/InfiniteList/InfiniteGridList";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useMemo } from "react";
import type { WorkerParamList } from "../navigation/types";
import type { availablePickupShiftsListPageQuery } from "./__generated__/availablePickupShiftsListPageQuery.graphql";
import type { availablePickupShiftsListQuery } from "./__generated__/availablePickupShiftsListQuery.graphql";
import type { availablePickupShiftsList_availableGigs$key } from "./__generated__/availablePickupShiftsList_availableGigs.graphql";
import type { SearchFilter } from "./browse-screen-base/search";
import ShiftGigsDismissableCard from "./shift-gigs-screen/shift-gigs-dismissable-card";
import ShiftGigsEmptyView from "./shift-gigs-screen/shift-gigs-empty-view";
import ShiftGigsListFooter from "./shift-gigs-screen/shift-gigs-list-footer";

interface Props {
  filter: SearchFilter;
}
type ItemType = NonNullable<
  ObjectPath<
    availablePickupShiftsList_availableGigs$key,
    [" $data", "availableGigs", "edges", 0]
  >
>;

export default createSuspendedQueryContainer<
  availablePickupShiftsListQuery,
  Props
>(
  function AvailablePickupShiftsList({ filter, response }) {
    const nav = useNavigation<AppNavigationProp<WorkerParamList>>();
    const variables = useMemo(
      () => ({
        maxDistance: filter.radiusMiles,
        searchTerm: filter.searchTerm,
        gigTypes: filter.gigTypes
      }),
      [filter]
    );

    return (
      <InfiniteGridList<
        availablePickupShiftsListPageQuery,
        availablePickupShiftsList_availableGigs$key,
        ItemType
      >
        parentRef={response?.viewer}
        fragmentInput={graphql`
          fragment availablePickupShiftsList_availableGigs on Worker
          @refetchable(queryName: "availablePickupShiftsListPageQuery")
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 10 }
            after: { type: "String" }
            maxDistance: { type: "Int" }
            crisisId: { type: "ID" }
            searchTerm: { type: "String" }
            gigTypes: { type: "[GigType!]" }
            requiredReports: { type: "[ReportType!]" }
          ) {
            availableGigs: availableGigs(
              first: $count
              after: $after
              input: {
                crisisId: $crisisId
                maxDistance: $maxDistance
                searchTerm: $searchTerm
                gigTypes: $gigTypes
                requiredReports: $requiredReports
                pickupEligible: true
              }
            ) @connection(key: "availablePickupShiftsList_availableGigs") {
              edges {
                cursor
                node @trackImpressions(action: ACCESSED) {
                  __typename
                  id
                }
                ...WorkerAvailableShiftCard_gigEdge
              }
            }
          }
        `}
        getData={(data) => getConnectionEdges(data?.availableGigs)}
        refetchDebounce={10}
        refetchVars={variables}
        keyExtractor={(item) => item.node?.id ?? ""}
        renderItem={(item) => (
          <WorkerAvailableShiftCard
            fragmentRef={item}
            onPress={(id) =>
              nav.push("BrowseShiftDetails", { id, type: "pickups" })
            }
          />
        )}
        renderHeaderView={() => <ShiftGigsDismissableCard />}
        renderFooterView={() => <ShiftGigsListFooter />}
        testID="available-pickup-shifts-list"
        renderEmptyView={() => (
          <ShiftGigsEmptyView searchTerm={filter.searchTerm} />
        )}
      />
    );
  },
  {
    query: graphql`
      query availablePickupShiftsListQuery(
        $maxDistance: Int
        $crisisId: ID
        $searchTerm: String
        $gigTypes: [GigType!]
        $requiredReports: [ReportType!]
      ) {
        viewer {
          ...availablePickupShiftsList_availableGigs @arguments(
            maxDistance: $maxDistance
            crisisId: $crisisId
            searchTerm: $searchTerm
            gigTypes: $gigTypes
            requiredReports: $requiredReports
          )
        }
      }
    `,
    variables: ({ filter }) => ({
      crisisId: filter.crisisId,
      maxDistance: filter.radiusMiles,
      searchTerm: filter.searchTerm,
      gigTypes: filter.gigTypes,
      requiredReports: filter.requiredReports
    })
  }
);
