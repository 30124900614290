import {
  CollapsibleContainer,
  Column,
  FancyHeader,
  Spacer
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { useHistory } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelaySubscribedFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import { sortBy } from "lodash";
import React from "react";

import ProjectPreviewRow from "../gig/worker/ProjectPreviewRow";
import type { HiredProjectsCardSubscription } from "./__generated__/HiredProjectsCardSubscription.graphql";
import type { HiredProjectsCard_worker$key } from "./__generated__/HiredProjectsCard_worker.graphql";

interface Props {
  query: string;
}

export const HiredProjectsCard = ({
  hiredProjects
}: FragmentContainerInnerComponentProps<
  HiredProjectsCard_worker$key,
  Props
>) => {
  const history = useHistory();
  const data = sortBy(
    getConnectionNodes(hiredProjects),
    (item) => !item.gig.isAsap
  );
  const totalCount = hiredProjects?.totalCount ?? 0;
  if (!totalCount) return null;

  const renderProjectPreview = (d: (typeof data)[number]) => {
    return (
      <ProjectPreviewRow
        key={d?.id}
        fragmentRef={d.gig}
        onPress={() => history.push(`/gigs/${d.id}`)}
      />
    );
  };

  const renderContent = (start?: number, end?: number) => (
    <Column gap="compact">
      {data.slice(start, end).map(renderProjectPreview)}
    </Column>
  );

  return (
    <CollapsibleContainer
      testID="projects-container"
      startCollapsed
      variant="card"
      header={
        <FancyHeader
          icon="pen-ruler"
          title={`${pluralize(totalCount, "Project")} You are Hired On`}
        />
      }
      content={renderContent(0, 3)}
      expandLabel="Show More"
      collapseLabel="Show Less"
      contentSize="standard"
      contentVariant="standard"
    >
      {totalCount > 3 && <Spacer size="compact" />}
      {totalCount > 3 && renderContent(3)}
    </CollapsibleContainer>
  );
};

export default createRelaySubscribedFragmentContainer<
  HiredProjectsCard_worker$key,
  HiredProjectsCardSubscription,
  Props
>(HiredProjectsCard, {
  fragment: graphql`
    fragment HiredProjectsCard_worker on Worker
    @argumentDefinitions(query: { type: "String!" }) {
      id
      hiredProjects: engagements(first: 50, query: $query) {
        totalCount
        edges {
          node {
            id
            gig {
              isAsap
              ...ProjectPreviewRow_project
            }
          }
        }
      }
    }
  `,
  subscription: graphql`
    subscription HiredProjectsCardSubscription(
      $workerId: ID!
      $hiredProjectsQuery: String!
    ) {
      workerUpdated(workerId: $workerId) {
        worker {
          id
          ...HiredProjectsCard_worker @arguments(query: $hiredProjectsQuery)
        }
      }
    }
  `,
  getSubscriptionFragmentRef: (data) => data?.workerUpdated?.worker,
  subscriptionVariables: ({ query, id }) => ({
    workerId: id,
    hiredProjectsQuery: query
  })
});
