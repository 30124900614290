/**
 * @generated SignedSource<<2c43bda9bbf8d2c2fd75519b36513932>>
 * @relayHash a7b7d331d70b876386bcdc44c5fe0540
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:qzg5FzFeEMgRErsgljTVIvaBN_coU741Rnl4Vw-Ib3I

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
export type requestAdditionalPaymentStepQuery$variables = {
  engagementId: string;
  hasTimesheetId: boolean;
  timesheetId: string;
};
export type requestAdditionalPaymentStepQuery$data = {
  readonly engagement: {
    readonly finalTimesheet?: {
      readonly additionalPayment: string | null | undefined;
      readonly id: string;
      readonly paymentStyle: EngagementTimesheetPaymentStyle;
      readonly totalDurationWorked: string | null | undefined;
      readonly totalPaymentAmount: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ApprovedEarningsModalRow_engagement">;
  } | null | undefined;
  readonly timesheet?: {
    readonly disputedFinalTimesheet?: {
      readonly diff: {
        readonly additionalPayment: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type requestAdditionalPaymentStepQuery = {
  response: requestAdditionalPaymentStepQuery$data;
  variables: requestAdditionalPaymentStepQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engagementId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTimesheetId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timesheetId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "engagementId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalPayment",
  "storageKey": null
},
v6 = {
  "alias": "finalTimesheet",
  "args": [
    {
      "kind": "Literal",
      "name": "variant",
      "value": "FINAL"
    }
  ],
  "concreteType": "EngagementTimesheet",
  "kind": "LinkedField",
  "name": "timesheet",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentStyle",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalDurationWorked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPaymentAmount",
      "storageKey": null
    }
  ],
  "storageKey": "timesheet(variant:\"FINAL\")"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "timesheetId"
  }
],
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheetComparison",
      "kind": "LinkedField",
      "name": "disputedFinalTimesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheetDiff",
          "kind": "LinkedField",
          "name": "diff",
          "plural": false,
          "selections": [
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "requestAdditionalPaymentStepQuery",
    "selections": [
      {
        "alias": "engagement",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApprovedEarningsModalRow_engagement"
              },
              (v6/*: any*/)
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasTimesheetId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "timesheet",
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "requestAdditionalPaymentStepQuery",
    "selections": [
      {
        "alias": "engagement",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementPaymentInfo",
                "kind": "LinkedField",
                "name": "paymentInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billableDuration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "additionalPay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerServiceFee",
                    "kind": "LinkedField",
                    "name": "serviceFees",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hourlyRate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "feeType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "basePay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "netPay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payRate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "type": "Engagement",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasTimesheetId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "timesheet",
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v8/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "qpid:qzg5FzFeEMgRErsgljTVIvaBN_coU741Rnl4Vw-Ib3I",
    "metadata": {},
    "name": "requestAdditionalPaymentStepQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0242b0b15896261951417ff0c5f70090";

export default node;
