import { Column } from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import ProjectPreviewRow from "./ProjectPreviewRow";
import type { ProjectPreviewList_series$key } from "./__generated__/ProjectPreviewList_series.graphql";

type SeriesProjectPreviewListProps = {
  fragmentRef?: ProjectPreviewList_series$key | null;
};
export const SeriesProjectPreviewList = ({
  fragmentRef
}: SeriesProjectPreviewListProps) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const data = useRelayFragment(
    graphql`
      fragment ProjectPreviewList_series on AvailableGigSeriesConnection {
        edges {
          node {
            id
            availableGigs(first: 1) {
              edges {
                node {
                  id
                  ...ProjectPreviewRow_project
                }
              }

            }
          }
        }
        totalCount
      }
    `,
    fragmentRef ?? null
  );

  return (
    <Column gap="compact">
      {data?.edges?.map((it) => {
        const first = it?.node?.availableGigs?.edges?.[0];
        const gigId = first?.node?.id;
        if (!it?.node || !gigId) return null;

        return (
          <ProjectPreviewRow
            key={gigId}
            fragmentRef={first.node}
            onPress={() =>
              navigation.push("BrowseProjectDetails", { id: gigId })
            }
          />
        );
      })}
    </Column>
  );
};
