import { Button, IconText, Row } from "@gigsmart/atorasu";
import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import { PickupShiftPreviewList } from "../../gig/worker/ShiftPreviewList";
import HomeScreenCard from "../HomescreenCard";
import type { AvailablePickupShiftsCardQuery } from "./__generated__/AvailablePickupShiftsCardQuery.graphql";

type Props = {
  isEor?: boolean;
};

export default createSuspendedQueryContainer<
  AvailablePickupShiftsCardQuery,
  Props
>(
  function AvailablePickupShiftsCard({ isEor, response }) {
    const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
    if (!response?.viewer?.availableGigs?.totalCount) return null;
    return (
      <HomeScreenCard
        testID={`${isEor ? "eor-" : ""}available-pickup-shifts-card`}
        headerIcon="alarm-clock"
        headerTitle={
          isEor
            ? "W-2 Shifts Available for Pick Up Today"
            : "Shifts Available for Pick Up Today"
        }
        headerColor={isEor ? "purple" : "emphasized"}
        contentAreaGap="medium"
      >
        <Row alignItems="center" justifyContent="space-between">
          <IconText
            icon="business-time"
            textVariant="subheader"
            color="black"
            size="small"
          >
            Shifts
          </IconText>
          <Button
            testID="view-all-pickup-shifts-btn"
            color="primary"
            icon="chevron-right"
            iconPlacement="right"
            label="View All"
            onPress={() =>
              navigation.push("BrowseShifts", {
                type: isEor ? "pickups-eor" : "pickups"
              })
            }
            variant="clear"
            size="xsmall"
          />
        </Row>
        <PickupShiftPreviewList fragmentRef={response?.viewer?.availableGigs} />
      </HomeScreenCard>
    );
  },
  {
    query: graphql`
      query AvailablePickupShiftsCardQuery($isEor: Boolean!,$input: AvailableGigsInput) {
        viewer {
          ... on Worker {
            availableGigs(first: 3, input: $input) {
              ...ShiftPreviewList_pickup_availableGigs @arguments(isEor: $isEor)
              totalCount
            }
          }
        }
      }
    `,
    variables: ({ isEor }) => ({
      isEor: !!isEor,
      input: {
        gigTypes: ["PAID", "VOLUNTEER"],
        maxDistance: UnlimitedDistanceFeature.select(12425, 75),
        pickupEligible: true,
        [isEor ? "requiredReports" : "excludeRequiredReports"]: ["EOR_WORKER"]
      }
    })
  }
);
