/**
 * @generated SignedSource<<f0ac2ec5730192c5e7cc844972bada9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LinkedAccountsCard_worker$data = {
  readonly payableAccount: {
    readonly paymentMethod: {
      readonly __typename: string;
      readonly bankName?: string;
      readonly brand?: string;
      readonly last4: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"paymentMethod_payableAccount">;
  } | null | undefined;
  readonly " $fragmentType": "LinkedAccountsCard_worker";
};
export type LinkedAccountsCard_worker$key = {
  readonly " $data"?: LinkedAccountsCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"LinkedAccountsCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkedAccountsCard_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerPayableAccount",
      "kind": "LinkedField",
      "name": "payableAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "paymentMethod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last4",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "brand",
                  "storageKey": null
                }
              ],
              "type": "BankCard",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bankName",
                  "storageKey": null
                }
              ],
              "type": "BankAccount",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "paymentMethod_payableAccount"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "89b523dd72db7d2864e97a7a5f1dd55f";

export default node;
