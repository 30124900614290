import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { ThreeColumnRow } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import moment from "moment-timezone";
import React from "react";
import { Dimensions } from "react-native";
import type { UnpaidProjectEngagementRow_engagement$key } from "./__generated__/UnpaidProjectEngagementRow_engagement.graphql";

interface Props {
  first?: boolean;
}

const { width } = Dimensions.get("window");
const dateText = width < 375 ? "Date:" : "Earnings Date:";

export function UnpaidProjectEngagementRow({
  id,
  endsAt,
  gig,
  payments,
  first
}: FragmentContainerInnerComponentProps<
  UnpaidProjectEngagementRow_engagement$key,
  Props
>) {
  const history = useHistory();
  if (!id) return null;
  if (!payments?.edges || payments?.edges?.length === 0) return null;
  return (
    <>
      {payments?.edges?.map((payment, innerIndex) => (
        <ThreeColumnRow
          key={payment?.node?.id}
          column1Title={gig?.name ?? ""}
          column1Subtitle={`${dateText} ${moment(endsAt ?? undefined).format(
            "MM/DD/YY"
          )}`}
          onLinkPress={() => history.push(`/gigs/${id}`)}
          column2Text="View Gig"
          column3Text={
            payment?.node?.amount
              ? currency.humanize(payment?.node?.amount)
              : "-"
          }
          first={first && innerIndex === 0}
        />
      ))}
    </>
  );
}

export default createRelayFragmentContainer(
  graphql`
  fragment UnpaidProjectEngagementRow_engagement on Engagement {
    id
    endsAt
    gig {
      name
    }
    payments(first: 20) {
      edges {
        node {
          id
          amount
        }
      }
    }
  }
`,
  UnpaidProjectEngagementRow
);
