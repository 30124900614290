import {
  Card,
  type Color,
  Column,
  ContentArea,
  IconText,
  Row,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { LINE_BREAK } from "@gigsmart/isomorphic-shared";
import { PAYMENT_TYPES } from "@gigsmart/isomorphic-shared/gig/payment-types";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerShiftCardFooter_gigLike$key } from "./__generated__/WorkerShiftCardFooter_gigLike.graphql";
import { type ShiftCardExtras, formatNetPay } from "./extras";

interface Props
  extends Pick<ShiftCardExtras, "netPay" | "payRate" | "isTotal"> {
  attachment?: JSX.Element | null;
  attachmentShadow?: boolean;
  highlight?: boolean;
  isEor?: boolean;
  payRatePrefix?: string;
}

export const WorkerShiftCardFooter = ({
  acceptsTips,
  payRate,
  paymentType,
  gigType,
  netPay,
  attachment,
  isEor,
  isTotal = false,
  attachmentShadow = true,
  payRatePrefix,
  highlight = true
}: FragmentContainerInnerComponentProps<
  WorkerShiftCardFooter_gigLike$key,
  Props
>) => {
  const { color, fade }: { color: Color; fade: boolean } = highlight
    ? { color: "success", fade: true }
    : { color: "foreground", fade: false };

  const hasShadow = !!attachment && attachmentShadow;
  const isProject = gigType === "PROJECT";
  const payInfo = paymentType ? PAYMENT_TYPES[paymentType] : null;

  const content = (
    <Surface
      variant="flat"
      color={color}
      fade={fade}
      bottomRadius={hasShadow ? "none" : "small"}
    >
      <ContentArea
        size={isProject ? "standard" : "compact"}
        horizontal
        gap="medium"
        alignItems="center"
      >
        <Column fill>
          {acceptsTips && (
            <IconText icon="money-bill-wave" color="info" size="tiny">
              Tips
            </IconText>
          )}
        </Column>
        {isProject ? (
          // projects
          <Row gap="medium" alignItems="center">
            <Text>Paid via:</Text>
            {!!payInfo && (
              <IconText
                color="success"
                size="small"
                textWeight="bold"
                icon={payInfo.icon}
              >
                {payInfo.label}
              </IconText>
            )}
          </Row>
        ) : isEor ? (
          // W-2 Shift
          <Text align="right">
            Hourly Rate:
            {LINE_BREAK}
            {currency.humanizeRate(payRate)}
          </Text>
        ) : (
          // Regular Shift
          <Row gap="medium">
            <Text align="right" variant="note">
              {isTotal ? "Total Earnings" : "Est. Pay"}
              {LINE_BREAK}
              {payRatePrefix} {currency.humanizeRate(payRate)}
            </Text>
            <Text variant="title" color="success">
              {formatNetPay(netPay, isTotal)}
            </Text>
          </Row>
        )}
      </ContentArea>
    </Surface>
  );

  return (
    <>
      {hasShadow ? (
        <Card eventTargetName={null} testID="worker-shift-card-footer-shadow">
          {content}
        </Card>
      ) : (
        content
      )}
      {attachment}
    </>
  );
};

export default createRelayFragmentContainer<
  WorkerShiftCardFooter_gigLike$key,
  Props
>(
  graphql`
    fragment WorkerShiftCardFooter_gigLike on GigLike {
      id
      acceptsTips
      gigType
      paymentType
    }
  `,
  WorkerShiftCardFooter
);
