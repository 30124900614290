/**
 * @generated SignedSource<<476cd81ae7aec5407096bc8f82995f13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useWorkerEngagementSummary_worker$data = {
  readonly engagementStateSummary: ReadonlyArray<{
    readonly count: number;
    readonly name: EngagementStateName;
  }>;
  readonly paymentPending: {
    readonly totalCount: number;
  } | null | undefined;
  readonly pendingDispute: {
    readonly totalCount: number;
  } | null | undefined;
  readonly timesheetReview: {
    readonly totalCount: number;
  } | null | undefined;
  readonly " $fragmentType": "useWorkerEngagementSummary_worker";
};
export type useWorkerEngagementSummary_worker$key = {
  readonly " $data"?: useWorkerEngagementSummary_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"useWorkerEngagementSummary_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v2 = {
  "_eq": "PENDING_TIMESHEET_APPROVAL"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useWorkerEngagementSummary_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementStateSummary",
      "kind": "LinkedField",
      "name": "engagementStateSummary",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "pendingDispute",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "anyEngagementDisputeDisposition": {
              "_isNull": true
            },
            "currentStateName": {
              "_eq": "ENDED"
            }
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "engagements(first:0,where:{\"anyEngagementDisputeDisposition\":{\"_isNull\":true},\"currentStateName\":{\"_eq\":\"ENDED\"}})"
    },
    {
      "alias": "timesheetReview",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": (v2/*: any*/),
            "workerCanApproveTimesheet": {
              "_eq": true
            }
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_eq\":\"PENDING_TIMESHEET_APPROVAL\"},\"workerCanApproveTimesheet\":{\"_eq\":true}})"
    },
    {
      "alias": "paymentPending",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "currentStateName": (v2/*: any*/),
            "workerCanApproveTimesheet": {
              "_eq": false
            }
          }
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_eq\":\"PENDING_TIMESHEET_APPROVAL\"},\"workerCanApproveTimesheet\":{\"_eq\":false}})"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "21e48e84cb71704769e58eb2f8688fae";

export default node;
