import {
  Column,
  ContentArea,
  Link,
  List,
  ScrollView,
  Tag,
  Text,
  Well,
  showModal
} from "@gigsmart/atorasu";
import { WorkerAttendanceWarnings } from "@gigsmart/feature-flags";
import KatanaTabs from "@gigsmart/katana/nav/katana-tabs/katana-tabs";
import StyledLink from "@gigsmart/katana/tappable/styled-link";
import React from "react";
import LegalDocumentLink from "../user-consent/LegalDocumentLink";

interface Options {
  defaultTab?: string;
}

export function showShiftGigInfoModal({ defaultTab }: Options = {}) {
  return showModal({
    eventContext: "Shifts InfoModal",
    title: "Shifts",
    headerIcon: "business-time",
    variant: "border",
    fixedHeight: 600,
    children: () => (
      <KatanaTabs
        tabs={[
          {
            label: "ABOUT",
            Contents: (
              <ScrollView testID="shift-about-tab" showsVerticalScrollIndicator>
                <ContentArea gap="standard">
                  <Text>
                    Work hourly Shifts hiring ASAP or up to 30-days in advance.
                    Track your hours and get paid through the app.
                  </Text>
                  <Well>
                    <List variant="check">
                      <Text>Work local Shifts in person</Text>
                      <Text>Easily track the hours you work in the app</Text>
                      <Text>
                        Access your Shift earnings via your Worker Wallet
                      </Text>
                      <Text>
                        Work safely knowing you're protected by{" "}
                        <StyledLink openURL="https://gigsmart.com/workers/occupational-accident-insurance/">
                          Occupational Accident Insurance
                        </StyledLink>
                      </Text>
                    </List>
                  </Well>
                  <Link
                    align="center"
                    openURL="https://gigsmart.com/workers/how-it-works/"
                  >
                    View More Info about Shifts
                  </Link>
                </ContentArea>
              </ScrollView>
            )
          },
          {
            label: "GET HIRED",
            Contents: (
              <ScrollView
                testID="shift-get-hired-tab"
                showsVerticalScrollIndicator
              >
                <ContentArea gap="standard">
                  <Text>
                    For your best chance of being hired, do the following:
                  </Text>
                  <Well>
                    <List variant="ordered">
                      <Text>Have a professional and quality profile photo</Text>
                      <Text>
                        Fill out all relevant sections on your profile
                      </Text>
                      <Text>
                        Only Bid on Shifts that you have the Qualifications to
                        work
                      </Text>
                      {WorkerAttendanceWarnings.isEnabled() ? (
                        <Text>
                          Show up on time to your shifts and avoid late
                          cancellations or no-shows, as this information will be
                          visible to hiring Organizations.
                        </Text>
                      ) : null}
                    </List>
                  </Well>
                  <Text>
                    <Text weight="bold">Reminder: </Text>
                    Never accept payment for a Shifts outside of the app. View{" "}
                    <LegalDocumentLink filename="gigsmart/tos" />
                  </Text>
                </ContentArea>
              </ScrollView>
            )
          },
          {
            label: "STATUSES",
            Contents: (
              <ScrollView
                testID="shift-statuses-tab"
                showsVerticalScrollIndicator
              >
                <ContentArea gap="standard">
                  <Column gap="slim">
                    <Tag label="Hired" icon="check" color="success" />
                    <Text>
                      You are expected to show up and work the Shift at the date
                      and time the Shift is scheduled.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag
                      label="Confirming"
                      icon="circle-exclamation"
                      color="purple"
                    />
                    <Text>
                      The Requester has selected additional verification. If
                      required, a Background Check and/or an MVR Check will run
                      while you are being confirmed. If required for the Shift,
                      you will receive an email invitation to schedule an
                      on-site Drug Screening. You will be notified you are hired
                      for the Shift after clearing all required verification.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag
                      label="Timesheet Review"
                      icon="triangle-exclamation"
                      color="danger"
                    />
                    <Text>
                      Make sure your Timesheet is accurate. You have up to 6
                      hours after Shift end time to edit and submit your
                      Timesheet.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag
                      label="Payment Pending"
                      icon="spinner"
                      color="disabled"
                    />
                    <Text>
                      A Shift is Pending Payment after you have submitted a
                      Timesheet to the Requester and you are waiting for them to
                      approve a Timesheet.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag
                      label="Dispute Pending"
                      icon="file-exclamation"
                      color="disabled"
                    />
                    <Text>
                      You have submitted a Dispute and are waiting for a
                      response.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag
                      label="Bid Submitted"
                      icon="file-check"
                      color="primary"
                    />
                    <Text>
                      You have submitted your bid for the Shift for the
                      Organization to review. You will be notified if your Bid
                      is accepted or if the Organization sends you a
                      counter-offer.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag
                      label="Bid Requested"
                      icon="hand-holding-dollar"
                      color="info"
                    />
                    <Text>
                      You have been requested to submit a Bid for an available
                      Shift. If you are interested, submit a Bid.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag label="Applied" icon="user-check" color="primary" />
                    <Text>
                      Your Shift application has been submitted. We will notify
                      you if a Requester chooses to offer you a Shift.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag label="Pending Offer" icon="check" color="success" />
                    <Text>
                      The Organization has accepted your Bid. Accept the Shift
                      offer to be hired.
                    </Text>
                  </Column>
                  <Column gap="slim">
                    <Tag
                      label="Bid Countered"
                      icon="comment-dollar"
                      color="warning"
                    />
                    <Text>
                      The Organization sent a counter-offer to your Bid, You can
                      accept the counter-offer, submit a counter-bid, or reject
                      the Shift.
                    </Text>
                  </Column>
                </ContentArea>
              </ScrollView>
            )
          }
        ]}
        defaultTab={defaultTab}
      />
    ),
    actions: [{ label: "Got It", testID: "close-btn" }]
  });
}
