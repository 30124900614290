import { Button, ContentArea, FancyHeader, Surface } from "@gigsmart/atorasu";
import { useHistory } from "@gigsmart/kaizoku";
import { DataRow } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { LinkedAccountsCard_worker$key } from "./__generated__/LinkedAccountsCard_worker.graphql";
import { formatPaymentMethod } from "./helpers";

export function LinkedAccountCard({
  payableAccount
}: FragmentContainerInnerComponentProps<LinkedAccountsCard_worker$key, {}>) {
  const paymentMethod = payableAccount?.paymentMethod;
  const history = useHistory();
  const navigateToPayment = () =>
    history.push({
      pathname: "/wallet/payment"
    });
  const hasPaymentMethod = !!paymentMethod?.last4;
  return (
    <Surface>
      <FancyHeader title="Linked Accounts" icon="building-columns" />
      <ContentArea>
        <Surface variant="outline">
          {hasPaymentMethod && (
            <DataRow
              label={formatPaymentMethod(paymentMethod)}
              data={
                <Button
                  testID="update-payment-method-btn"
                  size="small"
                  outline
                  label="Update"
                  onPress={navigateToPayment}
                />
              }
            />
          )}
          {!hasPaymentMethod && (
            <DataRow
              label="No Account Added"
              iconName="circle-exclamation"
              color="red"
              data={
                <Button
                  testID="add-payment-method-btn"
                  label="Add"
                  icon="plus"
                  size="small"
                  onPress={navigateToPayment}
                />
              }
            />
          )}
        </Surface>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer(
  graphql`
  fragment LinkedAccountsCard_worker on Worker {
    payableAccount {
      paymentMethod {
      __typename
      last4
      ... on BankCard {
        brand
      }
      ... on BankAccount {
        bankName
      }
    }
    ...paymentMethod_payableAccount
    }
  }
`,
  LinkedAccountCard
);
