import React from "react";

import { IconText, Stack, Text } from "@gigsmart/atorasu";
import { BulletList, BulletListItem, StyledLink } from "@gigsmart/katana";

interface Props {
  showHeader?: boolean;
}

const ShiftGigsInfoContent = ({ showHeader }: Props) => (
  <Stack>
    {showHeader && (
      <IconText icon="business-time" size="medium">
        <Text variant="bodyLg" weight="semibold">
          Shift Gigs
        </Text>
      </IconText>
    )}
    <Text>
      Browse and apply to hourly Gigs hiring ASAP or up to 30-days in advance.
      Track hours and get paid through the app.
    </Text>
    <BulletList>
      <BulletListItem>
        <Text>Work local Gigs in person</Text>
      </BulletListItem>
      <BulletListItem>
        <Text>Easily track the hours you work in the app</Text>
      </BulletListItem>
      <BulletListItem>
        <Text>Access your Gig earnings via your Worker Wallet</Text>
      </BulletListItem>
      <BulletListItem>
        <Text>Work safely knowing you're protected by </Text>
        <StyledLink openURL="https://gigsmart.com/workers/occupational-accident-insurance/">
          Occupational Accident Insurance
        </StyledLink>
      </BulletListItem>
    </BulletList>
  </Stack>
);

export default ShiftGigsInfoContent;
