import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { RemainingTime, showRemainingTime } from "./RemainingTime";
import WorkerShiftCard from "./WorkerShiftCard";

import type { WorkerAvailableSeriesCard_seriesEdge$key } from "./__generated__/WorkerAvailableSeriesCard_seriesEdge.graphql";

interface Props {
  onPress?: (id: string) => void;
}

export const WorkerAvailableSeriesCard = ({
  node,
  gigDistance,
  estimatedPaymentRange,
  onPress
}: FragmentContainerInnerComponentProps<
  WorkerAvailableSeriesCard_seriesEdge$key,
  Props
>) => {
  const shiftCount = node?.availableGigs?.totalCount ?? 1;
  const gigEdge = node?.availableGigs?.edges?.[0];
  const gig = gigEdge?.node;

  // Sanity check
  // A Series should never be shown as available if there are no available Shifts in it
  if (!gig) return null;

  const isPickup = !!gig?.pickup?.eligible;
  const isEor = gig?.requiredReportTypes?.includes?.("EOR_WORKER");
  const date = isPickup ? gig?.pickup.windowEnd : gig?.startsAt ?? gig?.endsAt;
  const remainingTimeNode = showRemainingTime(isPickup, date) ? (
    <RemainingTime isPickup={isPickup} date={date} />
  ) : null;

  return (
    <WorkerShiftCard
      fragmentRef={node}
      distance={gigDistance}
      shiftCount={shiftCount}
      netPay={estimatedPaymentRange}
      payRate={gigEdge?.estimatedPayment?.payRate}
      nextGig={gig ?? null}
      bodyHeader={remainingTimeNode}
      onPress={onPress}
      isEor={isEor}
      showEorTag={false}
    />
  );
};

export default createRelayFragmentContainer<
  WorkerAvailableSeriesCard_seriesEdge$key,
  Props
>(
  graphql`
    fragment WorkerAvailableSeriesCard_seriesEdge on AvailableGigSeriesEdge {
      estimatedPaymentRange {
        max: maxAmount
        min: minAmount
      }
      gigDistance {
        max: maxDistance
        min: minDistance
      }
      node {
        ...WorkerShiftCard_gigLike
        availableGigs(first: 1) {
          totalCount
          edges {
            node {
              ...WorkerShiftCardBody_gig
              startsAt
              endsAt
              requiredReportTypes
              pickup {
                eligible
                windowEnd
              }
            }
            estimatedPayment {
              payRate
            }
          }
        }
      }
    }
  `,
  WorkerAvailableSeriesCard
);
