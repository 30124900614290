import {
  Button,
  Column,
  ContentArea,
  FancyHeader,
  HighlightedReminder,
  Surface,
  Text,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { W2ShiftsCard_shift$key } from "./__generated__/W2ShiftsCard_shift.graphql";

type Props = {};

export const W2ShiftsCard = ({
  result
}: FragmentContainerInnerComponentProps<W2ShiftsCard_shift$key, Props>) => {
  const user = useCurrentUser();
  const eorState = user?.verification?.reports?.find(
    ({ type }) => type === "EOR_WORKER"
  )?.state;
  return (
    <Surface>
      <FancyHeader
        icon="passport"
        title="W-2 Shifts"
        color="purple"
        variant="purple"
      />
      <ContentArea>
        <Column gap="standard">
          {eorState === "PENDING" && (
            <Text weight="bold">You are not currently a W-2 Employee</Text>
          )}
          <Text>
            {eorState === "PENDING"
              ? "This Shift is available to W-2 Employees. You can apply, and if the Requester offers you the Shift, you'll have the option to enroll as a W-2 Employee."
              : eorState === "REQUIRES_ATTENTION"
                ? "Your Ascen account needs attention. Please log in to your account or check your email for a communication from Ascen for next steps."
                : "This is a W-2 Shift opportunity employed through Ascen."}
          </Text>
          {eorState !== "COMPLETE" ? (
            <Column justifyContent="center">
              <Button
                testID="w-2-learn-more-btn"
                alignSelf="center"
                icon="external-link"
                label="Learn More About W-2 Shifts"
                variant="clear"
                onPress={() =>
                  showEmbeddedBrowser({
                    url: "https://help.gigsmart.com/bid-tips"
                  })
                }
              />
            </Column>
          ) : (
            <HighlightedReminder
              icon="circle-info"
              header="You will be paid via Ascen the next Business day after your timesheet is approved."
            />
          )}
        </Column>
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<W2ShiftsCard_shift$key, Props>(
  graphql`
    fragment W2ShiftsCard_shift on GigLike {
      id
    }
  `,
  W2ShiftsCard
);
