/**
 * @generated SignedSource<<59f56415989db30ee1ea41d23578a701>>
 * @relayHash 0f3ff7d625fdaefd1dad552fa174a761
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Mg3uvRX7UfDP-5eAvfbh7dx2cyUJIJ5N0yJaqNthQP8

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerTransportationState = "MOTORCYCLE" | "SEDAN" | "SUV" | "TRUCK" | "VAN" | "%future added value";
export type UpdateWorkerInput = {
  administrativeArea1?: string | null | undefined;
  allowLegalDuplicates?: boolean | null | undefined;
  biography?: string | null | undefined;
  birthdate?: string | null | undefined;
  demographic?: string | null | undefined;
  disableOtp?: boolean | null | undefined;
  firstName?: string | null | undefined;
  hubspotVid?: string | null | undefined;
  isApproved?: boolean | null | undefined;
  isAvailable?: boolean | null | undefined;
  isVolunteer?: boolean | null | undefined;
  lastName?: string | null | undefined;
  locality?: string | null | undefined;
  minimumHourlyRate?: string | null | undefined;
  postalCode?: string | null | undefined;
  preferredTimeZone?: string | null | undefined;
  referralSource?: string | null | undefined;
  street1?: string | null | undefined;
  street2?: string | null | undefined;
  transportationMethod?: string | null | undefined;
  transportationState?: WorkerTransportationState | null | undefined;
  workerId?: string | null | undefined;
};
export type EditProfileFormUpdateWorkerMutation$variables = {
  input: UpdateWorkerInput;
};
export type EditProfileFormUpdateWorkerMutation$data = {
  readonly updateWorker: {
    readonly worker: {
      readonly " $fragmentSpreads": FragmentRefs<"EditProfileForm_worker">;
    };
  } | null | undefined;
};
export type EditProfileFormUpdateWorkerMutation = {
  response: EditProfileFormUpdateWorkerMutation$data;
  variables: EditProfileFormUpdateWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfileFormUpdateWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditProfileForm_worker"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProfileFormUpdateWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkerPayload",
        "kind": "LinkedField",
        "name": "updateWorker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Worker",
            "kind": "LinkedField",
            "name": "worker",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserPronoun",
                "kind": "LinkedField",
                "name": "pronoun",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeArea1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePhoto",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "public",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "favoritedByOrganizationsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "biography",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMobile",
                "kind": "LinkedField",
                "name": "primaryMobile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkerVerification",
                "kind": "LinkedField",
                "name": "verification",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VerificationReport",
                    "kind": "LinkedField",
                    "name": "reports",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Mg3uvRX7UfDP-5eAvfbh7dx2cyUJIJ5N0yJaqNthQP8",
    "metadata": {},
    "name": "EditProfileFormUpdateWorkerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "85b6bb0c8a31833cafda0b0706e0f879";

export default node;
