/**
 * @generated SignedSource<<3fd640931d74a30b3928c1126b9a4dbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiDaySelectGigsStep_engagement$data = {
  readonly endsAt: string | null | undefined;
  readonly estimatedPayment: {
    readonly netPay: string;
  } | null | undefined;
  readonly gig: {
    readonly area: string | null | undefined;
    readonly payRate: string | null | undefined;
  };
  readonly id: string;
  readonly insertedAt: string;
  readonly startsAt: string | null | undefined;
  readonly workerDistance: number | null | undefined;
  readonly " $fragmentType": "MultiDaySelectGigsStep_engagement";
};
export type MultiDaySelectGigsStep_engagement$key = {
  readonly " $data"?: MultiDaySelectGigsStep_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_engagement">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "MultiDaySelectGigsStep_engagement"
};

(node as any).hash = "1bae536ccae6779004d96216aadb9d58";

export default node;
