import {
  Button,
  Carousel,
  Column,
  ContentArea,
  DocumentTile,
  FancyHeader,
  GoogleMapMarker,
  GoogleMapParkingPin,
  GridNull,
  MaxWidthContainer,
  Pressable,
  Row,
  Surface,
  Text,
  showPDFViewer
} from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showLocation } from "react-native-map-link";
import { ShiftLocationMap } from "../gig/ShiftLocationMap";
import type { ParkingLocationCard_orgLocation$key } from "./__generated__/ParkingLocationCard_orgLocation.graphql";

interface Props {
  currentState: EngagementStateName;
  isEOR?: boolean;
}

export const ParkingLocationCard = ({
  id,
  parkingLocations,
  currentState,
  isEOR
}: FragmentContainerInnerComponentProps<
  ParkingLocationCard_orgLocation$key,
  Props
>) => {
  const parkingLocation = parkingLocations?.edges?.[0]?.node;
  const attachmentNodes = getConnectionNodes(parkingLocation?.attachments);
  const imageAttachments = attachmentNodes
    .filter((item) => item.mimeType !== "application/pdf")
    .map((item) => item.url);
  const fileAttachments = attachmentNodes.filter(
    (item) => item.mimeType === "application/pdf"
  );

  if (LocationUpdates.isDisabled() || !parkingLocation) return <GridNull />;

  const handleShowLocation = () => {
    void showLocation({
      latitude: parkingLocation.location.latitude ?? "",
      longitude: parkingLocation.location.longitude ?? "",
      googlePlaceId: parkingLocation.place.id ?? "",
      alwaysIncludeGoogle: true,
      dialogTitle: "Get Directions",
      dialogMessage: "Select a preferred navigation app"
    });
  };

  if (currentState === "CANCELED" || currentState === "CANCELED_WITH_PAY")
    return <GridNull />;

  return (
    <Surface>
      <FancyHeader
        icon="square-parking"
        title="Parking Location"
        color={isEOR ? "purple" : "primary"}
        variant={isEOR ? "purple" : undefined}
      />
      <ContentArea gap="standard">
        <Row gap="standard">
          <Column justifyContent="center" fill>
            <Text>{parkingLocation?.place?.streetAddress}</Text>
          </Column>
          <Button
            label="Directions"
            outline
            size="small"
            onPress={handleShowLocation}
            testID="parking-location-directions-button"
          />
        </Row>
        <Column>
          <ShiftLocationMap
            center={parkingLocation?.location ?? { latitude: 0, longitude: 0 }}
          >
            <GoogleMapMarker
              center={
                parkingLocation?.location ?? { latitude: 0, longitude: 0 }
              }
              draggable={false}
            >
              <GoogleMapParkingPin color="primary" />
            </GoogleMapMarker>
          </ShiftLocationMap>
        </Column>
        {parkingLocation.instructions && (
          <Text>{parkingLocation?.instructions}</Text>
        )}
        {!!imageAttachments.length && (
          <Carousel sources={imageAttachments} thumbSize={32.5} />
        )}
        {fileAttachments.map(({ url, filename }, index: number) => (
          <MaxWidthContainer key={url} maxWidth={180}>
            <Column>
              <Pressable
                testID="select-message-attachment"
                eventEntityType="Pressable"
                eventTargetName="SelectMessageAttachment"
                onPress={() =>
                  showPDFViewer({
                    source: { uri: url ?? "" },
                    filename: filename ?? "Document.pdf"
                  })
                }
              >
                <Surface variant="flat" color="background">
                  <DocumentTile
                    name={filename ?? `Attachment ${index + 1}`}
                    type="PDF"
                    icon="file-pdf"
                  />
                </Surface>
              </Pressable>
            </Column>
          </MaxWidthContainer>
        ))}
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<
  ParkingLocationCard_orgLocation$key,
  Props
>(
  graphql`
    fragment ParkingLocationCard_orgLocation on OrganizationLocation {
      id
      parkingLocations(first: 1) {
        edges {
          node {
            id
            instructions
            place {
              id
              streetAddress
            }
            location {
              latitude
              longitude
            }
            attachments(first: 50) {
              edges {
                node {
                  id
                  url
                  filename
                  mimeType
                }
              }
            }
          }
        }
      }
    }
  `,
  ParkingLocationCard
);
