import {
  ActionRow,
  Button,
  Column,
  ContentArea,
  Divider,
  Linking,
  Row,
  Stack,
  Tag,
  Text
} from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import React, { useState } from "react";
import HomeScreenCard from "../worker/HomescreenCard";
import type { MyEORCenterCardQuery } from "./__generated__/MyEORCenterCardQuery.graphql";

type Report = NonNullable<
  NonNullable<
    NonNullable<MyEORCenterCardQuery["response"]["viewer"]>["verification"]
  >["reports"]
>[number];

export default createSuspendedQueryContainer<MyEORCenterCardQuery>(
  function MyEORCenterCard({ response }) {
    const enrollmentState =
      response?.viewer?.verification?.reports?.find(
        (report: Report) => report.type === "EOR_WORKER"
      )?.state ?? "NOT_STARTED";
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => setCollapsed((prev) => !prev);
    const pressHandler = () => null;
    return (
      <HomeScreenCard
        headerColor="purple"
        headerTitle="My W-2 Center"
        testID="my-eor-center-card"
        headerIcon="passport"
        footerLinkText="For support contact support@ascen.com"
        footerLinkUrl="mailto:support@ascen.com"
        footer={
          collapsed ? null : (
            <ContentArea size="compact" variant="compact" alignItems="center">
              <Text>
                For Support contact&nbsp;
                <Text
                  color="info"
                  onPress={async () =>
                    await Linking.openURL("mailto:support@ascen.com")
                  }
                >
                  support@ascen.com
                </Text>
              </Text>
            </ContentArea>
          )
        }
        action={
          enrollmentState === "PENDING" ? (
            <Button
              label="Continue"
              size="small"
              variant="solid"
              onPress={pressHandler}
              testID="my-eor-center-card-continue-btn"
            />
          ) : (
            <Button
              testID="toggle-collapsible-btn"
              size="xsmall"
              variant="clear"
              onPress={toggleCollapsed}
              label={collapsed ? "Show" : "Hide"}
              icon={collapsed ? "chevron-down" : "chevron-up"}
              iconPlacement="right"
            />
          )
        }
        hideContent={collapsed}
      >
        {enrollmentState === "PENDING" && (
          <Column gap="standard">
            <Row justifyContent="center">
              <Text weight="bold">Become a W-2 Employee of Ascen</Text>
            </Row>
            <Row>
              <Text>
                Expand your opportunities by becoming a W-2 employee. Complete
                the onboarding process to get started.
              </Text>
            </Row>
            <Row gap="compact" alignItems="center">
              <Text weight="bold" color="primary">
                Enrollment Status:
              </Text>
              <Tag label="Incomplete" color="neutral" />
            </Row>
          </Column>
        )}
        {enrollmentState === "REQUIRES_ATTENTION" && (
          <Column gap="standard">
            <Row>
              <Text>
                Your Ascen account needs attention. Please log in to your
                account or check your email for a communication from Ascen for
                next steps.
              </Text>
            </Row>
          </Column>
        )}
        {enrollmentState === "COMPLETE" && (
          <Column gap="standard">
            <Divider />
            <ContentArea size="none">
              <Row justifyContent="space-between" alignItems="center">
                <Text>
                  <Text weight="bold">Ascen </Text> Employment status
                </Text>
                <Tag label="Employed" color="purple" />
              </Row>
            </ContentArea>
            <Divider />
            <Row>
              <Text>
                Access your W-2 employment information from Ascen using the
                quick links below.
              </Text>
            </Row>
            <Stack size="compact">
              <ActionRow
                testID="account-overview-btn"
                icon="user"
                title="Account Overview"
                onPress={() => null}
              />
              <ActionRow
                testID="paystubs-btn"
                icon="receipt"
                title="Paystubs"
                onPress={() => null}
              />
              <ActionRow
                testID="benefits-btn"
                icon="hands-holding-dollar"
                title="Benefits"
                onPress={() => null}
              />
              <ActionRow
                testID="tax-forms-btn"
                icon="file-lines"
                title="Tax Forms"
                onPress={() => null}
              />
            </Stack>
          </Column>
        )}
      </HomeScreenCard>
    );
  },
  {
    query: graphql`
    query MyEORCenterCardQuery {
      viewer {
        ... on Worker {
          verification {
            reports {
              type
              state
              state
              statusDescription
            }
          }
        }
      }
    }
  `,
    variables: () => ({})
  }
);
