import { useDebouncedCallback } from "@gigsmart/atorasu";
import {
  type GraphQLTaggedNode,
  type KeyType,
  type QuerySpec,
  refetchOnly,
  useRelayOrchestrator,
  useRelayPaginationFragment
} from "@gigsmart/relay";
import isEqual from "lodash/isEqual";
import {
  type ComponentType,
  type ReactElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

export interface Options<Q extends QuerySpec, TKey extends KeyType, T> {
  pageSize?: number;
  getData: (data: TKey[" $data"] | null) => T[] | null;
  refetchVars?: Partial<Q["variables"]>;
  refetchDebounce?: number;

  renderEmptyView?: (
    loading: boolean
  ) => ComponentType<any> | ReactElement | null;
  renderFooterView?: (
    loading: boolean,
    totalCount: number
  ) => ComponentType<any> | ReactElement | null;
  renderHeaderView?: (
    empty: boolean,
    isRefetching: boolean,
    totalCount: number
  ) => ComponentType<any> | ReactElement | null;
}

export const getOrRenderElement = (
  Element?: ComponentType | JSX.Element | null
): JSX.Element | null => {
  if (!Element) return null;
  if (isValidElement(Element)) return Element;

  // @ts-ignore
  return <Element />;
};

export default function useInfiniteList<
  Q extends QuerySpec,
  TKey extends KeyType,
  T
>(
  fragmentInput: GraphQLTaggedNode,
  parentRef: TKey | null | undefined,
  {
    getData,
    pageSize = 20,
    refetchVars,
    refetchDebounce = 300,
    renderEmptyView,
    renderFooterView,
    renderHeaderView
  }: Options<Q, TKey, T>
) {
  const { fetchQuery } = useRelayOrchestrator();
  const {
    data: fragmentData,
    loadNext,
    isLoadingNext,
    hasNext,
    refetch
  } = useRelayPaginationFragment<Q, TKey>(fragmentInput, parentRef);
  const [isRefetching, setRefetching] = useState(false);
  const handleLoadNext = useCallback(() => {
    if (isLoadingNext || !hasNext) return;
    loadNext(pageSize);
  }, [loadNext, pageSize, isLoadingNext, hasNext]);

  const fragmentId =
    !!fragmentData && typeof fragmentData === "object" && "id" in fragmentData
      ? (fragmentData as { id: string }).id
      : undefined;

  // refetch
  const initialRefetchVars = useRef(refetchVars);
  const refetchFn = useDebouncedCallback((vars: typeof refetchVars) => {
    refetchOnly<Q>(
      refetch,
      fetchQuery,
      parentRef,
      fragmentInput,
      fragmentId,
      vars
    ).finally(() => setRefetching(false));
  }, refetchDebounce);
  const handleRefetch = useCallback(() => {
    // setRefetching(true);
    refetchFn(initialRefetchVars.current);
  }, []);

  useEffect(() => {
    const shouldRefetch =
      !!refetchVars && !isEqual(initialRefetchVars.current, refetchVars);
    if (shouldRefetch) {
      setRefetching(true);
      initialRefetchVars.current = refetchVars;
      refetchFn(refetchVars);
    }
  }, [refetchFn, refetchVars]);
  const data = useMemo(
    () => (isRefetching ? null : getData(fragmentData)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fragmentData, isRefetching]
  );

  const isLoading = isRefetching || isLoadingNext;
  const totalCount = data?.length ?? 0;
  const isEmpty = !isLoading && totalCount === 0;

  return {
    data,
    fragmentData,
    refetch: handleRefetch,
    hasNext,
    isRefetching,
    isLoadingNext,
    isEmpty,

    onEndReached: handleLoadNext,
    onEndReachedThreshold: 0.3,
    ListEmptyComponent: renderEmptyView?.(isLoading),
    ListFooterComponent: renderFooterView?.(isLoading, totalCount),
    ListHeaderComponent: renderHeaderView?.(
      !isRefetching && totalCount === 0,
      isRefetching,
      totalCount
    )
  };
}
