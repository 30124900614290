import { Column, Dot, Row, Text, TimeRemaining } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import { formatGigLocalDateTime } from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import HomeScreenCard from "@gigsmart/seibutsu/worker/HomescreenCard";
import { DateTime } from "luxon";
import React from "react";
import ShiftPreviewRow from "../gig/worker/ShiftPreviewRow";
import type { WorkerShiftParams } from "../shifts/navigation";
import type { PendingTimesheetsReviewCardSubscription } from "./__generated__/PendingTimesheetsReviewCardSubscription.graphql";
import type { PendingTimesheetsReviewCard_worker$key } from "./__generated__/PendingTimesheetsReviewCard_worker.graphql";

type Props = {};

export function PendingTimesheetsReviewCard({
  reviewProjects
}: FragmentContainerInnerComponentProps<
  PendingTimesheetsReviewCard_worker$key,
  Props
>) {
  useRelaySubscription<PendingTimesheetsReviewCardSubscription>(
    graphql`
      subscription PendingTimesheetsReviewCardSubscription {
        workerUpdated {
          worker {
            ...PendingTimesheetsReviewCard_worker
          }
        }
      }
    `,
    {}
  );

  const navigation = useNavigation<AppNavigationProp<WorkerShiftParams>>();
  const nodes = getConnectionNodes(reviewProjects);
  if (!nodes.length) return null;

  return (
    <HomeScreenCard
      headerTitle={`${pluralize(nodes.length, "Timesheet")} Needs Your Review`}
      headerIcon="triangle-exclamation"
      headerColor="danger"
    >
      <Column gap="compact">
        {nodes.map((node) => {
          if (!node?.gig) return null;

          const { startsAt, endsAt, timezone } = node.gig;
          const formattedDate = formatGigLocalDateTime(
            { startsAt, endsAt, timezone },
            { dateFormat: "fullDateShort" }
          );

          const transitionTime =
            node.timesheet?.states?.edges?.[0]?.node?.transitionedAt;
          const transitionedBeforeEndtime =
            new Date(transitionTime ?? Date.now()).getTime() <
            new Date(node.gig?.endsAt ?? Date.now()).getTime();

          const timeRemaining = DateTime.fromJSDate(
            new Date(
              transitionedBeforeEndtime
                ? node.gig?.endsAt ?? Date.now()
                : transitionTime ?? Date.now()
            )
          )
            .plus({ hours: 6 })
            .toJSON();

          return (
            <ShiftPreviewRow
              key={node?.id}
              fragmentRef={node.gig}
              onPress={() => navigation.push("ShiftDetails", { id: node?.id })}
              note={
                <Text
                  variant="note"
                  color="neutral"
                >{`${formattedDate.dateString.replace(/,? \d{4}/, "")} ${
                  formattedDate.timeString
                }`}</Text>
              }
              footer={
                <Row alignItems="center" gap="compact">
                  <Dot animation="flash" />
                  <Text>
                    <TimeRemaining
                      weight="bold"
                      color="danger"
                      date={timeRemaining}
                    />{" "}
                    left to review your Timesheet
                  </Text>
                </Row>
              }
            />
          );
        })}
      </Column>
    </HomeScreenCard>
  );
}

export default createRelayFragmentContainer<
  PendingTimesheetsReviewCard_worker$key,
  Props
>(
  graphql`
    fragment PendingTimesheetsReviewCard_worker on Worker {
      id
      reviewProjects: engagements(
        first: 5,
        where: {
          currentStateName: { _eq: PENDING_TIMESHEET_APPROVAL }
          workerCanApproveTimesheet: { _eq: true }
        }
      ) {
        edges {
          node {
            id
            timesheet(variant: SYSTEM) {
              states(
                first: 1
                query: "WHERE name = PENDING_TIMESHEET_APPROVAL"
              ) {
                edges {
                  node {
                    transitionedAt
                  }
                }
              }
            }
            gig {
              ...ShiftPreviewRow_shift
              startsAt
              endsAt
              name
              insertedAt
              timezone
              organization {
                logoUrl
              }
              position {
                name
              }
            }
          }
        }
      }
    }
  `,
  PendingTimesheetsReviewCard
);
