import {
  CollapsibleContainer,
  Column,
  FancyHeader,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import { formatGigLocalDateTime } from "@gigsmart/seibutsu/gig/useGigLocalDateTime";
import React from "react";
import ShiftPreviewRow from "../gig/worker/ShiftPreviewRow";
import type { WorkerShiftParams } from "../shifts/navigation";
import type { UpcomingShiftsQuery } from "./__generated__/UpcomingShiftsQuery.graphql";
import type { UpcomingShiftsWorkerHomeScreenSubscription } from "./__generated__/UpcomingShiftsWorkerHomeScreenSubscription.graphql";

type Props = {
  query: string;
};

export default createSuspendedQueryContainer<UpcomingShiftsQuery, Props>(
  function UpcomingShiftsWrapper({ query, response }) {
    const navigation = useNavigation<AppNavigationProp<WorkerShiftParams>>();
    const data = getConnectionNodes(response?.viewer?.upcomingShifts);
    const shiftCount = data.length;
    const shiftNoun = shiftCount > 1 ? "Shifts" : "Shift";
    const headerTitle = `${shiftCount} Upcoming Hired ${shiftNoun} (Next 7 Days)`;

    useRelaySubscription<UpcomingShiftsWorkerHomeScreenSubscription>(
      graphql`
        subscription UpcomingShiftsWorkerHomeScreenSubscription(
          $upcomingShiftsQuery: String!
        ) {
          workerUpdated {
            worker {
              ...UpcomingShifts_WorkerHomeScreen
                @arguments(upcomingShiftsQuery: $upcomingShiftsQuery)
            }
          }
        }
      `,
      { upcomingShiftsQuery: query }
    );

    if (shiftCount < 1) return null;

    const renderEngagement = (d: (typeof data)[number]) => {
      const { dateString, timeString } = formatGigLocalDateTime(
        { startsAt: d.startsAt, endsAt: d.endsAt, timezone: d.timezone },
        { dateFormat: "fullDateShort" }
      );

      return (
        <ShiftPreviewRow
          key={d.id}
          type="shifts"
          fragmentRef={d.gig}
          note={
            <Text variant="note" color="neutral">
              {dateString} {timeString}
            </Text>
          }
          onPress={() => navigation.push("ShiftDetails", { id: d.id })}
        />
      );
    };

    const renderContent = (start?: number, end?: number) => (
      <Column gap="compact">
        {data.slice(start, end).map(renderEngagement)}
      </Column>
    );

    return (
      <CollapsibleContainer
        testID="upcoming-shifts"
        startCollapsed
        variant="card"
        header={<FancyHeader icon="calendar-week" title={headerTitle} />}
        content={renderContent(0, 3)}
        expandLabel="Show More"
        collapseLabel="Show Less"
        contentSize="standard"
        contentVariant="standard"
      >
        {shiftCount > 3 && (
          <>
            <Spacer size="compact" />
            {renderContent(3)}
          </>
        )}
      </CollapsibleContainer>
    );
  },
  {
    query: graphql`
      query UpcomingShiftsQuery($upcomingShiftsQuery: String) {
        viewer {
          ... on Worker {
            upcomingShifts: engagements(
              first: 10
              query: $upcomingShiftsQuery
            ) {
              totalCount
              edges {
                node {
                  id
                  endsAt
                  startsAt
                  timezone
                  gig {
                    ...ShiftPreviewRow_shift
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: ({ query }) => ({ upcomingShiftsQuery: query })
  }
);

export const UpcomingShiftsFragment = graphql`
  fragment UpcomingShifts_WorkerHomeScreen on Worker
  @argumentDefinitions(upcomingShiftsQuery: { type: "String" }) {
    upcomingShifts: engagements(first: 10, query: $upcomingShiftsQuery) {
      totalCount
      edges {
        node {
          id
          currentState {
            id
            name
          }
          endsAt
          startsAt
          gig {
            name
            description
            timezone
            organization {
              logoUrl
            }
          }
        }
      }
    }
  }
`;
