import * as React from "react";
import Svg, { Path, type SvgProps } from "react-native-svg";

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width="280"
      height="251"
      preserveAspectRatio="xMaxYMax"
      viewBox="0 0 228 204"
      {...props}
    >
      <Path
        d="M126.592 45.773v1.555a4.441 4.441 0 01.779-.15 5.309 5.309 0 01-.779-1.405zM167.83 194.97l-.427-2.21h-.02l-6.973-54.007-10.291 55.578a911.76 911.76 0 0117.71.64zM99.779 103.287V74.281l-6.266 10.2c.145 1.692.263 3.468.342 5.333zM107.23 191.283h.8v-18.126a364.326 364.326 0 01-5.097-26.855 278.964 278.964 0 01-2.99-30.795c-.26-.05-6.438-1.318-2.762-10.045 0 0-.643-1.047-3.281-5.207a3.43 3.43 0 01-4.705 2.773l-1.178-.484-.64 16.639.53 74.881a1417.7 1417.7 0 0114.623-.173 5.67 5.67 0 014.7-2.608zM123.19 94.281l-.18-7.452a66.527 66.527 0 00-1.912 11.203l2.198-.876zM140.263 129.358v-2.148l-11.15-.67a1.507 1.507 0 01-.376-.077 4.961 4.961 0 01-.219.085l-.117-.243a1.515 1.515 0 01-.717-1.295c0-.052.011-.1.017-.15l-1.946-4.019c1.116 9.108-1.273 16.955-3.402 26.584a152.215 152.215 0 00-1.129 5.52c-.997 5.43-1.896 10.418-2.684 14.873l1.149 23.466.17 2.588c8.366.037 16.395.142 23.921.305l.2-64.82zM65.529 192.142h-.022l-.488 2.536c5.185-.197 10.771-.362 16.665-.491l-9.629-57.528zM19.168 76.37h-1.841v2.07a18.223 18.223 0 001.841-2.07zM14.233 81.068V76.37H9.91a16.731 16.731 0 004.323 4.697z"
        fill="none"
      />
      <Path
        d="M138.684 98L140.263 101.57 140.263 94.63 138.684 98z"
        fill="none"
      />
      <Path
        d="M171.268 38.512l-.076 1.962-.111 2.921v.437l-.001 6.456a2.912 2.912 0 01.128.814v.299h-.128l-.001 4.094a5.431 5.431 0 01-4.06 3.232l2.8 1.24 4.94 2.188V38.51h-3.49zM133.846 112.89l-6.36 2.534c-.126 1.48-.181 2.25-.181 2.25.405.259.766.509 1.108.755l8.117.064zM140.263 62.676l.002-.003.9-.257v-6.33h-6.613a38.037 38.037 0 004.846 8.115c.286-.526.574-1.04.865-1.525zM129.747 90.66c1.954-7.149 5.025-17.145 8.634-24.494a41.39 41.39 0 01-5.934-10.08h-4.64a4.433 4.433 0 01-1.215-.177v4.788l1.03.335s3.007 8.251 2.125 29.627zM145.537 61.167a32.851 32.851 0 003.176-5.08h-3.578v5.195zM58 192.142h.033l-1.34-72.96h-.008l-.608-17.542H34.821l11.66 93.984c1.986-.135 4.107-.264 6.335-.386A5.886 5.886 0 0158 192.142zM130.026 119.745c1.604 1.528 1.98 2.789 1.765 3.793l7.205.104-1.84-3.841zM153.527 58.881v.002h.002v-2.796h-2.651a40.32 40.32 0 01-2.289 4.207z"
        fill="none"
      />
      <Path
        d="M168.54 198.662l-.71-3.691a911.837 911.837 0 00-17.711-.64l-.132.709a3.143 3.143 0 01.145.898v2.724h-6.355v-2.724a3.174 3.174 0 01.078-.686h-.078l.003-1.075c-7.526-.163-15.555-.268-23.921-.305l.243 3.692-7.05.012-.003.011h-11.63a6.634 6.634 0 011.11-3.696 1417.7 1417.7 0 00-14.621.173l.007 1.022-.023-.002c.005.077.023.15.023.228v2.718h-6.34v-2.718a3.132 3.132 0 01.146-.904l-.037-.221c-5.894.13-11.48.294-16.665.49l-.646 3.353h-12.26a5.861 5.861 0 01.703-2.792c-2.228.122-4.35.251-6.335.386l.265 2.136-2.173.27-.28-2.25c-11.147.827-17.786 1.86-17.786 2.981 0 2.71 38.697 4.907 86.433 4.907s86.432-2.197 86.432-4.907c0-1.173-7.264-2.25-19.365-3.094a5.867 5.867 0 01.823 2.995z"
        fill="#dadde2"
      />
      <Path
        d="M17.327 78.44v-2.07h-3.094v4.698A16.731 16.731 0 019.91 76.37H8.22c1.315 2.128 3.442 4.995 6.013 6.335v19.398h3.094V80.336a19.452 19.452 0 003.607-3.965h-1.766a18.223 18.223 0 01-1.841 2.069z"
        fill="#685228"
      />
      <Path
        d="M22.742 73.4V60.488H16.35a3.975 3.975 0 00-3.643-2.385h-.442a3.976 3.976 0 00-3.976 3.976v1.017a4.112 4.112 0 00-4.807 6.331A3.47 3.47 0 00.56 74.096z"
        fill="#6fb741"
      />
      <Path
        d="M.56 74.096A3.478 3.478 0 003.82 76.37h18.92V73.4z"
        fill="#66a83b"
      />
      <Path
        d="M29.648 59.943h.306v2.462l.79 6.372V67.73H34.5v6.998h-.706a4.262 4.262 0 01.246 1.504l5.83 4.657h.023l.006-.01.015.01h1.776v1.189l.79.529V80.89h3.755v.442l3.435-7.205V67.73h3.05l.377-.79h-3.427v-6.998h3.755v6.31l.79-1.657v-4.653h3.754v1.999l3.436-1.623v-.376h.797l2.655-1.254-.005-.007V55.88a16.59 16.59 0 01-2.812-.712v-1.35h-33.82l.076.605.658-.081zm12.831 0h3.754v6.998H42.48zm0 7.788h3.754v6.998H42.48zm-4.545-7.788h3.755v6.998h-3.755zm0 7.788h3.755v6.998h-3.755zm-7.19-7.788H34.5v6.998h-3.755z"
        fill="#ab7348"
      />
      <Path
        d="M32.202 80.522L32.247 80.89 32.571 80.89 32.202 80.522z"
        fill="#ab7348"
      />
      <Path
        d="M56.076 101.604h-2.938V85.416q-.877 1.513-1.848 3.09l-.01.015q-.952 1.54-1.998 3.134c-.593.901-1.663 2.496-2.291 3.394-.246-.147-.5-.308-.758-.473v1.1H42.48v-3.799q-.399-.309-.79-.62v4.42h-3.755v-6.999h.626c-1.438-1.225-2.409-2.104-2.409-2.104l.844-1.283-2.496-2.483v5.08h-1.384l.098.79h1.285v6.999h-.416l.74 5.963h21.255zM30.744 95.677v-6.999h.262l-.098-.79h-.164v-1.322l-.844-6.804a6.55 6.55 0 01-2.776-5.033H26.2v-6.998h2.208l-.098-.79H26.2v-6.998h1.242l-.662-5.333.66-.082-.087-.71h-4.611v47.822h9.872l-.74-5.963zm-.79 0H26.2v-6.999h3.754zm0-7.789H26.2V80.89h3.754z"
        fill="#ab7348"
      />
      <Path
        d="M36.798 33.896L35.041 33.896 31.219 37.702 36.798 37.009 36.798 33.896z"
        fill="#be6045"
      />
      <Path
        d="M28.22 53.817h33.819V48.18a1.89 1.89 0 01-1.16-1.744V42.53a1.891 1.891 0 011.16-1.744v-2.42h-3.29V36.24h2.712v-1.517l-.83-.826H43.615v5.05h-1.63l.174 1.4.406 3.273-19.749 2.45-2.416 2.407 7.048-.875z"
        fill="#be6045"
      />
      <Path
        d="M26.701 48.561L19.405 49.466 15.036 53.817 22.742 53.817 27.353 53.817 26.701 48.561z"
        fill="#be6045"
      />
      <Path
        d="M80.637 53.817L79.013 52.201 79.013 53.817 80.637 53.817z"
        fill="#be6045"
      />
      <Path
        d="M53.138 101.604h2.938l-.7-20.22q-1.032 1.946-2.238 4.032z"
        fill="#825834"
      />
      <Path
        d="M30.744 95.677L31.875 95.677 31.006 88.678 30.744 88.678 30.744 95.677z"
        fill="#93d1d0"
      />
      <Path
        d="M34.499 88.678L33.213 88.678 34.082 95.677 34.499 95.677 34.499 88.678z"
        fill="#93d1d0"
      />
      <Path
        d="M34.499 82.808L32.571 80.89 32.247 80.89 33.115 87.888 34.499 87.888 34.499 82.808z"
        fill="#93d1d0"
      />
      <Path
        d="M30.908 87.888L30.744 86.566 30.744 87.888 30.908 87.888z"
        fill="#93d1d0"
      />
      <Path d="M26.19968 80.88991H29.95395V87.88814H26.19968z" fill="#93d1d0" />
      <Path d="M26.19968 88.67836H29.95395V95.67659H26.19968z" fill="#93d1d0" />
      <Path
        d="M42.48 95.677h3.753v-1.101c-1.193-.769-2.493-1.72-3.754-2.699z"
        fill="#93d1d0"
      />
      <Path
        d="M46.233 81.332L46.233 80.89 42.479 80.89 42.479 82.608 44.864 84.204 46.233 81.332z"
        fill="#93d1d0"
      />
      <Path
        d="M39.868 80.89L39.883 80.902 39.891 80.89 39.868 80.89z"
        fill="#93d1d0"
      />
      <Path
        d="M41.689 80.89L39.913 80.89 41.689 82.079 41.689 80.89z"
        fill="#93d1d0"
      />
      <Path
        d="M37.934 88.678v6.999h3.755v-4.42a118.606 118.606 0 01-3.129-2.579zM34.499 67.731h-3.755v1.046l.391 3.149.997.656a4.222 4.222 0 011.66 2.147h.707z"
        fill="#93d1d0"
      />
      <Path d="M30.74436 59.94283H34.49851V66.94082H30.74436z" fill="#93d1d0" />
      <Path
        d="M27.442 59.943L26.2 59.943 26.2 66.941 28.31 66.941 27.442 59.943z"
        fill="#93d1d0"
      />
      <Path
        d="M29.648 59.943L29.954 62.405 29.954 59.943 29.648 59.943z"
        fill="#93d1d0"
      />
      <Path
        d="M28.19 70.786l.366-.557.176.115-.324-2.613H26.2v6.998h.924a6.53 6.53 0 011.066-3.943z"
        fill="#93d1d0"
      />
      <Path d="M42.4791 67.73122H46.23349V74.72945H42.4791z" fill="#93d1d0" />
      <Path d="M42.4791 59.94283H46.23349V66.94082H42.4791z" fill="#93d1d0" />
      <Path d="M37.93442 59.94283H41.68869V66.94082H37.93442z" fill="#93d1d0" />
      <Path d="M37.93442 67.73122H41.68869V74.72945H37.93442z" fill="#93d1d0" />
      <Path
        d="M54.214 59.943L54.214 64.596 54.756 63.459 57.968 61.942 57.968 59.943 54.214 59.943z"
        fill="#93d1d0"
      />
      <Path
        d="M53.424 59.943L49.669 59.943 49.669 66.941 53.096 66.941 53.424 66.253 53.424 59.943z"
        fill="#93d1d0"
      />
      <Path
        d="M49.669 67.731L49.669 74.127 52.719 67.731 49.669 67.731z"
        fill="#93d1d0"
      />
      <Path
        d="M61.404 60.319L62.201 59.943 61.404 59.943 61.404 60.319z"
        fill="#93d1d0"
      />
      <Path
        d="M41.67 36.405L41.985 38.945 43.615 38.945 43.615 33.896 43.615 29.576 36.798 29.576 36.798 33.896 36.798 37.009 41.67 36.405z"
        fill="#be6045"
      />
      <Path
        d="M44.483 29.576L44.483 27.407 35.93 27.407 35.93 29.576 36.798 29.576 43.615 29.576 44.483 29.576z"
        fill="#be6045"
      />
      <Path
        d="M141.165 56.087L141.165 62.416 145.135 61.282 145.135 56.087 143.777 56.087 141.165 56.087z"
        fill="#685228"
      />
      <Path
        d="M132.447 56.087a41.39 41.39 0 005.934 10.08q.502-1.021 1.017-1.966a38.037 38.037 0 01-4.846-8.114zM148.713 56.087a32.851 32.851 0 01-3.176 5.08l.808-.231 2.244-.642a40.32 40.32 0 002.289-4.207z"
        fill="#685228"
      />
      <Path
        d="M127.37 47.178a4.441 4.441 0 00-.778.15v5.747l26.937-.845v-2.593h-.544a1.912 1.912 0 01-1.911-1.913v-2.921a3.327 3.327 0 01-1.13-2.493v-1.836l.004-1.962v-.002h-3.103v-2.16h2.298v-.642h-5.262a5.1 5.1 0 00-4.673-3.06h-.567a5.102 5.102 0 00-5.102 5.102v1.304a5.256 5.256 0 00-6.947 3.078v3.641a5.309 5.309 0 00.779 1.405z"
        fill="#6fb741"
      />
      <Path
        d="M127.806 56.087h25.723V52.23l-26.937.845v2.834a4.433 4.433 0 001.214.178z"
        fill="#66a83b"
      />
      <Path
        d="M174.759 8.648v27.703h.127v2.16h-.127v23.644l3.66 1.62-.001.011a78.73 78.73 0 011.64 7.853h10.26v11.846h-8.82c.323 3.992.537 8.513.537 13.51 0 1.255.001 2.513.003 3.764l.007.025h10.812V71.639h16.077v29.145h18.098V8.648zm15.56 54.826h-11.847V51.63h11.846zm0-18.67h-11.847V32.96h11.846zm0-18.669h-11.847V14.29h11.846zm16.5 37.34h-11.847V51.629h11.846zm0-18.67h-11.847V32.959h11.846zm0-18.67h-11.847V14.29h11.846zm16.593 57.35h-11.846V71.64h11.846zm0-20.01h-11.846V51.629h11.846zm0-18.67h-11.846V32.959h11.846zm0-18.67h-11.846V14.29h11.846z"
        fill="#c6c6c6"
      />
      <Path
        d="M192.85678 71.63899H208.93392999999998V100.78401000000001H192.85678z"
        fill="#606060"
      />
      <Path
        d="M178.47226 14.28902H190.31845V26.13521H178.47226z"
        fill="#d2efff"
      />
      <Path
        d="M211.56626 14.28902H223.41196V26.13521H211.56626z"
        fill="#d2efff"
      />
      <Path
        d="M194.97202 14.28902H206.81821V26.13521H194.97202z"
        fill="#d2efff"
      />
      <Path
        d="M178.47226 32.9587H190.31845V44.80489H178.47226z"
        fill="#d2efff"
      />
      <Path
        d="M211.56626 32.9587H223.41196V44.80489H211.56626z"
        fill="#d2efff"
      />
      <Path
        d="M194.97202 32.9587H206.81821V44.80489H194.97202z"
        fill="#d2efff"
      />
      <Path
        d="M178.47226 51.62874H190.31845V63.4745H178.47226z"
        fill="#d2efff"
      />
      <Path
        d="M190.318 83.485V71.64h-10.26c.52 3.118 1.057 7.107 1.44 11.846z"
        fill="#d2efff"
      />
      <Path
        d="M211.56626 51.62874H223.41196V63.4745H211.56626z"
        fill="#d2efff"
      />
      <Path
        d="M211.56626 71.63899H223.41196V83.48518000000001H211.56626z"
        fill="#d2efff"
      />
      <Path
        d="M194.97202 51.62874H206.81821V63.4745H194.97202z"
        fill="#d2efff"
      />
      <Path
        d="M182.045 100.784l-.007-.025c-.002-1.251-.003-2.509-.003-3.765 0-4.996-.214-9.517-.537-13.509-.383-4.74-.92-8.728-1.44-11.846a78.73 78.73 0 00-1.64-7.853l-.005.097-.004 12.77v14.271l-.003 13.105-.003 12.926 3.03-.046s-.048.601-.18 1.4l.907.597a13.791 13.791 0 00.304-2.548l3.256.026z"
        fill="#003b5c"
      />
      <Path
        d="M178.413 63.775L178.413 63.883 178.418 63.786 178.418 63.775 174.759 62.155 169.819 59.967 178.413 63.775z"
        fill="#003b5c"
      />
      <Path
        d="M157.891 121.107l-.001.193-.758-.006.001-.193-.391-.003-.002.193-.757-.006.001-.193-.504-.004.005-.74-1.958-.015v1.648a7.367 7.367 0 01-.058.886h8.702l.009-1.165c-2.268-.101-3.83-.173-3.83-.173l.017-2.314-.015 1.895zM169.476 80.32v-8.45c0-10.069-2.175-12.789-2.495-13.134l-.04.009V59s-1.76 2.644-6.706 2.644c-4.945 0-6.706-2.644-6.706-2.644v-.117h-.002v59.337l1.975.016.004-.569.505.004.001-.193.758.006-.001.193.391.003.002-.193.757.006-.001.193.461.004.003-.434 11.094-.167V80.321z"
        fill="#003b5c"
      />
      <Path
        d="M146.345 60.936L145.537 61.167 145.135 61.282 141.165 62.416 140.265 62.673 140.265 62.675 146.345 60.936z"
        fill="#003b5c"
      />
      <Path
        d="M153.28 123.847l5.215.075-.136-1.054h-4.89a7.292 7.292 0 01-.19.98zM162.501 129.357a.874.874 0 01-.822-.88l.035-4.509-.035 4.529-2.614-.157-.38-.023-.8-.048-6.745-.406a6.592 6.592 0 01-1.137.4 7.329 7.329 0 01-3.852 1.095h-2.17l-.2 64.82-.004 1.074h.078a3.166 3.166 0 016.132-.212l.132-.71 10.29-55.577 6.973 54.007h7.514l.826-62.609zm-.612 3.69l-.88 4.754-.74-.137-.078-.014.045-.242.895-4.83 3.196-2.41.5.663z"
        fill="#4d627a"
      />
      <Path
        d="M158.359 122.867L158.495 123.922 161.714 123.968 162.162 123.975 162.171 122.867 158.359 122.867z"
        fill="#4d627a"
      />
      <Path
        d="M160.269 137.664L161.008 137.801 161.889 133.046 164.827 130.831 164.327 130.167 161.131 132.578 160.236 137.408 160.192 137.65 160.269 137.664z"
        fill="#3a3b4d"
      />
      <Path
        d="M153.529 58.883V59s1.761 2.644 6.706 2.644S166.941 59 166.941 59v-.255a9.281 9.281 0 01-2.092.23c-8.397 0-9.928-5.13-9.928-5.13v-6.027l-.001 1.819h-1.391v9.246z"
        fill="#60432f"
      />
      <Path
        transform="rotate(-82.875 154.92 46.635)"
        d="M153.99929 46.63452H155.84161V46.635130000000004H153.99929z"
        fill="#60432f"
      />
      <Path
        d="M167.83 194.97l.71 3.692h12.29a5.917 5.917 0 00-5.901-5.902h-7.526zM146.954 192.76a3.197 3.197 0 00-3.177 3.178v2.724h6.355v-2.724a3.24 3.24 0 00-3.178-3.178z"
        fill="#2b2c40"
      />
      <Path
        d="M148.589 60.294L146.345 60.936 140.265 62.675 142.107 76.658 142.107 76.654 153.527 76.654 153.527 58.883 153.527 58.881 148.589 60.294z"
        fill="#f08a59"
      />
      <Path
        d="M140.263 62.676L140.264 62.675 140.263 62.675 140.263 62.676z"
        fill="#f08a59"
      />
      <Path
        d="M153.527 121.981v-1.648l-4.98-.038.235.532-.56.35a1.18 1.18 0 011.477.437l1.393 2.202 2.187.031a7.292 7.292 0 00.19-.98 7.367 7.367 0 00.058-.886z"
        fill="#f08a59"
      />
      <Path
        d="M142.107 90.924L142.107 90.694 140.263 94.63 140.263 101.57 147.609 118.174 153.527 118.22 153.527 90.924 142.107 90.924z"
        fill="#f08a59"
      />
      <Path
        d="M142.59 80.32l.551 4.187a10.699 10.699 0 01-.01 2.754h10.396v-6.94zM144.954 127.492l-4.69-.282v2.148h5.887a7.329 7.329 0 003.852-1.095 6.654 6.654 0 01-5.049-.771zM178.41 90.924h-8.934v26.165l8.927-.134.003-12.926zM166.981 58.736c.32.345 2.495 3.065 2.495 13.134v4.784h8.933l.004-12.77v-.11l-8.594-3.807-2.8-1.24z"
        fill="#f08a59"
      />
      <Path
        d="M169.47641 80.32076H178.40927V87.26137000000001H169.47641z"
        fill="#f08a59"
      />
      <Path
        d="M142.107 76.654L142.107 76.658 142.59 80.321 153.527 80.321 153.527 76.654 142.107 76.654z"
        fill="#fff"
      />
      <Path
        d="M169.47641 76.65383H178.40927V80.32075999999999H169.47641z"
        fill="#fff"
      />
      <Path
        d="M143.13 87.261a10.694 10.694 0 01-.909 3.19l-.114.243v.23h11.42v-3.663z"
        fill="#fff"
      />
      <Path d="M169.47641 87.26137H178.40927V90.92415H169.47641z" fill="#fff" />
      <Path
        d="M179.059 122.376c-1.936-.015-11.444-.43-16.88-.674l-.008 1.165-.009 1.108 14.861.213a4.335 4.335 0 003.56-1.676l-.737-.485a1.136 1.136 0 01-.787.35zM136.53 118.493l-8.117-.064a15.403 15.403 0 011.613 1.316l7.13.056z"
        fill="#383735"
      />
      <Path
        d="M148.547 120.295L153.527 120.333 155.485 120.349 155.502 118.236 153.527 118.22 147.609 118.174 148.547 120.295z"
        fill="#474543"
      />
      <Path
        d="M187.272 125.136l2.97.023a.832.832 0 01.825.839l-.004.482a.823.823 0 01-.27.601v1.16l1.662.012c3.734.03 6.711-5.708 3.539-9.747a4.474 4.474 0 00-2.054-1.692l-.046 5.884-2.129-.016.001-.09c-.238.88-1.08 1.526-2.866 1.512l-1.342-.01c-.069.3-.162.652-.286 1.042z"
        fill="#403f3d"
      />
      <Path
        d="M190.793 126.5v.581a.823.823 0 00.27-.6l.004-.483a.832.832 0 00-.825-.839l-2.97-.023a11.737 11.737 0 01-.531 1.365z"
        fill="#30302e"
      />
      <Path
        d="M193.894 122.698L193.94 116.814 193.954 114.886 191.827 114.87 191.766 122.592 191.765 122.682 193.894 122.698z"
        fill="#82878a"
      />
      <Path
        d="M179.846 122.027l.737.485a8.65 8.65 0 001.577-3.606l-.907-.597c-.207 1.254-.626 2.99-1.407 3.718z"
        fill="#383735"
      />
      <Path
        d="M169.476 117.09l-11.094.166-.003.434-.012 1.525-.018 2.314s1.563.072 3.83.173c5.436.244 14.944.66 16.88.674a1.136 1.136 0 00.787-.35c.78-.727 1.2-2.463 1.407-3.717.132-.799.18-1.4.18-1.4l-3.03.046z"
        fill="#82878a"
      />
      <Path
        d="M157.891 121.107L158.352 121.11 158.367 119.215 158.379 117.69 158.379 117.69 157.918 117.686 157.891 121.107z"
        fill="#82878a"
      />
      <Path
        transform="rotate(-89.553 156.951 119.389)"
        d="M155.24067 119.19328H158.66137V119.58465H155.24067z"
        fill="#82878a"
      />
      <Path
        d="M155.485 120.349L155.48 121.088 155.984 121.092 156.011 117.671 155.506 117.667 155.502 118.236 155.485 120.349z"
        fill="#82878a"
      />
      <Path
        d="M157.133 121.101L157.132 121.294 157.889 121.3 157.891 121.107 157.918 117.686 157.919 117.493 157.161 117.487 157.16 117.68 157.133 121.101z"
        fill="#a1a8ab"
      />
      <Path
        d="M155.984 121.092L155.983 121.285 156.74 121.291 156.742 121.098 156.769 117.677 156.77 117.484 156.012 117.478 156.011 117.671 155.984 121.092z"
        fill="#a1a8ab"
      />
      <Path
        d="M188.51 123.144L189.74 123.154 189.805 114.876 187.802 114.861 188.575 114.867 188.51 123.144z"
        fill="#82878a"
      />
      <Path
        d="M182.16 118.906a8.65 8.65 0 01-1.577 3.606 4.335 4.335 0 01-3.56 1.676l-14.861-.213-.448-.007-.035 4.509a.874.874 0 00.822.88l13.22.794 4.974.299a6.101 6.101 0 001.657-.11 3.583 3.583 0 01-.084-.759v-3.08h4.473a11.737 11.737 0 00.53-1.365c.125-.39.218-.741.287-1.043.13-.573.18-.955.18-.955l.052-6.738-2.07-.016-3.256-.026a13.791 13.791 0 01-.304 2.548zm1.517 5.01a1.105 1.105 0 111.114-1.096 1.105 1.105 0 01-1.114 1.096z"
        fill="#ffc887"
      />
      <Path
        d="M127.684 125.01a1.515 1.515 0 00.717 1.295l-.7-1.445c-.006.05-.016.098-.017.15zM153.28 123.847l-2.188-.031 1.88 2.972-1.02.645a6.648 6.648 0 01-.812.43l6.745.406-.684-.041.034-4.314 1.485.011-.035 4.392.38.023 2.614.157.035-4.529-3.219-.046zM144.954 127.492a6.609 6.609 0 01-2.168-2.122l-.36-.57.661-.418-2.876 1.795-1.215-2.535-7.205-.104c-.361 1.687-2.382 2.649-3.054 2.925a1.507 1.507 0 00.377.077l11.15.67z"
        fill="#383735"
      />
      <Path
        d="M158.685 128.317L158.72 123.925 157.235 123.914 157.201 128.228 157.885 128.269 158.685 128.317 158.685 128.317z"
        fill="#ffc887"
      />
      <Path
        d="M183.694 121.706a1.105 1.105 0 101.097 1.114 1.105 1.105 0 00-1.097-1.114z"
        fill="#403f3d"
      />
      <Path
        d="M188.51 123.144L188.575 114.867 187.802 114.861 187.737 123.138 188.51 123.144 188.51 123.144z"
        fill="#403f3d"
      />
      <Path
        d="M149.699 121.614a1.18 1.18 0 00-1.478-.437l-5.134 3.205-.662.418.36.57a6.697 6.697 0 009.167 2.063l1.02-.645-1.88-2.972z"
        fill="#60432f"
      />
      <Path
        d="M141.038 110.023l-7.192 2.866 2.684 5.604.626 1.308 1.84 3.84 1.215 2.536 2.876-1.795 5.134-3.205.561-.35-.235-.532-.938-2.12-7.346-16.605-1.579-3.57 1.58-3.37 1.843-3.936.114-.243a11.125 11.125 0 00.92-5.944l-.551-4.186-.483-3.663-1.842-13.983v-.002l-.001.002v.001c-.292.485-.58 1-.866 1.525q-.514.946-1.017 1.965c-3.609 7.349-6.68 17.345-8.634 24.493a220.072 220.072 0 01-.206 4.009l4.641-1.85z"
        fill="#003b5c"
      />
      <Path
        d="M154.92 47.556L154.92 47.818 154.921 47.828 154.921 47.556 154.92 47.556z"
        fill="#db7170"
      />
      <Path
        d="M171.081 40.479L171.081 43.395 171.192 40.475 171.081 40.475 171.081 40.479z"
        fill="#212121"
      />
      <Path
        d="M171.268 38.512L171.268 38.51 170.969 38.51 170.969 38.512 171.268 38.512z"
        fill="#212121"
      />
      <Path
        d="M149.9476 38.51027H151.07529V38.51204H149.9476z"
        fill="#212121"
      />
      <Path
        d="M154.921 44.347L154.921 44.347 154.92 45.714 154.921 45.714 154.921 44.347z"
        fill="#212121"
      />
      <Path
        d="M171.081 40.474H157.31a6.3 6.3 0 01-.38 1.286 25.473 25.473 0 001.461 5.515 5.296 5.296 0 004.09 3.323 2.939 2.939 0 012.894-2.441h2.89a2.926 2.926 0 012.287 1.112 31.062 31.062 0 00.53-5.43v-3.36zm-2.48 7.38h-1.815v-.812h.908l-.196-1.764.808-.089z"
        fill="#60432f"
      />
      <Path
        d="M157.42124 38.51027H157.42173000000003V38.51204H157.42124z"
        fill="#60432f"
      />
      <Path
        d="M170.969 38.512L170.969 38.51 157.522 38.51 157.522 38.512 170.969 38.512z"
        fill="#60432f"
      />
      <Path
        d="M165.195 51.4h-.476a2.54 2.54 0 002.443 1.54 2.656 2.656 0 002.534-1.564 2.927 2.927 0 01-2.128-1.191 2.933 2.933 0 01-2.373 1.215z"
        fill="#60432f"
      />
      <Path
        d="M151.07529 38.51027H157.42123999999998V38.51204H151.07529z"
        fill="#212121"
      />
      <Path
        d="M154.92 47.818v-4.023a1.934 1.934 0 00-1.933-1.934 1.912 1.912 0 00-1.913 1.911v3.952a1.912 1.912 0 001.911 1.913h1.934z"
        fill="#60432f"
      />
      <Path
        d="M167.498 45.278L167.694 47.042 166.786 47.042 166.786 47.854 168.601 47.854 168.306 45.189 167.498 45.278z"
        fill="#281c14"
      />
      <Path
        d="M157.522 38.512L157.522 38.51 157.422 38.51 157.422 38.512 157.522 38.512z"
        fill="#a16971"
      />
      <Path
        d="M171.08 50.288v-6.45a31.055 31.055 0 01-.529 5.43 2.926 2.926 0 00-2.287-1.11h-.125l-2.765-.001a2.939 2.939 0 00-2.894 2.441 5.296 5.296 0 01-4.09-3.323 25.473 25.473 0 01-1.461-5.515 6.3 6.3 0 00.38-1.286h-7.366v1.836a3.327 3.327 0 001.131 2.493v-1.031a1.912 1.912 0 011.913-1.911 1.934 1.934 0 011.934 1.934V53.845s1.531 5.13 9.928 5.13a9.281 9.281 0 002.092-.23l.04-.01.038-.008a5.431 5.431 0 004.06-3.232v-4.094h.129v-.3a2.912 2.912 0 00-.128-.813zm-3.918 2.651a2.54 2.54 0 01-2.443-1.54h.476a2.933 2.933 0 002.373-1.214 2.927 2.927 0 002.128 1.19 2.656 2.656 0 01-2.534 1.564z"
        fill="#212121"
      />
      <Path
        d="M170.217 36.351L170.217 36.727 167.48 36.727 167.48 36.351 166.796 36.351 166.796 36.727 164.059 36.727 164.059 36.351 163.067 36.351 163.067 36.727 160.33 36.727 160.33 36.351 149.143 36.351 146.845 36.351 146.845 38.51 149.948 38.51 151.075 38.51 157.421 38.51 157.422 38.51 157.522 38.51 170.969 38.51 171.268 38.51 174.759 38.51 174.886 38.51 174.886 36.351 174.759 36.351 172.132 36.351 170.217 36.351z"
        fill="#ffc887"
      />
      <Path
        d="M170.217 36.35h1.915v-4.525a10.384 10.384 0 00-2.683-6.969 7.431 7.431 0 01.768 3.284zM160.33 36.35v-8.21a4.76 4.76 0 00-4.755-4.755h-1.334v-.515a10.42 10.42 0 00-5.098 8.955v4.526zM164.06 28.14a4.75 4.75 0 00-2.138-3.963 7.437 7.437 0 011.145 3.963v8.21h.992zM167.48 28.14a4.727 4.727 0 00-1.552-3.491 7.44 7.44 0 01.868 3.491v8.21h.684z"
        fill="#fbb766"
      />
      <Path
        d="M161.922 24.177a4.75 4.75 0 012.137 3.963v8.587h2.737V28.14a7.44 7.44 0 00-.868-3.491 4.723 4.723 0 00-3.203-1.264h-1.334V20.95a7.46 7.46 0 00-2.087-.302h-1.334v.4a7.52 7.52 0 013.952 3.129z"
        fill="#ffc887"
      />
      <Path
        d="M161.391 23.385h1.334a4.798 4.798 0 014.755 4.755v8.587h2.737V28.14a7.52 7.52 0 00-7.492-7.492h-1.334v2.737z"
        fill="#ffc887"
      />
      <Path
        d="M155.575 23.385a4.76 4.76 0 014.755 4.755v8.587h2.737V28.14a7.437 7.437 0 00-1.145-3.963 7.512 7.512 0 00-6.347-3.529h-1.334v2.737z"
        fill="#ffc887"
      />
      <Path
        d="M171.081 40.474h.111l.076-1.962h-.299a16.546 16.546 0 01.112 1.962z"
        fill="#212121"
      />
      <Path
        d="M171.081 40.474h.111l.076-1.962h-.299a16.546 16.546 0 01.112 1.962z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M171.081 40.474a16.546 16.546 0 00-.112-1.962h-13.447a5.527 5.527 0 00-.1 1.032v-1.032l-.001.797a6.366 6.366 0 01-.112 1.165z"
        fill="#60432f"
      />
      <Path
        d="M171.081 40.474a16.546 16.546 0 00-.112-1.962h-13.447a5.527 5.527 0 00-.1 1.032v-1.032l-.001.797a6.366 6.366 0 01-.112 1.165z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M156.201 40.474h1.108a6.366 6.366 0 00.112-1.165v-.797h-7.473l-.005 1.962h6.258z"
        fill="#212121"
      />
      <Path
        d="M156.201 40.474h1.108a6.366 6.366 0 00.112-1.165v-.797h-7.473l-.005 1.962h6.258z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M157.522 38.512h-.1v1.032a5.527 5.527 0 01.1-1.032z"
        fill="#a16971"
      />
      <Path
        d="M157.522 38.512h-.1v1.032a5.527 5.527 0 01.1-1.032z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M190.793 126.5h-8.525v3.081a3.598 3.598 0 003.582 3.582h1.362a3.582 3.582 0 003.581-3.582v-3.08z"
        fill="#60432f"
      />
      <Path
        d="M42.159 40.346L41.985 38.945 41.67 36.405 36.798 37.009 31.219 37.702 10.845 40.229 11.74 47.443 11.334 44.17 42.159 40.346z"
        fill="#e5e5e5"
      />
      <Path
        d="M27.353 53.817L27.441 54.528 28.295 54.422 28.22 53.817 27.448 47.601 20.4 48.476 10.496 49.704 9.882 44.758 11.384 44.572 11.278 43.718 8.922 44.01 9.748 50.664 19.405 49.466 26.701 48.561 27.353 53.817z"
        fill="#383735"
      />
      <Path
        d="M46.746 197.76l-.265-2.136-11.66-93.984-.74-5.963-.868-6.999-.098-.79-.868-6.998-.045-.368-.118-.116-.37.562-1.648-1.084c-.058-.038-.11-.082-.166-.122l.844 6.804.164 1.322.098.79.869 6.999.74 5.963 11.679 94.14.279 2.25z"
        fill="#875c16"
      />
      <Path
        d="M31.135 71.926L30.744 68.777 29.954 62.405 29.648 59.943 28.953 54.341 28.295 54.422 27.441 54.528 26.78 54.61 27.442 59.943 28.31 66.941 28.408 67.731 28.732 70.345 31.135 71.926z"
        fill="#875c16"
      />
      <Path
        d="M42.159 40.346L42.159 40.346 11.334 44.17 11.74 47.443 22.816 46.069 42.565 43.619 42.159 40.346z"
        fill="#c6c6c6"
      />
      <Path
        d="M49.282 91.655q1.048-1.593 1.998-3.134l-4.289 6.528c.628-.898 1.698-2.493 2.29-3.394z"
        fill="#e7e7e7"
      />
      <Path
        d="M39.868 80.89l-5.829-4.657a4.327 4.327 0 00-1.907-3.651l-.997-.656-2.403-1.582-.176-.115-.366.557a6.575 6.575 0 001.71 8.976c.057.04.108.084.166.122l1.648 1.084.37-.562.118.117.369.367 1.928 1.918 2.496 2.483 2.888-4.389z"
        fill="#edc6a0"
      />
      <Path
        d="M82.686 95.05L83.405 93.17 82.686 95.05 82.686 95.05z"
        fill="#e7e7e7"
      />
      <Path
        d="M54.214 64.596l-.79 1.657-.328.688-.377.79-3.05 6.396-3.436 7.205-1.37 2.872-2.384-1.596-.79-.53-1.776-1.188-.015-.01-.007.01-.008.012-2.888 4.39-.844 1.282s.971.88 2.41 2.104c.9.767 1.98 1.667 3.128 2.579q.39.31.79.62c1.261.98 2.561 1.93 3.755 2.699.257.165.511.326.757.473l4.29-6.528.01-.015h-.001q.973-1.577 1.848-3.09 1.206-2.082 2.238-4.033l.7 20.221.001.036.608 17.543-.161-9.148L58.15 75.53l-.5-13.437-2.894 1.367z"
        fill="#91a8c4"
      />
      <Path
        d="M57.65 62.092L57.65 62.092 57.968 61.942 61.387 60.327 61.387 60.327 57.65 62.092z"
        fill="#e7e7e7"
      />
      <Path
        d="M79.013 58.68v.002s-1.86 2.461-7.081 2.461c-5.146 0-7.023-2.385-7.076-2.454L62.2 59.943l-.797.376-.017.008.3 14.638h13.512l6.33-15.14z"
        fill="#91a8c4"
      />
      <Path
        d="M74.442 89.743l.57.218a9.197 9.197 0 00-.555-.463c0 .081-.01.163-.015.245zM67.114 87.792a9.118 9.118 0 00-7.256 8.21h17.38l-4.904-2.514-1.051-.402a6.566 6.566 0 01-4.17-5.294z"
        fill="#003b5c"
      />
      <Path
        d="M78.018 44.34h-.625v7.175a13.948 13.948 0 01-12.542 4.364v2.803l.005.007c.053.07 1.93 2.454 7.076 2.454 5.221 0 7.081-2.46 7.081-2.46V48.326h-.995z"
        fill="#edc6a0"
      />
      <Path
        d="M82.686 95.05L81.567 97.976 82.686 95.05 82.686 95.05z"
        fill="#003b5c"
      />
      <Path
        d="M81.567 97.976L81.488 98.183 81.488 98.183 81.567 97.976z"
        fill="#003b5c"
      />
      <Path
        d="M87.908 194.064l-.531-74.881.206-5.66-2.247-.04-1.846-12.837a98.796 98.796 0 01-2.52-1.11l.518-1.353-2.069-1.061v.874h-1.807l-1.407 17.585H61.65l-1.407-17.585h-1.808v-1.993h.486a10.05 10.05 0 018.147-9.152 6.543 6.543 0 01.426-2.246l.238-.623 3.998 1.529a4.236 4.236 0 012.525 2.705 10.107 10.107 0 012.699 2.487l5.405 2.066L79.91 75.75l5.854-14-4.227-1.946-.008.02-6.331 15.14H61.687l-.3-14.637-3.737 1.765.5 13.437-1.626 34.506.16 9.148h.01l1.339 72.96h7.496l6.526-55.484 9.629 57.528.037.221a3.152 3.152 0 016.17.676l.024.002z"
        fill="#003b5c"
      />
      <Path
        d="M58.033 192.142H58a5.887 5.887 0 00-5.888 5.888h12.261l.646-3.352.488-2.536zM84.744 192.142a3.23 3.23 0 00-3.17 3.17v2.718h6.34v-2.718c0-.078-.017-.15-.022-.228a3.16 3.16 0 00-3.148-2.942z"
        fill="#2b2c40"
      />
      <Path
        d="M62.05 40.3h-.008v-1.934h-.003v2.42l.003-.001c0-.16.002-.32.009-.484z"
        fill="#45362c"
      />
      <Path
        d="M62.039 40.928l.003-.143h-.003a1.891 1.891 0 00-1.16 1.744v3.907a1.89 1.89 0 001.16 1.744z"
        fill="#edc6a0"
      />
      <Path
        d="M64.85 55.88a13.948 13.948 0 0012.543-4.365V44.34h-3.495V40.3H62.051c-.007.166-.008.325-.01.485l-.002.143v14.239a16.59 16.59 0 002.812.712z"
        fill="#edc6a0"
      />
      <Path
        d="M73.898 40.3v4.04h4.12v-1.79a1.913 1.913 0 011.912-1.912 1.884 1.884 0 011.688 1.054v-1.391z"
        fill="#45362c"
      />
      <Path
        d="M79.93 40.638a1.913 1.913 0 00-1.912 1.913v5.776h1.912a1.89 1.89 0 001.891-1.89v-3.908a1.912 1.912 0 00-1.89-1.89z"
        fill="#edc6a0"
      />
      <Path
        d="M74.457 89.498c.192.147.376.302.556.463l1.94.742a10.107 10.107 0 00-2.699-2.487 4.228 4.228 0 01.203 1.282zM67.114 87.792a6.55 6.55 0 01-.047-.94 10.05 10.05 0 00-8.147 9.15h.938a9.118 9.118 0 017.256-8.21z"
        fill="#3a3b4d"
      />
      <Path
        d="M60.242 97.996L61.649 115.581 76.205 115.581 77.612 97.996 60.242 97.996z"
        fill="#e6e7e8"
      />
      <Path
        d="M77.238 96.003L59.858 96.003 58.92 96.003 58.434 96.003 58.434 97.996 60.242 97.996 77.612 97.996 79.42 97.996 79.42 97.122 77.238 96.003z"
        fill="#fff"
      />
      <Path
        d="M73.083 36.239L73.083 36.61 70.388 36.61 70.388 36.239 69.411 36.239 69.411 36.61 66.716 36.61 66.716 36.239 66.042 36.239 66.042 36.61 63.347 36.61 63.347 36.239 61.461 36.239 58.75 36.239 58.75 38.366 62.039 38.366 62.042 38.366 62.284 38.366 73.898 38.366 81.618 38.366 81.628 38.366 86.362 38.366 86.362 36.239 84.1 36.239 73.083 36.239z"
        fill="#003b5c"
      />
      <Path
        d="M77.766 23.471a4.688 4.688 0 00-4.683 4.683v8.085H84.1v-3.413a11.307 11.307 0 00-5.02-9.405v.05zM63.347 36.24v-8.086a7.344 7.344 0 01.272-1.958 11.254 11.254 0 00-2.158 6.63v3.413zM70.388 28.154a7.323 7.323 0 011.128-3.903 4.678 4.678 0 00-2.105 3.903v8.085h.977zM66.716 28.154a7.327 7.327 0 01.855-3.438 4.655 4.655 0 00-1.529 3.438v8.085h.674z"
        fill="#2c5696"
      />
      <Path
        d="M72.039 23.471h-1.314a4.65 4.65 0 00-3.154 1.245 7.327 7.327 0 00-.855 3.438v8.456h2.695v-8.456a4.678 4.678 0 012.105-3.903 7.405 7.405 0 013.891-3.081v-.394h-1.313a7.343 7.343 0 00-2.055.297z"
        fill="#003b5c"
      />
      <Path
        d="M63.347 36.24v.37h2.695v-8.456a4.725 4.725 0 014.683-4.683h1.314v-2.695h-1.314a7.41 7.41 0 00-7.378 7.378z"
        fill="#003b5c"
      />
      <Path
        d="M71.516 24.251a7.323 7.323 0 00-1.128 3.903v8.456h2.695v-8.456a4.688 4.688 0 014.683-4.683h1.314v-2.695h-1.314a7.397 7.397 0 00-6.25 3.475z"
        fill="#003b5c"
      />
      <Path
        d="M81.61777 38.36574H81.62801999999999V40.300740000000005H81.61777z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M62.05 40.3a12.231 12.231 0 01.234-1.934h-.242V40.3z"
        fill="#45362c"
      />
      <Path
        d="M62.05 40.3a12.231 12.231 0 01.234-1.934h-.242V40.3z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M73.898 40.3v-1.934H62.284a12.231 12.231 0 00-.233 1.935z"
        fill="#edc6a0"
      />
      <Path
        d="M73.898 40.3v-1.934H62.284a12.231 12.231 0 00-.233 1.935z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M73.89829 38.36574H81.61777000000001V40.300740000000005H73.89829z"
        fill="#45362c"
      />
      <Path
        d="M73.89829 38.36574H81.61777000000001V40.300740000000005H73.89829z"
        fill="#2e2e3b"
        opacity={0.22}
      />
      <Path
        d="M93.513 84.482l-1.167 1.9 1.509 3.432c-.08-1.865-.197-3.641-.342-5.332z"
        fill="#e7e7e7"
      />
      <Path
        d="M90.98 95.661a5809.52 5809.52 0 00-4.462-6.99l-1.51-2.363-.031-.013.01-.02-.01-.015.028-.018c.77-1.473 1.501-2.851 2.202-4.156a285.914 285.914 0 014.824-8.652 68.081 68.081 0 00-2.509-9.975l-3.758-1.709-5.853 14 2.447 17.02 1.047.4-.72 1.88-1.714 4.486s.726.34 2.52 1.11l1.845 12.837 2.247.04-.206 5.66.64-16.639 1.178.484a3.43 3.43 0 004.705-2.773c-.785-1.237-1.748-2.752-2.92-4.594z"
        fill="#91a8c4"
      />
      <Path
        d="M126.405 60.636l.187.06v-1.722a7.604 7.604 0 01-.187 1.662z"
        fill="#f6ca82"
      />
      <Path
        d="M99.943 115.507a210.804 210.804 0 01-.165-7.906v-3.798l-2.597 1.659c-3.676 8.727 2.502 9.995 2.762 10.045z"
        fill="#fadfca"
      />
      <Path d="M84.977 86.26l.01.016.018-.034z" fill="#84c4e4" />
      <Path
        d="M102.933 146.302a364.318 364.318 0 005.098 26.855v-26.56zM114.638 191.283h5.05l-1.148-23.465c-2.574 14.57-3.989 23.454-3.99 23.465z"
        fill="#ddc1af"
      />
      <Path
        d="M119.689 191.283L114.638 191.283 113.052 197.576 120.102 197.564 119.859 193.872 119.689 191.284 119.689 191.283z"
        fill="#d3d3d3"
      />
      <Path
        d="M108.03 146.597v44.686h6.52c.001-.01 1.416-8.896 3.99-23.465.788-4.455 1.687-9.443 2.684-14.873.35-1.916.737-3.746 1.129-5.52l-4.821-.278z"
        fill="#fadfca"
      />
      <Path
        d="M114.638 191.283h-7.408a5.67 5.67 0 00-4.7 2.608 6.634 6.634 0 00-1.11 3.696h11.63l.002-.011z"
        fill="#fff"
      />
      <Path
        d="M99.943 115.507a278.964 278.964 0 002.99 30.795l5.098.295 9.5.55 4.822.278c2.13-9.629 4.518-17.476 3.402-26.584l-1.72-3.55-.018-.485-8.827 3.517-5.07-12.722H99.779c0 2.634.066 5.272.165 7.906z"
        fill="#003b5c"
      />
      <Path d="M129.362 97.357c-1.047 8.901-1.616 15.014-1.876 18.067l6.36-2.535 7.192-2.866-6.856-17.205-4.64 1.85-.801.318c.525.403.77 1.105.62 2.37z" />
      <Path d="M115.19 120.323L124.017 116.806 123.296 97.156 121.098 98.032 108.334 103.118 110.121 107.601 115.19 120.323z" />
      <Path
        d="M131.79 123.538c.216-1.004-.16-2.265-1.764-3.793a15.403 15.403 0 00-1.613-1.316 23.292 23.292 0 00-1.108-.754l-3.27-.385 1.72 3.551 1.946 4.019.7 1.445.117.243s.083-.03.22-.085c.671-.276 2.692-1.238 3.053-2.925z"
        fill="#fadfca"
      />
      <Path
        d="M127.623 61.032l-1.03-.335-.188-.061a7.603 7.603 0 01-4.18 5.208v3.939a7.68 7.68 0 01-7.681 7.68h-.78v-14.1a7.638 7.638 0 01.857-3.515 12.02 12.02 0 01-2.082.188c-3.633 0-3.337-1.775-3.337-1.775v-.504h-.006l-6.69 2.956-2.727 1.206v.02l-.007-.012s-2.114 1.854-7.741 11.507a286.14 286.14 0 00-4.824 8.652c-.7 1.304-1.432 2.682-2.202 4.156l-.018.034-.01.02.03.012 1.511 2.363c1.75 2.74 3.224 5.048 4.461 6.99a2893.88 2893.88 0 012.92 4.594 564.09 564.09 0 013.282 5.207l2.598-1.659v3.798h10.342l-1.787-4.483 12.764-5.086a66.527 66.527 0 011.912-11.203l.18 7.452.106 2.875.721 19.65.019.484 3.269.385s.055-.771.18-2.251c.261-3.053.83-9.166 1.877-18.067.149-1.266-.096-1.968-.62-2.37l.8-.32c.082-1.38.152-2.722.205-4.008.882-21.376-2.124-29.627-2.124-29.627zm-27.844 42.256l-5.924-13.474-1.509-3.432 1.167-1.9 6.266-10.2z"
        fill="#84c4e4"
      />
      <Path
        d="M119.058 44.996c-6.146-5.562-12.15-6.61-14.837-6.777v9.657a8.258 8.258 0 003.783 7.283 6.593 6.593 0 001.198.507v2.595s-.296 1.775 3.337 1.775a12.02 12.02 0 002.082-.188 7.695 7.695 0 014.437-3.783v-6.751h.24a2.358 2.358 0 002.281-1.778 33.33 33.33 0 00-2.521-2.54z"
        fill="#fadfca"
      />
      <Path
        d="M114.671 26.107a11.93 11.93 0 00-10.746 6.65h-.515a6.517 6.517 0 00-6.517 6.517v4.98a26.838 26.838 0 005.614 16.46l6.69-2.957h.005v-2.091a6.593 6.593 0 01-1.198-.507 8.258 8.258 0 01-3.783-7.283V38.22c2.688.166 8.69 1.215 14.837 6.777a33.33 33.33 0 012.521 2.54 2.358 2.358 0 01-2.281 1.778h-.24v6.751a7.695 7.695 0 00-5.294 7.298v14.1h.78a7.68 7.68 0 007.68-7.68v-3.939a7.617 7.617 0 004.368-6.87V37.791a11.805 11.805 0 00-11.921-11.684z"
        fill="#f6ca82"
      />
      <Path
        d="M82.358 92.77l-5.405-2.067-1.94-.742-.571-.218c.004-.082.015-.164.015-.245a4.319 4.319 0 00-2.728-3.987l-3.998-1.529-.238.623a6.567 6.567 0 003.79 8.481l1.05.402 4.905 2.515 2.181 1.119 2.07 1.06 1.197-3.132.719-1.88z"
        fill="#edc6a0"
      />
    </Svg>
  );
}

export default SvgComponent;
