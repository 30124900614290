import type { Color, IconName, IconVariant } from "@gigsmart/atorasu";
import {
  type ConfirmPromptOptions,
  confirmPrompt,
  dismiss
} from "@gigsmart/katana";
import { merge, startCase, toLower } from "lodash";
import type {
  EngagementCapabilityType,
  EngagementStateAction,
  EngagementStateName
} from "../gig/helpers";

export type InternalAction =
  | EngagementStateAction
  | EngagementCapabilityType
  | "CALL"
  | "TEXT"
  | "EXTEND"
  | "REVIEW"
  | "PAY_WORKER_AGAIN"
  | "SEND_MESSAGE"
  | "VIEW_BADGES"
  | "VIEW_MESSAGE"
  | "VIEW_QUESTIONNAIRE"
  | "MARK_AS_COMPLETE"
  | "AUTO_ARRIVE"
  | "APPROVE_SYSTEM_TIMESHEET"
  | "CANCEL_ENGAGEMENT"
  | "DISPUTE_TIMESHEET"
  | "NEGOTIATE";

export interface ActionSpec {
  color: Color;
  icon?: IconName;
  buttonVariant?: "outline" | "solid";
  iconVariant?: IconVariant;
  quickToast?: boolean;
  confirmation?: string | ConfirmPromptOptions;
  confirmationTitle?: string;
  toastMessage?: (receiverName: string) => string;
  label?: string;
  onPress?: (action?: InternalAction) => void;
  canEnable?: (availableActions?: readonly InternalAction[]) => boolean;
  testID?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onSuccess?: () => void;
}

export type ActionMap = { [key in InternalAction]?: ActionSpec };

export const GIG_ACTION_MAP: ActionMap = {
  CANCEL: {
    label: "Cancel",
    color: "danger",
    icon: "ban",
    testID: "cancel-gig"
  },
  REJECT: {
    label: "Reject",
    color: "error",
    icon: "ban",
    confirmation: "Are you sure you want to reject this Gig?",
    testID: "reject-gig"
  },
  TEXT: {
    color: "primary",
    label: "Message",
    icon: "message-lines",
    canEnable: (availableActions) => !!availableActions?.includes("TEXT"),
    testID: "text-action"
  },
  EXTEND: {
    color: "primary",
    icon: "plus",
    label: "Extend Time",
    testID: "extend-action"
  },
  END: {
    label: "End",
    color: "danger",
    icon: "ban",
    testID: "end-gig"
  },
  ACCEPT: {
    label: "Accept",
    color: "success"
  },
  EMBARK: {
    label: "En Route",
    color: "highlight",
    toastMessage: (name) => `${name} has been notified you are en route.`,
    testID: "en-route"
  },
  REQUEST_START: {
    label: "Arrived",
    color: "success",
    icon: "location-dot",
    toastMessage: (name) => `${name} has been notified of your arrival.`,
    testID: "arrived"
  },
  HIRE: {
    label: "Hire",
    color: "success",
    icon: "user-plus",
    testID: "hire-action"
  },
  APPLY: {
    label: "Apply to Gig",
    color: "success",
    icon: "file-signature",
    testID: "apply-action"
  },
  START: {
    label: "Start",
    color: "success",
    icon: "play",
    testID: "start-action",
    confirmation: "Are you sure you want to start the Shift clock?",
    toastMessage: (name) =>
      `You have successfully started the Shift. ${name} has been notified.`
  },
  PAUSE: {
    label: "Pause",
    color: "warning",
    icon: "pause",
    toastMessage: (name) =>
      `You have successfully paused the Shift. ${name} has been notified.`,
    testID: "pause-gig"
  },
  RESUME: {
    label: "Resume",
    color: "success",
    icon: "play",
    toastMessage: (name) =>
      `You have successfully resumed the Shift clock. ${name} has been notified.`,
    testID: "resume-gig"
  },
  APPROVE: {
    label: "Review",
    color: "highlight",
    testID: "approve-engagement"
  },
  MARK_AS_COMPLETE: {
    label: "Mark Project as Complete",
    icon: "circle-check",
    testID: "end-gig",
    color: "primary",
    onPress: () => null
  },
  REVIEW: {
    label: "Rate & Review",
    icon: "star",
    testID: "review-gig",
    color: "primary",
    onPress: () => null
  },
  ACCEPT_COMMITMENT: {
    label: "Confirm",
    icon: "check",
    testID: "accept-commitment-gig",
    color: "success"
  },
  DECLINE_COMMITMENT: {
    label: "Cancel",
    icon: "ban",
    testID: "cancel-gig",
    color: "danger"
  }
};

export const getGigActionMap = (
  overrides?: {
    [key in InternalAction]?: Partial<ActionSpec>;
  }
) => {
  if (!overrides) return GIG_ACTION_MAP;
  return merge({}, GIG_ACTION_MAP, overrides);
};

export const confirmAction = (
  spec: ActionSpec,
  action: InternalAction,
  onConfirm: (dismiss?: () => void) => void
) => {
  if (spec.confirmation) {
    const confirmationSpec =
      typeof spec.confirmation === "string"
        ? {
            title:
              spec.confirmationTitle ?? `${startCase(toLower(action))} Gig`,
            subTitle: spec.confirmation,
            yesLabel: `${spec.confirmLabel ?? "Yes"}`,
            cancelLabel: `${spec.cancelLabel ?? "No"}`
          }
        : spec.confirmation;

    confirmPrompt({
      ...confirmationSpec,
      onDo: () => onConfirm(dismiss),
      onCancel: () => dismiss()
    });
  } else {
    onConfirm();
  }
};

export const getTransportationMethodIcon = (
  transportationMethod?: string | null
): IconName => {
  if (transportationMethod === "bike") return "bicycle";
  if (transportationMethod === "transit") return "bus";
  if (transportationMethod === "walking") return "walking";
  return "car";
};

export const getActionIconName = (
  transportationMethod?: string | null,
  stateName?: EngagementStateName,
  actionSpecIcon?: IconName
): IconName | undefined => {
  switch (stateName) {
    case "SCHEDULED":
      return getTransportationMethodIcon(transportationMethod);
    case "EN_ROUTE":
      return "location-dot";
    case "ENDED":
      return undefined;
    default:
      return actionSpecIcon;
  }
};
