import {
  Button,
  CollapsibleContainer,
  ContentArea,
  ContentRow,
  Divider,
  ProductInfoRow,
  Row,
  Spacer,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";
import { currency, duration } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import TimesheetList from "../../engagement/TimesheetList";
import DisputeCommentContainer from "../DisputeCommentContainer";
import type { DisputeTimesheetCard_EngagementTimesheet$key } from "./__generated__/DisputeTimesheetCard_EngagementTimesheet.graphql";

interface Props {
  onCreatePress: () => void;
  comment?: string | null;
}

export function DisputeTimesheetCard({
  id,
  paymentInfo,
  onCreatePress,
  comment,
  engagement,
  disputedFinalTimesheet,
  result
}: FragmentContainerInnerComponentProps<
  DisputeTimesheetCard_EngagementTimesheet$key,
  Props
>) {
  const isEOR = engagement?.requiredReportTypes?.includes("EOR_WORKER");
  const disputeTimesheetExists = !!id && !!comment;
  const basePaymentMinusFees = (
    Number(disputedFinalTimesheet?.diff?.basePayment?.replace(" USD", "")) -
    Number(
      disputedFinalTimesheet?.diff?.totalWorkerServiceFeeAmount?.replace(
        " USD",
        ""
      ) ?? 0
    )
  ).toFixed(2);

  if (!disputeTimesheetExists) {
    return (
      <DisputeTimesheetCardFallback
        comment={comment}
        onCreatePress={onCreatePress}
      />
    );
  }
  return (
    <CollapsibleContainer
      testID="dispute-timesheet-container"
      startCollapsed
      header={
        <ProductInfoRow
          variant="shadow"
          testID="dispute-timesheet-title"
          icon="user-clock"
          name={
            <Text color="primary" variant="subheader">
              Dispute Timesheet{" "}
              {!disputeTimesheetExists && (
                <Text color="danger">(Required)</Text>
              )}
            </Text>
          }
          callToActionLabel="Edit"
          callToActionIcon="pen-to-square"
          callToActionOnPress={onCreatePress}
        />
      }
      content={
        <ContentArea>
          <Stack size="compact">
            <Stack horizontal justifyContent="space-between">
              <Text>Time Added</Text>
              <Text color="success" weight="semibold">
                +{" "}
                {duration.humanize(
                  disputedFinalTimesheet?.diff?.billableDuration,
                  "semi-compact-no-spaces"
                )}
              </Text>
            </Stack>
            {!isEOR && (
              <Stack horizontal justifyContent="space-between">
                <Text>Additional Earnings via Time Added</Text>
                <Text color="success" weight="semibold">
                  + ${basePaymentMinusFees}
                </Text>
              </Stack>
            )}
          </Stack>
        </ContentArea>
      }
    >
      <Divider />
      <ContentArea>
        <Stack>
          <Surface variant="outline">
            <ContentArea>
              <TimesheetList
                fragmentRef={result}
                variant="worker"
                workerEta={null}
                endsAt={null}
              />
              <Spacer />
              <Divider />
              <Stack variant="divider">
                <ContentRow verticalSize="compact">
                  <Row fill justifyContent="space-between" alignItems="center">
                    <Stack size="slim">
                      <Text>Time Worked</Text>
                      <Text variant="note" color="neutral">
                        Approved by Requester
                      </Text>
                    </Stack>
                    <Text>
                      {duration.humanize(
                        disputedFinalTimesheet?.engagementTimesheet?.paymentInfo
                          ?.billableDuration,
                        "semi-compact-no-spaces"
                      )}
                    </Text>
                  </Row>
                </ContentRow>
                <ContentRow verticalSize="compact">
                  <Row fill justifyContent="space-between" alignItems="center">
                    <Text>Time Worked Added</Text>
                    <Text color="success" weight="semibold">
                      +{" "}
                      {duration.humanize(
                        disputedFinalTimesheet?.diff?.billableDuration,
                        "semi-compact-no-spaces"
                      )}
                    </Text>
                  </Row>
                </ContentRow>
                {!isEOR && (
                  <ContentRow verticalSize="compact">
                    <Row
                      fill
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack size="slim">
                        <Text>Additional Earnings</Text>
                        <Text variant="note" color="neutral">
                          (
                          {duration.humanize(
                            disputedFinalTimesheet?.diff?.billableDuration,
                            "hours-decimal"
                          )}
                          hr x {currency?.humanize(paymentInfo?.payRate)}/hr) -
                          $1/hr T&S Fee
                        </Text>
                      </Stack>
                      <Text color="success" weight="semibold">
                        + ${basePaymentMinusFees}
                      </Text>
                    </Row>
                  </ContentRow>
                )}
              </Stack>
              <Divider />
            </ContentArea>
          </Surface>
          <DisputeCommentContainer
            label="Why you updated your Timesheet"
            comment={comment}
          />
        </Stack>
      </ContentArea>
    </CollapsibleContainer>
  );
}

export function DisputeTimesheetCardFallback({
  comment,
  onCreatePress
}: Props) {
  return (
    <Surface>
      <ProductInfoRow
        variant="shadow"
        testID="dispute-timesheet-title"
        icon="user-clock"
        name={
          <Text color="primary" variant="subheader">
            Dispute Timesheet{" "}
            {!comment && <Text color="danger">(Required)</Text>}
          </Text>
        }
      />
      <ContentArea>
        <Stack>
          <Text weight="semibold">
            Please create a Dispute Timesheet to correct your Hourly Pay.
          </Text>
          <Row justifyContent="center">
            <Button
              testID="create-dispute-timesheet-btn"
              label="Create Dispute Timesheet"
              size="small"
              icon="plus"
              onPress={onCreatePress}
            />
          </Row>
        </Stack>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<
  DisputeTimesheetCard_EngagementTimesheet$key,
  Props
>(
  graphql`
    fragment DisputeTimesheetCard_EngagementTimesheet on EngagementTimesheet {
      ...TimesheetList_timesheet @arguments(overridden: false)
      id
      engagement {
        requiredReportTypes
      }
      timezone
      paymentInfo {
        status
        billableDuration
        additionalPay
        payRate
        serviceFees {
          hourlyRate
          amount
          feeType
        }
      }
      disputedFinalTimesheet {
        diff {
          basePayment
          billableDuration
          totalWorkerServiceFeeAmount
        }
        engagementTimesheet {
          paymentInfo {
            billableDuration
            additionalPay
          }
        }
      }
    }
  `,
  DisputeTimesheetCard,
  DisputeTimesheetCardFallback
);

export function getAdditionalEarnings(
  hours: number,
  payRate?: string | null,
  fee?: string | null
) {
  const feeRate = Number(fee?.replace(" USD", "")) ?? 0;
  const rate = Number(payRate?.replace(" USD", ""));
  return currency.roundDown(rate * hours - hours * feeRate, 2).toFixed(2);
}
