import { Button, Column, NavIcon } from "@gigsmart/atorasu";
import { EORWorkers, ShiftGigsMapScreen } from "@gigsmart/feature-flags";
import { type AppScreenProps, NavPortalEntrance } from "@gigsmart/kaizoku";
import { showShiftGigInfoModal } from "@gigsmart/seibutsu/modals/ShiftGigsInfoModalTabs";
import { compact } from "lodash";
import React, { useLayoutEffect, useState } from "react";
import { useGeolocation } from "../../geolocation";
import type { WorkerParamList } from "../../navigation/types";
import AvailableGigsList from "../available-gigs-list";
import AvailablePickupShiftsList from "../available-pickup-shifts-list";
import BrowseGigsSearch, {
  type SearchFilter
} from "../browse-screen-base/search";
import useSearchDistance from "../use-search-distance";
import ShiftGigsMap from "./shift-gigs-map";

type Props = AppScreenProps<WorkerParamList, "BrowseShifts">;

export default function ShiftGigsScreen({ navigation, route }: Props) {
  const [mapView, setMapview] = useState(() => !!route.params.region);
  const isPickupShifts =
    route.params.type === "pickups" || route.params.type === "pickups-eor";
  const isEor =
    EORWorkers.isEnabled() &&
    (route.params.type === "pickups-eor" || route.params.type === "eor");

  const { distance, persistDistance, isLoaded } = useSearchDistance();
  const currentLocation = useGeolocation();
  const [filter, setFilter] = useState<SearchFilter>(() => ({
    searchTerm: "",
    location: {
      lat: currentLocation?.latitude ?? 0,
      lng: currentLocation?.longitude ?? 0,
      label: compact([currentLocation?.area, currentLocation?.postalCode]).join(
        " "
      )
    },
    radiusMiles: distance,
    gigTypes: ["PAID", "VOLUNTEER"],
    requiredReports: isEor ? ["EOR_WORKER"] : ["IDENTITY_CHECK"]
  }));

  useLayoutEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      radiusMiles: distance,
      location: {
        lat: currentLocation?.latitude ?? 0,
        lng: currentLocation?.longitude ?? 0,
        label: compact([
          currentLocation?.area,
          currentLocation?.postalCode
        ]).join(" ")
      },
      requiredReports: isEor ? ["EOR_WORKER"] : ["IDENTITY_CHECK"]
    }));
  }, [
    currentLocation?.area,
    currentLocation?.latitude,
    currentLocation?.longitude,
    currentLocation?.postalCode,
    distance,
    isEor
  ]);

  const renderButton = () => {
    if (ShiftGigsMapScreen.isDisabled()) return null;

    return (
      <Button
        outline
        size="small"
        testID="shift-gigs-map-btn"
        icon={mapView ? "list-ul" : "location-dot"}
        label={mapView ? "View List" : "View Map"}
        onPress={() => setMapview(!mapView)}
      />
    );
  };

  if (!isLoaded) return null;

  const renderContent = () => {
    if (mapView) {
      return <ShiftGigsMap filter={filter} isPickupShifts={isPickupShifts} />;
    }

    // TODO: add W-2 Specific filters for each list component
    return isPickupShifts ? (
      <AvailablePickupShiftsList filter={filter} />
    ) : (
      <AvailableGigsList filter={filter} />
    );
  };

  return (
    <Column fill>
      <NavPortalEntrance
        rightAccessory={
          <NavIcon
            testID="shift-gig-info-modal-btn"
            name="circle-question"
            variant="regular"
            onPress={() => showShiftGigInfoModal()}
          />
        }
      />
      <BrowseGigsSearch
        collapsed={mapView}
        filter={filter}
        // crisis={crisis}
        onClearCrisis={() => navigation.setParams({})}
        onChange={(filter) => {
          persistDistance(filter.radiusMiles);
          setFilter(filter);
        }}
        renderTopComponent={renderButton}
        isPickupShifts={isPickupShifts}
        isEor={isEor}
      />
      {renderContent()}
    </Column>
  );
}
