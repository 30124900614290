import {
  ErrorPage,
  Markdown,
  Persistence,
  toast,
  useHasPermission
} from "@gigsmart/atorasu";
import { captureError } from "@gigsmart/dekigoto";
import {
  DisableRelaySubscriptions,
  RefreshOnNavigate,
  RelayDebug,
  RelayEtagCache,
  RelaySessionPinning
} from "@gigsmart/feature-flags";
import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import { useIsFocused } from "@gigsmart/kaizoku";
import {
  type PayloadError,
  RelayNetworkRequest,
  RelayOrchestrationProvider,
  type RelayOrchestratorOptions,
  type RelayOrchestratorProp,
  type RelayResponse
} from "@gigsmart/relay";
import { useIsInScreen } from "@gigsmart/seibutsu/layouts/AppLayout";
import NetInfo from "@react-native-community/netinfo";
import * as Sentry from "@sentry/react-native";
import { Buffer } from "buffer/";
import { titleize } from "inflected";
import { Duration } from "luxon";
import React, {
  type ComponentType,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AppState, Platform } from "react-native";
import { getTimeZone } from "react-native-localize";
import { v4 as uuidv4 } from "uuid";
import capabilities from "../app/capabilities";
import { getDeviceId } from "../app/device-id";
import { useOtpCapture } from "../app/otp-capture";
import { UndergoingMaintenance } from "../app/undergoing-maintenance";
import { getAuthToken, refreshAuthToken, setAuthToken } from "./auth-token";
import OfflineRequestQueue from "./offline-request-queue";
import {
  sentryBreadcrumbs,
  sentrySubscriptionBreadcrumbs,
  sentryTracer
} from "./sentry-middleware";

const graphqlUrl = process.env.GRAPHQL_BASE_URL ?? "";
const graphqlSocket = process.env.GRAPHQL_BASE_SOCKET_URL ?? "";
const packageBuildNumber = process.env.PACKAGE_BUILD_NUMBER ?? "development";

const slowMessage =
  "There was an issue with your last request due to slow network connectivity. Please confirm network connection and try again.";
const genericMessage =
  "There was an issue with your last request. Please try again and contact support if the issue persists.";

const showError = (genericMessage: string, detail: string, error: Error) => {
  captureError(error, "warning");
  toast.network(
    {
      title: genericMessage,
      detail: process.env.CONFIG_ENV !== "prod" ? error.message : detail
    },
    {
      label: `request-issue-${error.name}`,
      exclusive: true
    }
  );
};

const onSocketError: RelayOrchestratorOptions["onSocketError"] = captureError;

const AppRelayOrchestrate =
  ({
    initStore,
    appStateRefresh = true,
    ...options
  }: Partial<RelayOrchestratorOptions> & {
    initStore?: any;
    appStateRefresh?: boolean;
  } = {}): HOCVoid =>
  (WrappedComponent: ComponentType<any>) =>
    applyHOCProperties({
      displayName: "AppRelayOrchestrate",
      WrappedComponent,
      HigherOrderComponent: (props: any) => {
        const hasLocationPermission = useHasPermission("location", {
          trace: "app-relay-orchestrate"
        });
        const hasNotificationsPermission = useHasPermission("notification", {
          trace: "app-relay-orchestrate"
        });
        const hasBackgroundLocationPermission = useHasPermission(
          "backgroundLocation",
          { trace: "app-relay-orchestrate" }
        );
        const [otpCode, otpMethod, showOtpModal] = useOtpCapture();
        const shouldRetry = useRef<boolean>(true);
        const [showMaintenance, setShowMaintenance] = useState(false);
        const [permanentError, setPermanentError] = useState<Error | null>(
          null
        );
        const initOptions = useMemo<RelayOrchestratorOptions>(
          () => ({
            debug: RelayDebug.isEnabled(),
            EXPERIMENTAL_sessionPinning: RelaySessionPinning.isEnabled(),
            EXPERIMENTAL_etagCaching: RelayEtagCache.isEnabled(),
            enablePersistedQueries: true,
            queryCacheExpirationTime: Duration.fromISO("PT30M").toMillis(),
            graphqlUrl,
            graphqlSocket,
            queueMiddleware: OfflineRequestQueue.createMiddleware(),
            beforeReset: async () => {
              await Persistence.clear();
            },
            afterReset: () => {},
            onResponse: (
              res: RelayResponse | null | undefined,
              { reset }: RelayOrchestratorProp
            ) => {
              if (res && res.status === 401) void reset(null);
              if (res && res.status === 200) setShowMaintenance(false);
            },
            onServiceUnavailable: async () => {
              const { isConnected } = await NetInfo.fetch();
              if (isConnected) setShowMaintenance(true);
            },
            retryDelay: process.env.IS_TESTING === "true" ? 1000 : 100,
            onRequestError: (error) => {
              const response: RelayResponse = (error as any).res;
              if (response?.status === 403) {
                setPermanentError(new Error(response?.text));
                return;
              }
              showError("Request Issue", slowMessage, error);
            },
            onQueryError: (error: Error) =>
              showError("Query Error", genericMessage, error),
            onSocketError,
            payloadErrorTransformer: (error: PayloadError) => {
              if (!error.field || !error.message) return error;
              error.message = `${titleize(error.field)} ${error.message}`;
              return error;
            },
            setTokenFn: setAuthToken,
            getTokenFn: getAuthToken,
            refreshTokenFn: refreshAuthToken,
            loggerMiddleware: sentryBreadcrumbs,
            onSubscriptionNext: sentrySubscriptionBreadcrumbs,
            buildMiddlewares: (mw) => [sentryTracer, ...mw],
            reportErrorCodes: __DEV__
              ? "*"
              : process.env.CONFIG_ENV === "prod"
                ? []
                : ["UNAUTHORIZED"],
            enableSubscriptions: DisableRelaySubscriptions.isDisabled(),
            onSocketChange: (status) => {
              if (status === "disconnected") {
                shouldRetry.current = true;
              }
            },
            onMutationError(error, retry) {
              const otpError = error.payloadErrors?.find(
                (e) => e.code === "OTP_INVALID"
              );
              if (
                otpError &&
                (otpError?.metadata?.remainingAttempts ??
                  Number.POSITIVE_INFINITY) > 0
              ) {
                showOtpModal(
                  otpError?.message,
                  otpError?.metadata?.method ?? "SMS",
                  retry
                );
                return false;
              }
            },
            useDecorateQuery: (name, retry) => {
              const inScreen = useIsInScreen();
              const isFocused = useIsFocused();
              const runs = useRef(0);
              const lastAppRetry = useRef(+new Date());
              const selectiveRetry = useCallback(() => {
                if (isFocused && inScreen) {
                  runs.current += 1;
                  if (runs.current) void retry();
                }
                lastAppRetry.current = +new Date();
              }, [isFocused, inScreen, retry]);
              useEffect(() => {
                if (!appStateRefresh) return;
                const listener = AppState.addEventListener(
                  "change",
                  (state) => {
                    if (
                      state === "active" &&
                      (shouldRetry.current ||
                        lastAppRetry.current < +new Date() - 30_000)
                    ) {
                      selectiveRetry();
                      shouldRetry.current = false;
                    }
                  }
                );
                return () => listener.remove();
              }, [selectiveRetry, appStateRefresh]);

              useEffect(() => {
                RefreshOnNavigate.isEnabled() && selectiveRetry();
              }, [selectiveRetry]);
            },
            errorCodeReporter: (error, request) => {
              if (request instanceof RelayNetworkRequest) {
                const { field, message, code = "UNKNOWN", path } = error ?? {};
                const finalMessage = field ? `${field} ${message}` : message;
                const operationName = (request as RelayNetworkRequest).operation
                  .name;
                const pathName = path?.join(".") ?? "";
                const err = new Error(
                  `${code}: ${finalMessage} in ${operationName} at \`${pathName}\``
                );
                captureError(err, "warning", {
                  fingerprint: [
                    "RELAY",
                    operationName,
                    pathName,
                    code,
                    finalMessage
                  ]
                });
              }
            },
            requestHeaders: async () => {
              const transactionId = uuidv4();
              Sentry.getCurrentScope().setTag("transaction_id", transactionId);
              const { id, strategy } = await getDeviceId();
              return await Promise.resolve({
                "GigSmart-Transaction-Id": transactionId,
                "GigSmart-Client-Version": packageBuildNumber,
                "GigSmart-Device-Id": id,
                "GigSmart-Device-Id-Strategy": strategy,
                "GigSmart-Capabilities": await capabilities({
                  hasLocationPermission,
                  hasNotificationsPermission,
                  hasBackgroundLocationPermission
                }),
                "GigSmart-Platform": Platform.OS,
                "GigSmart-Env": process.env.CONFIG_ENV ?? "local",
                "IANA-Time-Zone": getTimeZone(),
                ...(otpMethod.current
                  ? { "GigSmart-Escalation-Method": otpMethod.current }
                  : {}),
                ...(otpCode.current
                  ? {
                      "GigSmart-Escalation-Token": Buffer.from(
                        otpCode.current
                      ).toString("base64")
                    }
                  : {})
              });
            },
            ...options
          }),
          [
            otpCode,
            otpMethod,
            showOtpModal,
            appStateRefresh,
            hasBackgroundLocationPermission,
            hasLocationPermission,
            hasNotificationsPermission,
            options
          ]
        );

        if (permanentError) {
          void Persistence.clear();
          return (
            <ErrorPage
              title="Device Blocked"
              message={
                <Markdown>
                  This device has been blocked due to a breach of our terms.
                  {"\n"}[Contact us](https://gigsmart.com/contact/) if you wish
                  to have your device unblocked.
                </Markdown>
              }
            />
          );
        }

        return (
          <RelayOrchestrationProvider {...initOptions}>
            {showMaintenance ? (
              <UndergoingMaintenance showReload />
            ) : (
              <Suspense fallback={null}>
                <WrappedComponent {...props} />
              </Suspense>
            )}
          </RelayOrchestrationProvider>
        );
      }
    });

export default AppRelayOrchestrate;
