import {
  Column,
  Divider,
  HighlightedReminder,
  Link,
  ListRow,
  Text
} from "@gigsmart/atorasu";
import { PositionsAndLocations } from "@gigsmart/feature-flags";
import { getEngagementAddonSpec } from "@gigsmart/isomorphic-shared/addon/dictionary";
import { currency, duration } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql,
  readRelayInlineFragment
} from "@gigsmart/relay";
import { compact } from "lodash";
import React from "react";
import type { WorkerPaymentRows_availableGig$key } from "./__generated__/WorkerPaymentRows_availableGig.graphql";
import type { WorkerPaymentRows_payment$key } from "./__generated__/WorkerPaymentRows_payment.graphql";

type Props = {
  showTips?: boolean;
  isEOR?: boolean;
};

export const WorkerPaymentRows = ({
  estimatedPayment,
  paymentInfo,
  showTips,
  isEOR
}: FragmentContainerInnerComponentProps<
  WorkerPaymentRows_availableGig$key,
  Props
>) => {
  const isPayable = !!paymentInfo;
  const prefix = isPayable ? "" : "Estimated ";
  const info = readRelayInlineFragment<WorkerPaymentRows_payment$key>(
    graphql`
      fragment WorkerPaymentRows_payment on EngagementPaymentInfo @inline {
        payRate
        paySchedule
        billableDuration
        grossPay
        netPay
        status
        serviceFees {
          amount
          hourlyRate
          feeType
        }
      }
    `,
    paymentInfo ?? estimatedPayment ?? null
  );

  const durationString = duration.humanize(
    info?.billableDuration,
    "compact-no-seconds"
  );

  const listProps = {
    verticalSpacing: "none",
    rightSpacing: "none",
    leftSpacing: "none",
    alignItems: "flex-start",
    shrink: true
  } as const;

  const reminders = compact([
    !isPayable && isEOR && (
      <HighlightedReminder
        key="est-reminder"
        header="Earnings are based on approved hours from your finalized timesheet and are not guaranteed. Payment will be provided outside the app."
        icon="circle-info"
      />
    ),
    showTips && (
      <HighlightedReminder
        key="tips-reminder"
        variant="info"
        header={PositionsAndLocations.select(
          "Tips are commonly paid out to Workers who work this Shift.",
          "Tips are commonly paid out to Workers who work this Shift Gig."
        )}
        icon="hand-holding-dollar"
      />
    )
  ]);
  const renderHourlyPay =
    (!isEOR && info?.grossPay !== "0 USD") ||
    (isEOR && info?.status === "FINAL");
  const renderServiceFees = !isEOR || (isEOR && info?.status === "FINAL");
  const renderTotalEarnings =
    (!isEOR && info?.netPay !== "0 USD") || (isEOR && info?.status === "FINAL");

  return (
    <Column gap="standard">
      {renderHourlyPay && (
        <>
          <Divider />
          <ListRow {...listProps} right={currency.humanize(info?.grossPay)}>
            <Text>{prefix}Hourly Pay</Text>
            <Text variant="note" color="neutral">
              {currency.humanize(info?.payRate)}/hr ({durationString})
            </Text>
          </ListRow>
        </>
      )}
      {renderServiceFees &&
        info?.serviceFees?.map((fee, idx) => {
          const addonSpec = getEngagementAddonSpec(fee?.feeType);
          if (isPayable) return null;
          return (
            <ListRow
              {...listProps}
              key={fee?.feeType ?? `idx-${idx}`}
              right={`- ${currency.humanize(fee.amount)}`}
            >
              <Text>
                {prefix}
                {addonSpec.name}
              </Text>
              {fee.hourlyRate ? (
                <Text variant="note" color="neutral">
                  {currency.humanizeRate(fee?.hourlyRate)} ({durationString}){" "}
                  <Link variant="note" openURL={addonSpec.linkUrl}>
                    {addonSpec.linkText}
                  </Link>
                </Text>
              ) : (
                <Link variant="note" openURL={addonSpec.linkUrl}>
                  {addonSpec.linkText}
                </Link>
              )}
            </ListRow>
          );
        })}
      {renderTotalEarnings && (
        <>
          <Divider />
          <ListRow
            {...listProps}
            right={<Text weight="bold">{currency.humanize(info?.netPay)}</Text>}
          >
            <Text weight="bold">{prefix}Total Earnings</Text>
          </ListRow>
        </>
      )}
      {!!reminders.length && <Column gap="compact">{reminders}</Column>}
    </Column>
  );
};

export default createRelayFragmentContainer<
  WorkerPaymentRows_availableGig$key,
  Props
>(
  graphql`
    fragment WorkerPaymentRows_availableGig on AvailableGigOrEngagement {
      ... on AvailableGigsEdge {
        node {
          ... on GigLike {
            requiredReportTypes
          }
        }
        estimatedPayment {
          ...WorkerPaymentRows_payment
        }
      }
      ... on Engagement {
        estimatedPayment {
          ...WorkerPaymentRows_payment
        }
        paymentInfo {
          ...WorkerPaymentRows_payment
        }
      }
    }
  `,
  WorkerPaymentRows
);
