/**
 * @generated SignedSource<<baff93df6160297f351e70059d8b679a>>
 * @relayHash a71d5c449de8c59d8fda445e7e4b5b5f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:XuL0TG0B-cABDiyKrgOJBXxwj90ItzR4TusfepbecVk

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type EngagementTimesheetPaymentStyle = "CALCULATED" | "FIXED_AMOUNT" | "FIXED_HOURS" | "%future added value";
export type PaymentType = "CASH" | "CHECK" | "DIGITAL_TRANSFER" | "IN_APP" | "NO_PREFERENCE" | "%future added value";
export type UserType = "ADMIN" | "ORGANIZATION_APPLICATION" | "ORGANIZATION_REQUESTER" | "REQUESTER" | "SYSTEM" | "WORKER" | "%future added value";
export type SetEngagementSummaryInput = {
  additionalPayment?: string | null | undefined;
  amount?: string | null | undefined;
  comment?: string | null | undefined;
  complete?: boolean | null | undefined;
  createOverrides?: boolean | null | undefined;
  disputedTimesheetId?: string | null | undefined;
  disputesTimesheetId?: string | null | undefined;
  engagementId: string;
  estimatedMileage?: number | null | undefined;
  isApproved?: boolean | null | undefined;
  onBehalfOf?: UserInput | null | undefined;
  overrides?: ReadonlyArray<EngagementStateOverrideInput> | null | undefined;
  paid?: boolean | null | undefined;
  paymentStyle?: EngagementTimesheetPaymentStyle | null | undefined;
  paymentToken?: string | null | undefined;
  paymentType?: PaymentType | null | undefined;
  totalBreakDuration?: string | null | undefined;
  totalDurationWorked?: string | null | undefined;
  totalPaymentAmount?: string | null | undefined;
};
export type UserInput = {
  id: string;
  type: UserType;
};
export type EngagementStateOverrideInput = {
  action: EngagementStateAction;
  engagementStateId?: string | null | undefined;
  name: EngagementStateName;
  transitionedAt: string;
};
export type timesheetEditHooksResetMutation$variables = {
  input: SetEngagementSummaryInput;
  isRequester: boolean;
};
export type timesheetEditHooksResetMutation$data = {
  readonly setEngagementSummary: {
    readonly engagement: {
      readonly currentState: {
        readonly action: EngagementStateAction;
        readonly name: EngagementStateName;
      };
      readonly endsAt: string | null | undefined;
      readonly gig: {
        readonly estimatedMileage: number | null | undefined;
        readonly timezone: string | null | undefined;
      };
      readonly inactiveCancelStates: {
        readonly totalCount: number;
      } | null | undefined;
      readonly original: {
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly startedCount: {
          readonly totalCount: number;
        } | null | undefined;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly requesterTimesheet: {
        readonly editable: boolean;
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly isOverridden: boolean;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly timesheet: {
        readonly id: string;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
      } | null | undefined;
      readonly workerTimesheet: {
        readonly editable: boolean;
        readonly estimatedMileage: number | null | undefined;
        readonly isApproved: boolean;
        readonly isOverridden: boolean;
        readonly paymentStyle: EngagementTimesheetPaymentStyle;
        readonly totalDurationWorked: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ShiftRosterTable_engagement">;
    };
    readonly engagementTimesheet: {
      readonly additionalPayment: string | null | undefined;
      readonly editable: boolean;
      readonly isOverridden: boolean;
      readonly paymentStyle: EngagementTimesheetPaymentStyle;
      readonly totalBreakDuration: string | null | undefined;
      readonly totalDurationWorked: string | null | undefined;
    };
  } | null | undefined;
};
export type timesheetEditHooksResetMutation = {
  response: timesheetEditHooksResetMutation$data;
  variables: timesheetEditHooksResetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isRequester"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDurationWorked",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalBreakDuration",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOverridden",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStyle",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalPayment",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualStartsAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementBillingInfo",
  "kind": "LinkedField",
  "name": "estimatedBilling",
  "plural": false,
  "selections": [
    (v11/*: any*/)
  ],
  "storageKey": null
},
v20 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementBillingInfo",
  "kind": "LinkedField",
  "name": "billingInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = [
  (v7/*: any*/),
  (v2/*: any*/),
  (v6/*: any*/),
  (v21/*: any*/)
],
v23 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "REQUESTER"
  }
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v26 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v27 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v28 = {
  "alias": "inactiveCancelStates",
  "args": [
    (v26/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = INACTIVE_CANCEL"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = INACTIVE_CANCEL\")"
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v30 = {
  "alias": "startedCount",
  "args": [
    (v26/*: any*/),
    {
      "kind": "Literal",
      "name": "query",
      "value": "WHERE action = START"
    }
  ],
  "concreteType": "EngagementStateOrOverridesConnection",
  "kind": "LinkedField",
  "name": "states",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": "states(first:0,query:\"WHERE action = START\")"
},
v31 = {
  "kind": "Literal",
  "name": "overridden",
  "value": false
},
v32 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "WORKER"
  }
],
v33 = {
  "kind": "Literal",
  "name": "overridden",
  "value": true
},
v34 = [
  (v4/*: any*/),
  (v29/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v25/*: any*/),
  (v2/*: any*/),
  {
    "args": [
      (v33/*: any*/)
    ],
    "kind": "FragmentSpread",
    "name": "TimesheetList_timesheet"
  }
],
v35 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementTimesheet",
  "kind": "LinkedField",
  "name": "timesheet",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v6/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v36 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v37 = {
  "transitionedAt": {
    "direction": "ASC"
  }
},
v38 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    (v37/*: any*/)
  ]
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transitionedAt",
  "storageKey": null
},
v41 = [
  (v39/*: any*/),
  (v14/*: any*/),
  (v8/*: any*/)
],
v42 = [
  (v8/*: any*/)
],
v43 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v39/*: any*/),
          (v8/*: any*/),
          (v12/*: any*/),
          (v24/*: any*/),
          (v40/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transitionedBy",
            "plural": false,
            "selections": (v41/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementNegotiation",
            "kind": "LinkedField",
            "name": "negotiation",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submittedBy",
                "plural": false,
                "selections": (v41/*: any*/),
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "overridesState",
                "plural": false,
                "selections": (v42/*: any*/),
                "storageKey": null
              }
            ],
            "type": "EngagementStateOverride",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementStateLocation",
                "kind": "LinkedField",
                "name": "workerTransitionLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EngagementState",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v44 = [
  (v4/*: any*/),
  (v29/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v25/*: any*/),
  (v2/*: any*/),
  (v8/*: any*/),
  (v18/*: any*/),
  {
    "alias": null,
    "args": [
      (v36/*: any*/),
      (v38/*: any*/),
      (v33/*: any*/)
    ],
    "concreteType": "EngagementStateOrOverridesConnection",
    "kind": "LinkedField",
    "name": "states",
    "plural": false,
    "selections": (v43/*: any*/),
    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:true)"
  }
],
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentType",
  "storageKey": null
},
v48 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v49 = {
  "kind": "InlineFragment",
  "selections": (v42/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v50 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": (v37/*: any*/)
},
v51 = {
  "kind": "Literal",
  "name": "where",
  "value": {
    "name": {
      "_in": [
        "SCHEDULED",
        "WORKING",
        "ENDED",
        "PAUSED",
        "PENDING_TIMESHEET_APPROVAL"
      ]
    }
  }
},
v52 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EngagementStateOrOverridesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v39/*: any*/),
          (v12/*: any*/),
          (v40/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "timesheetEditHooksResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetEngagementSummaryPayload",
        "kind": "LinkedField",
        "name": "setEngagementSummary",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementTimesheet",
            "kind": "LinkedField",
            "name": "engagementTimesheet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              {
                "condition": "isRequester",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "ShiftRosterTable_engagement",
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "TimeWorked_engagement"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MoneyEarned_engagement"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EngagementActions_engagement"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RosterTimeCell_engagement"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RosterTimeBreakCell_engagement"
                      },
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementBillingInfo",
                        "kind": "LinkedField",
                        "name": "billingInfo",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementState",
                        "kind": "LinkedField",
                        "name": "currentState",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Worker",
                        "kind": "LinkedField",
                        "name": "worker",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "WorkerAvatar_worker"
                          },
                          (v8/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Gig",
                        "kind": "LinkedField",
                        "name": "gig",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v12/*: any*/),
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GigState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": "systemTimesheet",
                        "args": (v20/*: any*/),
                        "concreteType": "EngagementTimesheet",
                        "kind": "LinkedField",
                        "name": "timesheet",
                        "plural": false,
                        "selections": (v22/*: any*/),
                        "storageKey": "timesheet(variant:\"SYSTEM\")"
                      },
                      {
                        "alias": "requesterTimesheet",
                        "args": (v23/*: any*/),
                        "concreteType": "EngagementTimesheet",
                        "kind": "LinkedField",
                        "name": "timesheet",
                        "plural": false,
                        "selections": (v22/*: any*/),
                        "storageKey": "timesheet(variant:\"REQUESTER\")"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ]
              },
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v28/*: any*/),
              {
                "alias": "original",
                "args": (v20/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v29/*: any*/),
                  (v25/*: any*/),
                  (v2/*: any*/),
                  (v30/*: any*/),
                  {
                    "args": [
                      (v31/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "TimesheetList_timesheet"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v32/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v34/*: any*/),
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": (v23/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v34/*: any*/),
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              (v35/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "timesheetEditHooksResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetEngagementSummaryPayload",
        "kind": "LinkedField",
        "name": "setEngagementSummary",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngagementTimesheet",
            "kind": "LinkedField",
            "name": "engagementTimesheet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Engagement",
            "kind": "LinkedField",
            "name": "engagement",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  (v12/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v18/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v28/*: any*/),
              {
                "alias": "original",
                "args": (v20/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": [
                  (v29/*: any*/),
                  (v25/*: any*/),
                  (v2/*: any*/),
                  (v30/*: any*/),
                  (v8/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v36/*: any*/),
                      (v38/*: any*/),
                      (v31/*: any*/)
                    ],
                    "concreteType": "EngagementStateOrOverridesConnection",
                    "kind": "LinkedField",
                    "name": "states",
                    "plural": false,
                    "selections": (v43/*: any*/),
                    "storageKey": "states(first:50,orderBy:[{\"transitionedAt\":{\"direction\":\"ASC\"}}],overridden:false)"
                  }
                ],
                "storageKey": "timesheet(variant:\"SYSTEM\")"
              },
              {
                "alias": "workerTimesheet",
                "args": (v32/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v44/*: any*/),
                "storageKey": "timesheet(variant:\"WORKER\")"
              },
              {
                "alias": "requesterTimesheet",
                "args": (v23/*: any*/),
                "concreteType": "EngagementTimesheet",
                "kind": "LinkedField",
                "name": "timesheet",
                "plural": false,
                "selections": (v44/*: any*/),
                "storageKey": "timesheet(variant:\"REQUESTER\")"
              },
              (v35/*: any*/),
              (v8/*: any*/),
              {
                "condition": "isRequester",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": [
                      (v40/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementBillingInfo",
                    "kind": "LinkedField",
                    "name": "billingInfo",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v45/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "finalTimesheet",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "variant",
                        "value": "FINAL"
                      }
                    ],
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v46/*: any*/),
                      (v47/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"FINAL\")"
                  },
                  {
                    "alias": "workerTimesheet",
                    "args": (v32/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v46/*: any*/),
                      (v47/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"WORKER\")"
                  },
                  {
                    "alias": null,
                    "args": [
                      (v48/*: any*/)
                    ],
                    "concreteType": "EngagementPaymentsConnection",
                    "kind": "LinkedField",
                    "name": "payments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementPaymentsConnectionAggregate",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentsConnectionAggregateOperationSum",
                            "kind": "LinkedField",
                            "name": "sum",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "payments(first:10)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigState",
                        "kind": "LinkedField",
                        "name": "currentState",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBlocked",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isFavorite",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "belongsToWorkerGroup",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "profilePhoto",
                        "plural": false,
                        "selections": [
                          (v39/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v49/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v26/*: any*/)
                    ],
                    "concreteType": "WorkerLocationsConnection",
                    "kind": "LinkedField",
                    "name": "workerLocations",
                    "plural": false,
                    "selections": (v27/*: any*/),
                    "storageKey": "workerLocations(first:0)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementCapability",
                    "kind": "LinkedField",
                    "name": "capabilities",
                    "plural": true,
                    "selections": [
                      (v45/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v39/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v42/*: any*/),
                        "type": "OrganizationWorkerConversation",
                        "abstractKey": null
                      },
                      (v49/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  {
                    "alias": "systemTimesheet",
                    "args": (v20/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": "rosterStates",
                        "args": [
                          (v48/*: any*/),
                          (v50/*: any*/),
                          (v51/*: any*/)
                        ],
                        "concreteType": "EngagementStateOrOverridesConnection",
                        "kind": "LinkedField",
                        "name": "states",
                        "plural": false,
                        "selections": (v52/*: any*/),
                        "storageKey": "states(first:10,orderBy:{\"transitionedAt\":{\"direction\":\"ASC\"}},where:{\"name\":{\"_in\":[\"SCHEDULED\",\"WORKING\",\"ENDED\",\"PAUSED\",\"PENDING_TIMESHEET_APPROVAL\"]}})"
                      },
                      (v7/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"SYSTEM\")"
                  },
                  {
                    "alias": "requesterTimesheet",
                    "args": (v23/*: any*/),
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "rosterStates",
                        "args": [
                          (v48/*: any*/),
                          (v50/*: any*/),
                          (v33/*: any*/),
                          (v51/*: any*/)
                        ],
                        "concreteType": "EngagementStateOrOverridesConnection",
                        "kind": "LinkedField",
                        "name": "states",
                        "plural": false,
                        "selections": (v52/*: any*/),
                        "storageKey": "states(first:10,orderBy:{\"transitionedAt\":{\"direction\":\"ASC\"}},overridden:true,where:{\"name\":{\"_in\":[\"SCHEDULED\",\"WORKING\",\"ENDED\",\"PAUSED\",\"PENDING_TIMESHEET_APPROVAL\"]}})"
                      },
                      (v7/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"REQUESTER\")"
                  },
                  (v9/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/)
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:XuL0TG0B-cABDiyKrgOJBXxwj90ItzR4TusfepbecVk",
    "metadata": {},
    "name": "timesheetEditHooksResetMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "90d7c47a8a007cd76a39199074f8a495";

export default node;
