import {
  CollapsibleContainer,
  Column,
  FancyHeader,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import React from "react";
import ShiftPreviewRow from "../../gig/worker/ShiftPreviewRow";
import type { WorkerShiftParams } from "../../shifts/navigation";
import type { HomescreenPendingDisputesCardQuery } from "./__generated__/HomescreenPendingDisputesCardQuery.graphql";
import type { HomescreenPendingDisputesCardSubscription } from "./__generated__/HomescreenPendingDisputesCardSubscription.graphql";

export default createSuspendedQueryContainer<HomescreenPendingDisputesCardQuery>(
  function HomescreenPendingDisputesCard({ response: result }) {
    const navigation = useNavigation<AppNavigationProp<WorkerShiftParams>>();

    useRelaySubscription<HomescreenPendingDisputesCardSubscription>(
      graphql`
        subscription HomescreenPendingDisputesCardSubscription {
          workerUpdated {
            worker {
              engagements(
                first: 10
                where: {
                  anyEngagementDisputeDisposition: { _isNull: true },
                  gigType: { _neq: PROJECT }
                }
              ) {
                totalCount
                edges {
                  node {
                    id
                    disputes(
                      first: 1
                      where: { approvalDisposition: { _isNull: true } }
                    ) {
                      edges {
                        node {
                          disputeTarget {
                            ... on EngagementTimesheet {
                              disputedFinalTimesheet {
                                diff {
                                  totalPayment
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    gig {
                      ...ShiftPreviewRow_shift
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {}
    );

    const data = getConnectionNodes(result?.viewer?.engagements);
    const totalCount = result?.viewer?.engagements?.totalCount;
    if (!totalCount) return null;

    const renderEngagement = (d: (typeof data)[number]) => {
      const gig = d.gig;
      const hasEorType = gig.requiredReportTypes?.includes("EOR_WORKER");
      const val =
        d.disputes?.edges?.[0]?.node?.disputeTarget.disputedFinalTimesheet?.diff
          ?.totalPayment;
      return (
        <ShiftPreviewRow
          key={d.id}
          type="shifts"
          fragmentRef={d.gig}
          note={
            <Text variant="note" color="neutral">
              {hasEorType
                ? currency.humanizeRate(gig.payRate)
                : `${currency.humanize(val)} Submitted to Requester`}
            </Text>
          }
          onPress={() => navigation.push("ShiftDetails", { id: d.id })}
        />
      );
    };
    const renderContent = (start?: number, end?: number) => (
      <Column gap="compact">
        {data.slice(start, end).map(renderEngagement)}
      </Column>
    );

    const title = `${pluralize(totalCount, "Dispute")} ${
      totalCount === 1 ? "is" : "are"
    } Pending a Response`;

    return (
      <CollapsibleContainer
        testID="pending-disputes-container"
        startCollapsed
        variant="card"
        header={
          <FancyHeader
            icon="file-exclamation"
            title={title}
            color="black"
            variant="warning"
          />
        }
        content={renderContent(0, 3)}
        expandLabel="Show More"
        collapseLabel="Show Less"
        contentSize="standard"
        contentVariant="standard"
      >
        {totalCount > 3 && (
          <>
            <Spacer size="compact" />
            {renderContent(3)}
          </>
        )}
      </CollapsibleContainer>
    );
  },
  {
    query: graphql`
      query HomescreenPendingDisputesCardQuery {
        viewer {
          ... on Worker {
            engagements(
              first: 10
              where: {
                anyEngagementDisputeDisposition: { _isNull: true },
                gigType: { _neq: PROJECT }
              }
            ) {
              totalCount
              edges {
                node {
                  id
                  disputes(
                    first: 1
                    where: { approvalDisposition: { _isNull: true } }
                  ) {
                    edges {
                      node {
                        disputeTarget {
                          ... on EngagementTimesheet {
                            disputedFinalTimesheet {
                              diff {
                                totalPayment
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  gig {
                    payRate
                    requiredReportTypes
                    ...ShiftPreviewRow_shift
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {}
  }
);
