/**
 * @generated SignedSource<<b30d2c6aaed4750af1a337b25b6f3d04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementPaymentInfoStatus = "ACCURATE" | "FINAL" | "INACCURATE" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DisputeTimesheetCard_EngagementTimesheet$data = {
  readonly disputedFinalTimesheet: {
    readonly diff: {
      readonly basePayment: string;
      readonly billableDuration: string;
      readonly totalWorkerServiceFeeAmount: string;
    } | null | undefined;
    readonly engagementTimesheet: {
      readonly paymentInfo: {
        readonly additionalPay: string;
        readonly billableDuration: string;
      } | null | undefined;
    };
  } | null | undefined;
  readonly engagement: {
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
  };
  readonly id: string;
  readonly paymentInfo: {
    readonly additionalPay: string;
    readonly billableDuration: string;
    readonly payRate: string;
    readonly serviceFees: ReadonlyArray<{
      readonly amount: string;
      readonly feeType: EngagementAddonFeeType;
      readonly hourlyRate: string | null | undefined;
    }>;
    readonly status: EngagementPaymentInfoStatus;
  } | null | undefined;
  readonly timezone: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TimesheetList_timesheet">;
  readonly " $fragmentType": "DisputeTimesheetCard_EngagementTimesheet";
};
export type DisputeTimesheetCard_EngagementTimesheet$key = {
  readonly " $data"?: DisputeTimesheetCard_EngagementTimesheet$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisputeTimesheetCard_EngagementTimesheet">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableDuration",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalPay",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisputeTimesheetCard_EngagementTimesheet",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "overridden",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "TimesheetList_timesheet"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Engagement",
      "kind": "LinkedField",
      "name": "engagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredReportTypes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerServiceFee",
          "kind": "LinkedField",
          "name": "serviceFees",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hourlyRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementTimesheetComparison",
      "kind": "LinkedField",
      "name": "disputedFinalTimesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheetDiff",
          "kind": "LinkedField",
          "name": "diff",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "basePayment",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalWorkerServiceFeeAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementTimesheet",
          "kind": "LinkedField",
          "name": "engagementTimesheet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementPaymentInfo",
              "kind": "LinkedField",
              "name": "paymentInfo",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EngagementTimesheet",
  "abstractKey": null
};
})();

(node as any).hash = "ea9afa7d8050d05375ef71ff799f567c";

export default node;
