import React from "react";

import { StyledScrollView } from "@gigsmart/katana";

import {
  Button,
  Column,
  ContentArea,
  ContentRow,
  Divider,
  Stack,
  Surface,
  Text
} from "@gigsmart/atorasu";

import { EORWorkers } from "@gigsmart/feature-flags";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import type { WorkerParamList } from "../../navigation/types";

interface Props {
  address: string | null | undefined;
}

const BrowseTabHeader = ({ address }: Props) => {
  const navigation = useNavigation<AppNavigationProp<WorkerParamList>>();
  return (
    <Surface>
      <ContentRow verticalSize="compact" alignItems="center">
        <Column>
          <Text variant="note">Browsing</Text>
          <Text variant="header" color="primary" weight="bold">
            Shifts, Jobs and Projects{address ? ` in ${address}` : ""}
          </Text>
        </Column>
      </ContentRow>

      <Divider />
      <StyledScrollView horizontal showsHorizontalScrollIndicator={false}>
        <ContentArea size="compact">
          <Stack horizontal alignItems="center" size="compact">
            <Text color="primary" weight="bold" variant="note">
              Go to:
            </Text>
            {EORWorkers.isEnabled() && (
              <Button
                outline
                icon="business-time"
                iconPlacement="left"
                label="W-2"
                onPress={() => navigation.push("BrowseShifts", { type: "eor" })}
                color="purple"
                variant="clear"
                size="small"
                testID="browse-eor-section-footer-button"
              />
            )}
            <Button
              outline
              icon="business-time"
              iconPlacement="left"
              label="Shifts"
              onPress={() =>
                navigation.push("BrowseShifts", { type: "shifts" })
              }
              color="primary"
              variant="clear"
              size="small"
              testID="browse-gigs-section-footer-button"
            />
            <Button
              outline
              icon="newspaper"
              iconPlacement="left"
              label="Job Board "
              onPress={() => navigation.push("BrowseJobs")}
              color="primary"
              variant="clear"
              size="small"
              testID="browse-gigs-section-footer-button"
            />
            <Button
              outline
              icon="pen-ruler"
              iconPlacement="left"
              label="Project Gigs "
              onPress={() => navigation.push("BrowseProjects")}
              color="primary"
              variant="clear"
              size="small"
              testID="browse-gigs-section-footer-button"
            />
          </Stack>
        </ContentArea>
      </StyledScrollView>
    </Surface>
  );
};

export default BrowseTabHeader;
