import { Spacer } from "@gigsmart/atorasu";
import {
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql,
  useRelaySubscription
} from "@gigsmart/relay";
import { WorkerEngagementCardV2 } from "@gigsmart/seibutsu/gig-like/WorkerShiftCard";
import InfiniteGridList from "@gigsmart/seibutsu/shared/InfiniteList/InfiniteGridList";
import useWorkerEngagementSummary from "@gigsmart/seibutsu/worker/useWorkerEngagementSummary";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useMemo } from "react";
import MyGigsEmptyList from "./MyGigsEmpty";
import MyGigsFooter from "./MyGigsFooter";
import ShiftGigsFilter, {
  type ShiftFilterType,
  makeShiftQuery
} from "./ShiftGigsFilter";
import type { ShiftGigsTabPageQuery } from "./__generated__/ShiftGigsTabPageQuery.graphql";
import type { ShiftGigsTabQuery } from "./__generated__/ShiftGigsTabQuery.graphql";
import type { ShiftGigsTabSubscription } from "./__generated__/ShiftGigsTabSubscription.graphql";
import type { ShiftGigsTab_worker$key } from "./__generated__/ShiftGigsTab_worker.graphql";

type EntryType = NonNullable<
  ObjectPath<
    ShiftGigsTab_worker$key,
    [" $data", "engagements", "edges", 0, "node"]
  >
>;

type Props = {
  active?: ShiftFilterType;
  onChange: (value: ShiftFilterType) => void;
  onPressItem: (id: string) => void;
};

export default createSuspendedQueryContainer<ShiftGigsTabQuery, Props>(
  function ShiftGigsTab({ onPressItem, active, onChange, response, retry }) {
    const worker = response?.viewer ?? null;
    const refetchVars = useMemo(
      () => ({ ...makeShiftQuery(active) }),
      [active]
    );

    useRelaySubscription<ShiftGigsTabSubscription>(
      graphql`
        subscription ShiftGigsTabSubscription(
          $where: CqlFilterEngagementInput!
          $orderBy: [CqlOrderEngagementInput!]
        ) {
          workerUpdated {
            worker {
              id
              ...useWorkerEngagementSummary_worker
              ...ShiftGigsTab_worker
                @arguments(where: $where, orderBy: $orderBy)
            }
          }
        }
      `,
      makeShiftQuery(active),
      { onNext: () => retry() }
    );

    const getCount = useWorkerEngagementSummary(worker);
    const shiftsCount = getCount("ALL_SHIFTS");

    return (
      <InfiniteGridList<
        ShiftGigsTabPageQuery,
        ShiftGigsTab_worker$key,
        EntryType
      >
        testID="shift-gigs-tab"
        refetchDebounce={0}
        refetchVars={refetchVars}
        parentRef={worker}
        fragmentInput={graphql`
          fragment ShiftGigsTab_worker on Worker
          @refetchable(queryName: "ShiftGigsTabPageQuery")
          @argumentDefinitions(
            count: { type: "Int", defaultValue: 10 }
            after: { type: "String" }
            where: { type: "CqlFilterEngagementInput!" }
            orderBy: { type: "[CqlOrderEngagementInput!]" }
          ) {
            engagements(
              first: $count
              after: $after
              where: $where
              orderBy: $orderBy
            ) @connection(key: "ShiftGigsTab_engagements", filters: ["where"]) {
              edges {
                node {
                  id
                  ...WorkerEngagementCardV2_engagement
                }
              }
            }
          }
        `}
        getData={(result) => getConnectionNodes(result?.engagements)}
        keyExtractor={(node) => node.id}
        renderHeaderView={() => (
          <>
            <ShiftGigsFilter
              active={active}
              onChange={onChange}
              getCount={getCount}
            />
            <Spacer size="compact" />
          </>
        )}
        renderFooterView={(isLoading) => (
          <MyGigsFooter
            variant="shifts"
            active={active}
            onChange={onChange}
            isEmpty={shiftsCount === 0}
            isLoading={isLoading}
          />
        )}
        renderEmptyView={(isLoading) => (
          <MyGigsEmptyList
            variant="shifts"
            isLoading={isLoading}
            active={active}
          />
        )}
        renderItem={(item) => (
          <WorkerEngagementCardV2
            fragmentRef={item}
            onPress={() => onPressItem(item.id)}
          />
        )}
      />
    );
  },
  {
    query: graphql`
      query ShiftGigsTabQuery(
        $where: CqlFilterEngagementInput!
        $orderBy: [CqlOrderEngagementInput!]
      ) {
        viewer {
          ... on Worker {
            ...useWorkerEngagementSummary_worker
            ...ShiftGigsTab_worker @arguments(where: $where, orderBy: $orderBy)
          }
        }
      }
    `,
    variables: ({ active }) => useMemo(() => makeShiftQuery(active), [])
  }
);
