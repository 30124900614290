/**
 * @generated SignedSource<<d10516fa4122f8783c5c2fd7237417bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
export type VerificationReportState = "AWAITING_DEPENDENCY" | "CANCELED" | "COMPLETE" | "EXPIRED" | "FAILED" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
export type VerificationReportStatus = "CANCELED" | "EXPIRED" | "FAIL" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PASS" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
export type WorkerVerificationStatus = "ENROLLED" | "NOT_ELIGIBLE" | "NOT_ENROLLED" | "PENDING" | "REQUIRED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VerificationCenterCard_worker$data = {
  readonly legalInformation: {
    readonly backgroundCheckOptIn: boolean;
    readonly driverCheckOptIn: boolean;
    readonly drugScreenOptIn: boolean;
    readonly id: string;
  } | null | undefined;
  readonly verification: {
    readonly reports: ReadonlyArray<{
      readonly state: VerificationReportState;
      readonly status: VerificationReportStatus;
      readonly statusDescription: string | null | undefined;
      readonly type: ReportType;
    }>;
    readonly status: WorkerVerificationStatus;
  };
  readonly " $fragmentType": "VerificationCenterCard_worker";
};
export type VerificationCenterCard_worker$key = {
  readonly " $data"?: VerificationCenterCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerificationCenterCard_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VerificationCenterCard_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerVerification",
      "kind": "LinkedField",
      "name": "verification",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "VerificationReport",
          "kind": "LinkedField",
          "name": "reports",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "statusDescription",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerLegalInformation",
      "kind": "LinkedField",
      "name": "legalInformation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundCheckOptIn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "driverCheckOptIn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "drugScreenOptIn",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "c14133b6cc27de3b3a1e64d0ef1a87cf";

export default node;
