/**
 * @generated SignedSource<<ac2b2790774532580b0e9a436719730e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftEarningsTabContent_worker$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountBalanceCard_worker" | "EarningsAndTransfersCard_worker" | "LinkedAccountsCard_worker">;
  readonly " $fragmentType": "ShiftEarningsTabContent_worker";
};
export type ShiftEarningsTabContent_worker$key = {
  readonly " $data"?: ShiftEarningsTabContent_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftEarningsTabContent_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftEarningsTabContent_worker",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountBalanceCard_worker"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LinkedAccountsCard_worker"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EarningsAndTransfersCard_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "8eccbb16fae29afd659a960d1ae3a107";

export default node;
