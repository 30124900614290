/**
 * @generated SignedSource<<91e1d400fcfee406339cede839c2bdb6>>
 * @relayHash 7a62bd0d9160b4bfc3bd84e2ebe3aaa2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:ecyjFIwIXxS2B99X9wTL0xLELinr8NNi3JNM9XtkLt0

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type GigType = "PAID" | "PROJECT" | "VOLUNTEER" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
export type BrowseShiftDetailsScreenQuery$variables = {
  id: string;
  isSeries: boolean;
};
export type BrowseShiftDetailsScreenQuery$data = {
  readonly viewer: {
    readonly gig?: {
      readonly currentState?: {
        readonly action: EngagementStateAction;
        readonly name: EngagementStateName;
      };
      readonly estimatedPayment?: {
        readonly netPay: string;
      } | null | undefined;
      readonly gig?: {
        readonly gigType: GigType | null | undefined;
        readonly organization: {
          readonly id: string;
        } | null | undefined;
        readonly payRate: string | null | undefined;
        readonly requiredReportTypes: ReadonlyArray<ReportType>;
        readonly " $fragmentSpreads": FragmentRefs<"AvailableShiftsSummary_shift" | "BlockOrganizationCollapsibleRow_shift" | "BrowseShiftHeader_shift" | "BrowseShiftTopAccessory_shift" | "ShiftDescriptionCard_shift" | "ShiftDetailsCard_shift" | "W2ShiftsCard_shift" | "WorkerApplyFooter_gigLike">;
      };
      readonly id?: string;
      readonly node?: {
        readonly gigType: GigType | null | undefined;
        readonly organization: {
          readonly id: string;
        } | null | undefined;
        readonly payRate: string | null | undefined;
        readonly requiredReportTypes?: ReadonlyArray<ReportType>;
        readonly " $fragmentSpreads": FragmentRefs<"AvailableShiftsSummary_shift" | "BlockOrganizationCollapsibleRow_shift" | "BrowseShiftHeader_shift" | "BrowseShiftTopAccessory_shift" | "ShiftDescriptionCard_shift" | "ShiftDetailsCard_shift" | "W2ShiftsCard_shift" | "WorkerApplyFooter_gigLike">;
      } | null | undefined;
      readonly workerDistance?: number | null | undefined;
    } | null | undefined;
    readonly gigSeries?: {
      readonly estimatedPaymentRange: {
        readonly max: string;
        readonly min: string;
      } | null | undefined;
      readonly gigDistance: {
        readonly min: number;
      } | null | undefined;
      readonly node: {
        readonly gigType: GigType | null | undefined;
        readonly organization: {
          readonly id: string;
        } | null | undefined;
        readonly payRate: string | null | undefined;
        readonly requiredReportTypes: ReadonlyArray<ReportType>;
        readonly " $fragmentSpreads": FragmentRefs<"AvailableShiftsSummary_shift" | "BlockOrganizationCollapsibleRow_shift" | "BrowseShiftHeader_shift" | "BrowseShiftTopAccessory_shift" | "ShiftDescriptionCard_shift" | "ShiftDetailsCard_shift" | "W2ShiftsCard_shift" | "WorkerApplyFooter_gigLike">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type BrowseShiftDetailsScreenQuery = {
  response: BrowseShiftDetailsScreenQuery$data;
  variables: BrowseShiftDetailsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSeries"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DistanceRange",
  "kind": "LinkedField",
  "name": "gigDistance",
  "plural": false,
  "selections": [
    {
      "alias": "min",
      "args": null,
      "kind": "ScalarField",
      "name": "minDistance",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MoneyRange",
  "kind": "LinkedField",
  "name": "estimatedPaymentRange",
  "plural": false,
  "selections": [
    {
      "alias": "min",
      "args": null,
      "kind": "ScalarField",
      "name": "minAmount",
      "storageKey": null
    },
    {
      "alias": "max",
      "args": null,
      "kind": "ScalarField",
      "name": "maxAmount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "kind": "InlineDataFragmentSpread",
  "name": "BlockOrganizationCollapsibleRow_shift",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        (v7/*: any*/)
      ],
      "type": "GigLike",
      "abstractKey": "__isGigLike"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "BrowseShiftHeader_shift"
},
v10 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ShiftDescriptionCard_shift"
},
v11 = {
  "args": [
    {
      "kind": "Literal",
      "name": "isBrowsing",
      "value": true
    }
  ],
  "kind": "FragmentSpread",
  "name": "ShiftDetailsCard_shift"
},
v12 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AvailableShiftsSummary_shift"
},
v13 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "BrowseShiftTopAccessory_shift"
},
v14 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "WorkerApplyFooter_gigLike"
},
v15 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "W2ShiftsCard_shift"
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": [
    (v21/*: any*/)
  ],
  "storageKey": null
},
v23 = {
  "alias": "workerDistance",
  "args": null,
  "kind": "ScalarField",
  "name": "distance",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v19/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 0
        }
      ],
      "concreteType": "EngagementReviewsConnection",
      "kind": "LinkedField",
      "name": "reviewsReceived",
      "plural": false,
      "selections": [
        (v25/*: any*/)
      ],
      "storageKey": "reviewsReceived(first:0)"
    }
  ],
  "storageKey": null
},
v27 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v5/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationRequester",
  "kind": "LinkedField",
  "name": "primaryContact",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptsTips",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedMileage",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "GigCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v33 = [
  (v19/*: any*/),
  (v5/*: any*/)
],
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "GigPosition",
  "kind": "LinkedField",
  "name": "position",
  "plural": false,
  "selections": (v33/*: any*/),
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationLocation",
  "kind": "LinkedField",
  "name": "organizationLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "place",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v35/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "concreteType": "Crisis",
  "kind": "LinkedField",
  "name": "crisis",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "concreteType": "RemoteApplicationTag",
  "kind": "LinkedField",
  "name": "customTags",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteApplicationIcon",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slots",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "addonType",
      "value": "EOR_WORKERS"
    }
  ],
  "concreteType": "GigAddon",
  "kind": "LinkedField",
  "name": "addon",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addonType",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": "addon(addonType:\"EOR_WORKERS\")"
},
v42 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v43 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v5/*: any*/)
],
v44 = {
  "alias": null,
  "args": (v42/*: any*/),
  "concreteType": "GigPhotosConnection",
  "kind": "LinkedField",
  "name": "photos",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GigPhotosEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GigPhoto",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v43/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "photos(first:10)"
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupEligible",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestArrivalTime",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "concreteType": "Pickup",
  "kind": "LinkedField",
  "name": "pickup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eligible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "windowEnd",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "GigState",
  "kind": "LinkedField",
  "name": "currentState",
  "plural": false,
  "selections": (v33/*: any*/),
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredPermissions",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boosted",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationPosition",
  "kind": "LinkedField",
  "name": "organizationPosition",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v42/*: any*/),
      "concreteType": "OrganizationPositionPhotosConnection",
      "kind": "LinkedField",
      "name": "photos",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationPositionPhotosEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationPositionPhoto",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v43/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "photos(first:10)"
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v54 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distance",
  "storageKey": null
},
v56 = [
  (v17/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paySchedule",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "billableDuration",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "grossPay",
    "storageKey": null
  },
  (v21/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "WorkerServiceFee",
    "kind": "LinkedField",
    "name": "serviceFees",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hourlyRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feeType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v57 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": (v56/*: any*/),
  "storageKey": null
},
v58 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "TypeDiscriminator",
              "abstractKey": "__isGigLike"
            }
          ],
          "storageKey": null
        },
        (v57/*: any*/)
      ],
      "type": "AvailableGigsEdge",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v57/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentInfo",
          "kind": "LinkedField",
          "name": "paymentInfo",
          "plural": false,
          "selections": (v56/*: any*/),
          "storageKey": null
        }
      ],
      "type": "Engagement",
      "abstractKey": null
    }
  ],
  "type": "AvailableGigOrEngagement",
  "abstractKey": "__isAvailableGigOrEngagement"
},
v59 = {
  "kind": "InlineFragment",
  "selections": [
    (v51/*: any*/),
    (v45/*: any*/),
    (v46/*: any*/),
    (v47/*: any*/),
    (v49/*: any*/),
    (v39/*: any*/),
    (v40/*: any*/),
    (v41/*: any*/),
    (v44/*: any*/),
    (v48/*: any*/),
    (v50/*: any*/)
  ],
  "type": "Gig",
  "abstractKey": null
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "nextGig",
      "args": (v54/*: any*/),
      "concreteType": "AvailableGigsConnection",
      "kind": "LinkedField",
      "name": "availableGigs",
      "plural": false,
      "selections": [
        (v25/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableGigsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Gig",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v4/*: any*/),
                (v16/*: any*/),
                (v51/*: any*/),
                (v45/*: any*/),
                (v46/*: any*/),
                (v47/*: any*/),
                (v49/*: any*/),
                (v17/*: any*/),
                (v50/*: any*/)
              ],
              "storageKey": null
            },
            (v55/*: any*/),
            (v58/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "availableGigs(first:1)"
    },
    (v40/*: any*/),
    (v52/*: any*/),
    (v39/*: any*/),
    (v53/*: any*/)
  ],
  "type": "GigSeries",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrowseShiftDetailsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "AvailableGigSeriesEdge",
                    "kind": "LinkedField",
                    "name": "gigSeries",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigSeries",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v4/*: any*/),
                          (v17/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v14/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v15/*: any*/),
                              (v4/*: any*/),
                              (v17/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Engagement",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v14/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v15/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v16/*: any*/)
                                ],
                                "type": "GigLike",
                                "abstractKey": "__isGigLike"
                              },
                              (v4/*: any*/),
                              (v17/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AvailableGigsEdge",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrowseShiftDetailsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v24/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "AvailableGigSeriesEdge",
                    "kind": "LinkedField",
                    "name": "gigSeries",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigSeries",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v4/*: any*/),
                          (v17/*: any*/),
                          (v7/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v26/*: any*/),
                              (v19/*: any*/),
                              (v28/*: any*/),
                              (v29/*: any*/),
                              (v30/*: any*/),
                              (v31/*: any*/),
                              (v32/*: any*/),
                              (v34/*: any*/),
                              (v35/*: any*/),
                              (v36/*: any*/),
                              (v37/*: any*/),
                              (v24/*: any*/),
                              (v38/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v39/*: any*/),
                                  (v40/*: any*/),
                                  (v41/*: any*/),
                                  (v44/*: any*/),
                                  (v45/*: any*/),
                                  (v46/*: any*/),
                                  (v47/*: any*/),
                                  (v48/*: any*/),
                                  (v49/*: any*/),
                                  (v50/*: any*/),
                                  (v51/*: any*/)
                                ],
                                "type": "Gig",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v40/*: any*/),
                                  (v52/*: any*/),
                                  (v39/*: any*/),
                                  (v53/*: any*/),
                                  {
                                    "alias": "nextGig",
                                    "args": (v54/*: any*/),
                                    "concreteType": "AvailableGigsConnection",
                                    "kind": "LinkedField",
                                    "name": "availableGigs",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AvailableGigsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          (v55/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Gig",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              (v45/*: any*/),
                                              (v5/*: any*/),
                                              (v16/*: any*/),
                                              (v46/*: any*/),
                                              (v47/*: any*/),
                                              (v49/*: any*/),
                                              (v50/*: any*/),
                                              (v4/*: any*/),
                                              (v51/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v58/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v25/*: any*/)
                                    ],
                                    "storageKey": "availableGigs(first:1)"
                                  }
                                ],
                                "type": "GigSeries",
                                "abstractKey": null
                              }
                            ],
                            "type": "GigLike",
                            "abstractKey": "__isGigLike"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "isSeries",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v4/*: any*/),
                              (v17/*: any*/),
                              (v7/*: any*/),
                              (v5/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v26/*: any*/),
                                  (v19/*: any*/),
                                  (v28/*: any*/),
                                  (v24/*: any*/),
                                  (v29/*: any*/),
                                  (v30/*: any*/),
                                  (v31/*: any*/),
                                  (v32/*: any*/),
                                  (v34/*: any*/),
                                  (v35/*: any*/),
                                  (v36/*: any*/),
                                  (v37/*: any*/),
                                  (v38/*: any*/),
                                  (v59/*: any*/),
                                  (v60/*: any*/)
                                ],
                                "type": "GigLike",
                                "abstractKey": "__isGigLike"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Engagement",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v17/*: any*/),
                              (v7/*: any*/),
                              (v5/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v26/*: any*/),
                                  (v19/*: any*/),
                                  (v16/*: any*/),
                                  (v28/*: any*/),
                                  (v24/*: any*/),
                                  (v29/*: any*/),
                                  (v30/*: any*/),
                                  (v31/*: any*/),
                                  (v32/*: any*/),
                                  (v34/*: any*/),
                                  (v35/*: any*/),
                                  (v36/*: any*/),
                                  (v37/*: any*/),
                                  (v38/*: any*/),
                                  (v59/*: any*/),
                                  (v60/*: any*/)
                                ],
                                "type": "GigLike",
                                "abstractKey": "__isGigLike"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AvailableGigsEdge",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v6/*: any*/),
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:ecyjFIwIXxS2B99X9wTL0xLELinr8NNi3JNM9XtkLt0",
    "metadata": {},
    "name": "BrowseShiftDetailsScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "06da041664206baa17c8736bf8b0d047";

export default node;
