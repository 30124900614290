/**
 * @generated SignedSource<<6ca6b3578574d4268a3b76976656649c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementPaymentInfoStatus = "ACCURATE" | "FINAL" | "INACCURATE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DisputedTimesheetProjectionsSurface_Engagement$data = {
  readonly paymentInfo: {
    readonly payRate: string;
    readonly serviceFees: ReadonlyArray<{
      readonly amount: string;
      readonly feeType: EngagementAddonFeeType;
      readonly hourlyRate: string | null | undefined;
    }>;
    readonly status: EngagementPaymentInfoStatus;
  } | null | undefined;
  readonly " $fragmentType": "DisputedTimesheetProjectionsSurface_Engagement";
};
export type DisputedTimesheetProjectionsSurface_Engagement$key = {
  readonly " $data"?: DisputedTimesheetProjectionsSurface_Engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisputedTimesheetProjectionsSurface_Engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisputedTimesheetProjectionsSurface_Engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerServiceFee",
          "kind": "LinkedField",
          "name": "serviceFees",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hourlyRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "1e76ce19217ac4914c408f9a50f31685";

export default node;
