import { addDelimiter } from "@gigsmart/isomorphic-shared/worker/helpers";
import type { ObjectPath } from "@gigsmart/type-utils";
import { startCase, upperCase } from "lodash";
import moment from "moment-timezone";
import { Dimensions } from "react-native";
import type { LinkedAccountsCard_workerPayableAccount$data } from "./__generated__/LinkedAccountsCard_workerPayableAccount.graphql";

export type PayableAccount = ObjectPath<
  LinkedAccountsCard_workerPayableAccount$data,
  ["paymentMethod"]
>;

const { width } =
  process.env.NODE_ENV === "test" ? { width: 400 } : Dimensions.get("window"); // Dimensions doesnt work in tests due to a bug in react 61.5
const dateFormat = width <= 375 ? "MM/DD/YY" : "MM/DD/YYYY";

export const formatDate = (date?: string) =>
  date ? moment(date).format(dateFormat) : "N/A";

export const todayOrFutureDate = (date?: string | null) => {
  if (!date) return "N/A";
  return moment(date).isSame(moment(), "day")
    ? "Today"
    : moment(date).format(dateFormat);
};

export const formatPaymentAmount = (price: null | undefined | number) =>
  price ? `$${addDelimiter(price.toFixed(2))}` : "";

export const formatBalance = (balance: number): string =>
  addDelimiter((Math.floor(balance * 100) / 100).toFixed(2));

export const toFixedWithoutRounding = (balance: number) =>
  Number((Math.floor(balance * 100) / 100).toFixed(2));

export const formatPaymentMethod = (data: PayableAccount) => {
  if (!data) return "";

  const { __typename, brand, bankName, last4 } = data;
  const parts: string[] = [];

  if (__typename === "BankCard") {
    if (brand) parts.push(upperCase(brand));
    parts.push("DEBIT");
  }
  if (__typename === "BankAccount" && bankName) {
    parts.push(upperCase(bankName));
  }
  parts.push(`*${last4}`);

  return parts.join(" ");
};

export const formatStatusText = (text?: string) =>
  text ? text.split("_").map(startCase).join(" ") : "";
