import firebase from "@react-native-firebase/app";
import React, { useCallback, useEffect, useRef } from "react";
import { AppState, Linking, Platform } from "react-native";
import "@react-native-firebase/messaging";
import { toast, usePermission } from "@gigsmart/atorasu";
import { captureError, createConversion } from "@gigsmart/dekigoto";
import { useHistory } from "@gigsmart/kaizoku";
import { graphql, useRelayMutation } from "@gigsmart/relay";
import { createLogger } from "@gigsmart/roga";
import type { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import { getNonFieldErrors } from "../debug/errors";
import type { notificationsRegisterDeviceMutation } from "./__generated__/notificationsRegisterDeviceMutation.graphql";

export type RemoteMessage = FirebaseMessagingTypes.RemoteMessage;

const logger = createLogger("🔔", "Push Notifications");

export interface NotificationAlike {
  title?: string | null;
  body?: string | null;
  data?: Record<string, string | object>;
  notificationId?: string;
  alreadyViewed?: boolean;
}

export interface PushNotificationProps {
  onNotification: (
    notification: NotificationAlike,
    fromBackground: boolean
  ) => unknown;
}

const notificationConversion = createConversion("Received Push Notification");
const notificationRegistrationConversion = createConversion(
  "Registered for Push Notifications"
);

type Props = {
  enable: boolean;
  requestPermission?: boolean;
  onNotification: (
    notification: NotificationAlike,
    fromBackground: boolean
  ) => void;
};

type CustomMessageEvent = MessageEvent & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

const isWeb = Platform.OS === "web";

const serviceWorkerOnMessage = (
  handler: (message: CustomMessageEvent) => void
) => {
  navigator.serviceWorker.addEventListener("message", handler);
  return () => navigator.serviceWorker.removeEventListener("message", handler);
};

let messaging: ReturnType<typeof firebase.messaging> | undefined;
try {
  messaging = firebase.messaging();
} catch (_e) {
  /* noop */
}

export const getToken = async () => {
  if (!messaging?.isDeviceRegisteredForRemoteMessages) return;
  return await messaging.getToken().catch(() => null);
};

const handledNotifications: string[] = [];

export function PushNotificationsListener({
  requestPermission,
  enable: shouldEnable,
  onNotification
}: Props) {
  const handlers = React.useRef<Array<() => void>>([]);
  const history = useHistory();
  const permission = usePermission("notification", {
    trace: "PushNotificationsListener",
    init: requestPermission && shouldEnable ? "request" : "none",
    preview: true
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMutationError = useCallback(({ res }: any) => {
    const message = "Error registering your device for push notifications.";
    const detail = res?.errors
      ? getNonFieldErrors(res.errors)
      : "unknown error";
    captureError(new Error(`${message} ${detail}`));
    toast.error(
      { title: message, detail },
      {
        label: "notifications-error"
      }
    );
  }, []);

  const [updateDeviceToken] =
    useRelayMutation<notificationsRegisterDeviceMutation>(
      graphql`
        mutation notificationsRegisterDeviceMutation(
          $input: UpdateUserDeviceInput!
        ) {
          updateUserDevice(input: $input) {
            userDevice {
              pushRegistrationToken
              active
            }
          }
        }
      `,
      { onError: handleMutationError }
    );

  const handleTokenRefresh = useCallback(
    async (token?: string) => {
      if (!messaging || !permission.has) return;
      try {
        const pushRegistrationToken = token ?? (await getToken());
        if (!token) return;
        await notificationRegistrationConversion.track({
          pushRegistrationToken
        });
        logger.wrap(
          updateDeviceToken,
          "updateDeviceToken"
        )({
          input: { pushRegistrationToken }
        });
      } catch (e) {
        captureError(e, "error");
      }
    },
    [updateDeviceToken, permission.has]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNotification = useCallback(
    logger.wrap(async function handleNotification(
      message: RemoteMessage,
      fromBackground = false
    ) {
      if (
        message.messageId &&
        !handledNotifications.includes(message.messageId)
      ) {
        void notificationConversion.track({
          message,
          fromBackground,
          isPush: true
        });
        if (
          typeof message?.data?.uri === "string" &&
          (await Linking.canOpenURL(message.data.uri))
        ) {
          const url = new URL(message.data.uri);
          if (
            url.protocol === "https:" &&
            url.host === process.env.UNIVERSAL_LINK_DOMAIN
          ) {
            history.push(url.pathname + url.search + url.hash);
          } else {
            void Linking.openURL(message.data.uri);
          }
          return;
        }
        const { notification, data, messageId } = message;
        const { title, body } = notification ?? {};
        onNotification(
          { title, body, data, notificationId: messageId },
          fromBackground
        );
        handledNotifications.push(message.messageId);
      }
    }),
    [history, onNotification]
  );

  const handleInitialNotification = useCallback(
    async (fromBackground = false) => {
      if (isWeb || !messaging) return;
      const message = await messaging.getInitialNotification();
      if (message) {
        await handleNotification(message, fromBackground);
      }
    },
    [handleNotification]
  );

  const handleServiceWorkerMessage = useCallback(
    (event: CustomMessageEvent) => {
      // A custom implementation of messaging.onMessage() to
      // receive messages sent from our firebase-messaging-sw.js
      const { data, fromNotifClickEvent, notificationId } =
        event?.data.firebaseMessaging?.payload ?? {};
      const { title, body } = data ?? {};
      if (
        !title ||
        !notificationId ||
        handledNotifications.includes(notificationId)
      )
        return;
      onNotification(
        { title, body, data, notificationId },
        !!fromNotifClickEvent
      );
      handledNotifications.push(notificationId);
    },
    [onNotification]
  );

  const handleAppStateChange = useCallback(
    (nextAppState: string) => {
      if (nextAppState === "active") void handleInitialNotification(true);
    },
    [handleInitialNotification]
  );

  const disable = useCallback(() => {
    while (handlers.current.length > 0) {
      const handler = handlers.current.pop();
      if (handler) handler();
    }
  }, []);

  const enable = useCallback(async () => {
    if (!messaging) return;

    disable();
    await handleTokenRefresh();
    await handleInitialNotification();

    if (isWeb) {
      handlers.current.push(
        messaging.onTokenRefresh(() => {
          void handleTokenRefresh();
        })
      );

      if (navigator.serviceWorker) {
        handlers.current.push(
          serviceWorkerOnMessage(handleServiceWorkerMessage)
        );
      }
    } else {
      handlers.current.push(messaging.onMessage(handleNotification));

      handlers.current.push(
        messaging.onNotificationOpenedApp((notificationOpened) => {
          void handleNotification(notificationOpened, true);
        })
      );

      handlers.current.push(
        messaging.onTokenRefresh((token) => {
          void handleTokenRefresh(token);
        })
      );
    }
  }, [
    disable,
    handleInitialNotification,
    handleNotification,
    handleServiceWorkerMessage,
    handleTokenRefresh
  ]);

  useEffect(
    () => AppState.addEventListener("change", handleAppStateChange).remove,
    [handleAppStateChange]
  );

  useEffect(() => {
    shouldEnable && permission.has ? void enable() : disable();
  }, [shouldEnable, permission.has, enable, disable]);

  useEffect(() => disable, [disable]);

  return null;
}

export function useSubscribeToTopic(
  name: string,
  subscribe: boolean,
  onError?: (error: Error) => void
) {
  const chainRef = useRef(Promise.resolve());
  const chain = useCallback(
    (
      fn: (_: ReturnType<typeof firebase.messaging>) => Promise<void> | void
    ) => {
      chainRef.current = Promise.race([
        chainRef.current.then(async () => messaging && (await fn(messaging))),
        new Promise<void>((resolve, reject) =>
          setTimeout(reject, 5000, new Error("Timeout"))
        )
      ])
        .catch(onError ?? logger.error)
        .then(() => void 0);
    },
    []
  );

  const sub = useCallback(() => {
    logger.info("subscribing to topic", name);
    chain(async (m) => await m.subscribeToTopic(name));
    return unsub;
  }, [name]);

  const unsub = useCallback(() => {
    logger.info("unsubscribing to topic", name);
    chain(async (m) => await m.unsubscribeFromTopic(name));
  }, [name]);

  useEffect(() => {
    return subscribe ? sub() : unsub();
  }, [subscribe, sub, unsub]);
}
