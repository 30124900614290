import { sortBy } from "lodash";
import React, { useEffect } from "react";

import { Stack, Surface, Text, useStepper } from "@gigsmart/atorasu";
import { Form, FormSubmit, FormToggle } from "@gigsmart/fomu";
import type { ReportType } from "@gigsmart/isomorphic-shared/report/types";
import { NavPortalEntrance, defaultBackHandler } from "@gigsmart/kaizoku";
import {
  ResponsiveStepperBtnPortal,
  type Step
} from "@gigsmart/seibutsu/shared/Stepper";
import StepperNavActions from "../../shared/Stepper/StepperNavActions";
import { showVerificationIntroductionModal } from "../../worker-verification/VerificationIntroductionModal";
import OptInRow from "./OptInRow";

interface OptIn {
  title: string;
  description: string;
  required?: boolean;
  fieldName?: string;
}

interface Props {
  requiredReportTypes: readonly ReportType[];
  hasMissingOptIns?: boolean;
  onSubmit: (nextStep: (data: any) => void, data: any) => void;
}

export default function VerificationOptInStep({
  hasMissingOptIns,
  requiredReportTypes,
  onSubmit
}: Props) {
  const { nextStep, prevStep, stepsData } = useStepper<Step, {}>();

  const optIns: OptIn[] = sortBy(
    [
      {
        title: "Financial Verification",
        description: "Verify key account information to ensure you get paid.",
        required: true
      },
      {
        title: "Background Checks",
        fieldName: "backgroundCheckOptIn",
        description:
          "Verify your background so you are eligible to work gigs that require background checks.",
        required: requiredReportTypes.includes("BACKGROUND_CHECK")
      },
      {
        title: "MVR Checks",
        fieldName: "driverCheckOptIn",
        description:
          "Verify your driving record so you are eligible to work gigs that require Motor Vehicle Record checks.",
        required: requiredReportTypes.includes("MOTOR_VEHICLE_CHECK")
      },
      {
        title: "Drug Screens",
        fieldName: "drugScreenOptIn",
        description: "Pass an on-site 9-panel comprehensive drug screening.",
        required: requiredReportTypes.includes("DRUG_SCREEN")
      }
    ],
    (o) => (o.required ? -1 : 1)
  );
  const last = optIns.length - 1;

  useEffect(() => {
    if (hasMissingOptIns) {
      showVerificationIntroductionModal({
        onDeny: () => defaultBackHandler({ safeExit: true })
      });
    }
  }, []);

  return (
    <>
      <StepperNavActions />
      <Form
        onSubmit={({ values, valid }) => {
          if (!valid) return;
          onSubmit(nextStep, {
            ...stepsData,
            driverCheckOptIn:
              requiredReportTypes.includes("MOTOR_VEHICLE_CHECK") ||
              values.driverCheckOptIn,
            backgroundCheckOptIn:
              requiredReportTypes.includes("BACKGROUND_CHECK") ||
              values.backgroundCheckOptIn,
            drugScreenOptIn:
              requiredReportTypes.includes("DRUG_SCREEN") ||
              values.drugScreenOptIn
          });
        }}
      >
        <Stack>
          <Text>
            Your information is secure and used only for verification purposes.
          </Text>
          <Surface variant="outline" testID="verification-optin-step-card">
            {optIns.map(({ fieldName, title, description, required }, index) =>
              !fieldName || required ? (
                <OptInRow
                  key={title}
                  title={`${title} (required)`}
                  description={description}
                  checked
                  disabled
                  border={index < last}
                  testID={`${fieldName}-toggle`}
                />
              ) : (
                <FormToggle key={title} name={fieldName}>
                  {({ value, toggle }) => (
                    <OptInRow
                      title={title}
                      description={description}
                      onPress={() => {
                        toggle();
                      }}
                      checked={value}
                      border={index < last}
                      testID={`${fieldName}-toggle`}
                    />
                  )}
                </FormToggle>
              )
            )}
          </Surface>
        </Stack>
        <FormSubmit>
          {({ invalid, submit }) => {
            return (
              <>
                <NavPortalEntrance onBackPress={prevStep} />
                <ResponsiveStepperBtnPortal.Entrance
                  testID="verification-optin-step-gig-stepper-next-button"
                  label="Select & Continue"
                  disabled={invalid}
                  onPress={submit}
                />
              </>
            );
          }}
        </FormSubmit>
      </Form>
    </>
  );
}
