/**
 * @generated SignedSource<<7c3e55fce8f91cdcc2ddec312f50c1bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftPreviewList_pickup_availableGigs$data = {
  readonly edges: ReadonlyArray<{
    readonly estimatedPayment?: {
      readonly netPay: string;
    } | null | undefined;
    readonly node: {
      readonly gigSeries: {
        readonly boosted: boolean;
      };
      readonly id: string;
      readonly payRate: string | null | undefined;
      readonly pickup: {
        readonly eligible: boolean;
      };
      readonly requiredReportTypes: ReadonlyArray<ReportType>;
      readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewRow_shift">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "ShiftPreviewList_pickup_availableGigs";
};
export type ShiftPreviewList_pickup_availableGigs$key = {
  readonly " $data"?: ShiftPreviewList_pickup_availableGigs$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewList_pickup_availableGigs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isEor"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftPreviewList_pickup_availableGigs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailableGigsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "condition": "isEor",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementPaymentInfo",
              "kind": "LinkedField",
              "name": "estimatedPayment",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPay",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gig",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShiftPreviewRow_shift"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requiredReportTypes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GigSeries",
              "kind": "LinkedField",
              "name": "gigSeries",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "boosted",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Pickup",
              "kind": "LinkedField",
              "name": "pickup",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eligible",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AvailableGigsConnection",
  "abstractKey": null
};

(node as any).hash = "164ae08d2d1de8925ea06fbb6d1b5562";

export default node;
