import { IconText, Spacer, Stack, Text } from "@gigsmart/atorasu";
import { type ViewStyle, useStyles } from "@gigsmart/atorasu/style";
import { ProjectGigsPayInApp } from "@gigsmart/feature-flags";
import {
  BulletList,
  BulletListItem,
  Card,
  SmallOutlineButton
} from "@gigsmart/katana";
import React from "react";

interface Props {
  onClose?: () => void;
}
const ProjectGigsInfoDismissableCard = ({ onClose }: Props) => {
  const styles = useStyles(({ getUnits }) => ({
    card: {
      paddingHorizontal: getUnits(4)
    },
    icon: {
      fontSize: 24
    }
  }));
  return (
    <Card
      style={styles.card}
      onClose={onClose}
      iconStyle={styles.icon as ViewStyle}
    >
      <Stack>
        <Spacer size="none" />
        <Stack>
          <IconText icon="pen-ruler">
            <Text variant="bodyLg" weight="semibold">
              Project Gigs
            </Text>
          </IconText>
          <Text>
            Work Project Gigs on-site or remotely. Submit bids and finalize your
            price and timeline directly with the Requester.
          </Text>
          <BulletList>
            <BulletListItem>
              <Text>Work Projects on-site or remotely</Text>
            </BulletListItem>
            <BulletListItem>
              <Text>Negotiate your pay rate with the Requester</Text>
            </BulletListItem>
            <BulletListItem>
              <Text>
                Discuss and agree upon the project timeline with the Requester
              </Text>
            </BulletListItem>
            <BulletListItem>
              <Text>
                Get paid via{" "}
                {ProjectGigsPayInApp.isEnabled() ? "the app, " : ""}
                cash, check, or digital transfer
              </Text>
            </BulletListItem>
          </BulletList>
        </Stack>
        <SmallOutlineButton fill title="Got It" onPress={onClose} />
      </Stack>
    </Card>
  );
};

export default ProjectGigsInfoDismissableCard;
