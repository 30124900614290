import {
  Button,
  Carousel,
  Column,
  ContentArea,
  DocumentTile,
  FancyHeader,
  GoogleMapMarker,
  GridNull,
  MaxWidthContainer,
  Pressable,
  Row,
  Surface,
  Text,
  WysiwygContent,
  showPDFViewer
} from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import type { EngagementStateName } from "@gigsmart/isomorphic-shared/gig/helpers";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showLocation } from "react-native-map-link";
import { ShiftLocationMap } from "../gig/ShiftLocationMap";
import type { ClockinLocationCard_organizationLocation$key } from "./__generated__/ClockinLocationCard_organizationLocation.graphql";

type Props = {
  currentState: EngagementStateName;
  isEOR?: boolean;
};

export const ClockinLocationCard = ({
  id,
  place,
  arrivalInstructions,
  locationInstructions,
  attachments,
  currentState,
  isEOR
}: FragmentContainerInnerComponentProps<
  ClockinLocationCard_organizationLocation$key,
  Props
>) => {
  const attachmentNodes = getConnectionNodes(attachments);
  const imageAttachments = attachmentNodes
    .filter((item) => item.mimeType !== "application/pdf")
    .map((item) => item.url);
  const fileAttachments = attachmentNodes.filter(
    (item) => item.mimeType === "application/pdf"
  );

  const handleShowLocation = () => {
    void showLocation({
      latitude: place?.location?.latitude ?? "",
      longitude: place?.location?.longitude ?? "",
      googlePlaceId: place?.id ?? "",
      alwaysIncludeGoogle: true,
      dialogTitle: "Get Directions",
      dialogMessage: "Select a preferred navigation app"
    });
  };

  if (
    LocationUpdates.isDisabled() ||
    !place ||
    ["CANCELED", "CANCELED_WITH_PAY"].includes(currentState)
  )
    return <GridNull />;

  return (
    <Surface>
      <FancyHeader
        icon="clock"
        title="Clock-In Location"
        color={isEOR ? "purple" : "primary"}
        variant={isEOR ? "purple" : undefined}
      />
      <ContentArea gap="standard">
        <Row gap="standard">
          <Column justifyContent="center" fill>
            <Text>{place?.streetAddress}</Text>
          </Column>
          <Button
            label="Directions"
            outline
            size="small"
            onPress={handleShowLocation}
            testID="clock-location-directions-button"
          />
        </Row>
        <Column gap="standard">
          <ShiftLocationMap
            center={place?.location ?? { latitude: 0, longitude: 0 }}
          >
            <GoogleMapMarker
              center={place?.location ?? { latitude: 0, longitude: 0 }}
            />
          </ShiftLocationMap>
          {!!arrivalInstructions && (
            <Text>
              <Text weight="bold">Arrival Instructions: </Text>
              {arrivalInstructions}
            </Text>
          )}
          {locationInstructions ? (
            <Column gap="slim">
              <Text weight="bold">Location Information: </Text>
              <WysiwygContent html={locationInstructions} />
            </Column>
          ) : (
            <Text>
              <Text weight="bold">Location Information: </Text>None
            </Text>
          )}
          {!!imageAttachments.length && (
            <Carousel sources={imageAttachments} thumbSize={32.5} />
          )}
          {fileAttachments.map(({ url, filename }, index: number) => (
            <MaxWidthContainer key={url} maxWidth={180}>
              <Column>
                <Pressable
                  testID="select-message-attachment"
                  eventEntityType="Pressable"
                  eventTargetName="SelectMessageAttachment"
                  onPress={() =>
                    showPDFViewer({
                      source: { uri: url ?? "" },
                      filename: filename ?? "Document.pdf"
                    })
                  }
                >
                  <Surface variant="flat" color="background">
                    <DocumentTile
                      name={filename ?? `Attachment ${index + 1}`}
                      type="PDF"
                      icon="file-pdf"
                    />
                  </Surface>
                </Pressable>
              </Column>
            </MaxWidthContainer>
          ))}
        </Column>
      </ContentArea>
    </Surface>
  );
};

export default createRelayFragmentContainer<
  ClockinLocationCard_organizationLocation$key,
  Props
>(
  graphql`
    fragment ClockinLocationCard_organizationLocation on OrganizationLocation {
      id
      arrivalInstructions
      locationInstructions
      place {
        id
        streetAddress
        location {
          latitude
          longitude
        }
      }
      attachments(first: 50) {
          edges {
            node {
              id
              url
              filename
              mimeType
            }
          }
        }
    }
  `,
  ClockinLocationCard
);
