/**
 * @generated SignedSource<<48a4ff2f39ec0f0fa5bb2d6a108e5d33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CancellationReasonOptionVariant = "ISSUE_REPORTED" | "MISSING_QUALIFICATION" | "NO_SHOW" | "STANDARD" | "%future added value";
export type CancellationReasonType = "CANCELED" | "DID_NOT_WORK" | "ISSUE_WITH_WORKER" | "NO_LONGER_AVAILABLE" | "RECEIVED_ANOTHER_OFFER" | "REPORT_NO_SHOW" | "REQUESTER_UNRESPONSIVE" | "UNABLE_TO_FINALIZE" | "UNSPECIFIED" | "%future added value";
export type EngagementCapabilityStatus = "AVAILABLE" | "RESTRICTED" | "UNAVAILABLE" | "%future added value";
export type EngagementCapabilityType = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_SYSTEM_TIMESHEET" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CALL" | "CANCEL" | "CANCEL_ENGAGEMENT" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "DISPUTE_TIMESHEET" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MARK_AS_COMPLETE" | "MODIFY_BID" | "MODIFY_OFFER" | "NEGOTIATE" | "OFFER" | "PAUSE" | "PAY_WITHOUT_TIMESHEET" | "PAY_WORKER_AGAIN" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_START" | "RESUME" | "REVIEW" | "SEND_MESSAGE" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "VIEW_BADGES" | "VIEW_MESSAGE" | "VIEW_QUESTIONNAIRE" | "%future added value";
export type EngagementStateAction = "ACCEPT" | "ACCEPT_COMMITMENT" | "ACCEPT_OTHER" | "ACCEPT_PAUSE" | "APPLICATION_TIMEOUT" | "APPLY" | "APPROVE" | "APPROVE_GIG" | "APPROVE_TIMESHEET" | "ARRIVE" | "AUTO_ARRIVE" | "AUTO_BID" | "BECOME_AVAILABLE" | "BECOME_UNAVAILABLE" | "BID" | "CANCEL" | "CANCEL_WITH_PAY" | "COMMITMENT_TIMEOUT" | "CONFIRM" | "COUNTER_BID" | "COUNTER_OFFER" | "CREATED" | "DECLINE_COMMITMENT" | "DENY_APPLICATION" | "DISBURSE" | "EMBARK" | "END" | "ENGAGE" | "EXCEED_DURATION" | "EXCEED_LATEST_ARRIVAL_TIME" | "FAIL_PAYMENT" | "FAIL_PREAUTH" | "HIRE" | "INACTIVE_CANCEL" | "INACTIVE_END" | "MODIFY_BID" | "MODIFY_OFFER" | "OFFER" | "PAUSE" | "PAUSE_REQUEST_TIMEOUT" | "PAY" | "PAY_WITHOUT_TIMESHEET" | "PICKUP" | "REAPPLY" | "REBID" | "REJECT" | "REJECT_PAUSE" | "REOFFER" | "REQUEST_BID" | "REQUEST_HIRE" | "REQUEST_PAUSE" | "REQUEST_START" | "RESCIND" | "RESUME" | "START" | "TIMEOUT" | "TIMEOUT_CONFIRMATION" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkerEngagementCardHeader_engagement$data = {
  readonly approvedDisputeCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly cancellationReason: {
    readonly option: {
      readonly variant: CancellationReasonOptionVariant;
    };
    readonly reasonType: CancellationReasonType;
  } | null | undefined;
  readonly capabilities: ReadonlyArray<{
    readonly expiresAt: string | null | undefined;
    readonly restrictedBy: {
      readonly message: string;
    } | null | undefined;
    readonly status: EngagementCapabilityStatus;
    readonly type: EngagementCapabilityType;
  }>;
  readonly currentState: {
    readonly action: EngagementStateAction;
    readonly name: EngagementStateName;
  };
  readonly disputes: {
    readonly totalCount: number;
  } | null | undefined;
  readonly finalTimesheet: {
    readonly resolvedDisputesCount: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly gig: {
    readonly eorAddons: {
      readonly totalCount: number;
    } | null | undefined;
  };
  readonly id: string;
  readonly insertedAt: string;
  readonly rejectedDisputes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly approval: {
          readonly submittedBy: {
            readonly __typename: string;
          };
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly systemTimeSheetSubmittedByWorker: {
    readonly approvals: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly timeSheetCreatedByWorker: {
    readonly approvals: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WorkerEngagementCardHeader_engagement";
};
export type WorkerEngagementCardHeader_engagement$key = {
  readonly " $data"?: WorkerEngagementCardHeader_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerEngagementCardHeader_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkerEngagementCardHeader_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CancellationReason",
      "kind": "LinkedField",
      "name": "cancellationReason",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reasonType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CancellationReasonOption",
          "kind": "LinkedField",
          "name": "option",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "variant",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE approvalDisposition = NULL"
        }
      ],
      "concreteType": "EngagementDisputesConnection",
      "kind": "LinkedField",
      "name": "disputes",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = NULL\")"
    },
    {
      "alias": "approvedDisputeCount",
      "args": [
        (v3/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE approvalDisposition = APPROVED"
        }
      ],
      "concreteType": "EngagementDisputesConnection",
      "kind": "LinkedField",
      "name": "disputes",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "disputes(first:0,query:\"WHERE approvalDisposition = APPROVED\")"
    },
    {
      "alias": "rejectedDisputes",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE approvalDisposition = REJECTED"
        }
      ],
      "concreteType": "EngagementDisputesConnection",
      "kind": "LinkedField",
      "name": "disputes",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementDisputesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EngagementDispute",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementDisputeApproval",
                  "kind": "LinkedField",
                  "name": "approval",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "submittedBy",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = REJECTED\")"
    },
    {
      "alias": "finalTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "FINAL"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": "resolvedDisputesCount",
          "args": (v4/*: any*/),
          "concreteType": "EngagementDisputesConnection",
          "kind": "LinkedField",
          "name": "resolvedDisputes",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "resolvedDisputes(first:0)"
        }
      ],
      "storageKey": "timesheet(variant:\"FINAL\")"
    },
    {
      "alias": "systemTimeSheetSubmittedByWorker",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "SYSTEM"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            (v3/*: any*/),
            {
              "kind": "Literal",
              "name": "query",
              "value": "WHERE approvedByType = WORKER"
            }
          ],
          "concreteType": "EngagementTimesheetApprovalsConnection",
          "kind": "LinkedField",
          "name": "approvals",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "approvals(first:0,query:\"WHERE approvedByType = WORKER\")"
        }
      ],
      "storageKey": "timesheet(variant:\"SYSTEM\")"
    },
    {
      "alias": "timeSheetCreatedByWorker",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "WORKER"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v4/*: any*/),
          "concreteType": "EngagementTimesheetApprovalsConnection",
          "kind": "LinkedField",
          "name": "approvals",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "approvals(first:0)"
        }
      ],
      "storageKey": "timesheet(variant:\"WORKER\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementCapability",
      "kind": "LinkedField",
      "name": "capabilities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "restrictedBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expiresAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": "eorAddons",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "addonType": {
                  "_eq": "EOR_WORKERS"
                }
              }
            }
          ],
          "concreteType": "GigAddonsConnection",
          "kind": "LinkedField",
          "name": "addons",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "addons(first:1,where:{\"addonType\":{\"_eq\":\"EOR_WORKERS\"}})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "79383f5fce39f68efd2e292fba418caf";

export default node;
