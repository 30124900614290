/**
 * @generated SignedSource<<f5db6e18080c83a025193c7c9d2a0751>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
export type VerificationReportState = "AWAITING_DEPENDENCY" | "CANCELED" | "COMPLETE" | "EXPIRED" | "FAILED" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
export type VerificationReportStatus = "CANCELED" | "EXPIRED" | "FAIL" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PASS" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftInfoCollapsable_engagement$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly endsAt: string | null | undefined;
    readonly requiredReportTypes: ReadonlyArray<ReportType>;
    readonly startsAt: string | null | undefined;
  };
  readonly worker: {
    readonly verification: {
      readonly reports: ReadonlyArray<{
        readonly state: VerificationReportState;
        readonly status: VerificationReportStatus;
        readonly type: ReportType;
      }>;
    };
  };
  readonly " $fragmentType": "ShiftInfoCollapsable_engagement";
};
export type ShiftInfoCollapsable_engagement$key = {
  readonly " $data"?: ShiftInfoCollapsable_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftInfoCollapsable_engagement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftInfoCollapsable_engagement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredReportTypes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worker",
      "kind": "LinkedField",
      "name": "worker",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerVerification",
          "kind": "LinkedField",
          "name": "verification",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VerificationReport",
              "kind": "LinkedField",
              "name": "reports",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};

(node as any).hash = "843ca25e3332181fe7bf73d8bb50d5bf";

export default node;
