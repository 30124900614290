import {
  CardHeader,
  type Color,
  type ColorProp,
  type IconName,
  IconText,
  Stack,
  Tag,
  Text
} from "@gigsmart/atorasu";
import { WorkerShiftCardUI } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { time } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { useHasEngagementCapability } from "../WithEngagementCapability";
import type {
  EngagementStateAction,
  EngagementStateName,
  WorkerEngagementCardHeader_engagement$data,
  WorkerEngagementCardHeader_engagement$key
} from "./__generated__/WorkerEngagementCardHeader_engagement.graphql";

function getTagIcon(
  {
    name,
    action
  }: { name: EngagementStateName; action: EngagementStateAction },
  workerCanApproveTimesheet?: boolean,
  hasOpenDispute?: boolean,
  hasApprovedDispute?: boolean,
  hasRejectedDispute?: boolean
): IconName | undefined {
  switch (name) {
    case "APPLIED":
      return "user-check";
    case "CONFIRMING":
      return "circle-exclamation";
    case "SCHEDULED":
      return "check";
    case "RUNNING_LATE":
    case "AWAITING_START":
      return "circle";
    case "EN_ROUTE":
      return "car";
    case "WORKING":
      return "play";
    case "PAUSED":
      return "pause";
    case "ENDED":
      if (hasOpenDispute) return undefined;
      if (hasRejectedDispute) return "circle-exclamation";
      if (hasApprovedDispute) return "dollar-sign";
      return "check";
    case "CANCELED":
      return "ban";
    case "CANCELED_WITH_PAY":
      return "dollar-sign";
    case "PENDING_TIMESHEET_APPROVAL":
      return workerCanApproveTimesheet ? "triangle-exclamation" : "spinner";
    case "BID_REQUESTED":
      return "hand-holding-dollar";
    case "OFFERED":
      return action === "COUNTER_OFFER" ? "comment-dollar" : "check";
    case "BID_REVIEW":
      // fixme: may need to check actions?
      return "file-check";
    default:
      return undefined;
  }
}

function getTagText(
  {
    name,
    action
  }: { name: EngagementStateName; action: EngagementStateAction },
  workerCanApproveTimesheet?: boolean,
  hasOpenDispute?: boolean,
  hasApprovedDispute?: boolean,
  hasRejectedDispute?: boolean,
  hasModifiedDispute?: boolean,
  noResponseToDispute?: boolean
): string | undefined {
  switch (name) {
    case "APPLIED":
      return "Applied";
    case "CONFIRMING":
      return "Confirming";
    case "SCHEDULED":
      return "Hired";
    case "RUNNING_LATE":
      return "Late Arrival";
    case "AWAITING_START":
      return "Awaiting Start";
    case "EN_ROUTE":
      return "En Route";
    case "WORKING":
      return "Working";
    case "PAUSED":
      return "Paused";
    case "ENDED":
      if (hasOpenDispute) return "Dispute Submitted";
      if (hasApprovedDispute && hasRejectedDispute) return "Different Approved";
      if (hasApprovedDispute) return "Dispute Paid";
      if (hasModifiedDispute) return "Different Approved";
      if (noResponseToDispute) return "No Response to Dispute";
      if (hasRejectedDispute) return "Dispute Rejected";
      return "Approved";
    case "CANCELED":
      return "Canceled";
    case "CANCELED_WITH_PAY":
      return "Paid";
    case "PENDING_TIMESHEET_APPROVAL":
      return workerCanApproveTimesheet ? "Timesheet Review" : "Payment Pending";
    case "MISSED":
    case "REJECTED":
    case "APPLICATION_DENIED":
    case "APPLICATION_CANCELED":
      return "Not Hired";
    case "BID_REQUESTED":
      return "Bid Requested";
    case "OFFERED":
      return action === "COUNTER_OFFER" ? "Bid Countered" : "Pending Offer";
    case "BID_REVIEW":
      // fixme: may need to check actions?
      return "Bid Submitted";

    default:
      return undefined;
  }
}

function getBgColor(
  {
    name,
    action
  }: { name: EngagementStateName; action: EngagementStateAction },
  workerCanApproveTimesheet?: boolean
): ColorProp | undefined {
  switch (name) {
    case "APPLIED":
      return "primary";
    case "CONFIRMING":
      return "purple";
    case "SCHEDULED":
    case "AWAITING_START":
    case "EN_ROUTE":
    case "WORKING":
    case "PAUSED":
    case "RUNNING_LATE":
    case "ENDED":
      return "success";
    case "PENDING_TIMESHEET_APPROVAL":
      return workerCanApproveTimesheet ? "danger" : "disabled";
    case "CANCELED":
    case "MISSED":
    case "REJECTED":
    case "APPLICATION_DENIED":
    case "APPLICATION_CANCELED":
    case "CANCELED_WITH_PAY":
      return "disabled";
    case "BID_REQUESTED":
      return "link";
    case "OFFERED":
      return action === "COUNTER_OFFER" ? "warning" : "success";
    case "BID_REVIEW":
      return "primary";
  }
}

function getTagColor(
  {
    name,
    action
  }: { name: EngagementStateName; action: EngagementStateAction },
  workerCanApproveTimesheet?: boolean,
  hasOpenDispute?: boolean,
  hasRejectedDispute?: boolean
): Color | undefined {
  switch (name) {
    case "APPLIED":
    case "AWAITING_START":
    case "EN_ROUTE":
      return "primary";
    case "CONFIRMING":
      return "purple";
    case "WORKING":
    case "SCHEDULED":
      return "success";
    case "PAUSED":
      return "warning";
    case "ENDED":
      if (hasOpenDispute || hasRejectedDispute) return "disabled";
      return "success";
    case "PENDING_TIMESHEET_APPROVAL":
      return workerCanApproveTimesheet ? "danger" : "disabled";
    case "RUNNING_LATE":
    case "CANCELED":
    case "MISSED":
    case "REJECTED":
    case "APPLICATION_DENIED":
    case "APPLICATION_CANCELED":
    case "CANCELED_WITH_PAY":
      return "disabled";
    case "BID_REQUESTED":
      return "link";
    case "OFFERED":
      return action === "COUNTER_OFFER" ? "warning" : "success";
    case "BID_REVIEW":
      return "primary";
  }
}

const generateHeaderText = (
  id: string,
  {
    name,
    action
  }: { name: EngagementStateName; action: EngagementStateAction },
  insertedAt: string,
  startsAt: string | null | undefined,
  cancellationReason: WorkerEngagementCardHeader_engagement$data["cancellationReason"],
  workerCanApproveTimesheet?: boolean,
  workerHasApprovedSystemTimesheet?: boolean,
  eorState?: string
) => {
  const testID = "engagement-card-header-text";
  switch (name) {
    case "CONFIRMING":
      return (
        <Text color="purple" testID={testID}>
          {`${
            eorState && eorState !== "COMPLETE"
              ? "Pending Enrollment Confirmation"
              : "You are not hired yet"
          }`}
        </Text>
      );
    case "APPLIED":
      return <Text testID={testID}>Your Application was Submitted</Text>;
    case "PENDING_TIMESHEET_APPROVAL":
      return workerCanApproveTimesheet ? (
        <IconText size="small" icon="circle" color="danger" spacing="compact">
          <Text color="danger" testID={testID}>
            Action Required
          </Text>
        </IconText>
      ) : (
        <Text color="black" testID={testID}>
          {workerHasApprovedSystemTimesheet ? "" : "No Timesheet Submitted"}
        </Text>
      );
    case "SCHEDULED":
      return (
        <Text testID={testID}>
          Upcoming Shift:{" "}
          <Text weight="bold">
            {startsAt ? time.humanize(startsAt, "dateShort") : "ASAP"}
          </Text>
        </Text>
      );
    case "AWAITING_START":
    case "EN_ROUTE":
    case "WORKING":
    case "PAUSED":
    case "RUNNING_LATE":
      return <Text testID={testID}>Active</Text>;
    case "ENDED":
      return <Text testID={testID}>Payment Approved</Text>;
    case "CANCELED":
    case "CANCELED_WITH_PAY":
      return (
        <Text testID={testID}>
          {cancellationReason?.option?.variant === "NO_SHOW" ||
          cancellationReason?.reasonType === "REPORT_NO_SHOW"
            ? "Marked as No-Show"
            : WorkerShiftCardUI.select("", "Shift was canceled")}
        </Text>
      );
    case "MISSED":
      return <Text testID={testID}>Missed</Text>;
    case "REJECTED":
      return <Text testID={testID}>Rejected</Text>;
    case "APPLICATION_DENIED":
      return <Text testID={testID}>Applied, Not Hired</Text>;
    case "APPLICATION_CANCELED":
      return <Text testID={testID}>Bid Withdrawn</Text>;
    case "OFFERED":
      return (
        <Text
          testID={testID}
          color={action === "COUNTER_OFFER" ? "black" : "success"}
        >
          {action === "COUNTER_OFFER"
            ? "Counter-Offer Received"
            : "Confirm to be Hired"}
        </Text>
      );

    case "BID_REQUESTED":
      return (
        <Text color="link" testID={testID}>
          Submit a Bid for this Shift
        </Text>
      );

    case "BID_REVIEW":
      return <Text testID={testID}>Your Bid was Submitted</Text>;

    default:
      return null;
  }
};

export const WorkerEngagementCardHeader = ({
  id,
  startsAt,
  currentState,
  insertedAt,
  cancellationReason,
  capabilities,
  disputes,
  approvedDisputeCount,
  rejectedDisputes,
  finalTimesheet,
  systemTimeSheetSubmittedByWorker,
  timeSheetCreatedByWorker,
  gig
}: FragmentContainerInnerComponentProps<WorkerEngagementCardHeader_engagement$key>) => {
  const user = useCurrentUser();
  const isEOR = (gig?.eorAddons?.totalCount ?? 0) > 0;
  const eorState = !isEOR
    ? undefined
    : user?.verification?.reports?.find(({ type }) => type === "EOR_WORKER")
        ?.state;
  const hasOpenDispute = (disputes?.totalCount ?? 0) >= 1;
  const hasApprovedDispute = (approvedDisputeCount?.totalCount ?? 0) >= 1;
  const hasRejectedDispute = (rejectedDisputes?.totalCount ?? 0) >= 1;
  const noResponseToDispute =
    rejectedDisputes?.edges?.[0]?.node?.approval?.submittedBy?.__typename ===
    "SystemUser";
  const hasModifiedDispute =
    (finalTimesheet?.resolvedDisputesCount?.totalCount ?? 0) >= 1 &&
    hasRejectedDispute;
  const approveTimesheetCapability = useHasEngagementCapability(
    capabilities,
    "APPROVE_SYSTEM_TIMESHEET"
  );
  const tagText = getTagText(
    currentState,
    approveTimesheetCapability?.available,
    hasOpenDispute,
    hasApprovedDispute,
    hasRejectedDispute,
    hasModifiedDispute,
    noResponseToDispute
  );
  const workerHasApprovedTimeSheet =
    !!systemTimeSheetSubmittedByWorker?.approvals?.totalCount ||
    !!timeSheetCreatedByWorker?.approvals?.totalCount;

  return (
    <CardHeader
      color={getBgColor(currentState, approveTimesheetCapability?.available)}
      variant="well"
      size="medium"
    >
      <Stack
        horizontal
        alignItems="center"
        justifyContent="space-between"
        testID={`engagement-card-header-${id}`}
      >
        {generateHeaderText(
          id,
          currentState,
          insertedAt,
          startsAt,
          cancellationReason,
          approveTimesheetCapability?.available,
          workerHasApprovedTimeSheet,
          eorState
        )}
        {!!tagText && (
          <Tag
            label={tagText}
            icon={getTagIcon(
              currentState,
              approveTimesheetCapability?.available,
              hasOpenDispute,
              hasApprovedDispute,
              hasRejectedDispute
            )}
            color={getTagColor(
              currentState,
              approveTimesheetCapability?.available,
              hasOpenDispute,
              hasRejectedDispute
            )}
          />
        )}
      </Stack>
    </CardHeader>
  );
};

export default createRelayFragmentContainer<WorkerEngagementCardHeader_engagement$key>(
  graphql`
    fragment WorkerEngagementCardHeader_engagement on Engagement {
      id
      startsAt
      insertedAt
      currentState {
        name
        action
      }
      cancellationReason {
        reasonType
        option {
          variant
        }
      }
      disputes(first: 1, query: "WHERE approvalDisposition = NULL") {
        totalCount
      }
      approvedDisputeCount: disputes(
        first: 0
        query: "WHERE approvalDisposition = APPROVED"
      ) {
        totalCount
      }
      rejectedDisputes: disputes(
        first: 1
        query: "WHERE approvalDisposition = REJECTED"
      ) {
        totalCount
        edges {
          node {
            approval {
              submittedBy {
                __typename
              }
            }
          }
        }
      }
      finalTimesheet: timesheet(variant: FINAL) {
        resolvedDisputesCount: resolvedDisputes(first: 0) {
          totalCount
        }
      }
      systemTimeSheetSubmittedByWorker: timesheet(variant: SYSTEM) {
        approvals(first: 0, query: "WHERE approvedByType = WORKER") {
          totalCount
        }
      }
      timeSheetCreatedByWorker: timesheet(variant: WORKER) {
        approvals(first: 0) {
          totalCount
        }
      }
      capabilities {
        status
        type
        restrictedBy {
          message
        }
        expiresAt
      }
      gig {
        eorAddons: addons(first: 1, where: { addonType: { _eq: EOR_WORKERS } }) {
          totalCount
        }
      }
    }
  `,
  WorkerEngagementCardHeader
);
