/**
 * @generated SignedSource<<2420190d96f36ba9530485d0fa675dc8>>
 * @relayHash 0ba21a165deaec4b8364ac37d19e9201
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:Mpu0jMnfHDgkCvmOXzRAjzBcdBPKDeR7_C2O6Am6-dU

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pendingTimesheetsScreenQuery$variables = Record<PropertyKey, never>;
export type pendingTimesheetsScreenQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"pendingTimesheetsScreen_worker">;
  } | null | undefined;
};
export type pendingTimesheetsScreenQuery = {
  response: pendingTimesheetsScreenQuery$data;
  variables: pendingTimesheetsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "WHERE workerCanApproveTimesheet = TRUE ORDER BY currentStateTransitionedAt ASC"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPay",
    "storageKey": null
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "variant",
    "value": "SYSTEM"
  }
],
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = [
  (v8/*: any*/)
],
v12 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v14 = [
  (v5/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pendingTimesheetsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "pendingTimesheetsScreen_worker"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pendingTimesheetsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engagement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "action",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CancellationReason",
                            "kind": "LinkedField",
                            "name": "cancellationReason",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reasonType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CancellationReasonOption",
                                "kind": "LinkedField",
                                "name": "option",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "variant",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "estimatedPayment",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "paymentInfo",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": "pickupStateCount",
                            "args": (v7/*: any*/),
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  (v8/*: any*/),
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE action = PICKUP"
                                  }
                                ],
                                "concreteType": "EngagementStateOrOverridesConnection",
                                "kind": "LinkedField",
                                "name": "states",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": "states(first:0,query:\"WHERE action = PICKUP\")"
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"SYSTEM\")"
                          },
                          {
                            "alias": "systemTimeSheetSubmittedByWorker",
                            "args": (v7/*: any*/),
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  (v8/*: any*/),
                                  {
                                    "kind": "Literal",
                                    "name": "query",
                                    "value": "WHERE approvedByType = WORKER"
                                  }
                                ],
                                "concreteType": "EngagementTimesheetApprovalsConnection",
                                "kind": "LinkedField",
                                "name": "approvals",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": "approvals(first:0,query:\"WHERE approvedByType = WORKER\")"
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"SYSTEM\")"
                          },
                          {
                            "alias": "timeSheetCreatedByWorker",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "variant",
                                "value": "WORKER"
                              }
                            ],
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": (v11/*: any*/),
                                "concreteType": "EngagementTimesheetApprovalsConnection",
                                "kind": "LinkedField",
                                "name": "approvals",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": "approvals(first:0)"
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"WORKER\")"
                          },
                          {
                            "alias": "finalTimesheet",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "variant",
                                "value": "FINAL"
                              }
                            ],
                            "concreteType": "EngagementTimesheet",
                            "kind": "LinkedField",
                            "name": "timesheet",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "resolvedDisputesCount",
                                "args": (v11/*: any*/),
                                "concreteType": "EngagementDisputesConnection",
                                "kind": "LinkedField",
                                "name": "resolvedDisputes",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": "resolvedDisputes(first:0)"
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": "timesheet(variant:\"FINAL\")"
                          },
                          {
                            "alias": null,
                            "args": [
                              (v12/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE approvalDisposition = NULL"
                              }
                            ],
                            "concreteType": "EngagementDisputesConnection",
                            "kind": "LinkedField",
                            "name": "disputes",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementDisputesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementDispute",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = NULL\")"
                          },
                          {
                            "alias": "approvedDisputeCount",
                            "args": [
                              (v8/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE approvalDisposition = APPROVED"
                              }
                            ],
                            "concreteType": "EngagementDisputesConnection",
                            "kind": "LinkedField",
                            "name": "disputes",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": "disputes(first:0,query:\"WHERE approvalDisposition = APPROVED\")"
                          },
                          {
                            "alias": "rejectedDisputeCount",
                            "args": [
                              (v12/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "WHERE approvalDisposition = REJECTED"
                              }
                            ],
                            "concreteType": "EngagementDisputesConnection",
                            "kind": "LinkedField",
                            "name": "disputes",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementDisputesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EngagementDispute",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "EngagementDisputeApproval",
                                        "kind": "LinkedField",
                                        "name": "approval",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "submittedBy",
                                            "plural": false,
                                            "selections": [
                                              (v0/*: any*/),
                                              (v1/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v1/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "disputes(first:1,query:\"WHERE approvalDisposition = REJECTED\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementCapability",
                            "kind": "LinkedField",
                            "name": "capabilities",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "restrictedBy",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "message",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workerDistance",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "area",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "payRate",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endsAt",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reconcilesAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "requiredReportTypes",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPosition",
                                "kind": "LinkedField",
                                "name": "position",
                                "plural": false,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": "eorAddons",
                                "args": [
                                  (v12/*: any*/),
                                  {
                                    "kind": "Literal",
                                    "name": "where",
                                    "value": {
                                      "addonType": {
                                        "_eq": "EOR_WORKERS"
                                      }
                                    }
                                  }
                                ],
                                "concreteType": "GigAddonsConnection",
                                "kind": "LinkedField",
                                "name": "addons",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": "addons(first:1,where:{\"addonType\":{\"_eq\":\"EOR_WORKERS\"}})"
                              },
                              {
                                "alias": null,
                                "args": [
                                  (v12/*: any*/)
                                ],
                                "concreteType": "GigSkillsConnection",
                                "kind": "LinkedField",
                                "name": "skills",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigSkillsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigSkill",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Skill",
                                            "kind": "LinkedField",
                                            "name": "skill",
                                            "plural": false,
                                            "selections": (v14/*: any*/),
                                            "storageKey": null
                                          },
                                          (v1/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "skills(first:1)"
                              }
                            ],
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "engagements(first:10,query:\"WHERE workerCanApproveTimesheet = TRUE ORDER BY currentStateTransitionedAt ASC\")"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "pendingTimesheetsScreen_engagements",
                "kind": "LinkedHandle",
                "name": "engagements"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:Mpu0jMnfHDgkCvmOXzRAjzBcdBPKDeR7_C2O6Am6-dU",
    "metadata": {},
    "name": "pendingTimesheetsScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "462874746fa0da9381e23f99dfb71ca8";

export default node;
