import { Column, IconText, Row, Stack, Tag, Text } from "@gigsmart/atorasu";
import {
  RecurringSchedule,
  UnlimitedDistanceFeature
} from "@gigsmart/feature-flags";
import React, { type ReactElement } from "react";
import { gigHelpers } from ".";
import { currency, time } from "../iso";

interface Props {
  small?: boolean;
  startsAt?: string | null;
  endsAt?: string | null;
  distance?: number | null;
  timezone?: string | null;
  estimatedNetPay?: string | null;
  primaryAction?: ReactElement | null;
  alwaysDisplayDate?: boolean;
  dateTag?: ReactElement | null;
  timeTag?: ReactElement | null;
  right?: ReactElement | null;
  isEOR?: boolean;
  payRate?: string | null;
  address?: string | null;
}

const GigDateTimeContent = ({
  startsAt,
  endsAt,
  small,
  distance,
  timezone,
  alwaysDisplayDate,
  dateTag,
  timeTag,
  estimatedNetPay,
  right,
  primaryAction,
  isEOR,
  payRate,
  address
}: Props) => {
  const showDayLine = !!startsAt || alwaysDisplayDate;
  const outOfRange =
    !!distance && distance > UnlimitedDistanceFeature.select(12425, 75);

  return (
    <Row gap="compact" alignItems="center" justifyContent="space-between">
      <Column fill gap="slim">
        {showDayLine && renderDayLine(startsAt, timezone, dateTag, right)}
        {renderDateTimeLine(
          small && showDayLine,
          startsAt,
          endsAt,
          timezone,
          timeTag
        )}
        {isEOR && (
          <Text variant="note" color="neutral">
            Hourly Rate: {currency.humanize(payRate)}
          </Text>
        )}
        {!isEOR && !!estimatedNetPay && (
          <Text variant="note" color="neutral">
            Estimated Earnings:{" "}
            <Text weight="bold">
              {currency.humanize(estimatedNetPay, { integer: true })}
            </Text>
          </Text>
        )}
        {!!address && (
          <IconText iconSize="small" icon="location-dot" color="neutral">
            <Text variant="subheader" weight="normal" color="neutral">
              {address}{" "}
              {typeof distance === "number" &&
                gigHelpers.formatDistanceRange({ min: distance }, " away")}
            </Text>
          </IconText>
        )}
      </Column>
      {outOfRange ? <Tag label="Out of range" /> : primaryAction}
    </Row>
  );
};

const renderDayLine = (
  gigDate?: string | null,
  timezone?: string | null,
  dateTag?: ReactElement | null,
  right?: ReactElement | null
) => {
  const today = time.getDateTime(new Date(), timezone);
  const gigDateTime = time.getDateTime(gigDate, timezone);

  const fmtPrefix = gigDateTime.hasSame(today, "day")
    ? "'Today'"
    : gigDateTime.hasSame(today.plus({ days: 1 }), "day")
      ? "'Tomorrow'"
      : "EEEE";
  const [dayOfWeek, date] = gigDateTime
    .toFormat(`${fmtPrefix} - MMMM d, yyyy`)
    .split(" - ");
  return (
    <Row justifyContent="space-between">
      <Text weight={RecurringSchedule.isEnabled() ? "bold" : "normal"}>
        {dayOfWeek === "Today" ? (
          <Text weight="bold">{dayOfWeek}</Text>
        ) : (
          dayOfWeek
        )}
        {", "}
        {date}
      </Text>
      {!right && dateTag}
      {right}
    </Row>
  );
};

const renderDateTimeLine = (
  isSecondary?: boolean,
  startsAt?: string | null,
  endsAt?: string | null,
  timezone?: string | null,
  timeTag?: ReactElement | null
) => {
  const gigDate = startsAt ?? endsAt;
  const timezoneFmt =
    !!gigDate && !!timezone && ` ${time.timezoneName(gigDate, timezone)}`;
  return (
    <Stack horizontal size="compact" alignItems="center">
      <Text
        variant={isSecondary ? "note" : undefined}
        color={isSecondary ? "textPrimary" : undefined}
      >
        {startsAt ? (
          time.humanize(startsAt, "hm", timezone)
        ) : (
          <Text weight="bold">ASAP</Text>
        )}
        {" to "}
        {time.humanize(endsAt, "hm", timezone)}
        {timezoneFmt}
      </Text>
      {timeTag}
    </Stack>
  );
};

export default GigDateTimeContent;
