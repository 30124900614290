import { Column, Tag, Text } from "@gigsmart/atorasu";
import { pluralize } from "@gigsmart/isomorphic-shared/app/inflector";
import { currency } from "@gigsmart/isomorphic-shared/iso";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import ShiftPreviewRow from "./ShiftPreviewRow";
import type { ShiftPreviewList_pickup_availableGigs$key } from "./__generated__/ShiftPreviewList_pickup_availableGigs.graphql";
import type { ShiftPreviewList_series$key } from "./__generated__/ShiftPreviewList_series.graphql";

type PickupShiftPreviewListProps = {
  fragmentRef?: ShiftPreviewList_pickup_availableGigs$key | null;
};
export const PickupShiftPreviewList = ({
  fragmentRef
}: PickupShiftPreviewListProps) => {
  const data = useRelayFragment(
    graphql`
      fragment ShiftPreviewList_pickup_availableGigs on AvailableGigsConnection
      @argumentDefinitions(isEor: { type: "Boolean!" }) {
        edges {
          estimatedPayment @skip(if: $isEor) {
            netPay
          }
          node {
            ...ShiftPreviewRow_shift
            id
            requiredReportTypes
            payRate
            gigSeries {
              boosted
            }
            pickup {
              eligible
            }
          }
        }
      }
    `,
    fragmentRef ?? null
  );

  return (
    <Column gap="compact">
      {data?.edges?.map((it) => {
        if (!it?.node) return null;
        const { node, estimatedPayment } = it;
        const hasEorType = !!node.requiredReportTypes?.includes("EOR_WORKER");
        return (
          <ShiftPreviewRow
            key={node.id}
            type={hasEorType ? "pickups-eor" : "pickups"}
            fragmentRef={node}
            boosted={node.gigSeries?.boosted}
            note={
              <Text variant="note" color="neutral">
                {hasEorType
                  ? currency.humanizeRate(node.payRate)
                  : currency.humanize(estimatedPayment?.netPay)}
              </Text>
            }
            footer={
              node.pickup?.eligible && (
                <Tag
                  color="emphasized"
                  icon="alarm-clock"
                  label="Shift Available Today"
                  variant="clear"
                />
              )
            }
          />
        );
      })}
    </Column>
  );
};

type SeriesShiftPreviewListProps = {
  fragmentRef?: ShiftPreviewList_series$key | null;
};
export const SeriesShiftPreviewList = ({
  fragmentRef
}: SeriesShiftPreviewListProps) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const data = useRelayFragment(
    graphql`
      fragment ShiftPreviewList_series on AvailableGigSeriesConnection {
        edges {
          node {
            id
            boosted
            availableGigs(first: 1) {
              totalCount
              edges {
                estimatedPayment {
                  netPay
                }
                node {
                  ...ShiftPreviewRow_shift
                  id
                  payRate
                  requiredReportTypes
                }
              }

            }
          }
        }
        totalCount
      }
    `,
    fragmentRef ?? null
  );

  return (
    <Column gap="compact">
      {data?.edges?.map((it) => {
        const first = it?.node?.availableGigs?.edges?.[0];
        if (!it?.node || !first?.node) return null;

        const { id: seriesId, boosted, availableGigs } = it.node;
        const { node, estimatedPayment } = first;
        const hasEorType = !!node.requiredReportTypes?.includes("EOR_WORKER");
        return (
          <ShiftPreviewRow
            key={seriesId}
            onPress={() =>
              navigation.push("BrowseShiftDetails", {
                id: seriesId,
                type: hasEorType ? "eor" : "shifts"
              })
            }
            note={
              <Text variant="note" color="neutral">
                {hasEorType
                  ? currency.humanizeRate(node.payRate)
                  : currency.humanize(estimatedPayment?.netPay)}{" "}
                • {pluralize(availableGigs?.totalCount, "Shift")}
              </Text>
            }
            boosted={boosted}
            fragmentRef={node}
          />
        );
      })}
    </Column>
  );
};
