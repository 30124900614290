/**
 * @generated SignedSource<<7cf845e9c005b29fa8a8e5b1cffd217d>>
 * @relayHash 1e714e2702b4f2291f9739cc749561ae
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:SwZh15TK1SdTx_MGxtpGzm-wpalND23w17PSoxh0Aa0

import { ConcreteRequest } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
export type VerificationReportState = "AWAITING_DEPENDENCY" | "CANCELED" | "COMPLETE" | "EXPIRED" | "FAILED" | "NOT_ELIGIBLE" | "NOT_STARTED" | "PENDING" | "REQUIRES_ATTENTION" | "%future added value";
export type MyEORCenterCardQuery$variables = Record<PropertyKey, never>;
export type MyEORCenterCardQuery$data = {
  readonly viewer: {
    readonly verification?: {
      readonly reports: ReadonlyArray<{
        readonly state: VerificationReportState;
        readonly statusDescription: string | null | undefined;
        readonly type: ReportType;
      }>;
    };
  } | null | undefined;
};
export type MyEORCenterCardQuery = {
  response: MyEORCenterCardQuery$data;
  variables: MyEORCenterCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerVerification",
      "kind": "LinkedField",
      "name": "verification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VerificationReport",
          "kind": "LinkedField",
          "name": "reports",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "statusDescription",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyEORCenterCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyEORCenterCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:SwZh15TK1SdTx_MGxtpGzm-wpalND23w17PSoxh0Aa0",
    "metadata": {},
    "name": "MyEORCenterCardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9061705ac98a920cefe0b89d1d771a32";

export default node;
