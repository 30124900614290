import {
  Button,
  Column,
  IconButton,
  IconText,
  Linking,
  Row,
  Spacer,
  Stack,
  Tag,
  Text
} from "@gigsmart/atorasu";
import {
  getReportState,
  getReportType,
  typeKeys
} from "@gigsmart/isomorphic-shared/report/dictionary";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useCallback, useMemo } from "react";
import type { SharedParamList } from "../navigation/types";
import HomeScreenCard from "../worker/HomescreenCard";

import type { VerificationStatusCard_worker$key } from "./__generated__/VerificationStatusCard_worker.graphql";

interface Props {
  onShowIntroductionModal?: () => void;
}

export const VerificationStatusCard = ({
  verification,
  legalInformation,
  onShowIntroductionModal
}: FragmentContainerInnerComponentProps<
  VerificationStatusCard_worker$key,
  Props
>) => {
  const nav =
    useNavigation<AppNavigationProp<SharedParamList, "GetVerified">>();
  const [dismissedReports, setDismissedReports] =
    useUserValue<Record<string, boolean>>("reportDismiss");
  const supportEmail = "support@gigsmart.com";
  const handleDismissFailure = useCallback(
    (type: string) => {
      void setDismissedReports({ ...dismissedReports, [type]: true });
    },
    [dismissedReports, setDismissedReports]
  );

  const reports = (verification?.reports || []).filter(
    (report) => report?.type !== "EOR_WORKER"
  );
  const nominalReports: string[] = typeKeys.filter(
    (type) =>
      !getReportState(
        reports.find((report) => report?.type === type)?.state ?? ""
      ).exclude
  );
  const noticeReports = reports.filter(
    (report) =>
      report &&
      !dismissedReports?.[report.type] &&
      !!report.statusDescription &&
      !!legalInformation &&
      legalInformation[getReportType(report.type).optInKey]
  );

  const optedInOptionCount: number = nominalReports.filter(
    (type) => !!legalInformation?.[getReportType(type).optInKey]
  ).length;
  const totalOptionCount: number = nominalReports.length;

  const { text, button, edit } = useMemo(() => {
    switch (verification?.status) {
      case "NOT_ELIGIBLE":
        return {
          text: (
            <Text>
              You are under 18 and are not eligible for verification at this
              time.
            </Text>
          )
        };
      case "NOT_ENROLLED":
        return {
          text: (
            <Text>
              Apply to more gigs and make more money by opting into account
              verification. It's <Text weight="bold">free</Text>.
            </Text>
          ),
          button: (
            <Button
              label="Start"
              size="small"
              testID="not_enrolled_start"
              onPress={onShowIntroductionModal}
            />
          )
        };
      case "REQUIRED":
        return {
          text: (
            <Text>
              Your account requires verification to receive additonal payouts,
              It's <Text weight="bold">free</Text>.
            </Text>
          ),
          button: (
            <Button
              label="Start"
              size="small"
              testID="required_start"
              onPress={onShowIntroductionModal}
            />
          )
        };
      case "PENDING":
        return {
          text: (
            <Text>
              Your account is pending verification, you can still apply to
              verified gigs while you wait.
            </Text>
          ),
          edit: true,
          button: (
            <IconButton
              name="pen-to-square"
              size="tiny"
              color="primary"
              variant="solid"
              testID="pending-edit-optin"
              onPress={() => nav.push("GetVerified")}
            />
          )
        };
      case "ENROLLED":
        return {
          text: (
            <Text>
              You are opted in for{" "}
              <Text weight="bold">
                {optedInOptionCount} / {totalOptionCount} options:
              </Text>
            </Text>
          ),
          edit: true,
          button: (
            <IconButton
              name="pen-to-square"
              size="tiny"
              color="primary"
              variant="solid"
              testID="enrolled-edit-optin"
              onPress={() => nav.push("GetVerified")}
            />
          )
        };
      default:
        return {};
    }
  }, [nav, optedInOptionCount, totalOptionCount, verification?.status]);

  const [collapseVerificationCard, setCollapseVerificationCard] =
    useUserValue<boolean>("verificationCard");

  const fancyHeaderRightButton = edit && (
    <IconButton
      name={collapseVerificationCard ? "chevron-down" : "chevron-up"}
      size="small"
      variant="solid"
      color="primary"
      onPress={async () =>
        await setCollapseVerificationCard(!collapseVerificationCard)
      }
      testID=""
    />
  );

  const renderLink = (description: string) => {
    const splitDescription = description?.split(supportEmail);

    return (
      <Text>
        {splitDescription?.[0]}
        <Text
          color="info"
          onPress={async () => await Linking.openURL(`mailto:${supportEmail}`)}
        >
          {supportEmail}
        </Text>
        {splitDescription?.[1]}
      </Text>
    );
  };

  return (
    <>
      <Stack>
        <HomeScreenCard
          headerTitle="Verification"
          headerIcon="shield-check"
          testID="profile-verification-card"
          action={fancyHeaderRightButton ?? button}
          hideContent={!!collapseVerificationCard}
        >
          <Row justifyContent="space-between">
            <Text>{text}</Text>
            {edit && button}
          </Row>
          <Spacer />
          <Stack size="medium">
            {!["NOT_ENROLLED", "NOT_ELIGIBLE"].includes(verification?.status) &&
              nominalReports.map((type, index) => {
                const {
                  title: reportTitle,
                  iconName,
                  optInKey
                } = getReportType(type);

                const { state } = reports.find(
                  (report) =>
                    report?.type === type && legalInformation?.[optInKey]
                ) ?? {
                  state: legalInformation?.[optInKey]
                    ? "NOT_STARTED"
                    : "OPTED_OUT"
                };
                const { title: statusTitle } = getReportState(state);

                return (
                  <Stack
                    horizontal
                    key={`${reportTitle}-${index}`}
                    alignItems="center"
                  >
                    <Column fill>
                      <IconText
                        color="primary"
                        icon={iconName}
                        size="small"
                        iconAlign="justify"
                      >
                        {reportTitle}
                      </IconText>
                    </Column>
                    <Column fill>
                      <Tag
                        label={statusTitle}
                        variant="clear"
                        color={state === "COMPLETE" ? "success" : undefined}
                      />
                    </Column>
                  </Stack>
                );
              })}
          </Stack>
        </HomeScreenCard>
        {noticeReports.map(({ type, statusDescription }) => {
          const { title, iconName } = getReportType(type);
          const containsLink = statusDescription?.includes(supportEmail);

          return (
            <HomeScreenCard
              headerTitle={title}
              headerIcon={iconName}
              onDismiss={() => handleDismissFailure(type)}
              headerVariant="warning"
              key={`verification-issue-${type}`}
            >
              {containsLink ? (
                renderLink(statusDescription ?? "")
              ) : (
                <Text>{statusDescription ?? ""}</Text>
              )}
            </HomeScreenCard>
          );
        })}
      </Stack>
    </>
  );
};

export default createRelayFragmentContainer<
  VerificationStatusCard_worker$key,
  Props
>(
  graphql`
    fragment VerificationStatusCard_worker on Worker {
      verification {
        status
        reports {
          type
          status
          state
          statusDescription
        }
      }
      legalInformation {
        id
        backgroundCheckOptIn
        driverCheckOptIn
        drugScreenOptIn
      }
    }
  `,
  VerificationStatusCard
);
