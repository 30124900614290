/**
 * @generated SignedSource<<ef6198e3fcf9998aed7ad333023885d3>>
 * @relayHash c749800c2898565a229a4e99b07c137b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:KWQjqpsez4RFOy7awsXVhq-lX1KBR98aY3-1i0hUYoE

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type offeredGigTopSectionQuery$variables = Record<PropertyKey, never>;
export type offeredGigTopSectionQuery$data = {
  readonly offeredViewer: {
    readonly " $fragmentSpreads": FragmentRefs<"offeredGigTopSection_worker">;
  } | null | undefined;
};
export type offeredGigTopSectionQuery = {
  response: offeredGigTopSectionQuery$data;
  variables: offeredGigTopSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "_neq": "PROJECT"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredReportTypes",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v13 = [
  (v12/*: any*/),
  (v1/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v18 = [
  (v1/*: any*/)
],
v19 = {
  "kind": "InlineFragment",
  "selections": (v18/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v20 = [
  (v7/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "offeredGigTopSectionQuery",
    "selections": [
      {
        "alias": "offeredViewer",
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "offeredGigTopSection_worker"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "offeredGigTopSectionQuery",
    "selections": [
      {
        "alias": "offeredViewer",
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "allOffers",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "OFFERED",
                          "BID_REQUESTED"
                        ]
                      }
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\",\"BID_REQUESTED\"]}})"
              },
              {
                "alias": "projectOffers",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_eq": "OFFERED"
                      },
                      "gigType": {
                        "_eq": "PROJECT"
                      }
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_eq\":\"OFFERED\"},\"gigType\":{\"_eq\":\"PROJECT\"}})"
              },
              {
                "alias": "pendingCount",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "OFFERED"
                        ]
                      },
                      "gigType": (v5/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"OFFERED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              },
              {
                "alias": "bidRequestsCount",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "currentStateName": {
                        "_in": [
                          "BID_REQUESTED"
                        ]
                      },
                      "gigType": (v5/*: any*/)
                    }
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,where:{\"currentStateName\":{\"_in\":[\"BID_REQUESTED\"]},\"gigType\":{\"_neq\":\"PROJECT\"}})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Engagement",
                "kind": "LinkedField",
                "name": "currentEngagement",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementPaymentInfo",
                    "kind": "LinkedField",
                    "name": "paymentInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billableDuration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "basePay",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerServiceFee",
                        "kind": "LinkedField",
                        "name": "serviceFees",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hourlyRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "feeType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementState",
                    "kind": "LinkedField",
                    "name": "currentState",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transitionedAt",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "action",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "transitionedBy",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Gig",
                    "kind": "LinkedField",
                    "name": "gig",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigState",
                        "kind": "LinkedField",
                        "name": "currentState",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigSeries",
                        "kind": "LinkedField",
                        "name": "gigSeries",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Place",
                            "kind": "LinkedField",
                            "name": "place",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requiredPermissions",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "latestArrivalTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logoUrl",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "conversationRule",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShiftGroupConversation",
                        "kind": "LinkedField",
                        "name": "conversation",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "last",
                                "value": 1
                              }
                            ],
                            "concreteType": "UserMessagesConnection",
                            "kind": "LinkedField",
                            "name": "messages",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": "messages(last:1)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ConversationParticipant",
                            "kind": "LinkedField",
                            "name": "participant",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserMessageStats",
                                "kind": "LinkedField",
                                "name": "messageStats",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unreadCount",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationRequester",
                        "kind": "LinkedField",
                        "name": "primaryContact",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationLocation",
                        "kind": "LinkedField",
                        "name": "organizationLocation",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Place",
                            "kind": "LinkedField",
                            "name": "place",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v1/*: any*/),
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              }
                            ],
                            "concreteType": "OrganizationParkingLocationsConnection",
                            "kind": "LinkedField",
                            "name": "parkingLocations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationParkingLocationsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrganizationParkingLocation",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Place",
                                        "kind": "LinkedField",
                                        "name": "place",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          (v9/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "workerParkingDistance",
                                        "storageKey": null
                                      },
                                      (v15/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": "parkingLocations(first:1)"
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Requester",
                        "kind": "LinkedField",
                        "name": "requester",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "negotiable",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Requester",
                            "kind": "LinkedField",
                            "name": "requester",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "profilePhoto",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  (v17/*: any*/),
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "GigLike",
                        "abstractKey": "__isGigLike"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transportationMethod",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RemoteApplicationApplet",
                    "kind": "LinkedField",
                    "name": "applets",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "params",
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "variant",
                        "value": "FINAL"
                      }
                    ],
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paid",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"FINAL\")"
                  },
                  {
                    "alias": "workerTimesheet",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "variant",
                        "value": "WORKER"
                      }
                    ],
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "editable",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalDurationWorked",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EngagementPaymentInfo",
                        "kind": "LinkedField",
                        "name": "paymentInfo",
                        "plural": false,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "timesheet(variant:\"WORKER\")"
                  },
                  {
                    "alias": "systemTimesheet",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "variant",
                        "value": "SYSTEM"
                      }
                    ],
                    "concreteType": "EngagementTimesheet",
                    "kind": "LinkedField",
                    "name": "timesheet",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "scheduledCount",
                        "args": [
                          (v2/*: any*/),
                          {
                            "kind": "Literal",
                            "name": "query",
                            "value": "WHERE name = SCHEDULED"
                          }
                        ],
                        "concreteType": "EngagementStateOrOverridesConnection",
                        "kind": "LinkedField",
                        "name": "states",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": "states(first:0,query:\"WHERE name = SCHEDULED\")"
                      },
                      {
                        "alias": "startedCount",
                        "args": [
                          (v2/*: any*/),
                          {
                            "kind": "Literal",
                            "name": "query",
                            "value": "WHERE action = START"
                          }
                        ],
                        "concreteType": "EngagementStateOrOverridesConnection",
                        "kind": "LinkedField",
                        "name": "states",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": "states(first:0,query:\"WHERE action = START\")"
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": "timesheet(variant:\"SYSTEM\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CancellationReason",
                    "kind": "LinkedField",
                    "name": "cancellationReason",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "authoredBy",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reasonType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 50
                          }
                        ],
                        "concreteType": "CancellationReasonDisqualificationsConnection",
                        "kind": "LinkedField",
                        "name": "disqualifications",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CancellationReasonDisqualificationsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CancellationReasonDisqualification",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigFieldItemDefinition",
                                    "kind": "LinkedField",
                                    "name": "gigFieldItemDefinition",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "label",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GigField",
                                        "kind": "LinkedField",
                                        "name": "gigField",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "iconName",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "disqualifications(first:50)"
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableActions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RemoteApplicationTag",
                    "kind": "LinkedField",
                    "name": "customTags",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RemoteApplicationIcon",
                        "kind": "LinkedField",
                        "name": "icon",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "data",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementCapability",
                    "kind": "LinkedField",
                    "name": "capabilities",
                    "plural": true,
                    "selections": [
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v19/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v18/*: any*/),
                        "type": "OrganizationWorkerConversation",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v18/*: any*/),
                        "type": "EngagementConversation",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v18/*: any*/),
                        "type": "ShiftGroupConversation",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "insertedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "workerDistance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserTopic",
                    "kind": "LinkedField",
                    "name": "topic",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canSendMessages",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "workerEta",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GigSeries",
                    "kind": "LinkedField",
                    "name": "gigSeries",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngagementReview",
                    "kind": "LinkedField",
                    "name": "reviewOfRequester",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "estimatedPayment",
                            "plural": false,
                            "selections": (v20/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v10/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AvailableGigsEdge",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementState",
                            "kind": "LinkedField",
                            "name": "currentState",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngagementNegotiation",
                                "kind": "LinkedField",
                                "name": "negotiation",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EngagementPaymentInfo",
                            "kind": "LinkedField",
                            "name": "estimatedPayment",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gig",
                            "kind": "LinkedField",
                            "name": "gig",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Engagement",
                        "abstractKey": null
                      }
                    ],
                    "type": "AvailableGigOrEngagement",
                    "abstractKey": "__isAvailableGigOrEngagement"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "pendingTimesheets",
                "args": [
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": "WHERE workerCanApproveTimesheet = TRUE"
                  }
                ],
                "concreteType": "EngagementsConnection",
                "kind": "LinkedField",
                "name": "engagements",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "engagements(first:0,query:\"WHERE workerCanApproveTimesheet = TRUE\")"
              }
            ],
            "type": "Worker",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:KWQjqpsez4RFOy7awsXVhq-lX1KBR98aY3-1i0hUYoE",
    "metadata": {},
    "name": "offeredGigTopSectionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7182fad497ff67ece000948e116520fa";

export default node;
