import { ContentArea, Spacer, Stack, Text } from "@gigsmart/atorasu";
import {} from "@gigsmart/biruda";
import {
  type SuspendedQueryContainerInnerComponentProps,
  createSuspendedQueryContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import type { ObjectPath } from "@gigsmart/type-utils";
import React, { useEffect } from "react";
import Loader from "../Brand/Loader";
import useInfiniteList from "../shared/InfiniteList/useInfiniteList";
import PriorTransfersRow from "./PriorTransfersRow";
import type { PriorTransfersPayoutsRefetchQuery } from "./__generated__/PriorTransfersPayoutsRefetchQuery.graphql";
import type { PriorTransfersQuery } from "./__generated__/PriorTransfersQuery.graphql";
import type {
  PriorTransfers_worker$data,
  PriorTransfers_worker$key
} from "./__generated__/PriorTransfers_worker.graphql";

type Payout = ObjectPath<
  PriorTransfers_worker$data,
  ["payouts", "edges", 0, "node"]
>;

export function PriorTransfers({
  response
}: SuspendedQueryContainerInnerComponentProps<PriorTransfersQuery, {}>) {
  const {
    data: payouts,
    onEndReached,
    hasNext,
    ListEmptyComponent
  } = useInfiniteList<
    PriorTransfersPayoutsRefetchQuery,
    PriorTransfers_worker$key,
    Payout
  >(
    graphql`
    fragment PriorTransfers_worker on Worker
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 10 }
      after: { type: "String" }
    )
    @refetchable(queryName: "PriorTransfersPayoutsRefetchQuery") {
        ... on Worker {
          payouts(
            first: $count
            after: $after
            query: "ORDER BY insertedAt DESC"
          ) @connection(key: "PriorTransfersPayouts_payouts") {
            totalCount
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            edges {
              cursor
              node {
                id
                ...PriorTransfersRow_payout
              }
            }
          }
        }
      }
  `,
    response?.viewer,
    {
      getData: (data) => getConnectionNodes(data?.payouts),
      renderEmptyView: (isLoading) => {
        const hasPayment =
          response?.viewer?.payableAccount?.paymentMethod?.last4;
        const balance = parseFloat(
          response?.viewer?.walletBalance?.replace(" USD", "") ?? ""
        );
        const message =
          balance > 0
            ? "Start a transfer to send Shift earnings to your account on file."
            : hasPayment
              ? "Shift earning transfers to your saved debit card or bank account will appear here once completed."
              : "Add a debit card or bank account to transfer your Account Balance.";
        if (isLoading) return <Loader />;
        return (
          <ContentArea size="none">
            <Text align="center">{message}</Text>
          </ContentArea>
        );
      }
    }
  );

  useEffect(() => {
    if (hasNext) onEndReached();
  }, [hasNext, onEndReached]);
  return (
    <>
      <Spacer />
      {payouts?.length === 0 && ListEmptyComponent}
      <Stack size="compact">
        {payouts?.map((payout, index) => (
          <PriorTransfersRow
            key={payout?.id}
            fragmentRef={payout}
            first={index === 0}
          />
        ))}
      </Stack>
      <Spacer />
    </>
  );
}

export default createSuspendedQueryContainer<PriorTransfersQuery>(
  PriorTransfers,
  {
    query: graphql`
    query PriorTransfersQuery {
      viewer {
        ... on Worker {
          walletBalance
          payableAccount {
            paymentMethod {
              last4
            }
          }
          ...PriorTransfers_worker
        }
      }
    }
  `,
    variables: {},
    FallbackComponent: () => (
      <ContentArea justifyContent="center" alignItems="center">
        <Loader />
      </ContentArea>
    )
  }
);
