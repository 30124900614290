import { graphql, useRelayFragment } from "@gigsmart/relay";
import { useMemo } from "react";
import type {
  EngagementStateName,
  useWorkerEngagementSummary_worker$key
} from "./__generated__/useWorkerEngagementSummary_worker.graphql";

export type WorkerEngagementSummaryState =
  | EngagementStateName
  | "ALL_SHIFTS"
  | "ALL_HIRED"
  | "TIMESHEET_REVIEW"
  | "PAYMENT_PENDING"
  | "PENDING_DISPUTE";

export default function useWorkerEngagementSummary(
  fragmentRef?: useWorkerEngagementSummary_worker$key | null
) {
  const worker = useRelayFragment(
    graphql`
      fragment useWorkerEngagementSummary_worker on Worker {
        engagementStateSummary {
          count
          name
        }
        pendingDispute: engagements(first: 0, where: {
          currentStateName: { _eq: ENDED },
          anyEngagementDisputeDisposition: { _isNull: true }
        }) {
          totalCount
        }
        timesheetReview: engagements(first: 0, where: {
          currentStateName: { _eq: PENDING_TIMESHEET_APPROVAL },
          workerCanApproveTimesheet: { _eq: true }
        }) {
          totalCount
        }
        paymentPending: engagements(first: 0, where: {
          currentStateName: { _eq: PENDING_TIMESHEET_APPROVAL },
          workerCanApproveTimesheet: { _eq: false }
        }) {
          totalCount
        }
      }
    `,
    fragmentRef ?? null
  );

  return useMemo(() => {
    const {
      engagementStateSummary,
      pendingDispute,
      timesheetReview,
      paymentPending
    } = worker ?? {};
    const cache = (engagementStateSummary ?? []).reduce((map, item) => {
      map.set(item?.name, item?.count);
      return map;
    }, new Map<WorkerEngagementSummaryState, number>());

    const pendingDisputeCount = pendingDispute?.totalCount ?? 0;
    const getCount = (...states: WorkerEngagementSummaryState[]) =>
      states.reduce((sum: number, state) => {
        const total = (sum + (cache.get(state) ?? 0)) as number;
        return total;
      }, 0);

    cache.set("PENDING_DISPUTE", pendingDisputeCount);
    cache.set("TIMESHEET_REVIEW", timesheetReview?.totalCount ?? 0);
    cache.set("PAYMENT_PENDING", paymentPending?.totalCount ?? 0);
    cache.set(
      "ALL_SHIFTS",
      getCount(
        "PAUSED",
        "WORKING",
        "EN_ROUTE",
        "RUNNING_LATE",
        "AWAITING_START",
        "SCHEDULED",
        "CONFIRMING",
        "PENDING_TIMESHEET_APPROVAL"
      ) + pendingDisputeCount
    );
    cache.set(
      "ALL_HIRED",
      getCount(
        "PAUSED",
        "WORKING",
        "EN_ROUTE",
        "RUNNING_LATE",
        "AWAITING_START",
        "SCHEDULED"
      )
    );

    return getCount;
  }, [worker]);
}
