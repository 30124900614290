/**
 * @generated SignedSource<<9f2f8db24d390daf47e7b74e660a3f60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftTopicPreview_worker$data = {
  readonly availableEorGigSeries: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewList_series">;
  } | null | undefined;
  readonly availableGigSeries: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"ShiftPreviewList_series">;
  } | null | undefined;
  readonly " $fragmentType": "ShiftTopicPreview_worker";
};
export type ShiftTopicPreview_worker$key = {
  readonly " $data"?: ShiftTopicPreview_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftTopicPreview_worker">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 3
},
v1 = {
  "kind": "Literal",
  "name": "gigTypes",
  "value": [
    "PAID",
    "VOLUNTEER"
  ]
},
v2 = {
  "kind": "Variable",
  "name": "maxDistance",
  "variableName": "maxDistance"
},
v3 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ShiftPreviewList_series"
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxDistance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftTopicPreview_worker",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "requiredReports",
              "value": [
                "IDENTITY_CHECK"
              ]
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "AvailableGigSeriesConnection",
      "kind": "LinkedField",
      "name": "availableGigSeries",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "availableEorGigSeries",
      "args": [
        (v0/*: any*/),
        {
          "fields": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "requiredReports",
              "value": [
                "EOR_WORKER"
              ]
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "AvailableGigSeriesConnection",
      "kind": "LinkedField",
      "name": "availableGigSeries",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};
})();

(node as any).hash = "9457fc577b4fad1b085d557075d80d70";

export default node;
