/**
 * @generated SignedSource<<6a0e5425b86e6b2e4bbcb497657ddd7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkerPayoutType = "INSTANT" | "STANDARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PriorTransfersRow_payout$data = {
  readonly amount: string;
  readonly engagements: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly endsAt: string | null | undefined;
        readonly gig: {
          readonly name: string | null | undefined;
        };
        readonly id: string;
        readonly paymentInfo: {
          readonly netPay: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly feeAmount: string | null | undefined;
  readonly id: string;
  readonly insertedAt: string;
  readonly providerDetails: {
    readonly arrivesAt: string | null | undefined;
    readonly automatic: boolean;
    readonly destination: {
      readonly __typename: string;
      readonly bankName?: string;
      readonly brand?: string;
      readonly last4: string;
    } | null | undefined;
    readonly method: string;
    readonly status: string;
  };
  readonly type: WorkerPayoutType;
  readonly " $fragmentType": "PriorTransfersRow_payout";
};
export type PriorTransfersRow_payout$key = {
  readonly " $data"?: PriorTransfersRow_payout$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriorTransfersRow_payout">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriorTransfersRow_payout",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feeAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insertedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkerPayoutProviderDetails",
      "kind": "LinkedField",
      "name": "providerDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "method",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "arrivesAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "automatic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "destination",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bankName",
                  "storageKey": null
                }
              ],
              "type": "BankAccount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "brand",
                  "storageKey": null
                }
              ],
              "type": "BankCard",
              "abstractKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last4",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        },
        {
          "kind": "Literal",
          "name": "query",
          "value": "WHERE gigType != PROJECT"
        }
      ],
      "concreteType": "EngagementsConnection",
      "kind": "LinkedField",
      "name": "engagements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Engagement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endsAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Gig",
                  "kind": "LinkedField",
                  "name": "gig",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EngagementPaymentInfo",
                  "kind": "LinkedField",
                  "name": "paymentInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "netPay",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "engagements(first:10,query:\"WHERE gigType != PROJECT\")"
    }
  ],
  "type": "WorkerPayout",
  "abstractKey": null
};
})();

(node as any).hash = "39ba2255cd71dc294a2c99e17785cabf";

export default node;
