import { Column, FooterSpacer, ScreenScroll, Spacer } from "@gigsmart/atorasu";
import { UnlimitedDistanceFeature } from "@gigsmart/feature-flags";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import PickupShiftTopicPreview from "@gigsmart/seibutsu/gig/worker/PickupShiftTopicPreview";
import ShiftTopicPreview from "@gigsmart/seibutsu/gig/worker/ShiftTopicPreview";
import JobBoardPreview from "@gigsmart/seibutsu/job-posts/worker/JobBoardPreview";
import useRetryOnFocus from "@gigsmart/seibutsu/nav/useRetryOnFocus";
import ProjectTopicPreview from "@gigsmart/seibutsu/project/worker/ProjectTopicPreview";
import React, { useMemo } from "react";
import { useGeolocation } from "../../geolocation";
import type {
  BrowseTabScreenQuery,
  OrderBy
} from "./__generated__/BrowseTabScreenQuery.graphql";
import BrowseTabHeader from "./browse-tab-header";

export default createSuspendedQueryContainer<BrowseTabScreenQuery>(
  function BrowseTabScreen({ response, retry }) {
    useRetryOnFocus(retry);

    const geolocation = useGeolocation(false);
    const stateName = response?.viewer?.currentEngagement?.currentState?.name;
    const address = geolocation.area;
    const hasCurrentEngagement =
      !!stateName &&
      !["PENDING_TIMESHEET_APPROVAL", "ENDED"].includes(stateName);

    return (
      <ScreenScroll testID="browse-tab">
        {hasCurrentEngagement && <Spacer />}
        <Column gap="standard">
          <BrowseTabHeader address={address} />
          <PickupShiftTopicPreview fragmentRef={response?.viewer} />
          <ShiftTopicPreview fragmentRef={response?.viewer} />
          <JobBoardPreview fragmentRef={response} />
          <ProjectTopicPreview fragmentRef={response?.viewer} />
        </Column>
        <FooterSpacer size="large" />
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query BrowseTabScreenQuery(
        $maxDistance: Int!
        $jobPostSearch: JobPostingSearch!
        $affiliateJobPostSearch: AffiliateJobPostingsInput!
      ) {
        ...JobBoardPreview_root
          @arguments(
            affiliateJobPostSearch: $affiliateJobPostSearch
            jobPostSearch: $jobPostSearch
          )
        viewer {
          ... on Worker {
            ...ShiftTopicPreview_worker @arguments(maxDistance: $maxDistance)
            ...ProjectTopicPreview_worker @arguments(maxDistance: $maxDistance)
            ...PickupShiftTopicPreview_worker @arguments(maxDistance: $maxDistance)
            currentEngagement {
              id
              currentState {
                name
              }
            }
          }
        }
      }
    `,
    variables: () => {
      const geolocation = useGeolocation(false);
      const maxDistance = UnlimitedDistanceFeature.select(12425, 75);

      return useMemo(
        () => ({
          maxDistance,
          jobPostSearch: {
            maxDistance,
            orderBy: [{ field: "boosted", direction: "DESC" }] as OrderBy[],
            latitude: geolocation.latitude,
            longitude: geolocation.longitude
          },
          affiliateJobPostSearch: {
            maxDistance,
            latitude: geolocation.latitude,
            longitude: geolocation.longitude
          }
        }),
        [geolocation, maxDistance]
      );
    }
  }
);
