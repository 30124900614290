import {
  Button,
  Column,
  Linking,
  TopicPreviewSurface
} from "@gigsmart/atorasu";
import { EORWorkers } from "@gigsmart/feature-flags";
import { type AppNavigationProp, useNavigation } from "@gigsmart/kaizoku";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import type { WorkerBrowseParams } from "../../browse/browse.nav";
import { showShiftGigInfoModal } from "../../modals/ShiftGigsInfoModalTabs";
import ShiftGigsEmptyView from "./ShiftGigsEmptyView";
import { SeriesShiftPreviewList } from "./ShiftPreviewList";
import type { ShiftTopicPreview_worker$key } from "./__generated__/ShiftTopicPreview_worker.graphql";

export const ShiftTopicPreview = ({
  availableGigSeries,
  availableEorGigSeries
}: FragmentContainerInnerComponentProps<ShiftTopicPreview_worker$key>) => {
  const navigation = useNavigation<AppNavigationProp<WorkerBrowseParams>>();
  const renderContent = (type: "shifts" | "eor") => {
    const { color, title, data, action } = {
      shifts: {
        title: "Shifts",
        color: "primary" as const,
        data: availableGigSeries,
        action: {
          icon: "circle-question" as const,
          onPress: showShiftGigInfoModal
        }
      },
      eor: {
        title: "W-2 Shifts",
        color: "purple" as const,
        variant: "purple",
        data: availableEorGigSeries,
        action: {
          icon: "arrow-up-right-from-square" as const,
          onPress: () =>
            Linking.openURL("https://help.gigsmart.com/get-gigs#w-2")
        }
      }
    }[type];

    return (
      <TopicPreviewSurface
        testID={`${type}-topic-preview`}
        iconName="business-time"
        color={color}
        title={title}
        subtitle={
          type === "eor"
            ? "Work quick on-demand available W-2 Shifts, ASAP or up to 30 days in the future. Track your hours easily in the app."
            : "Work quick on-demand available Shifts, ASAP or up to 30 days in the future. Track your hours and get paid through the app."
        }
        rightAccessory={
          <Button
            testID="learn-more-btn"
            size="xsmall"
            variant="clear"
            label="Learn More"
            color="primary"
            icon={action.icon}
            onPress={action.onPress}
          />
        }
      >
        {!data?.totalCount ? (
          <ShiftGigsEmptyView />
        ) : (
          <Column gap="standard">
            <SeriesShiftPreviewList fragmentRef={data} />
            <Button
              alignSelf="center"
              testID="view-all-shift-gigs-btn"
              size="small"
              outline
              label="View All Shifts"
              icon="chevron-right"
              iconPlacement="right"
              onPress={() => navigation.push("BrowseShifts", { type })}
            />
          </Column>
        )}
      </TopicPreviewSurface>
    );
  };

  return (
    <>
      {renderContent("shifts")}
      {EORWorkers.isEnabled() && renderContent("eor")}
    </>
  );
};

export default createRelayFragmentContainer<ShiftTopicPreview_worker$key>(
  graphql`
    fragment ShiftTopicPreview_worker on Worker
    @argumentDefinitions(maxDistance: { type: "Int" }) {
      availableGigSeries(
        first: 3
        input: {
          gigTypes: [PAID, VOLUNTEER],
          maxDistance: $maxDistance
          requiredReports: [IDENTITY_CHECK]
        }
      ) {
        ...ShiftPreviewList_series
        totalCount
      }
      availableEorGigSeries: availableGigSeries(
        first: 3
        input: {
          gigTypes: [PAID, VOLUNTEER]
          maxDistance: $maxDistance
          requiredReports: [EOR_WORKER]
        }
      ) {
        ...ShiftPreviewList_series
        totalCount
      }
    }
  `,
  ShiftTopicPreview
);
