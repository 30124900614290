/**
 * @generated SignedSource<<a49994ff4fa032c0a05ec99d4eab0c6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportType = "BACKGROUND_CHECK" | "CONTINUOUS_BACKGROUND_CHECK" | "CONTINUOUS_MOTOR_VEHICLE_CHECK" | "DRUG_SCREEN" | "EOR_ENGAGEMENT" | "EOR_WORKER" | "IDENTITY_CHECK" | "MOTOR_VEHICLE_CHECK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftDetailsCard_shift$data = {
  readonly crisis: {
    readonly title: string;
  } | null | undefined;
  readonly id: string;
  readonly requiredReportTypes: ReadonlyArray<ReportType>;
  readonly " $fragmentSpreads": FragmentRefs<"CategorySummaryRow_gigLike" | "GigPositionSummaryRow_gigLike" | "WorkerPaymentSummaryRow_shift" | "WorkerSummaryLocation_shift" | "WorkerVerificationSummaryRow_shift">;
  readonly " $fragmentType": "ShiftDetailsCard_shift";
};
export type ShiftDetailsCard_shift$key = {
  readonly " $data"?: ShiftDetailsCard_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDetailsCard_shift">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isBrowsing"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftDetailsCard_shift",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CategorySummaryRow_gigLike"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GigPositionSummaryRow_gigLike"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isBrowsing",
          "variableName": "isBrowsing"
        }
      ],
      "kind": "FragmentSpread",
      "name": "WorkerSummaryLocation_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerPaymentSummaryRow_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerVerificationSummaryRow_shift"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Crisis",
      "kind": "LinkedField",
      "name": "crisis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredReportTypes",
      "storageKey": null
    }
  ],
  "type": "GigLike",
  "abstractKey": "__isGigLike"
};

(node as any).hash = "8b491063453259434bbc8b57b11335ed";

export default node;
