/**
 * @generated SignedSource<<413a5a0a1a069a933140f4e62db867d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type YourEmployerCard_worker$data = {
  readonly id: string;
  readonly " $fragmentType": "YourEmployerCard_worker";
};
export type YourEmployerCard_worker$key = {
  readonly " $data"?: YourEmployerCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"YourEmployerCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "YourEmployerCard_worker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "6420b474c3c87a4f1a743d85d16cca53";

export default node;
