/**
 * @generated SignedSource<<23aec02eeb962eb72127f63c5e15a9d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectPreviewRow_project$data = {
  readonly id: string;
  readonly isAsap: boolean | null | undefined;
  readonly name: string | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly timezone: string | null | undefined;
  readonly " $fragmentType": "ProjectPreviewRow_project";
};
export type ProjectPreviewRow_project$key = {
  readonly " $data"?: ProjectPreviewRow_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectPreviewRow_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPreviewRow_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAsap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};

(node as any).hash = "432bbf593699cf58877110eb64769042";

export default node;
