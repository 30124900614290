import { currency } from "@gigsmart/isomorphic-shared/iso";
import { useHistory } from "@gigsmart/kaizoku";
import { ThreeColumnRow } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import moment from "moment-timezone";
import React from "react";
import { Dimensions } from "react-native";
import type { UnpaidEngagementRow_engagement$key } from "./__generated__/UnpaidEngagementRow_engagement.graphql";

interface Props {
  first?: boolean;
}

const { width } = Dimensions.get("window");
const dateText = width < 375 ? "Date:" : "Earnings Date:";

const EngagementRow = ({
  id,
  endsAt,
  gig,
  paymentInfo,
  first
}: FragmentContainerInnerComponentProps<
  UnpaidEngagementRow_engagement$key,
  Props
>) => {
  const history = useHistory();
  if (!id) return null;
  return (
    <ThreeColumnRow
      column1Title={gig?.name ?? ""}
      column1Subtitle={`${dateText} ${moment(endsAt ?? undefined).format(
        "MM/DD/YY"
      )}`}
      onLinkPress={() => history.push(`/gigs/${id}`)}
      column2Text="View Shift"
      column3Text={
        paymentInfo?.netPay ? currency.humanize(paymentInfo?.netPay) : "-"
      }
      first={first}
    />
  );
};

export default createRelayFragmentContainer(
  graphql`
  fragment UnpaidEngagementRow_engagement on Engagement {
    id
    endsAt
    gig {
      name
    }
    paymentInfo {
      netPay
    }
  }
`,
  EngagementRow
);
