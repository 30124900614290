import React from "react";

import {
  Button,
  ContentArea,
  type IconName,
  RadioButtonGroup,
  ScrollView,
  Stack,
  Text
} from "@gigsmart/atorasu";
import { VehicleTypeSelection } from "@gigsmart/feature-flags";
import {
  type FomuSubmitFn,
  Form,
  FormField,
  FormSubmit,
  Validator
} from "@gigsmart/fomu";

const TRANSPORTATION_OPTIONS: Array<{
  title: string;
  value: any;
  icon?: IconName;
  testID: string;
  eventTargetName: string;
}> = [
  {
    title: "Car",
    value: "car",
    icon: "car",
    testID: "car",
    eventTargetName: "Car Option Radio"
  },
  {
    title: "Public Transportation",
    value: "transit",
    icon: "bus",
    testID: "bus",
    eventTargetName: "Public Transportation Option Radio"
  },
  {
    title: "Bike",
    value: "bike",
    icon: "bicycle",
    testID: "bicycle",
    eventTargetName: "Bike Option Radio"
  },
  {
    title: "Walk",
    value: "walk",
    icon: "walking",
    testID: "walking",
    eventTargetName: "Walking Option Radio"
  }
];

interface Props {
  initialValues?: {
    transportationMethod: string | null | undefined;
    transportationState: string | null | undefined;
  };
  title?: string;
  subtitle?: string;
  submitLabel?: string;
  onSubmit: FomuSubmitFn;
}

const TransportationMethodForm = ({
  initialValues,
  title = "What is your primary method of transportation?",
  subtitle = "This can be changed at any time.",
  submitLabel = "Get Started",
  onSubmit
}: Props) => {
  return (
    <ScrollView testID="method-scroll">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          transportationMethod: initialValues?.transportationMethod ?? "car",
          transportationState:
            (VehicleTypeSelection.isEnabled() &&
              initialValues?.transportationState) ??
            null
        }}
      >
        <ContentArea fill>
          <Stack>
            <Text variant="header" color="primary">
              {title}
            </Text>
            <Text>{subtitle}</Text>
            <FormField
              name="transportationMethod"
              validates={Validator.presence()}
            >
              {({ value, setValue }) => (
                <RadioButtonGroup
                  value={value ?? initialValues?.transportationMethod}
                  onChange={setValue}
                  buttons={TRANSPORTATION_OPTIONS}
                />
              )}
            </FormField>
          </Stack>
        </ContentArea>
        <ContentArea>
          <FormSubmit>
            {({ invalid, submit, dirty }) => {
              return (
                <Button
                  label={submitLabel}
                  testID="submit-button"
                  disabled={invalid || !dirty}
                  onPress={submit}
                />
              );
            }}
          </FormSubmit>
        </ContentArea>
      </Form>
    </ScrollView>
  );
};

export default TransportationMethodForm;
