import {
  ContentArea,
  Divider,
  HighlightedReminder,
  IconText,
  Row,
  Surface,
  Tag
} from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { HourlyRateSummary } from "../gig/HourlyRateSummary";
import ShiftDescriptionHeader from "../gig/ShiftDescriptionHeader";
import ShiftGroupChatActionRow from "../gig/organization/ShiftGroupChatActionRow";
import ShiftOrgActionRow from "../gig/organization/ShiftOrgActionRow";
import EOREnrollmentStatus from "../worker/EOREnrollmentStatus";
import WorkerShiftApplets from "./WorkerShiftApplets";
import type { ActiveShiftHeader_engagement$key } from "./__generated__/ActiveShiftHeader_engagement.graphql";
import ShiftActions from "./accessory/ShiftActions";
import ShiftCommitmentReminder from "./accessory/ShiftCommitmentReminder";
import ShiftDescriptionItem from "./accessory/ShiftDescriptionItem";
import ShiftStatusTags from "./accessory/ShiftStatusTags";
import SingleShiftSummary from "./accessory/SingleShiftSummary";
import WorkerShiftStatusText from "./accessory/WorkerShiftStatusText";

type Props = {
  onRefreshLocation: () => void;
};

export const ActiveShiftHeader = ({
  gig,
  requiredReportTypes,
  currentState,
  commitment,
  result,
  onRefreshLocation
}: FragmentContainerInnerComponentProps<
  ActiveShiftHeader_engagement$key,
  Props
>) => {
  const user = useCurrentUser();
  const eorState = user?.verification?.reports?.find(
    ({ type }) => type === "EOR_WORKER"
  )?.state;
  const isEOR = requiredReportTypes?.includes?.("EOR_WORKER");
  const showParkingInfo =
    LocationUpdates.isEnabled() &&
    !!gig.organizationLocation?.parkingLocations?.totalCount;

  return (
    <Surface>
      <ContentArea gap="standard">
        <Row gap="standard" alignItems="center">
          <IconText
            icon="business-time"
            size="medium"
            iconAlign="center"
            textVariant="header"
            color={isEOR ? "purple" : "primary"}
            iconColor={isEOR ? "purple" : "primary"}
          >
            {gig.name}
          </IconText>
          {isEOR && (
            <Tag icon="passport" color="purple" label="W-2" variant="clear" />
          )}
        </Row>
        <ShiftStatusTags fragmentRef={result} />
        <WorkerShiftStatusText fragmentRef={result} />
        {isEOR && currentState.name === "CONFIRMING" && <EOREnrollmentStatus />}
        {isEOR && currentState.name === "APPLIED" && (
          <HourlyRateSummary
            variant="requested"
            currentState={currentState.name as any}
            payRate={gig?.payRate}
            labelOverride="W-2 Hourly Rate"
            isEOR={isEOR}
          />
        )}
        <ShiftDescriptionItem
          fragmentRef={result}
          showParkingInfo={showParkingInfo}
          showParkingReminder={showParkingInfo}
        />
        <ShiftDescriptionHeader fragmentRef={result} />
        <ShiftActions
          onRefreshLocation={onRefreshLocation}
          fragmentRef={result}
        />
        {isEOR &&
          ["OFFERED", "APPLIED"].includes(currentState.name) &&
          eorState !== "COMPLETE" && (
            <HighlightedReminder
              header="If you are offered this W-2 Shift, you can enroll to become a W-2 Employee."
              icon="circle-exclamation"
              variant="primary"
            />
          )}
        <ShiftCommitmentReminder fragmentRef={commitment} />
      </ContentArea>
      <Divider />
      <ShiftOrgActionRow fragmentRef={result}>
        <SingleShiftSummary
          fragmentRef={result}
          showAddress={[
            "BID_REQUESTED",
            "BID_REVIEW",
            "OFFERED",
            "APPLIED",
            "CONFIRMING"
          ].includes(currentState?.name)}
        />
        <ShiftGroupChatActionRow fragmentRef={gig} />
      </ShiftOrgActionRow>
      <WorkerShiftApplets showNote fragmentRef={result} />
    </Surface>
  );
};

export default createRelayFragmentContainer<
  ActiveShiftHeader_engagement$key,
  Props
>(
  graphql`
    fragment ActiveShiftHeader_engagement on Engagement {
      ...ShiftOrgActionRow_engagement
      ...SingleShiftSummary_engagement
      ...WorkerShiftStatusText_shift
      ...ShiftStatusTags_shift
      ...ShiftDescriptionHeader_availableGig
      ...ShiftActions_shift
      ...ShiftDescriptionItem_shift
      ...WorkerShiftApplets_shift
      currentState {
        name
      }
      requiredReportTypes
      gig {
        ...ShiftGroupChatActionRow_gig
        name
        organizationLocation {
          parkingLocations(first: 1) {
            totalCount
          }
        }
        payRate
      }
      commitment {
        ...ShiftCommitmentReminder_commitment
      }
    }
  `,
  ActiveShiftHeader
);
