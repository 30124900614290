import { Card } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import WorkerShiftCardBody from "./WorkerShiftCardBody";
import WorkerShiftCardFooter from "./WorkerShiftCardFooter";
import WorkerShiftCardHeader from "./WorkerShiftCardHeader";
import type { WorkerShiftCardBody_gig$key } from "./__generated__/WorkerShiftCardBody_gig.graphql";
import type { WorkerShiftCard_gigLike$key } from "./__generated__/WorkerShiftCard_gigLike.graphql";
import type { ShiftCardExtras } from "./extras";

interface Props extends ShiftCardExtras {
  shiftCount?: number;
  nextGig?: WorkerShiftCardBody_gig$key | null | undefined;
  onPress?: (id: string) => void;
  bodyHeader?: JSX.Element | null;
  headerAttachment?: JSX.Element | null;
  footerAttachment?: JSX.Element | null;
  footerAttachmentShadow?: boolean;
  isPickupElligible?: boolean;
  isEor?: boolean;
  showEorTag?: boolean;
}

export const WorkerShiftCard = ({
  __typename,
  id,
  estimatedMileage,
  distance,
  payRate,
  netPay,
  boosted,
  result,
  shiftCount = 1,
  nextGig,
  onPress,
  bodyHeader,
  headerAttachment,
  footerAttachment,
  footerAttachmentShadow = true,
  isPickupElligible,
  isEor,
  showEorTag = true
}: FragmentContainerInnerComponentProps<
  WorkerShiftCard_gigLike$key,
  Props
>) => {
  const handlePress = () => onPress?.(id);

  return (
    <Card
      testID={`worker-shift-card-${id}`}
      eventTargetName="Worker Shift Card"
      onPress={handlePress}
    >
      <WorkerShiftCardHeader
        shiftCount={shiftCount}
        fragmentRef={result}
        attachment={headerAttachment}
        isEor={showEorTag && isEor}
      />
      <WorkerShiftCardBody
        header={bodyHeader}
        boosted={boosted}
        distance={distance}
        shiftCount={shiftCount}
        fragmentRef={__typename === "Gig" ? result : nextGig ?? null}
        estimatedMileageOverride={estimatedMileage}
      />
      <WorkerShiftCardFooter
        fragmentRef={result}
        payRate={payRate}
        payRatePrefix={isPickupElligible ? "Pickup Rate:" : "Offered Rate:"}
        netPay={netPay}
        attachment={footerAttachment}
        attachmentShadow={footerAttachmentShadow}
        isEor={isEor}
      />
    </Card>
  );
};

export default createRelayFragmentContainer<WorkerShiftCard_gigLike$key, Props>(
  graphql`
    fragment WorkerShiftCard_gigLike on GigLike {
      __typename
      id
      estimatedMileage
      ...WorkerShiftCardHeader_gigLike
      ...WorkerShiftCardFooter_gigLike
      ...WorkerShiftCardBody_gig
      ... on GigSeries {
        boosted
      }
    }
  `,
  WorkerShiftCard
);
