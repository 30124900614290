/**
 * @generated SignedSource<<2ba0b369cbee9b0b6c83a037811cf3b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EarningsAndTransfersCard_worker$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WalletStats_worker">;
  readonly " $fragmentType": "EarningsAndTransfersCard_worker";
};
export type EarningsAndTransfersCard_worker$key = {
  readonly " $data"?: EarningsAndTransfersCard_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"EarningsAndTransfersCard_worker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EarningsAndTransfersCard_worker",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WalletStats_worker"
    }
  ],
  "type": "Worker",
  "abstractKey": null
};

(node as any).hash = "0bdfc0b79b34ff296ac81fe38b5e3e35";

export default node;
