/**
 * @generated SignedSource<<c4957f095c6c19722dc7fed6364796d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiDaySelectGigsStep_gig$data = {
  readonly actualStartsAt: string | null | undefined;
  readonly area: string | null | undefined;
  readonly endsAt: string | null | undefined;
  readonly id: string;
  readonly payRate: string | null | undefined;
  readonly startsAt: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerLatestArrivalTimeReminder_gig">;
  readonly " $fragmentType": "MultiDaySelectGigsStep_gig";
};
export type MultiDaySelectGigsStep_gig$key = {
  readonly " $data"?: MultiDaySelectGigsStep_gig$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiDaySelectGigsStep_gig">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "MultiDaySelectGigsStep_gig"
};

(node as any).hash = "86a078b67b38c4df6aa71a604f2846d3";

export default node;
