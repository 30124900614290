import {
  ScreenScroll,
  Spacer,
  StickyReminderFooter,
  Tabs
} from "@gigsmart/atorasu";
import { createSuspendedQueryContainer, graphql } from "@gigsmart/relay";
import EOREarningsTabContent from "@gigsmart/seibutsu/wallet/EOREarningsTabContent";
import ShiftEarningsTabContent from "@gigsmart/seibutsu/wallet/ShiftEarningsTabContent";
import UpcomingShiftReminder, {
  upcomingShiftReminderQuery
} from "@gigsmart/seibutsu/worker/UpcomingShiftReminder";
import React, { useState } from "react";
import type { workerWalletScreenQuery } from "./__generated__/workerWalletScreenQuery.graphql";

export default createSuspendedQueryContainer<workerWalletScreenQuery>(
  function WorkerWalletScreen({ response: result }) {
    const [activeTab, setActiveTab] = useState<"shift" | "eor">("shift");
    const worker = result?.viewer ?? null;
    const upcomingShiftCount = result?.viewer?.upcomingShiftCount?.totalCount;

    return (
      <ScreenScroll
        testID="scrollview-wallet"
        footer={!!upcomingShiftCount && <UpcomingShiftReminder />}
      >
        <Tabs
          activeTab={activeTab}
          onChangeTab={(key) => {
            setActiveTab(key);
          }}
          tabs={[
            {
              key: "shift",
              label: "SHIFT EARNINGS",
              testID: "shift-earnings-tab"
            },
            {
              key: "eor",
              label: "W-2 SHIFT EARNINGS",
              testID: "w-2-shift-earnings-tab"
            }
          ]}
        />
        <Spacer />
        {activeTab === "shift" && (
          <ShiftEarningsTabContent fragmentRef={worker} />
        )}
        {activeTab === "eor" && <EOREarningsTabContent fragmentRef={worker} />}
        {upcomingShiftCount ? (
          <StickyReminderFooter />
        ) : (
          <Spacer size="large" />
        )}
      </ScreenScroll>
    );
  },
  {
    query: graphql`
      query workerWalletScreenQuery(
        $upcomingShiftsQuery: String
      ) {
        viewer {
          ... on Worker {
            ...ShiftEarningsTabContent_worker
            ...EOREarningsTabContent_worker
            upcomingShiftCount: engagements(
              first: 0
              query: $upcomingShiftsQuery
            ) {
              totalCount
            }
          }
        }
      }
    `,
    variables: {
      upcomingShiftsQuery: upcomingShiftReminderQuery
    },
    fetchPolicy: "network-only"
  }
);
