import { ContentArea, FancyHeader, Surface, Tabs } from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React, { useState } from "react";
import PriorTransfers from "./PriorTransfers";
import WalletStats from "./WalletStats";
import type { EarningsAndTransfersCard_worker$key } from "./__generated__/EarningsAndTransfersCard_worker.graphql";

export function EarningsAndTransfersCard({
  result
}: FragmentContainerInnerComponentProps<
  EarningsAndTransfersCard_worker$key,
  {}
>) {
  const [activeTab, setActiveTab] = useState<"stats" | "prior_transfers">(
    "stats"
  );
  return (
    <Surface>
      <FancyHeader title="Earnings & Transfers" icon="chart-line" />
      <ContentArea>
        <Surface variant="outline">
          <Tabs
            activeTab={activeTab}
            onChangeTab={(key) => {
              setActiveTab(key);
            }}
            tabs={[
              {
                key: "stats",
                label: "STATS",
                testID: "stats-tab"
              },
              {
                key: "prior_transfers",
                label: "PRIOR TRANSFERS",
                testID: "prior-transfers-tab"
              }
            ]}
          />
          {activeTab === "stats" && <WalletStats fragmentRef={result} />}
          {activeTab === "prior_transfers" && <PriorTransfers />}
        </Surface>
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer(
  graphql`
  fragment EarningsAndTransfersCard_worker on Worker {
    ...WalletStats_worker
  }
  `,
  EarningsAndTransfersCard
);
